"use strict";
// source: pearls_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.jp.singcolor.PearlAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.PearlBalance', null, global);
goog.exportSymbol('proto.jp.singcolor.PearlBalanceInOutHistory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PearlAmount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PearlAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PearlAmount.displayName = 'proto.jp.singcolor.PearlAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PearlBalance = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PearlBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PearlBalance.displayName = 'proto.jp.singcolor.PearlBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PearlBalanceInOutHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PearlBalanceInOutHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PearlBalanceInOutHistory.displayName = 'proto.jp.singcolor.PearlBalanceInOutHistory';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PearlAmount.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PearlAmount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PearlAmount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PearlAmount.toObject = function (includeInstance, msg) {
        var f, obj = {
            redAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
            redAmountText: jspb.Message.getFieldWithDefault(msg, 2, ""),
            blueAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            blueAmountText: jspb.Message.getFieldWithDefault(msg, 4, ""),
            rainbowAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
            rainbowAmountText: jspb.Message.getFieldWithDefault(msg, 6, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.PearlAmount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PearlAmount;
    return proto.jp.singcolor.PearlAmount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PearlAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.PearlAmount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRedAmount(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedAmountText(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBlueAmount(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setBlueAmountText(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRainbowAmount(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setRainbowAmountText(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PearlAmount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PearlAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PearlAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PearlAmount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRedAmount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getRedAmountText();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getBlueAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getBlueAmountText();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getRainbowAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getRainbowAmountText();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
};
/**
 * optional int64 red_amount = 1;
 * @return {number}
 */
proto.jp.singcolor.PearlAmount.prototype.getRedAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setRedAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string red_amount_text = 2;
 * @return {string}
 */
proto.jp.singcolor.PearlAmount.prototype.getRedAmountText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setRedAmountText = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 blue_amount = 3;
 * @return {number}
 */
proto.jp.singcolor.PearlAmount.prototype.getBlueAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setBlueAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string blue_amount_text = 4;
 * @return {string}
 */
proto.jp.singcolor.PearlAmount.prototype.getBlueAmountText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setBlueAmountText = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 rainbow_amount = 5;
 * @return {number}
 */
proto.jp.singcolor.PearlAmount.prototype.getRainbowAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setRainbowAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string rainbow_amount_text = 6;
 * @return {string}
 */
proto.jp.singcolor.PearlAmount.prototype.getRainbowAmountText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PearlAmount} returns this
 */
proto.jp.singcolor.PearlAmount.prototype.setRainbowAmountText = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PearlBalance.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PearlBalance.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PearlBalance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PearlBalance.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlBalanceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            amount: (f = msg.getAmount()) && proto.jp.singcolor.PearlAmount.toObject(includeInstance, f),
            acquiredAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            expiresAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PearlBalance}
 */
proto.jp.singcolor.PearlBalance.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PearlBalance;
    return proto.jp.singcolor.PearlBalance.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PearlBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PearlBalance}
 */
proto.jp.singcolor.PearlBalance.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setPearlBalanceId(value);
                break;
            case 1:
                var value = new proto.jp.singcolor.PearlAmount;
                reader.readMessage(value, proto.jp.singcolor.PearlAmount.deserializeBinaryFromReader);
                msg.setAmount(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcquiredAt(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setExpiresAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PearlBalance.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PearlBalance.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PearlBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PearlBalance.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlBalanceId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getAmount();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getAcquiredAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getExpiresAt();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional string pearl_balance_id = 4;
 * @return {string}
 */
proto.jp.singcolor.PearlBalance.prototype.getPearlBalanceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PearlBalance} returns this
 */
proto.jp.singcolor.PearlBalance.prototype.setPearlBalanceId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional PearlAmount amount = 1;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.PearlBalance.prototype.getAmount = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.PearlAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.PearlBalance} returns this
*/
proto.jp.singcolor.PearlBalance.prototype.setAmount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PearlBalance} returns this
 */
proto.jp.singcolor.PearlBalance.prototype.clearAmount = function () {
    return this.setAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PearlBalance.prototype.hasAmount = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 acquired_at = 2;
 * @return {number}
 */
proto.jp.singcolor.PearlBalance.prototype.getAcquiredAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlBalance} returns this
 */
proto.jp.singcolor.PearlBalance.prototype.setAcquiredAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 expires_at = 3;
 * @return {number}
 */
proto.jp.singcolor.PearlBalance.prototype.getExpiresAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlBalance} returns this
 */
proto.jp.singcolor.PearlBalance.prototype.setExpiresAt = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PearlBalanceInOutHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PearlBalanceInOutHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PearlBalanceInOutHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PearlBalanceInOutHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            amount: (f = msg.getAmount()) && proto.jp.singcolor.PearlAmount.toObject(includeInstance, f),
            reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
            expiresAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PearlBalanceInOutHistory;
    return proto.jp.singcolor.PearlBalanceInOutHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PearlBalanceInOutHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.PearlAmount;
                reader.readMessage(value, proto.jp.singcolor.PearlAmount.deserializeBinaryFromReader);
                msg.setAmount(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setReason(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setExpiresAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PearlBalanceInOutHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PearlBalanceInOutHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PearlBalanceInOutHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAmount();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getReason();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getExpiresAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional PearlAmount amount = 1;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.getAmount = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.PearlAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory} returns this
*/
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.setAmount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory} returns this
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.clearAmount = function () {
    return this.setAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.hasAmount = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string reason = 2;
 * @return {string}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.getReason = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory} returns this
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.setReason = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory} returns this
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 expires_at = 4;
 * @return {number}
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.getExpiresAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory} returns this
 */
proto.jp.singcolor.PearlBalanceInOutHistory.prototype.setExpiresAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
goog.object.extend(exports, proto.jp.singcolor);
