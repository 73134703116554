/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_diamond_pb from './ope_diamond_pb';
var OpeDiamondV1Client = /** @class */ (function () {
    function OpeDiamondV1Client(hostname, credentials, options) {
        this.methodDescriptorListAcceptedWithdrawalDiamondLedgers = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListAcceptedWithdrawalDiamondLedgers', grpcWeb.MethodType.UNARY, ope_diamond_pb.ListAcceptedWithdrawalDiamondLedgersRequest, ope_diamond_pb.ListAcceptedWithdrawalDiamondLedgersResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ListAcceptedWithdrawalDiamondLedgersResponse.deserializeBinary);
        this.methodDescriptorApproveWithdrawalDiamondLedgers = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ApproveWithdrawalDiamondLedgers', grpcWeb.MethodType.UNARY, ope_diamond_pb.ApproveWithdrawalDiamondLedgersRequest, ope_diamond_pb.ApproveWithdrawalDiamondLedgersResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ApproveWithdrawalDiamondLedgersResponse.deserializeBinary);
        this.methodDescriptorListBankTransferRequests = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListBankTransferRequests', grpcWeb.MethodType.UNARY, ope_diamond_pb.ListBankTransferRequestsRequest, ope_diamond_pb.ListBankTransferRequestsResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ListBankTransferRequestsResponse.deserializeBinary);
        this.methodDescriptorCancelBankTransferRequest = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/CancelBankTransferRequest', grpcWeb.MethodType.UNARY, ope_diamond_pb.CancelBankTransferRequestRequest, ope_diamond_pb.CancelBankTransferRequestResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.CancelBankTransferRequestResponse.deserializeBinary);
        this.methodDescriptorGetUserBankAccountCreditCheckStatus = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/GetUserBankAccountCreditCheckStatus', grpcWeb.MethodType.UNARY, ope_diamond_pb.GetUserBankAccountCreditCheckStatusRequest, ope_diamond_pb.GetUserBankAccountCreditCheckStatusResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.GetUserBankAccountCreditCheckStatusResponse.deserializeBinary);
        this.methodDescriptorListUserBankAccountCreditCheckStatuses = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListUserBankAccountCreditCheckStatuses', grpcWeb.MethodType.UNARY, ope_diamond_pb.ListUserBankAccountCreditCheckStatusesRequest, ope_diamond_pb.ListUserBankAccountCreditCheckStatusesResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ListUserBankAccountCreditCheckStatusesResponse.deserializeBinary);
        this.methodDescriptorUpdateUserBankAccountCreditCheckStatus = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/UpdateUserBankAccountCreditCheckStatus', grpcWeb.MethodType.UNARY, ope_diamond_pb.UpdateUserBankAccountCreditCheckStatusRequest, ope_diamond_pb.UpdateUserBankAccountCreditCheckStatusResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.UpdateUserBankAccountCreditCheckStatusResponse.deserializeBinary);
        this.methodDescriptorListUserBankAccounts = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListUserBankAccounts', grpcWeb.MethodType.UNARY, ope_diamond_pb.ListUserBankAccountsRequest, ope_diamond_pb.ListUserBankAccountsResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ListUserBankAccountsResponse.deserializeBinary);
        this.methodDescriptorListRiskeyesArticleLinks = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListRiskeyesArticleLinks', grpcWeb.MethodType.UNARY, ope_diamond_pb.ListRiskeyesArticleLinksRequest, ope_diamond_pb.ListRiskeyesArticleLinksResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.ListRiskeyesArticleLinksResponse.deserializeBinary);
        this.methodDescriptorAddDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/AddDiamond', grpcWeb.MethodType.UNARY, ope_diamond_pb.OpeAddDiamondRequest, ope_diamond_pb.OpeAddDiamondResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.OpeAddDiamondResponse.deserializeBinary);
        this.methodDescriptorSubtractDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/SubtractDiamond', grpcWeb.MethodType.UNARY, ope_diamond_pb.OpeSubtractDiamondRequest, ope_diamond_pb.OpeSubtractDiamondResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.OpeSubtractDiamondResponse.deserializeBinary);
        this.methodDescriptorListStatements = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeDiamondV1/ListStatements', grpcWeb.MethodType.UNARY, ope_diamond_pb.OpeListStatementsRequest, ope_diamond_pb.OpeListStatementsResponse, function (request) {
            return request.serializeBinary();
        }, ope_diamond_pb.OpeListStatementsResponse.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeDiamondV1Client.prototype.listAcceptedWithdrawalDiamondLedgers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListAcceptedWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorListAcceptedWithdrawalDiamondLedgers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListAcceptedWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorListAcceptedWithdrawalDiamondLedgers);
    };
    OpeDiamondV1Client.prototype.approveWithdrawalDiamondLedgers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ApproveWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorApproveWithdrawalDiamondLedgers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ApproveWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorApproveWithdrawalDiamondLedgers);
    };
    OpeDiamondV1Client.prototype.listBankTransferRequests = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListBankTransferRequests', request, metadata || {}, this.methodDescriptorListBankTransferRequests, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListBankTransferRequests', request, metadata || {}, this.methodDescriptorListBankTransferRequests);
    };
    OpeDiamondV1Client.prototype.cancelBankTransferRequest = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/CancelBankTransferRequest', request, metadata || {}, this.methodDescriptorCancelBankTransferRequest, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/CancelBankTransferRequest', request, metadata || {}, this.methodDescriptorCancelBankTransferRequest);
    };
    OpeDiamondV1Client.prototype.getUserBankAccountCreditCheckStatus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/GetUserBankAccountCreditCheckStatus', request, metadata || {}, this.methodDescriptorGetUserBankAccountCreditCheckStatus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/GetUserBankAccountCreditCheckStatus', request, metadata || {}, this.methodDescriptorGetUserBankAccountCreditCheckStatus);
    };
    OpeDiamondV1Client.prototype.listUserBankAccountCreditCheckStatuses = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListUserBankAccountCreditCheckStatuses', request, metadata || {}, this.methodDescriptorListUserBankAccountCreditCheckStatuses, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListUserBankAccountCreditCheckStatuses', request, metadata || {}, this.methodDescriptorListUserBankAccountCreditCheckStatuses);
    };
    OpeDiamondV1Client.prototype.updateUserBankAccountCreditCheckStatus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/UpdateUserBankAccountCreditCheckStatus', request, metadata || {}, this.methodDescriptorUpdateUserBankAccountCreditCheckStatus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/UpdateUserBankAccountCreditCheckStatus', request, metadata || {}, this.methodDescriptorUpdateUserBankAccountCreditCheckStatus);
    };
    OpeDiamondV1Client.prototype.listUserBankAccounts = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListUserBankAccounts', request, metadata || {}, this.methodDescriptorListUserBankAccounts, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListUserBankAccounts', request, metadata || {}, this.methodDescriptorListUserBankAccounts);
    };
    OpeDiamondV1Client.prototype.listRiskeyesArticleLinks = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListRiskeyesArticleLinks', request, metadata || {}, this.methodDescriptorListRiskeyesArticleLinks, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListRiskeyesArticleLinks', request, metadata || {}, this.methodDescriptorListRiskeyesArticleLinks);
    };
    OpeDiamondV1Client.prototype.addDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/AddDiamond', request, metadata || {}, this.methodDescriptorAddDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/AddDiamond', request, metadata || {}, this.methodDescriptorAddDiamond);
    };
    OpeDiamondV1Client.prototype.subtractDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/SubtractDiamond', request, metadata || {}, this.methodDescriptorSubtractDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/SubtractDiamond', request, metadata || {}, this.methodDescriptorSubtractDiamond);
    };
    OpeDiamondV1Client.prototype.listStatements = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeDiamondV1/ListStatements', request, metadata || {}, this.methodDescriptorListStatements, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeDiamondV1/ListStatements', request, metadata || {}, this.methodDescriptorListStatements);
    };
    return OpeDiamondV1Client;
}());
export { OpeDiamondV1Client };
