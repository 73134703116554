"use strict";
// source: leagues_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.League', null, global);
goog.exportSymbol('proto.LeagueType', null, global);
goog.exportSymbol('proto.LiverPromotionMeter', null, global);
goog.exportSymbol('proto.LiverPromotionMeterStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.League = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.League, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.League.displayName = 'proto.League';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiverPromotionMeter = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiverPromotionMeter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiverPromotionMeter.displayName = 'proto.LiverPromotionMeter';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.League.prototype.toObject = function (opt_includeInstance) {
        return proto.League.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.League} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.League.toObject = function (includeInstance, msg) {
        var f, obj = {
            leagueType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            leagueTypeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            leagueId: jspb.Message.getFieldWithDefault(msg, 4, 0),
            chatDiamondUnitPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
            singDiamondUnitPrice: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.League}
 */
proto.League.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.League;
    return proto.League.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.League} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.League}
 */
proto.League.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.LeagueType} */ (reader.readEnum());
                msg.setLeagueType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLeagueTypeName(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueId(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setChatDiamondUnitPrice(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingDiamondUnitPrice(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.League.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.League.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.League} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.League.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLeagueType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getLeagueTypeName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLeagueId();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getChatDiamondUnitPrice();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSingDiamondUnitPrice();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};
/**
 * optional LeagueType league_type = 1;
 * @return {!proto.LeagueType}
 */
proto.League.prototype.getLeagueType = function () {
    return /** @type {!proto.LeagueType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.LeagueType} value
 * @return {!proto.League} returns this
 */
proto.League.prototype.setLeagueType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string league_type_name = 2;
 * @return {string}
 */
proto.League.prototype.getLeagueTypeName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.League} returns this
 */
proto.League.prototype.setLeagueTypeName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 league_id = 4;
 * @return {number}
 */
proto.League.prototype.getLeagueId = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.League} returns this
 */
proto.League.prototype.setLeagueId = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 chat_diamond_unit_price = 5;
 * @return {number}
 */
proto.League.prototype.getChatDiamondUnitPrice = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.League} returns this
 */
proto.League.prototype.setChatDiamondUnitPrice = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 sing_diamond_unit_price = 6;
 * @return {number}
 */
proto.League.prototype.getSingDiamondUnitPrice = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.League} returns this
 */
proto.League.prototype.setSingDiamondUnitPrice = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiverPromotionMeter.prototype.toObject = function (opt_includeInstance) {
        return proto.LiverPromotionMeter.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiverPromotionMeter} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiverPromotionMeter.toObject = function (includeInstance, msg) {
        var f, obj = {
            league: (f = msg.getLeague()) && proto.League.toObject(includeInstance, f),
            exceedingExp: jspb.Message.getFieldWithDefault(msg, 19, 0),
            day: jspb.Message.getFieldWithDefault(msg, 2, 0),
            leagueUpAnotherPoint: jspb.Message.getFieldWithDefault(msg, 3, 0),
            closingTimeJst: jspb.Message.getFieldWithDefault(msg, 4, 0),
            acquiredLiveScore: jspb.Message.getFieldWithDefault(msg, 5, 0),
            acquiredPoint: jspb.Message.getFieldWithDefault(msg, 6, 0),
            borderLiveScore1: jspb.Message.getFieldWithDefault(msg, 7, 0),
            borderLiveScore2: jspb.Message.getFieldWithDefault(msg, 8, 0),
            borderLiveScore3: jspb.Message.getFieldWithDefault(msg, 9, 0),
            no1LiveScore: jspb.Message.getFieldWithDefault(msg, 10, 0),
            promotionThresholdLiveScore1: jspb.Message.getFieldWithDefault(msg, 11, 0),
            promotionThresholdLiveScore2: jspb.Message.getFieldWithDefault(msg, 12, 0),
            promotionThresholdLiveScore3: jspb.Message.getFieldWithDefault(msg, 13, 0),
            liverPromotionMeterStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
            border1League: (f = msg.getBorder1League()) && proto.League.toObject(includeInstance, f),
            border2League: (f = msg.getBorder2League()) && proto.League.toObject(includeInstance, f),
            border3League: (f = msg.getBorder3League()) && proto.League.toObject(includeInstance, f),
            leagueUpHelpText: jspb.Message.getFieldWithDefault(msg, 18, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiverPromotionMeter}
 */
proto.LiverPromotionMeter.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiverPromotionMeter;
    return proto.LiverPromotionMeter.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiverPromotionMeter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiverPromotionMeter}
 */
proto.LiverPromotionMeter.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.League;
                reader.readMessage(value, proto.League.deserializeBinaryFromReader);
                msg.setLeague(value);
                break;
            case 19:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setExceedingExp(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDay(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLeagueUpAnotherPoint(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setClosingTimeJst(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcquiredLiveScore(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcquiredPoint(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore1(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore2(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderLiveScore3(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNo1LiveScore(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPromotionThresholdLiveScore1(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPromotionThresholdLiveScore2(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPromotionThresholdLiveScore3(value);
                break;
            case 14:
                var value = /** @type {!proto.LiverPromotionMeterStatus} */ (reader.readEnum());
                msg.setLiverPromotionMeterStatus(value);
                break;
            case 15:
                var value = new proto.League;
                reader.readMessage(value, proto.League.deserializeBinaryFromReader);
                msg.setBorder1League(value);
                break;
            case 16:
                var value = new proto.League;
                reader.readMessage(value, proto.League.deserializeBinaryFromReader);
                msg.setBorder2League(value);
                break;
            case 17:
                var value = new proto.League;
                reader.readMessage(value, proto.League.deserializeBinaryFromReader);
                msg.setBorder3League(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());
                msg.setLeagueUpHelpText(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiverPromotionMeter.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiverPromotionMeter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiverPromotionMeter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiverPromotionMeter.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLeague();
    if (f != null) {
        writer.writeMessage(1, f, proto.League.serializeBinaryToWriter);
    }
    f = message.getExceedingExp();
    if (f !== 0) {
        writer.writeInt64(19, f);
    }
    f = message.getDay();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getLeagueUpAnotherPoint();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getClosingTimeJst();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getAcquiredLiveScore();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getAcquiredPoint();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getBorderLiveScore1();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getBorderLiveScore2();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getBorderLiveScore3();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getNo1LiveScore();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getPromotionThresholdLiveScore1();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
    f = message.getPromotionThresholdLiveScore2();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getPromotionThresholdLiveScore3();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getLiverPromotionMeterStatus();
    if (f !== 0.0) {
        writer.writeEnum(14, f);
    }
    f = message.getBorder1League();
    if (f != null) {
        writer.writeMessage(15, f, proto.League.serializeBinaryToWriter);
    }
    f = message.getBorder2League();
    if (f != null) {
        writer.writeMessage(16, f, proto.League.serializeBinaryToWriter);
    }
    f = message.getBorder3League();
    if (f != null) {
        writer.writeMessage(17, f, proto.League.serializeBinaryToWriter);
    }
    f = message.getLeagueUpHelpText();
    if (f.length > 0) {
        writer.writeString(18, f);
    }
};
/**
 * optional League league = 1;
 * @return {?proto.League}
 */
proto.LiverPromotionMeter.prototype.getLeague = function () {
    return /** @type{?proto.League} */ (jspb.Message.getWrapperField(this, proto.League, 1));
};
/**
 * @param {?proto.League|undefined} value
 * @return {!proto.LiverPromotionMeter} returns this
*/
proto.LiverPromotionMeter.prototype.setLeague = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.clearLeague = function () {
    return this.setLeague(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverPromotionMeter.prototype.hasLeague = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 exceeding_exp = 19;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getExceedingExp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setExceedingExp = function (value) {
    return jspb.Message.setProto3IntField(this, 19, value);
};
/**
 * optional int64 day = 2;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setDay = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 league_up_another_point = 3;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getLeagueUpAnotherPoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setLeagueUpAnotherPoint = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 closing_time_jst = 4;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getClosingTimeJst = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setClosingTimeJst = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 acquired_live_score = 5;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getAcquiredLiveScore = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setAcquiredLiveScore = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 acquired_point = 6;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getAcquiredPoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setAcquiredPoint = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 border_live_score1 = 7;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getBorderLiveScore1 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setBorderLiveScore1 = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 border_live_score2 = 8;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getBorderLiveScore2 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setBorderLiveScore2 = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 border_live_score3 = 9;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getBorderLiveScore3 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setBorderLiveScore3 = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 no1_live_score = 10;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getNo1LiveScore = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setNo1LiveScore = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 promotion_threshold_live_score1 = 11;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getPromotionThresholdLiveScore1 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setPromotionThresholdLiveScore1 = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional int64 promotion_threshold_live_score2 = 12;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getPromotionThresholdLiveScore2 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setPromotionThresholdLiveScore2 = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional int64 promotion_threshold_live_score3 = 13;
 * @return {number}
 */
proto.LiverPromotionMeter.prototype.getPromotionThresholdLiveScore3 = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setPromotionThresholdLiveScore3 = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional LiverPromotionMeterStatus liver_promotion_meter_status = 14;
 * @return {!proto.LiverPromotionMeterStatus}
 */
proto.LiverPromotionMeter.prototype.getLiverPromotionMeterStatus = function () {
    return /** @type {!proto.LiverPromotionMeterStatus} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {!proto.LiverPromotionMeterStatus} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setLiverPromotionMeterStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 14, value);
};
/**
 * optional League border1_league = 15;
 * @return {?proto.League}
 */
proto.LiverPromotionMeter.prototype.getBorder1League = function () {
    return /** @type{?proto.League} */ (jspb.Message.getWrapperField(this, proto.League, 15));
};
/**
 * @param {?proto.League|undefined} value
 * @return {!proto.LiverPromotionMeter} returns this
*/
proto.LiverPromotionMeter.prototype.setBorder1League = function (value) {
    return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.clearBorder1League = function () {
    return this.setBorder1League(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverPromotionMeter.prototype.hasBorder1League = function () {
    return jspb.Message.getField(this, 15) != null;
};
/**
 * optional League border2_league = 16;
 * @return {?proto.League}
 */
proto.LiverPromotionMeter.prototype.getBorder2League = function () {
    return /** @type{?proto.League} */ (jspb.Message.getWrapperField(this, proto.League, 16));
};
/**
 * @param {?proto.League|undefined} value
 * @return {!proto.LiverPromotionMeter} returns this
*/
proto.LiverPromotionMeter.prototype.setBorder2League = function (value) {
    return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.clearBorder2League = function () {
    return this.setBorder2League(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverPromotionMeter.prototype.hasBorder2League = function () {
    return jspb.Message.getField(this, 16) != null;
};
/**
 * optional League border3_league = 17;
 * @return {?proto.League}
 */
proto.LiverPromotionMeter.prototype.getBorder3League = function () {
    return /** @type{?proto.League} */ (jspb.Message.getWrapperField(this, proto.League, 17));
};
/**
 * @param {?proto.League|undefined} value
 * @return {!proto.LiverPromotionMeter} returns this
*/
proto.LiverPromotionMeter.prototype.setBorder3League = function (value) {
    return jspb.Message.setWrapperField(this, 17, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.clearBorder3League = function () {
    return this.setBorder3League(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverPromotionMeter.prototype.hasBorder3League = function () {
    return jspb.Message.getField(this, 17) != null;
};
/**
 * optional string league_up_help_text = 18;
 * @return {string}
 */
proto.LiverPromotionMeter.prototype.getLeagueUpHelpText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiverPromotionMeter} returns this
 */
proto.LiverPromotionMeter.prototype.setLeagueUpHelpText = function (value) {
    return jspb.Message.setProto3StringField(this, 18, value);
};
/**
 * @enum {number}
 */
proto.LeagueType = {
    LEAGUE_TYPE_UNKNOWN: 0,
    LEAGUE_TYPE_ENJOY: 1,
    LEAGUE_TYPE_TOWN: 2,
    LEAGUE_TYPE_PREFECTURE: 3,
    LEAGUE_TYPE_REGION: 4,
    LEAGUE_TYPE_CARDINAL_DIRECTION: 5,
    LEAGUE_TYPE_COUNTRY: 6,
    LEAGUE_TYPE_ONI: 7
};
/**
 * @enum {number}
 */
proto.LiverPromotionMeterStatus = {
    HIDE_METER: 0,
    HIDE_BORDER_VALUE: 1,
    SHOW_BORDER_VALUE: 2,
    CALCULATING: 3,
    CONFIRMED: 4
};
goog.object.extend(exports, proto);
