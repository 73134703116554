"use strict";
// source: tsunagu_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var pearls_entity_pb = require('./pearls_entity_pb.js');
goog.object.extend(proto, pearls_entity_pb);
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
goog.exportSymbol('proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTransactionInfoV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetTransactionInfoV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.displayName = 'proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.displayName = 'proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTransactionInfoV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTransactionInfoV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTransactionInfoV1Request.displayName = 'proto.jp.singcolor.GetTransactionInfoV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetTransactionInfoV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetTransactionInfoV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetTransactionInfoV1Response.displayName = 'proto.jp.singcolor.GetTransactionInfoV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            price: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request;
    return proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setPrice(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrice();
    if (f !== 0) {
        writer.writeUint64(1, f);
    }
};
/**
 * optional uint64 price = 1;
 * @return {number}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.prototype.getPrice = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request} returns this
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request.prototype.setPrice = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response;
    return proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRedirectUrl();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.prototype.getRedirectUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response} returns this
 */
proto.jp.singcolor.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.prototype.setRedirectUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTransactionInfoV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTransactionInfoV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTransactionInfoV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTransactionInfoV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            paymentId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Request}
 */
proto.jp.singcolor.GetTransactionInfoV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTransactionInfoV1Request;
    return proto.jp.singcolor.GetTransactionInfoV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTransactionInfoV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Request}
 */
proto.jp.singcolor.GetTransactionInfoV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPaymentId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTransactionInfoV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTransactionInfoV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTransactionInfoV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTransactionInfoV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPaymentId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string payment_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetTransactionInfoV1Request.prototype.getPaymentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Request} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Request.prototype.setPaymentId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetTransactionInfoV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetTransactionInfoV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetTransactionInfoV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetTransactionInfoV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            paymentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pearlBalanceTotal: (f = msg.getPearlBalanceTotal()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            itemPrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
            redirectUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetTransactionInfoV1Response;
    return proto.jp.singcolor.GetTransactionInfoV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetTransactionInfoV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPaymentId(value);
                break;
            case 2:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlBalanceTotal(value);
                break;
            case 3:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setItemPrice(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setRedirectUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetTransactionInfoV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetTransactionInfoV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetTransactionInfoV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPaymentId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPearlBalanceTotal();
    if (f != null) {
        writer.writeMessage(2, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(3, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getItemPrice();
    if (f !== 0) {
        writer.writeUint64(4, f);
    }
    f = message.getRedirectUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional string payment_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.getPaymentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.setPaymentId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional PearlAmount pearl_balance_total = 2;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.getPearlBalanceTotal = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
*/
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.setPearlBalanceTotal = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.clearPearlBalanceTotal = function () {
    return this.setPearlBalanceTotal(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.hasPearlBalanceTotal = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional DiamondBalance diamond_balance = 3;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 3));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
*/
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional uint64 item_price = 4;
 * @return {number}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.getItemPrice = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.setItemPrice = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string redirect_url = 5;
 * @return {string}
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.getRedirectUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetTransactionInfoV1Response} returns this
 */
proto.jp.singcolor.GetTransactionInfoV1Response.prototype.setRedirectUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
goog.object.extend(exports, proto.jp.singcolor);
