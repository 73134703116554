"use strict";
// source: debug_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
goog.exportSymbol('proto.jp.singcolor.CoinPurchaseFailureMode', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugDiamondBalanceInOutHistory', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugFaveBonusSource', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugFavePointSource', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugListenerLiveActivities', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugLiveScoreSource', null, global);
goog.exportSymbol('proto.jp.singcolor.DebugLiverLiveActivities', null, global);
goog.exportSymbol('proto.jp.singcolor.ForceSuperLikeBonusCoinSetting', null, global);
goog.exportSymbol('proto.jp.singcolor.SingHistory', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugLiverLiveActivities = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugLiverLiveActivities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugLiverLiveActivities.displayName = 'proto.jp.singcolor.DebugLiverLiveActivities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugListenerLiveActivities = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugListenerLiveActivities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugListenerLiveActivities.displayName = 'proto.jp.singcolor.DebugListenerLiveActivities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugLiveScoreSource = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugLiveScoreSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugLiveScoreSource.displayName = 'proto.jp.singcolor.DebugLiveScoreSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugFavePointSource = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugFavePointSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugFavePointSource.displayName = 'proto.jp.singcolor.DebugFavePointSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugFaveBonusSource = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugFaveBonusSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugFaveBonusSource.displayName = 'proto.jp.singcolor.DebugFaveBonusSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SingHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SingHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SingHistory.displayName = 'proto.jp.singcolor.SingHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DebugDiamondBalanceInOutHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DebugDiamondBalanceInOutHistory.displayName = 'proto.jp.singcolor.DebugDiamondBalanceInOutHistory';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugLiverLiveActivities.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugLiverLiveActivities.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugLiverLiveActivities} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugLiverLiveActivities.toObject = function (includeInstance, msg) {
        var f, obj = {
            dayOfLiverTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
            favePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            faveBonusRate: jspb.Message.getFieldWithDefault(msg, 4, ""),
            liveScoreSource: (f = msg.getLiveScoreSource()) && proto.jp.singcolor.DebugLiveScoreSource.toObject(includeInstance, f),
            faveBonusSource: (f = msg.getFaveBonusSource()) && proto.jp.singcolor.DebugFaveBonusSource.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities}
 */
proto.jp.singcolor.DebugLiverLiveActivities.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugLiverLiveActivities;
    return proto.jp.singcolor.DebugLiverLiveActivities.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugLiverLiveActivities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities}
 */
proto.jp.singcolor.DebugLiverLiveActivities.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDayOfLiverTerm(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLiveScore(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePoints(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusRate(value);
                break;
            case 5:
                var value = new proto.jp.singcolor.DebugLiveScoreSource;
                reader.readMessage(value, proto.jp.singcolor.DebugLiveScoreSource.deserializeBinaryFromReader);
                msg.setLiveScoreSource(value);
                break;
            case 7:
                var value = new proto.jp.singcolor.DebugFaveBonusSource;
                reader.readMessage(value, proto.jp.singcolor.DebugFaveBonusSource.deserializeBinaryFromReader);
                msg.setFaveBonusSource(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugLiverLiveActivities.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugLiverLiveActivities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugLiverLiveActivities.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDayOfLiverTerm();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveScore();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getFavePoints();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getLiveScoreSource();
    if (f != null) {
        writer.writeMessage(5, f, proto.jp.singcolor.DebugLiveScoreSource.serializeBinaryToWriter);
    }
    f = message.getFaveBonusSource();
    if (f != null) {
        writer.writeMessage(7, f, proto.jp.singcolor.DebugFaveBonusSource.serializeBinaryToWriter);
    }
};
/**
 * optional string day_of_liver_term = 1;
 * @return {string}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getDayOfLiverTerm = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setDayOfLiverTerm = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 live_score = 2;
 * @return {number}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getLiveScore = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setLiveScore = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 fave_points = 3;
 * @return {number}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string fave_bonus_rate = 4;
 * @return {string}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional DebugLiveScoreSource live_score_source = 5;
 * @return {?proto.jp.singcolor.DebugLiveScoreSource}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getLiveScoreSource = function () {
    return /** @type{?proto.jp.singcolor.DebugLiveScoreSource} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.DebugLiveScoreSource, 5));
};
/**
 * @param {?proto.jp.singcolor.DebugLiveScoreSource|undefined} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
*/
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setLiveScoreSource = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.clearLiveScoreSource = function () {
    return this.setLiveScoreSource(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.hasLiveScoreSource = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional DebugFaveBonusSource fave_bonus_source = 7;
 * @return {?proto.jp.singcolor.DebugFaveBonusSource}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.getFaveBonusSource = function () {
    return /** @type{?proto.jp.singcolor.DebugFaveBonusSource} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.DebugFaveBonusSource, 7));
};
/**
 * @param {?proto.jp.singcolor.DebugFaveBonusSource|undefined} value
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
*/
proto.jp.singcolor.DebugLiverLiveActivities.prototype.setFaveBonusSource = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DebugLiverLiveActivities} returns this
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.clearFaveBonusSource = function () {
    return this.setFaveBonusSource(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DebugLiverLiveActivities.prototype.hasFaveBonusSource = function () {
    return jspb.Message.getField(this, 7) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugListenerLiveActivities.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugListenerLiveActivities.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugListenerLiveActivities} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugListenerLiveActivities.toObject = function (includeInstance, msg) {
        var f, obj = {
            dayOfLiverTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
            termlyFavePoint: jspb.Message.getFieldWithDefault(msg, 2, 0),
            monthlyFavePoint: jspb.Message.getFieldWithDefault(msg, 3, 0),
            pfFavePoint: jspb.Message.getFieldWithDefault(msg, 4, 0),
            faveBadge: jspb.Message.getFieldWithDefault(msg, 5, ""),
            songFave: jspb.Message.getFieldWithDefault(msg, 6, ""),
            numberOfGettingSongFaveClass: jspb.Message.getFieldWithDefault(msg, 7, 0),
            songFaveNumberOfMonthsInRow: jspb.Message.getFieldWithDefault(msg, 8, 0),
            favePointSource: (f = msg.getFavePointSource()) && proto.jp.singcolor.DebugFavePointSource.toObject(includeInstance, f),
            isFirstFaveBadge: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities}
 */
proto.jp.singcolor.DebugListenerLiveActivities.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugListenerLiveActivities;
    return proto.jp.singcolor.DebugListenerLiveActivities.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugListenerLiveActivities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities}
 */
proto.jp.singcolor.DebugListenerLiveActivities.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDayOfLiverTerm(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoint(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoint(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoint(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBadge(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongFave(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfGettingSongFaveClass(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSongFaveNumberOfMonthsInRow(value);
                break;
            case 9:
                var value = new proto.jp.singcolor.DebugFavePointSource;
                reader.readMessage(value, proto.jp.singcolor.DebugFavePointSource.deserializeBinaryFromReader);
                msg.setFavePointSource(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFirstFaveBadge(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugListenerLiveActivities.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugListenerLiveActivities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugListenerLiveActivities.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDayOfLiverTerm();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTermlyFavePoint();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonthlyFavePoint();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getPfFavePoint();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFaveBadge();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getSongFave();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getNumberOfGettingSongFaveClass();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getSongFaveNumberOfMonthsInRow();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getFavePointSource();
    if (f != null) {
        writer.writeMessage(9, f, proto.jp.singcolor.DebugFavePointSource.serializeBinaryToWriter);
    }
    f = message.getIsFirstFaveBadge();
    if (f) {
        writer.writeBool(10, f);
    }
};
/**
 * optional string day_of_liver_term = 1;
 * @return {string}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getDayOfLiverTerm = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setDayOfLiverTerm = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 termly_fave_point = 2;
 * @return {number}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getTermlyFavePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setTermlyFavePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 monthly_fave_point = 3;
 * @return {number}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getMonthlyFavePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setMonthlyFavePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 pf_fave_point = 4;
 * @return {number}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getPfFavePoint = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setPfFavePoint = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string fave_badge = 5;
 * @return {string}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getFaveBadge = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setFaveBadge = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string song_fave = 6;
 * @return {string}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getSongFave = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setSongFave = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional int64 number_of_getting_song_fave_class = 7;
 * @return {number}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getNumberOfGettingSongFaveClass = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setNumberOfGettingSongFaveClass = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 song_fave_number_of_months_in_row = 8;
 * @return {number}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getSongFaveNumberOfMonthsInRow = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setSongFaveNumberOfMonthsInRow = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional DebugFavePointSource fave_point_source = 9;
 * @return {?proto.jp.singcolor.DebugFavePointSource}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getFavePointSource = function () {
    return /** @type{?proto.jp.singcolor.DebugFavePointSource} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.DebugFavePointSource, 9));
};
/**
 * @param {?proto.jp.singcolor.DebugFavePointSource|undefined} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
*/
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setFavePointSource = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.clearFavePointSource = function () {
    return this.setFavePointSource(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.hasFavePointSource = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional bool is_first_fave_badge = 10;
 * @return {boolean}
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.getIsFirstFaveBadge = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.DebugListenerLiveActivities} returns this
 */
proto.jp.singcolor.DebugListenerLiveActivities.prototype.setIsFirstFaveBadge = function (value) {
    return jspb.Message.setProto3BooleanField(this, 10, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugLiveScoreSource.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugLiveScoreSource.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugLiveScoreSource} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugLiveScoreSource.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinEffect: jspb.Message.getFieldWithDefault(msg, 1, 0),
            activityScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
            debuffByDepopulation: jspb.Message.getFieldWithDefault(msg, 3, ""),
            debuffByTooFewSongs: jspb.Message.getFieldWithDefault(msg, 4, ""),
            debuffCoinEffectIfTooMuchFreeRate: jspb.Message.getFieldWithDefault(msg, 5, ""),
            debuffCoinEffectIfTooMuchNotPure: jspb.Message.getFieldWithDefault(msg, 6, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugLiveScoreSource}
 */
proto.jp.singcolor.DebugLiveScoreSource.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugLiveScoreSource;
    return proto.jp.singcolor.DebugLiveScoreSource.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugLiveScoreSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugLiveScoreSource}
 */
proto.jp.singcolor.DebugLiveScoreSource.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinEffect(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setActivityScore(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDebuffByDepopulation(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setDebuffByTooFewSongs(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setDebuffCoinEffectIfTooMuchFreeRate(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setDebuffCoinEffectIfTooMuchNotPure(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugLiveScoreSource.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugLiveScoreSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugLiveScoreSource.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinEffect();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getActivityScore();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getDebuffByDepopulation();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDebuffByTooFewSongs();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getDebuffCoinEffectIfTooMuchFreeRate();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getDebuffCoinEffectIfTooMuchNotPure();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
};
/**
 * optional int64 coin_effect = 1;
 * @return {number}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getCoinEffect = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setCoinEffect = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 activity_score = 2;
 * @return {number}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getActivityScore = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setActivityScore = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string debuff_by_depopulation = 3;
 * @return {string}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getDebuffByDepopulation = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setDebuffByDepopulation = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string debuff_by_too_few_songs = 4;
 * @return {string}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getDebuffByTooFewSongs = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setDebuffByTooFewSongs = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string debuff_coin_effect_if_too_much_free_rate = 5;
 * @return {string}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getDebuffCoinEffectIfTooMuchFreeRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setDebuffCoinEffectIfTooMuchFreeRate = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string debuff_coin_effect_if_too_much_not_pure = 6;
 * @return {string}
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.getDebuffCoinEffectIfTooMuchNotPure = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugLiveScoreSource} returns this
 */
proto.jp.singcolor.DebugLiveScoreSource.prototype.setDebuffCoinEffectIfTooMuchNotPure = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugFavePointSource.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugFavePointSource.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugFavePointSource} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugFavePointSource.toObject = function (includeInstance, msg) {
        var f, obj = {
            usedCoinSum: jspb.Message.getFieldWithDefault(msg, 1, 0),
            viewingtimeDurationSum: jspb.Message.getFieldWithDefault(msg, 2, 0),
            more5timesSuperLikeDaysSum: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugFavePointSource}
 */
proto.jp.singcolor.DebugFavePointSource.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugFavePointSource;
    return proto.jp.singcolor.DebugFavePointSource.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugFavePointSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugFavePointSource}
 */
proto.jp.singcolor.DebugFavePointSource.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUsedCoinSum(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setViewingtimeDurationSum(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMore5timesSuperLikeDaysSum(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugFavePointSource.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugFavePointSource.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugFavePointSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugFavePointSource.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUsedCoinSum();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getViewingtimeDurationSum();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMore5timesSuperLikeDaysSum();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional int64 used_coin_sum = 1;
 * @return {number}
 */
proto.jp.singcolor.DebugFavePointSource.prototype.getUsedCoinSum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugFavePointSource} returns this
 */
proto.jp.singcolor.DebugFavePointSource.prototype.setUsedCoinSum = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 viewingtime_duration_sum = 2;
 * @return {number}
 */
proto.jp.singcolor.DebugFavePointSource.prototype.getViewingtimeDurationSum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugFavePointSource} returns this
 */
proto.jp.singcolor.DebugFavePointSource.prototype.setViewingtimeDurationSum = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 more_5times_super_like_days_sum = 3;
 * @return {number}
 */
proto.jp.singcolor.DebugFavePointSource.prototype.getMore5timesSuperLikeDaysSum = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugFavePointSource} returns this
 */
proto.jp.singcolor.DebugFavePointSource.prototype.setMore5timesSuperLikeDaysSum = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugFaveBonusSource.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugFaveBonusSource.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugFaveBonusSource} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugFaveBonusSource.toObject = function (includeInstance, msg) {
        var f, obj = {
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 1, 0),
            monthlySongFaveListenerCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            favePtBonusRate: jspb.Message.getFieldWithDefault(msg, 3, ""),
            songFaveBonusRate: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugFaveBonusSource}
 */
proto.jp.singcolor.DebugFaveBonusSource.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugFaveBonusSource;
    return proto.jp.singcolor.DebugFaveBonusSource.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugFaveBonusSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugFaveBonusSource}
 */
proto.jp.singcolor.DebugFaveBonusSource.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlySongFaveListenerCount(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setFavePtBonusRate(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongFaveBonusRate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugFaveBonusSource.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugFaveBonusSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugFaveBonusSource.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getMonthlySongFaveListenerCount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getFavePtBonusRate();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getSongFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional int64 monthly_fave_points = 1;
 * @return {number}
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugFaveBonusSource} returns this
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 monthly_song_fave_listener_count = 2;
 * @return {number}
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.getMonthlySongFaveListenerCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugFaveBonusSource} returns this
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.setMonthlySongFaveListenerCount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string fave_pt_bonus_rate = 3;
 * @return {string}
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.getFavePtBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugFaveBonusSource} returns this
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.setFavePtBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string song_fave_bonus_rate = 4;
 * @return {string}
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.getSongFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugFaveBonusSource} returns this
 */
proto.jp.singcolor.DebugFaveBonusSource.prototype.setSongFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SingHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SingHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SingHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SingHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            songId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userSingHistoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            finishedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SingHistory}
 */
proto.jp.singcolor.SingHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SingHistory;
    return proto.jp.singcolor.SingHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SingHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SingHistory}
 */
proto.jp.singcolor.SingHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserSingHistoryId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFinishedAt(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SingHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SingHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SingHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SingHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserSingHistoryId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getFinishedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.SingHistory.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string song_id = 2;
 * @return {string}
 */
proto.jp.singcolor.SingHistory.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_sing_history_id = 3;
 * @return {string}
 */
proto.jp.singcolor.SingHistory.prototype.getUserSingHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setUserSingHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string live_id = 4;
 * @return {string}
 */
proto.jp.singcolor.SingHistory.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional int64 finished_at = 5;
 * @return {number}
 */
proto.jp.singcolor.SingHistory.prototype.getFinishedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setFinishedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.jp.singcolor.SingHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.SingHistory} returns this
 */
proto.jp.singcolor.SingHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DebugDiamondBalanceInOutHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DebugDiamondBalanceInOutHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondBalanceInOutHistoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            reason: jspb.Message.getFieldWithDefault(msg, 3, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DebugDiamondBalanceInOutHistory;
    return proto.jp.singcolor.DebugDiamondBalanceInOutHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDiamondBalanceInOutHistoryId(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setReason(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DebugDiamondBalanceInOutHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondBalanceInOutHistoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getReason();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional string diamond_balance_in_out_history_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.getDiamondBalanceInOutHistoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} returns this
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.setDiamondBalanceInOutHistoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional DiamondBalance diamond_balance = 2;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} returns this
*/
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} returns this
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string reason = 3;
 * @return {string}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.getReason = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} returns this
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.setReason = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DebugDiamondBalanceInOutHistory} returns this
 */
proto.jp.singcolor.DebugDiamondBalanceInOutHistory.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * @enum {number}
 */
proto.jp.singcolor.CoinPurchaseFailureMode = {
    COIN_PURCHASE_FAILURE_MODE_NORMAL: 0,
    COIN_PURCHASE_FAILURE_MODE_STEP1: 1,
    COIN_PURCHASE_FAILURE_MODE_STEP2: 2
};
/**
 * @enum {number}
 */
proto.jp.singcolor.ForceSuperLikeBonusCoinSetting = {
    FORCE_SUPER_LIKE_BONUS_COIN_SETTING_NONE: 0,
    FORCE_SUPER_LIKE_BONUS_COIN_SETTING_100: 1,
    FORCE_SUPER_LIKE_BONUS_COIN_SETTING_500: 2
};
goog.object.extend(exports, proto.jp.singcolor);
