"use strict";
// source: page_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.CursorPaginationRequest', null, global);
goog.exportSymbol('proto.CursorPaginationResult', null, global);
goog.exportSymbol('proto.SimplePaginationRequest', null, global);
goog.exportSymbol('proto.SimplePaginationResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CursorPaginationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CursorPaginationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CursorPaginationRequest.displayName = 'proto.CursorPaginationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CursorPaginationResult = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CursorPaginationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CursorPaginationResult.displayName = 'proto.CursorPaginationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SimplePaginationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SimplePaginationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.SimplePaginationRequest.displayName = 'proto.SimplePaginationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SimplePaginationResult = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SimplePaginationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.SimplePaginationResult.displayName = 'proto.SimplePaginationResult';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CursorPaginationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.CursorPaginationRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CursorPaginationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CursorPaginationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CursorPaginationRequest}
 */
proto.CursorPaginationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CursorPaginationRequest;
    return proto.CursorPaginationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CursorPaginationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CursorPaginationRequest}
 */
proto.CursorPaginationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CursorPaginationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CursorPaginationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CursorPaginationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CursorPaginationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.CursorPaginationRequest.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.CursorPaginationRequest} returns this
 */
proto.CursorPaginationRequest.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.CursorPaginationRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.CursorPaginationRequest} returns this
 */
proto.CursorPaginationRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CursorPaginationResult.prototype.toObject = function (opt_includeInstance) {
        return proto.CursorPaginationResult.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CursorPaginationResult} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CursorPaginationResult.toObject = function (includeInstance, msg) {
        var f, obj = {
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CursorPaginationResult}
 */
proto.CursorPaginationResult.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CursorPaginationResult;
    return proto.CursorPaginationResult.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CursorPaginationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CursorPaginationResult}
 */
proto.CursorPaginationResult.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CursorPaginationResult.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CursorPaginationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CursorPaginationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CursorPaginationResult.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.CursorPaginationResult.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.CursorPaginationResult} returns this
 */
proto.CursorPaginationResult.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.SimplePaginationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.SimplePaginationRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SimplePaginationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.SimplePaginationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: jspb.Message.getFieldWithDefault(msg, 1, 0),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SimplePaginationRequest}
 */
proto.SimplePaginationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.SimplePaginationRequest;
    return proto.SimplePaginationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SimplePaginationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SimplePaginationRequest}
 */
proto.SimplePaginationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SimplePaginationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.SimplePaginationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SimplePaginationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SimplePaginationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.SimplePaginationRequest.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationRequest} returns this
 */
proto.SimplePaginationRequest.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.SimplePaginationRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationRequest} returns this
 */
proto.SimplePaginationRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.SimplePaginationResult.prototype.toObject = function (opt_includeInstance) {
        return proto.SimplePaginationResult.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SimplePaginationResult} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.SimplePaginationResult.toObject = function (includeInstance, msg) {
        var f, obj = {
            totalPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
            currentPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
            nextPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
            prevPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SimplePaginationResult}
 */
proto.SimplePaginationResult.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.SimplePaginationResult;
    return proto.SimplePaginationResult.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SimplePaginationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SimplePaginationResult}
 */
proto.SimplePaginationResult.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTotalPage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextPage(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPrevPage(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SimplePaginationResult.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.SimplePaginationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SimplePaginationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SimplePaginationResult.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTotalPage();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getCurrentPage();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNextPage();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getPrevPage();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * optional int64 total_page = 1;
 * @return {number}
 */
proto.SimplePaginationResult.prototype.getTotalPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationResult} returns this
 */
proto.SimplePaginationResult.prototype.setTotalPage = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 current_page = 2;
 * @return {number}
 */
proto.SimplePaginationResult.prototype.getCurrentPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationResult} returns this
 */
proto.SimplePaginationResult.prototype.setCurrentPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 next_page = 3;
 * @return {number}
 */
proto.SimplePaginationResult.prototype.getNextPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationResult} returns this
 */
proto.SimplePaginationResult.prototype.setNextPage = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 prev_page = 4;
 * @return {number}
 */
proto.SimplePaginationResult.prototype.getPrevPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.SimplePaginationResult} returns this
 */
proto.SimplePaginationResult.prototype.setPrevPage = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional bool has_next = 5;
 * @return {boolean}
 */
proto.SimplePaginationResult.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.SimplePaginationResult} returns this
 */
proto.SimplePaginationResult.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
goog.object.extend(exports, proto);
