"use strict";
// source: karaoke_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.BlockString', null, global);
goog.exportSymbol('proto.ChangeFlagType', null, global);
goog.exportSymbol('proto.Fme', null, global);
goog.exportSymbol('proto.LiverAndListenerFavoriteSongsSort', null, global);
goog.exportSymbol('proto.RubiBlock', null, global);
goog.exportSymbol('proto.Singer', null, global);
goog.exportSymbol('proto.Song', null, global);
goog.exportSymbol('proto.Timing', null, global);
goog.exportSymbol('proto.TimingType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Singer = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Singer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Singer.displayName = 'proto.Singer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Song = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Song, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Song.displayName = 'proto.Song';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Fme = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.Fme.repeatedFields_, null);
};
goog.inherits(proto.Fme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Fme.displayName = 'proto.Fme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BlockString = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.BlockString.repeatedFields_, null);
};
goog.inherits(proto.BlockString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.BlockString.displayName = 'proto.BlockString';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RubiBlock = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RubiBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.RubiBlock.displayName = 'proto.RubiBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Timing = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Timing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Timing.displayName = 'proto.Timing';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Singer.prototype.toObject = function (opt_includeInstance) {
        return proto.Singer.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Singer} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Singer.toObject = function (includeInstance, msg) {
        var f, obj = {
            singerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 3, ""),
            kana: jspb.Message.getFieldWithDefault(msg, 4, ""),
            favorited: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            hasCanSingSong: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Singer}
 */
proto.Singer.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Singer;
    return proto.Singer.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Singer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Singer}
 */
proto.Singer.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingerId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setKana(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setFavorited(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasCanSingSong(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Singer.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Singer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Singer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Singer.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSingerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getKana();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getFavorited();
    if (f) {
        writer.writeBool(5, f);
    }
    f = message.getHasCanSingSong();
    if (f) {
        writer.writeBool(8, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
};
/**
 * optional string singer_id = 1;
 * @return {string}
 */
proto.Singer.prototype.getSingerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setSingerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */
proto.Singer.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string kana = 4;
 * @return {string}
 */
proto.Singer.prototype.getKana = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setKana = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional bool favorited = 5;
 * @return {boolean}
 */
proto.Singer.prototype.getFavorited = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setFavorited = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * optional bool has_can_sing_song = 8;
 * @return {boolean}
 */
proto.Singer.prototype.getHasCanSingSong = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setHasCanSingSong = function (value) {
    return jspb.Message.setProto3BooleanField(this, 8, value);
};
/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.Singer.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.Singer.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.Singer} returns this
 */
proto.Singer.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Song.prototype.toObject = function (opt_includeInstance) {
        return proto.Song.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Song} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Song.toObject = function (includeInstance, msg) {
        var f, obj = {
            songId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            title: jspb.Message.getFieldWithDefault(msg, 3, ""),
            kana: jspb.Message.getFieldWithDefault(msg, 4, ""),
            intro: jspb.Message.getFieldWithDefault(msg, 5, ""),
            bpm: jspb.Message.getFieldWithDefault(msg, 7, 0),
            playerTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
            musicUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
            guidMusicUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
            favorited: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            favoritedAsLiver: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
            favoritedAsListener: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
            singer: (f = msg.getSinger()) && proto.Singer.toObject(includeInstance, f),
            fme: (f = msg.getFme()) && proto.Fme.toObject(includeInstance, f),
            originalKey: jspb.Message.getFieldWithDefault(msg, 16, 0),
            latestSungAt: jspb.Message.getFieldWithDefault(msg, 17, 0),
            userSongStateOwnerId: jspb.Message.getFieldWithDefault(msg, 18, ""),
            duration: jspb.Message.getFieldWithDefault(msg, 19, 0),
            composer: jspb.Message.getFieldWithDefault(msg, 20, ""),
            canSing: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
            isRequestSong: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
            requestedToAddNewSong: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
            requestedUsersCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 15, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Song}
 */
proto.Song.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Song;
    return proto.Song.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Song} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Song}
 */
proto.Song.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setSongId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setKana(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setIntro(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBpm(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPlayerTime(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setMusicUrl(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setGuidMusicUrl(value);
                break;
            case 11:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setFavorited(value);
                break;
            case 21:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setFavoritedAsLiver(value);
                break;
            case 22:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setFavoritedAsListener(value);
                break;
            case 12:
                var value = new proto.Singer;
                reader.readMessage(value, proto.Singer.deserializeBinaryFromReader);
                msg.setSinger(value);
                break;
            case 13:
                var value = new proto.Fme;
                reader.readMessage(value, proto.Fme.deserializeBinaryFromReader);
                msg.setFme(value);
                break;
            case 16:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOriginalKey(value);
                break;
            case 17:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLatestSungAt(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserSongStateOwnerId(value);
                break;
            case 19:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDuration(value);
                break;
            case 20:
                var value = /** @type {string} */ (reader.readString());
                msg.setComposer(value);
                break;
            case 23:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanSing(value);
                break;
            case 24:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsRequestSong(value);
                break;
            case 25:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setRequestedToAddNewSong(value);
                break;
            case 26:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRequestedUsersCount(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Song.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Song.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Song} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Song.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSongId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getKana();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getIntro();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getBpm();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getPlayerTime();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getMusicUrl();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getGuidMusicUrl();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getFavorited();
    if (f) {
        writer.writeBool(11, f);
    }
    f = message.getFavoritedAsLiver();
    if (f) {
        writer.writeBool(21, f);
    }
    f = message.getFavoritedAsListener();
    if (f) {
        writer.writeBool(22, f);
    }
    f = message.getSinger();
    if (f != null) {
        writer.writeMessage(12, f, proto.Singer.serializeBinaryToWriter);
    }
    f = message.getFme();
    if (f != null) {
        writer.writeMessage(13, f, proto.Fme.serializeBinaryToWriter);
    }
    f = message.getOriginalKey();
    if (f !== 0) {
        writer.writeInt64(16, f);
    }
    f = message.getLatestSungAt();
    if (f !== 0) {
        writer.writeInt64(17, f);
    }
    f = message.getUserSongStateOwnerId();
    if (f.length > 0) {
        writer.writeString(18, f);
    }
    f = message.getDuration();
    if (f !== 0) {
        writer.writeInt64(19, f);
    }
    f = message.getComposer();
    if (f.length > 0) {
        writer.writeString(20, f);
    }
    f = message.getCanSing();
    if (f) {
        writer.writeBool(23, f);
    }
    f = message.getIsRequestSong();
    if (f) {
        writer.writeBool(24, f);
    }
    f = message.getRequestedToAddNewSong();
    if (f) {
        writer.writeBool(25, f);
    }
    f = message.getRequestedUsersCount();
    if (f !== 0) {
        writer.writeInt64(26, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(15, f);
    }
};
/**
 * optional string song_id = 1;
 * @return {string}
 */
proto.Song.prototype.getSongId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setSongId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string title = 3;
 * @return {string}
 */
proto.Song.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string kana = 4;
 * @return {string}
 */
proto.Song.prototype.getKana = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setKana = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string intro = 5;
 * @return {string}
 */
proto.Song.prototype.getIntro = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setIntro = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional int64 bpm = 7;
 * @return {number}
 */
proto.Song.prototype.getBpm = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setBpm = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 player_time = 8;
 * @return {number}
 */
proto.Song.prototype.getPlayerTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setPlayerTime = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional string music_url = 9;
 * @return {string}
 */
proto.Song.prototype.getMusicUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setMusicUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string guid_music_url = 10;
 * @return {string}
 */
proto.Song.prototype.getGuidMusicUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setGuidMusicUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional bool favorited = 11;
 * @return {boolean}
 */
proto.Song.prototype.getFavorited = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setFavorited = function (value) {
    return jspb.Message.setProto3BooleanField(this, 11, value);
};
/**
 * optional bool favorited_as_liver = 21;
 * @return {boolean}
 */
proto.Song.prototype.getFavoritedAsLiver = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setFavoritedAsLiver = function (value) {
    return jspb.Message.setProto3BooleanField(this, 21, value);
};
/**
 * optional bool favorited_as_listener = 22;
 * @return {boolean}
 */
proto.Song.prototype.getFavoritedAsListener = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setFavoritedAsListener = function (value) {
    return jspb.Message.setProto3BooleanField(this, 22, value);
};
/**
 * optional Singer singer = 12;
 * @return {?proto.Singer}
 */
proto.Song.prototype.getSinger = function () {
    return /** @type{?proto.Singer} */ (jspb.Message.getWrapperField(this, proto.Singer, 12));
};
/**
 * @param {?proto.Singer|undefined} value
 * @return {!proto.Song} returns this
*/
proto.Song.prototype.setSinger = function (value) {
    return jspb.Message.setWrapperField(this, 12, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.clearSinger = function () {
    return this.setSinger(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Song.prototype.hasSinger = function () {
    return jspb.Message.getField(this, 12) != null;
};
/**
 * optional Fme fme = 13;
 * @return {?proto.Fme}
 */
proto.Song.prototype.getFme = function () {
    return /** @type{?proto.Fme} */ (jspb.Message.getWrapperField(this, proto.Fme, 13));
};
/**
 * @param {?proto.Fme|undefined} value
 * @return {!proto.Song} returns this
*/
proto.Song.prototype.setFme = function (value) {
    return jspb.Message.setWrapperField(this, 13, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.clearFme = function () {
    return this.setFme(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Song.prototype.hasFme = function () {
    return jspb.Message.getField(this, 13) != null;
};
/**
 * optional int64 original_key = 16;
 * @return {number}
 */
proto.Song.prototype.getOriginalKey = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setOriginalKey = function (value) {
    return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * optional int64 latest_sung_at = 17;
 * @return {number}
 */
proto.Song.prototype.getLatestSungAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setLatestSungAt = function (value) {
    return jspb.Message.setProto3IntField(this, 17, value);
};
/**
 * optional string user_song_state_owner_id = 18;
 * @return {string}
 */
proto.Song.prototype.getUserSongStateOwnerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setUserSongStateOwnerId = function (value) {
    return jspb.Message.setProto3StringField(this, 18, value);
};
/**
 * optional int64 duration = 19;
 * @return {number}
 */
proto.Song.prototype.getDuration = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setDuration = function (value) {
    return jspb.Message.setProto3IntField(this, 19, value);
};
/**
 * optional string composer = 20;
 * @return {string}
 */
proto.Song.prototype.getComposer = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};
/**
 * @param {string} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setComposer = function (value) {
    return jspb.Message.setProto3StringField(this, 20, value);
};
/**
 * optional bool can_sing = 23;
 * @return {boolean}
 */
proto.Song.prototype.getCanSing = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setCanSing = function (value) {
    return jspb.Message.setProto3BooleanField(this, 23, value);
};
/**
 * optional bool is_request_song = 24;
 * @return {boolean}
 */
proto.Song.prototype.getIsRequestSong = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setIsRequestSong = function (value) {
    return jspb.Message.setProto3BooleanField(this, 24, value);
};
/**
 * optional bool requested_to_add_new_song = 25;
 * @return {boolean}
 */
proto.Song.prototype.getRequestedToAddNewSong = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setRequestedToAddNewSong = function (value) {
    return jspb.Message.setProto3BooleanField(this, 25, value);
};
/**
 * optional int64 requested_users_count = 26;
 * @return {number}
 */
proto.Song.prototype.getRequestedUsersCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setRequestedUsersCount = function (value) {
    return jspb.Message.setProto3IntField(this, 26, value);
};
/**
 * optional int64 created_at = 14;
 * @return {number}
 */
proto.Song.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional int64 updated_at = 15;
 * @return {number}
 */
proto.Song.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};
/**
 * @param {number} value
 * @return {!proto.Song} returns this
 */
proto.Song.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Fme.repeatedFields_ = [1, 2, 3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Fme.prototype.toObject = function (opt_includeInstance) {
        return proto.Fme.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Fme} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Fme.toObject = function (includeInstance, msg) {
        var f, obj = {
            blockStringsList: jspb.Message.toObjectList(msg.getBlockStringsList(), proto.BlockString.toObject, includeInstance),
            colorPalletsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            timingsList: jspb.Message.toObjectList(msg.getTimingsList(), proto.Timing.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Fme}
 */
proto.Fme.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Fme;
    return proto.Fme.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Fme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Fme}
 */
proto.Fme.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.BlockString;
                reader.readMessage(value, proto.BlockString.deserializeBinaryFromReader);
                msg.addBlockStrings(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.addColorPallets(value);
                break;
            case 3:
                var value = new proto.Timing;
                reader.readMessage(value, proto.Timing.deserializeBinaryFromReader);
                msg.addTimings(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Fme.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Fme.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Fme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Fme.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBlockStringsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.BlockString.serializeBinaryToWriter);
    }
    f = message.getColorPalletsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
    f = message.getTimingsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.Timing.serializeBinaryToWriter);
    }
};
/**
 * repeated BlockString block_strings = 1;
 * @return {!Array<!proto.BlockString>}
 */
proto.Fme.prototype.getBlockStringsList = function () {
    return /** @type{!Array<!proto.BlockString>} */ (jspb.Message.getRepeatedWrapperField(this, proto.BlockString, 1));
};
/**
 * @param {!Array<!proto.BlockString>} value
 * @return {!proto.Fme} returns this
*/
proto.Fme.prototype.setBlockStringsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.BlockString=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BlockString}
 */
proto.Fme.prototype.addBlockStrings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BlockString, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Fme} returns this
 */
proto.Fme.prototype.clearBlockStringsList = function () {
    return this.setBlockStringsList([]);
};
/**
 * repeated string color_pallets = 2;
 * @return {!Array<string>}
 */
proto.Fme.prototype.getColorPalletsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.Fme} returns this
 */
proto.Fme.prototype.setColorPalletsList = function (value) {
    return jspb.Message.setField(this, 2, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Fme} returns this
 */
proto.Fme.prototype.addColorPallets = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Fme} returns this
 */
proto.Fme.prototype.clearColorPalletsList = function () {
    return this.setColorPalletsList([]);
};
/**
 * repeated Timing timings = 3;
 * @return {!Array<!proto.Timing>}
 */
proto.Fme.prototype.getTimingsList = function () {
    return /** @type{!Array<!proto.Timing>} */ (jspb.Message.getRepeatedWrapperField(this, proto.Timing, 3));
};
/**
 * @param {!Array<!proto.Timing>} value
 * @return {!proto.Fme} returns this
*/
proto.Fme.prototype.setTimingsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.Timing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Timing}
 */
proto.Fme.prototype.addTimings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Timing, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Fme} returns this
 */
proto.Fme.prototype.clearTimingsList = function () {
    return this.setTimingsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BlockString.repeatedFields_ = [10];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.BlockString.prototype.toObject = function (opt_includeInstance) {
        return proto.BlockString.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.BlockString} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.BlockString.toObject = function (includeInstance, msg) {
        var f, obj = {
            changeFlag: jspb.Message.getFieldWithDefault(msg, 1, 0),
            x: jspb.Message.getFieldWithDefault(msg, 2, 0),
            y: jspb.Message.getFieldWithDefault(msg, 3, 0),
            beforeTextColor: jspb.Message.getFieldWithDefault(msg, 4, 0),
            afterTextColor: jspb.Message.getFieldWithDefault(msg, 5, 0),
            beforeBorderColor: jspb.Message.getFieldWithDefault(msg, 6, 0),
            afterBorderColor: jspb.Message.getFieldWithDefault(msg, 7, 0),
            text: jspb.Message.getFieldWithDefault(msg, 8, ""),
            width: jspb.Message.getFieldWithDefault(msg, 9, 0),
            rubiBlocksList: jspb.Message.toObjectList(msg.getRubiBlocksList(), proto.RubiBlock.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BlockString}
 */
proto.BlockString.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.BlockString;
    return proto.BlockString.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BlockString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BlockString}
 */
proto.BlockString.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.ChangeFlagType} */ (reader.readEnum());
                msg.setChangeFlag(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setX(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setY(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeforeTextColor(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAfterTextColor(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeforeBorderColor(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAfterBorderColor(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setText(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setWidth(value);
                break;
            case 10:
                var value = new proto.RubiBlock;
                reader.readMessage(value, proto.RubiBlock.deserializeBinaryFromReader);
                msg.addRubiBlocks(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BlockString.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.BlockString.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BlockString} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BlockString.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getChangeFlag();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getX();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getY();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getBeforeTextColor();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getAfterTextColor();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getBeforeBorderColor();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getAfterBorderColor();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getText();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getWidth();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getRubiBlocksList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(10, f, proto.RubiBlock.serializeBinaryToWriter);
    }
};
/**
 * optional ChangeFlagType change_flag = 1;
 * @return {!proto.ChangeFlagType}
 */
proto.BlockString.prototype.getChangeFlag = function () {
    return /** @type {!proto.ChangeFlagType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.ChangeFlagType} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setChangeFlag = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional int64 x = 2;
 * @return {number}
 */
proto.BlockString.prototype.getX = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setX = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 y = 3;
 * @return {number}
 */
proto.BlockString.prototype.getY = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setY = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 before_text_color = 4;
 * @return {number}
 */
proto.BlockString.prototype.getBeforeTextColor = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setBeforeTextColor = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 after_text_color = 5;
 * @return {number}
 */
proto.BlockString.prototype.getAfterTextColor = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setAfterTextColor = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 before_border_color = 6;
 * @return {number}
 */
proto.BlockString.prototype.getBeforeBorderColor = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setBeforeBorderColor = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 after_border_color = 7;
 * @return {number}
 */
proto.BlockString.prototype.getAfterBorderColor = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setAfterBorderColor = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional string text = 8;
 * @return {string}
 */
proto.BlockString.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setText = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional int64 width = 9;
 * @return {number}
 */
proto.BlockString.prototype.getWidth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.setWidth = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * repeated RubiBlock rubi_blocks = 10;
 * @return {!Array<!proto.RubiBlock>}
 */
proto.BlockString.prototype.getRubiBlocksList = function () {
    return /** @type{!Array<!proto.RubiBlock>} */ (jspb.Message.getRepeatedWrapperField(this, proto.RubiBlock, 10));
};
/**
 * @param {!Array<!proto.RubiBlock>} value
 * @return {!proto.BlockString} returns this
*/
proto.BlockString.prototype.setRubiBlocksList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 10, value);
};
/**
 * @param {!proto.RubiBlock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RubiBlock}
 */
proto.BlockString.prototype.addRubiBlocks = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.RubiBlock, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BlockString} returns this
 */
proto.BlockString.prototype.clearRubiBlocksList = function () {
    return this.setRubiBlocksList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.RubiBlock.prototype.toObject = function (opt_includeInstance) {
        return proto.RubiBlock.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.RubiBlock} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.RubiBlock.toObject = function (includeInstance, msg) {
        var f, obj = {
            relX: jspb.Message.getFieldWithDefault(msg, 1, 0),
            text: jspb.Message.getFieldWithDefault(msg, 2, ""),
            width: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RubiBlock}
 */
proto.RubiBlock.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.RubiBlock;
    return proto.RubiBlock.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RubiBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RubiBlock}
 */
proto.RubiBlock.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRelX(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setText(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setWidth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RubiBlock.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.RubiBlock.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RubiBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RubiBlock.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRelX();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getText();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getWidth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional int64 rel_x = 1;
 * @return {number}
 */
proto.RubiBlock.prototype.getRelX = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.RubiBlock} returns this
 */
proto.RubiBlock.prototype.setRelX = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string text = 2;
 * @return {string}
 */
proto.RubiBlock.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.RubiBlock} returns this
 */
proto.RubiBlock.prototype.setText = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 width = 3;
 * @return {number}
 */
proto.RubiBlock.prototype.getWidth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.RubiBlock} returns this
 */
proto.RubiBlock.prototype.setWidth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Timing.prototype.toObject = function (opt_includeInstance) {
        return proto.Timing.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Timing} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Timing.toObject = function (includeInstance, msg) {
        var f, obj = {
            time: jspb.Message.getFieldWithDefault(msg, 1, 0),
            type: jspb.Message.getFieldWithDefault(msg, 2, 0),
            data: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Timing}
 */
proto.Timing.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Timing;
    return proto.Timing.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Timing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Timing}
 */
proto.Timing.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTime(value);
                break;
            case 2:
                var value = /** @type {!proto.TimingType} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Timing.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Timing.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Timing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timing.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTime();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getData();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};
/**
 * optional int64 time = 1;
 * @return {number}
 */
proto.Timing.prototype.getTime = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.Timing} returns this
 */
proto.Timing.prototype.setTime = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional TimingType type = 2;
 * @return {!proto.TimingType}
 */
proto.Timing.prototype.getType = function () {
    return /** @type {!proto.TimingType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.TimingType} value
 * @return {!proto.Timing} returns this
 */
proto.Timing.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional int64 data = 3;
 * @return {number}
 */
proto.Timing.prototype.getData = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.Timing} returns this
 */
proto.Timing.prototype.setData = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * @enum {number}
 */
proto.ChangeFlagType = {
    NORMAL: 0,
    SUB_BLOCK: 1,
    NONE: 255
};
/**
 * @enum {number}
 */
proto.TimingType = {
    START_COLOR_CHANGE: 0,
    UPDATE_COLOR_CHANGE: 1,
    TITLE_END: 4,
    DISMISS_BLOCK: 5,
    SHOW_BLOCK: 6,
    START_SUB_COLOR_CHANGE: 7,
    UPDATE_SUB_COLOR_CHANGE: 8,
    STARTS_LOW_COLOR_CHANGE: 12,
    UPDATES_LOW_COLOR_CHANGE: 13,
    APPLAUSE: 23,
    CHORUS_BEGIN: 30,
    CUT_POSTLUDE: 31,
    TWO_CHORUS_END: 33
};
/**
 * @enum {number}
 */
proto.LiverAndListenerFavoriteSongsSort = {
    UNSPECIFIED: 0,
    CREATED_AT_ASC: 3,
    SING_COUNT_DESC: 4,
    SING_COUNT_ASC: 5,
    SONG_KANA_ASC: 6,
    SONG_KANA_DESC: 7,
    SINGER_KANA_ASC: 8,
    SINGER_KANA_DESC: 9,
    SONG_POPULARITY_DESC: 10,
    SONG_PUBLISHED_AT_DESC: 11,
    SONG_PUBLISHED_AT_ASC: 12,
    RANDOM: 13,
    CREATED_AT_DESC: 14
};
goog.object.extend(exports, proto);
