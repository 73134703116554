/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_organization_pb from './ope_organization_pb';
var OpeOrganizationV1Client = /** @class */ (function () {
    function OpeOrganizationV1Client(hostname, credentials, options) {
        this.methodDescriptorGetOrganizationByOwnerUserID = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOrganizationV1/GetOrganizationByOwnerUserID', grpcWeb.MethodType.UNARY, ope_organization_pb.OpeGetOrganizationByOwnerUserIDRequest, ope_organization_pb.OpeGetOrganizationByOwnerUserIDResponse, function (request) {
            return request.serializeBinary();
        }, ope_organization_pb.OpeGetOrganizationByOwnerUserIDResponse.deserializeBinary);
        this.methodDescriptorUpdateOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOrganizationV1/UpdateOrganization', grpcWeb.MethodType.UNARY, ope_organization_pb.OpeUpdateOrganizationRequest, ope_organization_pb.OpeUpdateOrganizationResponse, function (request) {
            return request.serializeBinary();
        }, ope_organization_pb.OpeUpdateOrganizationResponse.deserializeBinary);
        this.methodDescriptorGetOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOrganizationV1/GetOrganization', grpcWeb.MethodType.UNARY, ope_organization_pb.OpeGetOrganizationV1Request, ope_organization_pb.OpeGetOrganizationV1Response, function (request) {
            return request.serializeBinary();
        }, ope_organization_pb.OpeGetOrganizationV1Response.deserializeBinary);
        this.methodDescriptorListOrganizations = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOrganizationV1/ListOrganizations', grpcWeb.MethodType.UNARY, ope_organization_pb.OpeListOrganizationsV1Request, ope_organization_pb.OpeListOrganizationsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_organization_pb.OpeListOrganizationsV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeOrganizationV1Client.prototype.getOrganizationByOwnerUserID = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOrganizationV1/GetOrganizationByOwnerUserID', request, metadata || {}, this.methodDescriptorGetOrganizationByOwnerUserID, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOrganizationV1/GetOrganizationByOwnerUserID', request, metadata || {}, this.methodDescriptorGetOrganizationByOwnerUserID);
    };
    OpeOrganizationV1Client.prototype.updateOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOrganizationV1/UpdateOrganization', request, metadata || {}, this.methodDescriptorUpdateOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOrganizationV1/UpdateOrganization', request, metadata || {}, this.methodDescriptorUpdateOrganization);
    };
    OpeOrganizationV1Client.prototype.getOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOrganizationV1/GetOrganization', request, metadata || {}, this.methodDescriptorGetOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOrganizationV1/GetOrganization', request, metadata || {}, this.methodDescriptorGetOrganization);
    };
    OpeOrganizationV1Client.prototype.listOrganizations = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOrganizationV1/ListOrganizations', request, metadata || {}, this.methodDescriptorListOrganizations, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOrganizationV1/ListOrganizations', request, metadata || {}, this.methodDescriptorListOrganizations);
    };
    return OpeOrganizationV1Client;
}());
export { OpeOrganizationV1Client };
