"use strict";
// source: ope_master_data_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var master_version_pb = require('./master/version_pb.js');
goog.object.extend(proto, master_version_pb);
var master_violation_entity_pb = require('./master/violation_entity_pb.js');
goog.object.extend(proto, master_violation_entity_pb);
var validate_validate_pb = require('./validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.jp.singcolor.EnableVersionRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.EnableVersionResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBanLevelsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListBanLevelsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListVersionRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListVersionResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListViolationCategoriesRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListViolationCategoriesResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListViolationTargetsRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListViolationTargetsResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.RegisterVersionRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.RegisterVersionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListVersionRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListVersionRequest.displayName = 'proto.jp.singcolor.ListVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListVersionResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListVersionResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListVersionResponse.displayName = 'proto.jp.singcolor.ListVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RegisterVersionRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RegisterVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RegisterVersionRequest.displayName = 'proto.jp.singcolor.RegisterVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RegisterVersionResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RegisterVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RegisterVersionResponse.displayName = 'proto.jp.singcolor.RegisterVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.EnableVersionRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.EnableVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.EnableVersionRequest.displayName = 'proto.jp.singcolor.EnableVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.EnableVersionResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.EnableVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.EnableVersionResponse.displayName = 'proto.jp.singcolor.EnableVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListViolationCategoriesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListViolationCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListViolationCategoriesRequest.displayName = 'proto.jp.singcolor.ListViolationCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListViolationCategoriesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListViolationCategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListViolationCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListViolationCategoriesResponse.displayName = 'proto.jp.singcolor.ListViolationCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListViolationTargetsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListViolationTargetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListViolationTargetsRequest.displayName = 'proto.jp.singcolor.ListViolationTargetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListViolationTargetsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListViolationTargetsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListViolationTargetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListViolationTargetsResponse.displayName = 'proto.jp.singcolor.ListViolationTargetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBanLevelsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListBanLevelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBanLevelsRequest.displayName = 'proto.jp.singcolor.ListBanLevelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListBanLevelsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListBanLevelsResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListBanLevelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListBanLevelsResponse.displayName = 'proto.jp.singcolor.ListBanLevelsResponse';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListVersionRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListVersionRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListVersionRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListVersionRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListVersionRequest}
 */
proto.jp.singcolor.ListVersionRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListVersionRequest;
    return proto.jp.singcolor.ListVersionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListVersionRequest}
 */
proto.jp.singcolor.ListVersionRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListVersionRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListVersionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListVersionRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLimit();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.jp.singcolor.ListVersionRequest.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListVersionRequest} returns this
 */
proto.jp.singcolor.ListVersionRequest.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListVersionResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListVersionResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListVersionResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListVersionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListVersionResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            masterVersionsList: jspb.Message.toObjectList(msg.getMasterVersionsList(), master_version_pb.MasterVersion.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListVersionResponse}
 */
proto.jp.singcolor.ListVersionResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListVersionResponse;
    return proto.jp.singcolor.ListVersionResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListVersionResponse}
 */
proto.jp.singcolor.ListVersionResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_version_pb.MasterVersion;
                reader.readMessage(value, master_version_pb.MasterVersion.deserializeBinaryFromReader);
                msg.addMasterVersions(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListVersionResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListVersionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListVersionResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMasterVersionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, master_version_pb.MasterVersion.serializeBinaryToWriter);
    }
};
/**
 * repeated master.MasterVersion master_versions = 1;
 * @return {!Array<!proto.jp.singcolor.master.MasterVersion>}
 */
proto.jp.singcolor.ListVersionResponse.prototype.getMasterVersionsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.master.MasterVersion>} */ (jspb.Message.getRepeatedWrapperField(this, master_version_pb.MasterVersion, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.master.MasterVersion>} value
 * @return {!proto.jp.singcolor.ListVersionResponse} returns this
*/
proto.jp.singcolor.ListVersionResponse.prototype.setMasterVersionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.master.MasterVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.master.MasterVersion}
 */
proto.jp.singcolor.ListVersionResponse.prototype.addMasterVersions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.master.MasterVersion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListVersionResponse} returns this
 */
proto.jp.singcolor.ListVersionResponse.prototype.clearMasterVersionsList = function () {
    return this.setMasterVersionsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RegisterVersionRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RegisterVersionRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RegisterVersionRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RegisterVersionRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            description: jspb.Message.getFieldWithDefault(msg, 1, ""),
            masterDataZip: msg.getMasterDataZip_asB64()
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RegisterVersionRequest}
 */
proto.jp.singcolor.RegisterVersionRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RegisterVersionRequest;
    return proto.jp.singcolor.RegisterVersionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RegisterVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RegisterVersionRequest}
 */
proto.jp.singcolor.RegisterVersionRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 2:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());
                msg.setMasterDataZip(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RegisterVersionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RegisterVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RegisterVersionRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getMasterDataZip_asU8();
    if (f.length > 0) {
        writer.writeBytes(2, f);
    }
};
/**
 * optional string description = 1;
 * @return {string}
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RegisterVersionRequest} returns this
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bytes master_data_zip = 2;
 * @return {!(string|Uint8Array)}
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.getMasterDataZip = function () {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * optional bytes master_data_zip = 2;
 * This is a type-conversion wrapper around `getMasterDataZip()`
 * @return {string}
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.getMasterDataZip_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getMasterDataZip()));
};
/**
 * optional bytes master_data_zip = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMasterDataZip()`
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.getMasterDataZip_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getMasterDataZip()));
};
/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.jp.singcolor.RegisterVersionRequest} returns this
 */
proto.jp.singcolor.RegisterVersionRequest.prototype.setMasterDataZip = function (value) {
    return jspb.Message.setProto3BytesField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RegisterVersionResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RegisterVersionResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RegisterVersionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RegisterVersionResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            masterVersion: (f = msg.getMasterVersion()) && master_version_pb.MasterVersion.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RegisterVersionResponse}
 */
proto.jp.singcolor.RegisterVersionResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RegisterVersionResponse;
    return proto.jp.singcolor.RegisterVersionResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RegisterVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RegisterVersionResponse}
 */
proto.jp.singcolor.RegisterVersionResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_version_pb.MasterVersion;
                reader.readMessage(value, master_version_pb.MasterVersion.deserializeBinaryFromReader);
                msg.setMasterVersion(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RegisterVersionResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RegisterVersionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RegisterVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RegisterVersionResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMasterVersion();
    if (f != null) {
        writer.writeMessage(1, f, master_version_pb.MasterVersion.serializeBinaryToWriter);
    }
};
/**
 * optional master.MasterVersion master_version = 1;
 * @return {?proto.jp.singcolor.master.MasterVersion}
 */
proto.jp.singcolor.RegisterVersionResponse.prototype.getMasterVersion = function () {
    return /** @type{?proto.jp.singcolor.master.MasterVersion} */ (jspb.Message.getWrapperField(this, master_version_pb.MasterVersion, 1));
};
/**
 * @param {?proto.jp.singcolor.master.MasterVersion|undefined} value
 * @return {!proto.jp.singcolor.RegisterVersionResponse} returns this
*/
proto.jp.singcolor.RegisterVersionResponse.prototype.setMasterVersion = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.RegisterVersionResponse} returns this
 */
proto.jp.singcolor.RegisterVersionResponse.prototype.clearMasterVersion = function () {
    return this.setMasterVersion(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.RegisterVersionResponse.prototype.hasMasterVersion = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.EnableVersionRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.EnableVersionRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.EnableVersionRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.EnableVersionRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            version: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.EnableVersionRequest}
 */
proto.jp.singcolor.EnableVersionRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.EnableVersionRequest;
    return proto.jp.singcolor.EnableVersionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.EnableVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.EnableVersionRequest}
 */
proto.jp.singcolor.EnableVersionRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setVersion(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.EnableVersionRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.EnableVersionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.EnableVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EnableVersionRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getVersion();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string version = 1;
 * @return {string}
 */
proto.jp.singcolor.EnableVersionRequest.prototype.getVersion = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.EnableVersionRequest} returns this
 */
proto.jp.singcolor.EnableVersionRequest.prototype.setVersion = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.EnableVersionResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.EnableVersionResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.EnableVersionResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.EnableVersionResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            masterVersion: (f = msg.getMasterVersion()) && master_version_pb.MasterVersion.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.EnableVersionResponse}
 */
proto.jp.singcolor.EnableVersionResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.EnableVersionResponse;
    return proto.jp.singcolor.EnableVersionResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.EnableVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.EnableVersionResponse}
 */
proto.jp.singcolor.EnableVersionResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_version_pb.MasterVersion;
                reader.readMessage(value, master_version_pb.MasterVersion.deserializeBinaryFromReader);
                msg.setMasterVersion(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.EnableVersionResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.EnableVersionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.EnableVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.EnableVersionResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMasterVersion();
    if (f != null) {
        writer.writeMessage(1, f, master_version_pb.MasterVersion.serializeBinaryToWriter);
    }
};
/**
 * optional master.MasterVersion master_version = 1;
 * @return {?proto.jp.singcolor.master.MasterVersion}
 */
proto.jp.singcolor.EnableVersionResponse.prototype.getMasterVersion = function () {
    return /** @type{?proto.jp.singcolor.master.MasterVersion} */ (jspb.Message.getWrapperField(this, master_version_pb.MasterVersion, 1));
};
/**
 * @param {?proto.jp.singcolor.master.MasterVersion|undefined} value
 * @return {!proto.jp.singcolor.EnableVersionResponse} returns this
*/
proto.jp.singcolor.EnableVersionResponse.prototype.setMasterVersion = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.EnableVersionResponse} returns this
 */
proto.jp.singcolor.EnableVersionResponse.prototype.clearMasterVersion = function () {
    return this.setMasterVersion(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.EnableVersionResponse.prototype.hasMasterVersion = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListViolationCategoriesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListViolationCategoriesRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListViolationCategoriesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListViolationCategoriesRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListViolationCategoriesRequest}
 */
proto.jp.singcolor.ListViolationCategoriesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListViolationCategoriesRequest;
    return proto.jp.singcolor.ListViolationCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListViolationCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListViolationCategoriesRequest}
 */
proto.jp.singcolor.ListViolationCategoriesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListViolationCategoriesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListViolationCategoriesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListViolationCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListViolationCategoriesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListViolationCategoriesResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListViolationCategoriesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListViolationCategoriesResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListViolationCategoriesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListViolationCategoriesResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            violationCategoriesList: jspb.Message.toObjectList(msg.getViolationCategoriesList(), master_violation_entity_pb.ViolationCategory.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListViolationCategoriesResponse}
 */
proto.jp.singcolor.ListViolationCategoriesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListViolationCategoriesResponse;
    return proto.jp.singcolor.ListViolationCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListViolationCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListViolationCategoriesResponse}
 */
proto.jp.singcolor.ListViolationCategoriesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_violation_entity_pb.ViolationCategory;
                reader.readMessage(value, master_violation_entity_pb.ViolationCategory.deserializeBinaryFromReader);
                msg.addViolationCategories(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListViolationCategoriesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListViolationCategoriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListViolationCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListViolationCategoriesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getViolationCategoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, master_violation_entity_pb.ViolationCategory.serializeBinaryToWriter);
    }
};
/**
 * repeated master.ViolationCategory violation_categories = 1;
 * @return {!Array<!proto.jp.singcolor.master.ViolationCategory>}
 */
proto.jp.singcolor.ListViolationCategoriesResponse.prototype.getViolationCategoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.master.ViolationCategory>} */ (jspb.Message.getRepeatedWrapperField(this, master_violation_entity_pb.ViolationCategory, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.master.ViolationCategory>} value
 * @return {!proto.jp.singcolor.ListViolationCategoriesResponse} returns this
*/
proto.jp.singcolor.ListViolationCategoriesResponse.prototype.setViolationCategoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.master.ViolationCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.master.ViolationCategory}
 */
proto.jp.singcolor.ListViolationCategoriesResponse.prototype.addViolationCategories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.master.ViolationCategory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListViolationCategoriesResponse} returns this
 */
proto.jp.singcolor.ListViolationCategoriesResponse.prototype.clearViolationCategoriesList = function () {
    return this.setViolationCategoriesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListViolationTargetsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListViolationTargetsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListViolationTargetsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListViolationTargetsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListViolationTargetsRequest}
 */
proto.jp.singcolor.ListViolationTargetsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListViolationTargetsRequest;
    return proto.jp.singcolor.ListViolationTargetsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListViolationTargetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListViolationTargetsRequest}
 */
proto.jp.singcolor.ListViolationTargetsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListViolationTargetsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListViolationTargetsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListViolationTargetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListViolationTargetsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListViolationTargetsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListViolationTargetsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListViolationTargetsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListViolationTargetsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListViolationTargetsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            violationTargetsList: jspb.Message.toObjectList(msg.getViolationTargetsList(), master_violation_entity_pb.ViolationTarget.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListViolationTargetsResponse}
 */
proto.jp.singcolor.ListViolationTargetsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListViolationTargetsResponse;
    return proto.jp.singcolor.ListViolationTargetsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListViolationTargetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListViolationTargetsResponse}
 */
proto.jp.singcolor.ListViolationTargetsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_violation_entity_pb.ViolationTarget;
                reader.readMessage(value, master_violation_entity_pb.ViolationTarget.deserializeBinaryFromReader);
                msg.addViolationTargets(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListViolationTargetsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListViolationTargetsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListViolationTargetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListViolationTargetsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getViolationTargetsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, master_violation_entity_pb.ViolationTarget.serializeBinaryToWriter);
    }
};
/**
 * repeated master.ViolationTarget violation_targets = 1;
 * @return {!Array<!proto.jp.singcolor.master.ViolationTarget>}
 */
proto.jp.singcolor.ListViolationTargetsResponse.prototype.getViolationTargetsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.master.ViolationTarget>} */ (jspb.Message.getRepeatedWrapperField(this, master_violation_entity_pb.ViolationTarget, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.master.ViolationTarget>} value
 * @return {!proto.jp.singcolor.ListViolationTargetsResponse} returns this
*/
proto.jp.singcolor.ListViolationTargetsResponse.prototype.setViolationTargetsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.master.ViolationTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.master.ViolationTarget}
 */
proto.jp.singcolor.ListViolationTargetsResponse.prototype.addViolationTargets = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.master.ViolationTarget, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListViolationTargetsResponse} returns this
 */
proto.jp.singcolor.ListViolationTargetsResponse.prototype.clearViolationTargetsList = function () {
    return this.setViolationTargetsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBanLevelsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBanLevelsRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBanLevelsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBanLevelsRequest.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBanLevelsRequest}
 */
proto.jp.singcolor.ListBanLevelsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBanLevelsRequest;
    return proto.jp.singcolor.ListBanLevelsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBanLevelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBanLevelsRequest}
 */
proto.jp.singcolor.ListBanLevelsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBanLevelsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBanLevelsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBanLevelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBanLevelsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListBanLevelsResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListBanLevelsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListBanLevelsResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListBanLevelsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListBanLevelsResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            banLevelsList: jspb.Message.toObjectList(msg.getBanLevelsList(), master_violation_entity_pb.BanLevel.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListBanLevelsResponse}
 */
proto.jp.singcolor.ListBanLevelsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListBanLevelsResponse;
    return proto.jp.singcolor.ListBanLevelsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListBanLevelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListBanLevelsResponse}
 */
proto.jp.singcolor.ListBanLevelsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new master_violation_entity_pb.BanLevel;
                reader.readMessage(value, master_violation_entity_pb.BanLevel.deserializeBinaryFromReader);
                msg.addBanLevels(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListBanLevelsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListBanLevelsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListBanLevelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListBanLevelsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBanLevelsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, master_violation_entity_pb.BanLevel.serializeBinaryToWriter);
    }
};
/**
 * repeated master.BanLevel ban_levels = 1;
 * @return {!Array<!proto.jp.singcolor.master.BanLevel>}
 */
proto.jp.singcolor.ListBanLevelsResponse.prototype.getBanLevelsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.master.BanLevel>} */ (jspb.Message.getRepeatedWrapperField(this, master_violation_entity_pb.BanLevel, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.master.BanLevel>} value
 * @return {!proto.jp.singcolor.ListBanLevelsResponse} returns this
*/
proto.jp.singcolor.ListBanLevelsResponse.prototype.setBanLevelsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.master.BanLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.master.BanLevel}
 */
proto.jp.singcolor.ListBanLevelsResponse.prototype.addBanLevels = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.master.BanLevel, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListBanLevelsResponse} returns this
 */
proto.jp.singcolor.ListBanLevelsResponse.prototype.clearBanLevelsList = function () {
    return this.setBanLevelsList([]);
};
goog.object.extend(exports, proto.jp.singcolor);
