"use strict";
// source: ope_user_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var lives_entity_pb = require('./lives_entity_pb.js');
goog.object.extend(proto, lives_entity_pb);
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var diamonds_entity_pb = require('./diamonds_entity_pb.js');
goog.object.extend(proto, diamonds_entity_pb);
var pearls_entity_pb = require('./pearls_entity_pb.js');
goog.object.extend(proto, pearls_entity_pb);
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
var organization_entity_pb = require('./organization_entity_pb.js');
goog.object.extend(proto, organization_entity_pb);
var validate_validate_pb = require('./validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
var ope_user_entity_pb = require('./ope_user_entity_pb.js');
goog.object.extend(proto, ope_user_entity_pb);
goog.exportSymbol('proto.jp.singcolor.BanUserV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.BanUserV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLeagueResetCompensationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLeagueResetCompensationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLeagueResetCompensationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLeagueResetCompensationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteUserDeviceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.DeleteUserDeviceV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ForceUnbanUserV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ForceUnbanUserV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBanHistoryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserBanHistoryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDetailV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserDetailV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserFlagV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserFlagV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileScreeningV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetUserProfileScreeningV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.LeagueOverwriteResetAmount', null, global);
goog.exportSymbol('proto.jp.singcolor.LeagueResetCompensation', null, global);
goog.exportSymbol('proto.jp.singcolor.ListDevicesByUserIDRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.ListDevicesByUserIDResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLeagueResetCompensationsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLeagueResetCompensationsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserDevicesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserDevicesV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserProfileScreeningsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListUserProfileScreeningsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.MaskLiveBackgroundV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.MaskLiveBackgroundV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.MaskUserProfileV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.MaskUserProfileV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeAddCoinRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeAddCoinRequest.Type', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeAddCoinResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetCoinBalanceRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetCoinBalanceResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PassLivePermissionFirstReviewV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PassLivePermissionFirstReviewV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.PassLivePermissionSecondReviewV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.PassLivePermissionSecondReviewV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.RegisterBankTransferTokenV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.RegisterBankTransferTokenV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.SendSystemMessageV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.SendSystemMessageV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFlagV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateUserFlagV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetCoinBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetCoinBalanceRequest.displayName = 'proto.jp.singcolor.OpeGetCoinBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetCoinBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetCoinBalanceResponse.displayName = 'proto.jp.singcolor.OpeGetCoinBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeAddCoinRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeAddCoinRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeAddCoinRequest.displayName = 'proto.jp.singcolor.OpeAddCoinRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeAddCoinResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeAddCoinResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeAddCoinResponse.displayName = 'proto.jp.singcolor.OpeAddCoinResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserFlagV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserFlagV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserFlagV1Request.displayName = 'proto.jp.singcolor.GetUserFlagV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserFlagV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserFlagV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserFlagV1Response.displayName = 'proto.jp.singcolor.GetUserFlagV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFlagV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFlagV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFlagV1Request.displayName = 'proto.jp.singcolor.UpdateUserFlagV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateUserFlagV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateUserFlagV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateUserFlagV1Response.displayName = 'proto.jp.singcolor.UpdateUserFlagV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBanHistoryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBanHistoryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBanHistoryV1Request.displayName = 'proto.jp.singcolor.GetUserBanHistoryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserBanHistoryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserBanHistoryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserBanHistoryV1Response.displayName = 'proto.jp.singcolor.GetUserBanHistoryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanUserV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanUserV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanUserV1Request.displayName = 'proto.jp.singcolor.BanUserV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.BanUserV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.BanUserV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.BanUserV1Response.displayName = 'proto.jp.singcolor.BanUserV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ForceUnbanUserV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ForceUnbanUserV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ForceUnbanUserV1Request.displayName = 'proto.jp.singcolor.ForceUnbanUserV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ForceUnbanUserV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ForceUnbanUserV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ForceUnbanUserV1Response.displayName = 'proto.jp.singcolor.ForceUnbanUserV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileScreeningV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Request.displayName = 'proto.jp.singcolor.GetUserProfileScreeningV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserProfileScreeningV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Response.displayName = 'proto.jp.singcolor.GetUserProfileScreeningV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUserProfileScreeningsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Request.displayName = 'proto.jp.singcolor.ListUserProfileScreeningsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUserProfileScreeningsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUserProfileScreeningsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Response.displayName = 'proto.jp.singcolor.ListUserProfileScreeningsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.displayName = 'proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.displayName = 'proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MaskUserProfileV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.MaskUserProfileV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.MaskUserProfileV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MaskUserProfileV1Request.displayName = 'proto.jp.singcolor.MaskUserProfileV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MaskUserProfileV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MaskUserProfileV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MaskUserProfileV1Response.displayName = 'proto.jp.singcolor.MaskUserProfileV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.displayName = 'proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.displayName = 'proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.displayName = 'proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.displayName = 'proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDetailV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDetailV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDetailV1Request.displayName = 'proto.jp.singcolor.GetUserDetailV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetUserDetailV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetUserDetailV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetUserDetailV1Response.displayName = 'proto.jp.singcolor.GetUserDetailV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.displayName = 'proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.displayName = 'proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.displayName = 'proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.displayName = 'proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.displayName = 'proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.displayName = 'proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RegisterBankTransferTokenV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Request.displayName = 'proto.jp.singcolor.RegisterBankTransferTokenV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.RegisterBankTransferTokenV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Response.displayName = 'proto.jp.singcolor.RegisterBankTransferTokenV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LeagueOverwriteResetAmount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LeagueOverwriteResetAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LeagueOverwriteResetAmount.displayName = 'proto.jp.singcolor.LeagueOverwriteResetAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.displayName = 'proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.displayName = 'proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.displayName = 'proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.displayName = 'proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.displayName = 'proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.displayName = 'proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.LeagueResetCompensation = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.LeagueResetCompensation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.LeagueResetCompensation.displayName = 'proto.jp.singcolor.LeagueResetCompensation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListLeagueResetCompensationsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Request.displayName = 'proto.jp.singcolor.ListLeagueResetCompensationsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListLeagueResetCompensationsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListLeagueResetCompensationsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Response.displayName = 'proto.jp.singcolor.ListLeagueResetCompensationsV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLeagueResetCompensationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Request.displayName = 'proto.jp.singcolor.CreateLeagueResetCompensationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.CreateLeagueResetCompensationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Response.displayName = 'proto.jp.singcolor.CreateLeagueResetCompensationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLeagueResetCompensationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.displayName = 'proto.jp.singcolor.DeleteLeagueResetCompensationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteLeagueResetCompensationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.displayName = 'proto.jp.singcolor.DeleteLeagueResetCompensationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MaskLiveBackgroundV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Request.displayName = 'proto.jp.singcolor.MaskLiveBackgroundV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.MaskLiveBackgroundV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.MaskLiveBackgroundV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Response.displayName = 'proto.jp.singcolor.MaskLiveBackgroundV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserDevicesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListUserDevicesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserDevicesV1Request.displayName = 'proto.jp.singcolor.ListUserDevicesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListUserDevicesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListUserDevicesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListUserDevicesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListUserDevicesV1Response.displayName = 'proto.jp.singcolor.ListUserDevicesV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteUserDeviceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteUserDeviceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteUserDeviceV1Request.displayName = 'proto.jp.singcolor.DeleteUserDeviceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.DeleteUserDeviceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.DeleteUserDeviceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.DeleteUserDeviceV1Response.displayName = 'proto.jp.singcolor.DeleteUserDeviceV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListDevicesByUserIDRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListDevicesByUserIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListDevicesByUserIDRequest.displayName = 'proto.jp.singcolor.ListDevicesByUserIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListDevicesByUserIDResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListDevicesByUserIDResponse.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListDevicesByUserIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListDevicesByUserIDResponse.displayName = 'proto.jp.singcolor.ListDevicesByUserIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendSystemMessageV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.SendSystemMessageV1Request.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.SendSystemMessageV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendSystemMessageV1Request.displayName = 'proto.jp.singcolor.SendSystemMessageV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.SendSystemMessageV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.SendSystemMessageV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.SendSystemMessageV1Response.displayName = 'proto.jp.singcolor.SendSystemMessageV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PassLivePermissionFirstReviewV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.displayName = 'proto.jp.singcolor.PassLivePermissionFirstReviewV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PassLivePermissionFirstReviewV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.displayName = 'proto.jp.singcolor.PassLivePermissionFirstReviewV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PassLivePermissionSecondReviewV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.displayName = 'proto.jp.singcolor.PassLivePermissionSecondReviewV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.PassLivePermissionSecondReviewV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.displayName = 'proto.jp.singcolor.PassLivePermissionSecondReviewV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetCoinBalanceRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetCoinBalanceRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetCoinBalanceRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetCoinBalanceRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceRequest}
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetCoinBalanceRequest;
    return proto.jp.singcolor.OpeGetCoinBalanceRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetCoinBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceRequest}
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetCoinBalanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetCoinBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceRequest} returns this
 */
proto.jp.singcolor.OpeGetCoinBalanceRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetCoinBalanceResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetCoinBalanceResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetCoinBalanceResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceResponse}
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetCoinBalanceResponse;
    return proto.jp.singcolor.OpeGetCoinBalanceResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetCoinBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceResponse}
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetCoinBalanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetCoinBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceResponse} returns this
*/
proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetCoinBalanceResponse} returns this
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetCoinBalanceResponse.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeAddCoinRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeAddCoinRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeAddCoinRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeAddCoinRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            coinAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
            type: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeAddCoinRequest}
 */
proto.jp.singcolor.OpeAddCoinRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeAddCoinRequest;
    return proto.jp.singcolor.OpeAddCoinRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeAddCoinRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeAddCoinRequest}
 */
proto.jp.singcolor.OpeAddCoinRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmount(value);
                break;
            case 3:
                var value = /** @type {!proto.jp.singcolor.OpeAddCoinRequest.Type} */ (reader.readEnum());
                msg.setType(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeAddCoinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeAddCoinRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeAddCoinRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCoinAmount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * @enum {number}
 */
proto.jp.singcolor.OpeAddCoinRequest.Type = {
    TYPE_UNKNOWN: 0,
    TYPE_FREE: 1,
    TYPE_PAID: 2,
    TYPE_FROM_DIAMOND: 3,
    TYPE_DISCOUNT: 4,
    TYPE_DOUBLE_PAID: 5
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeAddCoinRequest} returns this
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 coin_amount = 2;
 * @return {number}
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.getCoinAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeAddCoinRequest} returns this
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.setCoinAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional Type type = 3;
 * @return {!proto.jp.singcolor.OpeAddCoinRequest.Type}
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.getType = function () {
    return /** @type {!proto.jp.singcolor.OpeAddCoinRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.jp.singcolor.OpeAddCoinRequest.Type} value
 * @return {!proto.jp.singcolor.OpeAddCoinRequest} returns this
 */
proto.jp.singcolor.OpeAddCoinRequest.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeAddCoinResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeAddCoinResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeAddCoinResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeAddCoinResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeAddCoinResponse}
 */
proto.jp.singcolor.OpeAddCoinResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeAddCoinResponse;
    return proto.jp.singcolor.OpeAddCoinResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeAddCoinResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeAddCoinResponse}
 */
proto.jp.singcolor.OpeAddCoinResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeAddCoinResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeAddCoinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeAddCoinResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeAddCoinResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(1, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
};
/**
 * optional CoinBalance coin_balance = 1;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.OpeAddCoinResponse.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 1));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.OpeAddCoinResponse} returns this
*/
proto.jp.singcolor.OpeAddCoinResponse.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeAddCoinResponse} returns this
 */
proto.jp.singcolor.OpeAddCoinResponse.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeAddCoinResponse.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserFlagV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserFlagV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserFlagV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserFlagV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserFlagV1Request}
 */
proto.jp.singcolor.GetUserFlagV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserFlagV1Request;
    return proto.jp.singcolor.GetUserFlagV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserFlagV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserFlagV1Request}
 */
proto.jp.singcolor.GetUserFlagV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserFlagV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserFlagV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserFlagV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserFlagV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserFlagV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserFlagV1Request} returns this
 */
proto.jp.singcolor.GetUserFlagV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserFlagV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserFlagV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserFlagV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserFlagV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userFlags: (f = msg.getUserFlags()) && users_entity_pb.UserFlag.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserFlagV1Response}
 */
proto.jp.singcolor.GetUserFlagV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserFlagV1Response;
    return proto.jp.singcolor.GetUserFlagV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserFlagV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserFlagV1Response}
 */
proto.jp.singcolor.GetUserFlagV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserFlag;
                reader.readMessage(value, users_entity_pb.UserFlag.deserializeBinaryFromReader);
                msg.setUserFlags(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserFlagV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserFlagV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserFlagV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserFlagV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserFlags();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserFlag.serializeBinaryToWriter);
    }
};
/**
 * optional UserFlag user_flags = 1;
 * @return {?proto.UserFlag}
 */
proto.jp.singcolor.GetUserFlagV1Response.prototype.getUserFlags = function () {
    return /** @type{?proto.UserFlag} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFlag, 1));
};
/**
 * @param {?proto.UserFlag|undefined} value
 * @return {!proto.jp.singcolor.GetUserFlagV1Response} returns this
*/
proto.jp.singcolor.GetUserFlagV1Response.prototype.setUserFlags = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserFlagV1Response} returns this
 */
proto.jp.singcolor.GetUserFlagV1Response.prototype.clearUserFlags = function () {
    return this.setUserFlags(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserFlagV1Response.prototype.hasUserFlags = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFlagV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFlagV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFlagV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFlagV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            isQa: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            isAntisocialForces: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            isCautionNeeded: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            isPermittedToStartLive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            isPermittedToUseOrganization: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFlagV1Request;
    return proto.jp.singcolor.UpdateUserFlagV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFlagV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsQa(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsAntisocialForces(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsCautionNeeded(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsPermittedToStartLive(value);
                break;
            case 9:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsPermittedToUseOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFlagV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFlagV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFlagV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIsQa();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getIsAntisocialForces();
    if (f) {
        writer.writeBool(5, f);
    }
    f = message.getIsCautionNeeded();
    if (f) {
        writer.writeBool(6, f);
    }
    f = message.getIsPermittedToStartLive();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getIsPermittedToUseOrganization();
    if (f) {
        writer.writeBool(9, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool is_qa = 4;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getIsQa = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setIsQa = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional bool is_antisocial_forces = 5;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getIsAntisocialForces = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setIsAntisocialForces = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * optional bool is_caution_needed = 6;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getIsCautionNeeded = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setIsCautionNeeded = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
/**
 * optional bool is_permitted_to_start_live = 7;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getIsPermittedToStartLive = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setIsPermittedToStartLive = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * optional bool is_permitted_to_use_organization = 9;
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.getIsPermittedToUseOrganization = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Request} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Request.prototype.setIsPermittedToUseOrganization = function (value) {
    return jspb.Message.setProto3BooleanField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateUserFlagV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateUserFlagV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateUserFlagV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateUserFlagV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userFlags: (f = msg.getUserFlags()) && users_entity_pb.UserFlag.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Response}
 */
proto.jp.singcolor.UpdateUserFlagV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateUserFlagV1Response;
    return proto.jp.singcolor.UpdateUserFlagV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateUserFlagV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Response}
 */
proto.jp.singcolor.UpdateUserFlagV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserFlag;
                reader.readMessage(value, users_entity_pb.UserFlag.deserializeBinaryFromReader);
                msg.setUserFlags(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateUserFlagV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateUserFlagV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateUserFlagV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateUserFlagV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserFlags();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserFlag.serializeBinaryToWriter);
    }
};
/**
 * optional UserFlag user_flags = 1;
 * @return {?proto.UserFlag}
 */
proto.jp.singcolor.UpdateUserFlagV1Response.prototype.getUserFlags = function () {
    return /** @type{?proto.UserFlag} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFlag, 1));
};
/**
 * @param {?proto.UserFlag|undefined} value
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Response} returns this
*/
proto.jp.singcolor.UpdateUserFlagV1Response.prototype.setUserFlags = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateUserFlagV1Response} returns this
 */
proto.jp.singcolor.UpdateUserFlagV1Response.prototype.clearUserFlags = function () {
    return this.setUserFlags(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateUserFlagV1Response.prototype.hasUserFlags = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBanHistoryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBanHistoryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBanHistoryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBanHistoryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Request}
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBanHistoryV1Request;
    return proto.jp.singcolor.GetUserBanHistoryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBanHistoryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Request}
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBanHistoryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBanHistoryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Request} returns this
 */
proto.jp.singcolor.GetUserBanHistoryV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserBanHistoryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserBanHistoryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserBanHistoryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBanHistory: (f = msg.getUserBanHistory()) && users_entity_pb.UserBanHistory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Response}
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserBanHistoryV1Response;
    return proto.jp.singcolor.GetUserBanHistoryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserBanHistoryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Response}
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBanHistory;
                reader.readMessage(value, users_entity_pb.UserBanHistory.deserializeBinaryFromReader);
                msg.setUserBanHistory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserBanHistoryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserBanHistoryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBanHistory();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBanHistory.serializeBinaryToWriter);
    }
};
/**
 * optional UserBanHistory user_ban_history = 1;
 * @return {?proto.UserBanHistory}
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.getUserBanHistory = function () {
    return /** @type{?proto.UserBanHistory} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBanHistory, 1));
};
/**
 * @param {?proto.UserBanHistory|undefined} value
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Response} returns this
*/
proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.setUserBanHistory = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserBanHistoryV1Response} returns this
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.clearUserBanHistory = function () {
    return this.setUserBanHistory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserBanHistoryV1Response.prototype.hasUserBanHistory = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanUserV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanUserV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanUserV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanUserV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            violationCategoryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            violationTargetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            banLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
            reportId: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanUserV1Request}
 */
proto.jp.singcolor.BanUserV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanUserV1Request;
    return proto.jp.singcolor.BanUserV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanUserV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanUserV1Request}
 */
proto.jp.singcolor.BanUserV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationCategoryId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationTargetId(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setBanLevel(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setReportId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanUserV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanUserV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanUserV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanUserV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getViolationCategoryId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getViolationTargetId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getBanLevel();
    if (f !== 0) {
        writer.writeUint32(4, f);
    }
    f = message.getReportId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.BanUserV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanUserV1Request} returns this
 */
proto.jp.singcolor.BanUserV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string violation_category_id = 2;
 * @return {string}
 */
proto.jp.singcolor.BanUserV1Request.prototype.getViolationCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanUserV1Request} returns this
 */
proto.jp.singcolor.BanUserV1Request.prototype.setViolationCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string violation_target_id = 3;
 * @return {string}
 */
proto.jp.singcolor.BanUserV1Request.prototype.getViolationTargetId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanUserV1Request} returns this
 */
proto.jp.singcolor.BanUserV1Request.prototype.setViolationTargetId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional uint32 ban_level = 4;
 * @return {number}
 */
proto.jp.singcolor.BanUserV1Request.prototype.getBanLevel = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.BanUserV1Request} returns this
 */
proto.jp.singcolor.BanUserV1Request.prototype.setBanLevel = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string report_id = 5;
 * @return {string}
 */
proto.jp.singcolor.BanUserV1Request.prototype.getReportId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.BanUserV1Request} returns this
 */
proto.jp.singcolor.BanUserV1Request.prototype.setReportId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.BanUserV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.BanUserV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.BanUserV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.BanUserV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBanHistory: (f = msg.getUserBanHistory()) && users_entity_pb.UserBanHistory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.BanUserV1Response}
 */
proto.jp.singcolor.BanUserV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.BanUserV1Response;
    return proto.jp.singcolor.BanUserV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.BanUserV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.BanUserV1Response}
 */
proto.jp.singcolor.BanUserV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBanHistory;
                reader.readMessage(value, users_entity_pb.UserBanHistory.deserializeBinaryFromReader);
                msg.setUserBanHistory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.BanUserV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.BanUserV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.BanUserV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.BanUserV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBanHistory();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBanHistory.serializeBinaryToWriter);
    }
};
/**
 * optional UserBanHistory user_ban_history = 1;
 * @return {?proto.UserBanHistory}
 */
proto.jp.singcolor.BanUserV1Response.prototype.getUserBanHistory = function () {
    return /** @type{?proto.UserBanHistory} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBanHistory, 1));
};
/**
 * @param {?proto.UserBanHistory|undefined} value
 * @return {!proto.jp.singcolor.BanUserV1Response} returns this
*/
proto.jp.singcolor.BanUserV1Response.prototype.setUserBanHistory = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.BanUserV1Response} returns this
 */
proto.jp.singcolor.BanUserV1Response.prototype.clearUserBanHistory = function () {
    return this.setUserBanHistory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.BanUserV1Response.prototype.hasUserBanHistory = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ForceUnbanUserV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ForceUnbanUserV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ForceUnbanUserV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ForceUnbanUserV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            banLevel: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Request}
 */
proto.jp.singcolor.ForceUnbanUserV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ForceUnbanUserV1Request;
    return proto.jp.singcolor.ForceUnbanUserV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ForceUnbanUserV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Request}
 */
proto.jp.singcolor.ForceUnbanUserV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setBanLevel(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ForceUnbanUserV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ForceUnbanUserV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ForceUnbanUserV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ForceUnbanUserV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getBanLevel();
    if (f !== 0) {
        writer.writeUint32(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ForceUnbanUserV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Request} returns this
 */
proto.jp.singcolor.ForceUnbanUserV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint32 ban_level = 2;
 * @return {number}
 */
proto.jp.singcolor.ForceUnbanUserV1Request.prototype.getBanLevel = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Request} returns this
 */
proto.jp.singcolor.ForceUnbanUserV1Request.prototype.setBanLevel = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ForceUnbanUserV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ForceUnbanUserV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ForceUnbanUserV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ForceUnbanUserV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userBanHistory: (f = msg.getUserBanHistory()) && users_entity_pb.UserBanHistory.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Response}
 */
proto.jp.singcolor.ForceUnbanUserV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ForceUnbanUserV1Response;
    return proto.jp.singcolor.ForceUnbanUserV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ForceUnbanUserV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Response}
 */
proto.jp.singcolor.ForceUnbanUserV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserBanHistory;
                reader.readMessage(value, users_entity_pb.UserBanHistory.deserializeBinaryFromReader);
                msg.setUserBanHistory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ForceUnbanUserV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ForceUnbanUserV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ForceUnbanUserV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ForceUnbanUserV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserBanHistory();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserBanHistory.serializeBinaryToWriter);
    }
};
/**
 * optional UserBanHistory user_ban_history = 1;
 * @return {?proto.UserBanHistory}
 */
proto.jp.singcolor.ForceUnbanUserV1Response.prototype.getUserBanHistory = function () {
    return /** @type{?proto.UserBanHistory} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBanHistory, 1));
};
/**
 * @param {?proto.UserBanHistory|undefined} value
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Response} returns this
*/
proto.jp.singcolor.ForceUnbanUserV1Response.prototype.setUserBanHistory = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ForceUnbanUserV1Response} returns this
 */
proto.jp.singcolor.ForceUnbanUserV1Response.prototype.clearUserBanHistory = function () {
    return this.setUserBanHistory(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ForceUnbanUserV1Response.prototype.hasUserBanHistory = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileScreeningV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Request}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileScreeningV1Request;
    return proto.jp.singcolor.GetUserProfileScreeningV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Request}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileScreeningV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Request} returns this
 */
proto.jp.singcolor.GetUserProfileScreeningV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserProfileScreeningV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserProfileScreeningV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfileScreening: (f = msg.getUserProfileScreening()) && users_entity_pb.UserProfileScreening.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Response}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserProfileScreeningV1Response;
    return proto.jp.singcolor.GetUserProfileScreeningV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Response}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfileScreening;
                reader.readMessage(value, users_entity_pb.UserProfileScreening.deserializeBinaryFromReader);
                msg.setUserProfileScreening(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserProfileScreeningV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserProfileScreeningV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfileScreening();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserProfileScreening.serializeBinaryToWriter);
    }
};
/**
 * optional UserProfileScreening user_profile_screening = 1;
 * @return {?proto.UserProfileScreening}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.getUserProfileScreening = function () {
    return /** @type{?proto.UserProfileScreening} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfileScreening, 1));
};
/**
 * @param {?proto.UserProfileScreening|undefined} value
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Response} returns this
*/
proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.setUserProfileScreening = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserProfileScreeningV1Response} returns this
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.clearUserProfileScreening = function () {
    return this.setUserProfileScreening(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserProfileScreeningV1Response.prototype.hasUserProfileScreening = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserProfileScreeningsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            updatedAtFrom: jspb.Message.getFieldWithDefault(msg, 1, 0),
            updatedAtTo: jspb.Message.getFieldWithDefault(msg, 2, 0),
            hasScreeningTarget: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            pageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserProfileScreeningsV1Request;
    return proto.jp.singcolor.ListUserProfileScreeningsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAtFrom(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAtTo(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasScreeningTarget(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserProfileScreeningsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUpdatedAtFrom();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getUpdatedAtTo();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getHasScreeningTarget();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(5, f);
    }
};
/**
 * optional int64 updated_at_from = 1;
 * @return {number}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.getUpdatedAtFrom = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.setUpdatedAtFrom = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 updated_at_to = 2;
 * @return {number}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.getUpdatedAtTo = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.setUpdatedAtTo = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional bool has_screening_target = 3;
 * @return {boolean}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.getHasScreeningTarget = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.setHasScreeningTarget = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional string page_token = 4;
 * @return {string}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional uint64 limit = 5;
 * @return {number}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserProfileScreeningsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserProfileScreeningsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfileScreeningsList: jspb.Message.toObjectList(msg.getUserProfileScreeningsList(), users_entity_pb.UserProfileScreening.toObject, includeInstance),
            prevPageToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserProfileScreeningsV1Response;
    return proto.jp.singcolor.ListUserProfileScreeningsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfileScreening;
                reader.readMessage(value, users_entity_pb.UserProfileScreening.deserializeBinaryFromReader);
                msg.addUserProfileScreenings(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPrevPageToken(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserProfileScreeningsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfileScreeningsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.UserProfileScreening.serializeBinaryToWriter);
    }
    f = message.getPrevPageToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated UserProfileScreening user_profile_screenings = 1;
 * @return {!Array<!proto.UserProfileScreening>}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.getUserProfileScreeningsList = function () {
    return /** @type{!Array<!proto.UserProfileScreening>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserProfileScreening, 1));
};
/**
 * @param {!Array<!proto.UserProfileScreening>} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} returns this
*/
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.setUserProfileScreeningsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.UserProfileScreening=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UserProfileScreening}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.addUserProfileScreenings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.UserProfileScreening, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.clearUserProfileScreeningsList = function () {
    return this.setUserProfileScreeningsList([]);
};
/**
 * optional string prev_page_token = 2;
 * @return {string}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.getPrevPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.setPrevPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListUserProfileScreeningsV1Response} returns this
 */
proto.jp.singcolor.ListUserProfileScreeningsV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request;
    return proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addUserIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};
/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.getUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.setUserIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.addUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request} returns this
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Request.prototype.clearUserIdsList = function () {
    return this.setUserIdsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfileScreeningsList: jspb.Message.toObjectList(msg.getUserProfileScreeningsList(), users_entity_pb.UserProfileScreening.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response;
    return proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfileScreening;
                reader.readMessage(value, users_entity_pb.UserProfileScreening.deserializeBinaryFromReader);
                msg.addUserProfileScreenings(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfileScreeningsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.UserProfileScreening.serializeBinaryToWriter);
    }
};
/**
 * repeated UserProfileScreening user_profile_screenings = 1;
 * @return {!Array<!proto.UserProfileScreening>}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.getUserProfileScreeningsList = function () {
    return /** @type{!Array<!proto.UserProfileScreening>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserProfileScreening, 1));
};
/**
 * @param {!Array<!proto.UserProfileScreening>} value
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response} returns this
*/
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.setUserProfileScreeningsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.UserProfileScreening=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UserProfileScreening}
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.addUserProfileScreenings = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.UserProfileScreening, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response} returns this
 */
proto.jp.singcolor.ConfirmUserProfileScreeningsV1Response.prototype.clearUserProfileScreeningsList = function () {
    return this.setUserProfileScreeningsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.MaskUserProfileV1Request.repeatedFields_ = [2];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MaskUserProfileV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MaskUserProfileV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MaskUserProfileV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MaskUserProfileV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            violationCategoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            violationTargetIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            reportId: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request}
 */
proto.jp.singcolor.MaskUserProfileV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MaskUserProfileV1Request;
    return proto.jp.singcolor.MaskUserProfileV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MaskUserProfileV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request}
 */
proto.jp.singcolor.MaskUserProfileV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationCategoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.addViolationTargetIds(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setReportId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MaskUserProfileV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MaskUserProfileV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MaskUserProfileV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getViolationCategoryId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getViolationTargetIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
    f = message.getReportId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string violation_category_id = 3;
 * @return {string}
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.getViolationCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.setViolationCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated string violation_target_ids = 2;
 * @return {!Array<string>}
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.getViolationTargetIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.setViolationTargetIdsList = function (value) {
    return jspb.Message.setField(this, 2, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.addViolationTargetIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.clearViolationTargetIdsList = function () {
    return this.setViolationTargetIdsList([]);
};
/**
 * optional string report_id = 4;
 * @return {string}
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.getReportId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.MaskUserProfileV1Request} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Request.prototype.setReportId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MaskUserProfileV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MaskUserProfileV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MaskUserProfileV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MaskUserProfileV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userProfileScreening: (f = msg.getUserProfileScreening()) && users_entity_pb.UserProfileScreening.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Response}
 */
proto.jp.singcolor.MaskUserProfileV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MaskUserProfileV1Response;
    return proto.jp.singcolor.MaskUserProfileV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MaskUserProfileV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Response}
 */
proto.jp.singcolor.MaskUserProfileV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserProfileScreening;
                reader.readMessage(value, users_entity_pb.UserProfileScreening.deserializeBinaryFromReader);
                msg.setUserProfileScreening(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MaskUserProfileV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MaskUserProfileV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MaskUserProfileV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MaskUserProfileV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserProfileScreening();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserProfileScreening.serializeBinaryToWriter);
    }
};
/**
 * optional UserProfileScreening user_profile_screening = 1;
 * @return {?proto.UserProfileScreening}
 */
proto.jp.singcolor.MaskUserProfileV1Response.prototype.getUserProfileScreening = function () {
    return /** @type{?proto.UserProfileScreening} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserProfileScreening, 1));
};
/**
 * @param {?proto.UserProfileScreening|undefined} value
 * @return {!proto.jp.singcolor.MaskUserProfileV1Response} returns this
*/
proto.jp.singcolor.MaskUserProfileV1Response.prototype.setUserProfileScreening = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.MaskUserProfileV1Response} returns this
 */
proto.jp.singcolor.MaskUserProfileV1Response.prototype.clearUserProfileScreening = function () {
    return this.setUserProfileScreening(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.MaskUserProfileV1Response.prototype.hasUserProfileScreening = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request;
    return proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            totalRemainAccumulationDiamondFundAmount: (f = msg.getTotalRemainAccumulationDiamondFundAmount()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            singDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 3, ""),
            chatDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 4, ""),
            nextAccumulationDiamondFundDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
            acquireAccumulationDiamondEndOfTermIfSatisfiedConditions: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
            acquirableAccumulationDiamondFundAmountIfSatisfiedConditions: (f = msg.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions()) && diamonds_entity_pb.DiamondAmount.toObject(includeInstance, f),
            condition1: (f = msg.getCondition1()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f),
            condition2: (f = msg.getCondition2()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f),
            condition3: (f = msg.getCondition3()) && users_entity_pb.AcquireDiamondCondition.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response;
    return proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 2:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setTotalRemainAccumulationDiamondFundAmount(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setSingDiamondAcquisitionRight(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setChatDiamondAcquisitionRight(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextAccumulationDiamondFundDate(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions(value);
                break;
            case 6:
                var value = new diamonds_entity_pb.DiamondAmount;
                reader.readMessage(value, diamonds_entity_pb.DiamondAmount.deserializeBinaryFromReader);
                msg.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions(value);
                break;
            case 7:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition1(value);
                break;
            case 8:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition2(value);
                break;
            case 9:
                var value = new users_entity_pb.AcquireDiamondCondition;
                reader.readMessage(value, users_entity_pb.AcquireDiamondCondition.deserializeBinaryFromReader);
                msg.setCondition3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(1, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getTotalRemainAccumulationDiamondFundAmount();
    if (f != null) {
        writer.writeMessage(2, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getSingDiamondAcquisitionRight();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getChatDiamondAcquisitionRight();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getNextAccumulationDiamondFundDate();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions();
    if (f) {
        writer.writeBool(10, f);
    }
    f = message.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions();
    if (f != null) {
        writer.writeMessage(6, f, diamonds_entity_pb.DiamondAmount.serializeBinaryToWriter);
    }
    f = message.getCondition1();
    if (f != null) {
        writer.writeMessage(7, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
    f = message.getCondition2();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
    f = message.getCondition3();
    if (f != null) {
        writer.writeMessage(9, f, users_entity_pb.AcquireDiamondCondition.serializeBinaryToWriter);
    }
};
/**
 * optional DiamondBalance diamond_balance = 1;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 1));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional DiamondAmount total_remain_accumulation_diamond_fund_amount = 2;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getTotalRemainAccumulationDiamondFundAmount = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setTotalRemainAccumulationDiamondFundAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearTotalRemainAccumulationDiamondFundAmount = function () {
    return this.setTotalRemainAccumulationDiamondFundAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasTotalRemainAccumulationDiamondFundAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string sing_diamond_acquisition_right = 3;
 * @return {string}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getSingDiamondAcquisitionRight = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setSingDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string chat_diamond_acquisition_right = 4;
 * @return {string}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getChatDiamondAcquisitionRight = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setChatDiamondAcquisitionRight = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string next_accumulation_diamond_fund_date = 5;
 * @return {string}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getNextAccumulationDiamondFundDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setNextAccumulationDiamondFundDate = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional bool acquire_accumulation_diamond_end_of_term_if_satisfied_conditions = 10;
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setAcquireAccumulationDiamondEndOfTermIfSatisfiedConditions = function (value) {
    return jspb.Message.setProto3BooleanField(this, 10, value);
};
/**
 * optional DiamondAmount acquirable_accumulation_diamond_fund_amount_if_satisfied_conditions = 6;
 * @return {?proto.jp.singcolor.DiamondAmount}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return /** @type{?proto.jp.singcolor.DiamondAmount} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondAmount, 6));
};
/**
 * @param {?proto.jp.singcolor.DiamondAmount|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return this.setAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasAcquirableAccumulationDiamondFundAmountIfSatisfiedConditions = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional AcquireDiamondCondition condition1 = 7;
 * @return {?proto.AcquireDiamondCondition}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getCondition1 = function () {
    return /** @type{?proto.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 7));
};
/**
 * @param {?proto.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setCondition1 = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearCondition1 = function () {
    return this.setCondition1(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasCondition1 = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional AcquireDiamondCondition condition2 = 8;
 * @return {?proto.AcquireDiamondCondition}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getCondition2 = function () {
    return /** @type{?proto.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 8));
};
/**
 * @param {?proto.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setCondition2 = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearCondition2 = function () {
    return this.setCondition2(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasCondition2 = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional AcquireDiamondCondition condition3 = 9;
 * @return {?proto.AcquireDiamondCondition}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.getCondition3 = function () {
    return /** @type{?proto.AcquireDiamondCondition} */ (jspb.Message.getWrapperField(this, users_entity_pb.AcquireDiamondCondition, 9));
};
/**
 * @param {?proto.AcquireDiamondCondition|undefined} value
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
*/
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.setCondition3 = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response} returns this
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.clearCondition3 = function () {
    return this.setCondition3(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetStatisticsCurrentDiamondV1Response.prototype.hasCondition3 = function () {
    return jspb.Message.getField(this, 9) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            pageKey: (f = msg.getPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request;
    return proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setPageKey(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPageKey();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional MonthlyStatisticsPageKey page_key = 2;
 * @return {?proto.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.getPageKey = function () {
    return /** @type{?proto.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 2));
};
/**
 * @param {?proto.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.setPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.clearPageKey = function () {
    return this.setPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Request.prototype.hasPageKey = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.repeatedFields_ = [7];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            prevPageKey: (f = msg.getPrevPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f),
            nextPageKey: (f = msg.getNextPageKey()) && users_entity_pb.MonthlyStatisticsPageKey.toObject(includeInstance, f),
            monthlyStatistics: (f = msg.getMonthlyStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            firstTermStatistics: (f = msg.getFirstTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            secondTermStatistics: (f = msg.getSecondTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            thirdTermStatistics: (f = msg.getThirdTermStatistics()) && users_entity_pb.StatisticsRecord.toObject(includeInstance, f),
            dailyStatisticsList: jspb.Message.toObjectList(msg.getDailyStatisticsList(), users_entity_pb.StatisticsRecord.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response;
    return proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setPrevPageKey(value);
                break;
            case 2:
                var value = new users_entity_pb.MonthlyStatisticsPageKey;
                reader.readMessage(value, users_entity_pb.MonthlyStatisticsPageKey.deserializeBinaryFromReader);
                msg.setNextPageKey(value);
                break;
            case 3:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setMonthlyStatistics(value);
                break;
            case 4:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setFirstTermStatistics(value);
                break;
            case 5:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setSecondTermStatistics(value);
                break;
            case 6:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.setThirdTermStatistics(value);
                break;
            case 7:
                var value = new users_entity_pb.StatisticsRecord;
                reader.readMessage(value, users_entity_pb.StatisticsRecord.deserializeBinaryFromReader);
                msg.addDailyStatistics(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPrevPageKey();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
    f = message.getNextPageKey();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.MonthlyStatisticsPageKey.serializeBinaryToWriter);
    }
    f = message.getMonthlyStatistics();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getFirstTermStatistics();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getSecondTermStatistics();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getThirdTermStatistics();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
    f = message.getDailyStatisticsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.StatisticsRecord.serializeBinaryToWriter);
    }
};
/**
 * optional MonthlyStatisticsPageKey prev_page_key = 1;
 * @return {?proto.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getPrevPageKey = function () {
    return /** @type{?proto.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 1));
};
/**
 * @param {?proto.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setPrevPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearPrevPageKey = function () {
    return this.setPrevPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasPrevPageKey = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional MonthlyStatisticsPageKey next_page_key = 2;
 * @return {?proto.MonthlyStatisticsPageKey}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getNextPageKey = function () {
    return /** @type{?proto.MonthlyStatisticsPageKey} */ (jspb.Message.getWrapperField(this, users_entity_pb.MonthlyStatisticsPageKey, 2));
};
/**
 * @param {?proto.MonthlyStatisticsPageKey|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setNextPageKey = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearNextPageKey = function () {
    return this.setNextPageKey(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasNextPageKey = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional StatisticsRecord monthly_statistics = 3;
 * @return {?proto.StatisticsRecord}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getMonthlyStatistics = function () {
    return /** @type{?proto.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 3));
};
/**
 * @param {?proto.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setMonthlyStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearMonthlyStatistics = function () {
    return this.setMonthlyStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasMonthlyStatistics = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional StatisticsRecord first_term_statistics = 4;
 * @return {?proto.StatisticsRecord}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getFirstTermStatistics = function () {
    return /** @type{?proto.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 4));
};
/**
 * @param {?proto.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setFirstTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearFirstTermStatistics = function () {
    return this.setFirstTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasFirstTermStatistics = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional StatisticsRecord second_term_statistics = 5;
 * @return {?proto.StatisticsRecord}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getSecondTermStatistics = function () {
    return /** @type{?proto.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 5));
};
/**
 * @param {?proto.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setSecondTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearSecondTermStatistics = function () {
    return this.setSecondTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasSecondTermStatistics = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional StatisticsRecord third_term_statistics = 6;
 * @return {?proto.StatisticsRecord}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getThirdTermStatistics = function () {
    return /** @type{?proto.StatisticsRecord} */ (jspb.Message.getWrapperField(this, users_entity_pb.StatisticsRecord, 6));
};
/**
 * @param {?proto.StatisticsRecord|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setThirdTermStatistics = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearThirdTermStatistics = function () {
    return this.setThirdTermStatistics(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.hasThirdTermStatistics = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * repeated StatisticsRecord daily_statistics = 7;
 * @return {!Array<!proto.StatisticsRecord>}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.getDailyStatisticsList = function () {
    return /** @type{!Array<!proto.StatisticsRecord>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.StatisticsRecord, 7));
};
/**
 * @param {!Array<!proto.StatisticsRecord>} value
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
*/
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.setDailyStatisticsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.StatisticsRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StatisticsRecord}
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.addDailyStatistics = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.StatisticsRecord, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response} returns this
 */
proto.jp.singcolor.OpeGetMonthlyStatisticsV1Response.prototype.clearDailyStatisticsList = function () {
    return this.setDailyStatisticsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDetailV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDetailV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDetailV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDetailV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDetailV1Request}
 */
proto.jp.singcolor.GetUserDetailV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDetailV1Request;
    return proto.jp.singcolor.GetUserDetailV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDetailV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDetailV1Request}
 */
proto.jp.singcolor.GetUserDetailV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDetailV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDetailV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDetailV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDetailV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.GetUserDetailV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Request} returns this
 */
proto.jp.singcolor.GetUserDetailV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetUserDetailV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetUserDetailV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetUserDetailV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetUserDetailV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFlag: (f = msg.getUserFlag()) && users_entity_pb.UserFlag.toObject(includeInstance, f),
            userBirthday: (f = msg.getUserBirthday()) && users_entity_pb.UserBirthday.toObject(includeInstance, f),
            pfFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            coinBalance: (f = msg.getCoinBalance()) && coins_entity_pb.CoinBalance.toObject(includeInstance, f),
            diamondBalance: (f = msg.getDiamondBalance()) && diamonds_entity_pb.DiamondBalance.toObject(includeInstance, f),
            pearlAmountTotal: (f = msg.getPearlAmountTotal()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            organization: (f = msg.getOrganization()) && organization_entity_pb.Organization.toObject(includeInstance, f),
            livePermission: (f = msg.getLivePermission()) && lives_entity_pb.LivePermission.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response}
 */
proto.jp.singcolor.GetUserDetailV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetUserDetailV1Response;
    return proto.jp.singcolor.GetUserDetailV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetUserDetailV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response}
 */
proto.jp.singcolor.GetUserDetailV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFlag;
                reader.readMessage(value, users_entity_pb.UserFlag.deserializeBinaryFromReader);
                msg.setUserFlag(value);
                break;
            case 3:
                var value = new users_entity_pb.UserBirthday;
                reader.readMessage(value, users_entity_pb.UserBirthday.deserializeBinaryFromReader);
                msg.setUserBirthday(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoints(value);
                break;
            case 5:
                var value = new coins_entity_pb.CoinBalance;
                reader.readMessage(value, coins_entity_pb.CoinBalance.deserializeBinaryFromReader);
                msg.setCoinBalance(value);
                break;
            case 6:
                var value = new diamonds_entity_pb.DiamondBalance;
                reader.readMessage(value, diamonds_entity_pb.DiamondBalance.deserializeBinaryFromReader);
                msg.setDiamondBalance(value);
                break;
            case 8:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmountTotal(value);
                break;
            case 7:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            case 9:
                var value = new lives_entity_pb.LivePermission;
                reader.readMessage(value, lives_entity_pb.LivePermission.deserializeBinaryFromReader);
                msg.setLivePermission(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetUserDetailV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetUserDetailV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetUserDetailV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFlag();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFlag.serializeBinaryToWriter);
    }
    f = message.getUserBirthday();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.UserBirthday.serializeBinaryToWriter);
    }
    f = message.getPfFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getCoinBalance();
    if (f != null) {
        writer.writeMessage(5, f, coins_entity_pb.CoinBalance.serializeBinaryToWriter);
    }
    f = message.getDiamondBalance();
    if (f != null) {
        writer.writeMessage(6, f, diamonds_entity_pb.DiamondBalance.serializeBinaryToWriter);
    }
    f = message.getPearlAmountTotal();
    if (f != null) {
        writer.writeMessage(8, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(7, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
    f = message.getLivePermission();
    if (f != null) {
        writer.writeMessage(9, f, lives_entity_pb.LivePermission.serializeBinaryToWriter);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFlag user_flag = 2;
 * @return {?proto.UserFlag}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getUserFlag = function () {
    return /** @type{?proto.UserFlag} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFlag, 2));
};
/**
 * @param {?proto.UserFlag|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setUserFlag = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearUserFlag = function () {
    return this.setUserFlag(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasUserFlag = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional UserBirthday user_birthday = 3;
 * @return {?proto.UserBirthday}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getUserBirthday = function () {
    return /** @type{?proto.UserBirthday} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserBirthday, 3));
};
/**
 * @param {?proto.UserBirthday|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setUserBirthday = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearUserBirthday = function () {
    return this.setUserBirthday(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasUserBirthday = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 pf_fave_points = 4;
 * @return {number}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getPfFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.setPfFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional CoinBalance coin_balance = 5;
 * @return {?proto.CoinBalance}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getCoinBalance = function () {
    return /** @type{?proto.CoinBalance} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinBalance, 5));
};
/**
 * @param {?proto.CoinBalance|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setCoinBalance = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearCoinBalance = function () {
    return this.setCoinBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasCoinBalance = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional DiamondBalance diamond_balance = 6;
 * @return {?proto.jp.singcolor.DiamondBalance}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getDiamondBalance = function () {
    return /** @type{?proto.jp.singcolor.DiamondBalance} */ (jspb.Message.getWrapperField(this, diamonds_entity_pb.DiamondBalance, 6));
};
/**
 * @param {?proto.jp.singcolor.DiamondBalance|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setDiamondBalance = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearDiamondBalance = function () {
    return this.setDiamondBalance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasDiamondBalance = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional PearlAmount pearl_amount_total = 8;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getPearlAmountTotal = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 8));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setPearlAmountTotal = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearPearlAmountTotal = function () {
    return this.setPearlAmountTotal(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasPearlAmountTotal = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional Organization organization = 7;
 * @return {?proto.Organization}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getOrganization = function () {
    return /** @type{?proto.Organization} */ (jspb.Message.getWrapperField(this, organization_entity_pb.Organization, 7));
};
/**
 * @param {?proto.Organization|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional LivePermission live_permission = 9;
 * @return {?proto.LivePermission}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.getLivePermission = function () {
    return /** @type{?proto.LivePermission} */ (jspb.Message.getWrapperField(this, lives_entity_pb.LivePermission, 9));
};
/**
 * @param {?proto.LivePermission|undefined} value
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
*/
proto.jp.singcolor.GetUserDetailV1Response.prototype.setLivePermission = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetUserDetailV1Response} returns this
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.clearLivePermission = function () {
    return this.setLivePermission(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetUserDetailV1Response.prototype.hasLivePermission = function () {
    return jspb.Message.getField(this, 9) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request;
    return proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request} returns this
*/
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            screenNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response;
    return proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addScreenNames(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getScreenNamesList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated string screen_names = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.getScreenNamesList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.setScreenNamesList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.addScreenNames = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.clearScreenNamesList = function () {
    return this.setScreenNamesList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} returns this
*/
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response} returns this
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListLivePermittedTwitterAccountV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterScreenName: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request;
    return proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterScreenName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterScreenName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string twitter_screen_name = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.prototype.getTwitterScreenName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Request.prototype.setTwitterScreenName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response;
    return proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLivePermissionTwitterAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            twitterScreenName: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request;
    return proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTwitterScreenName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTwitterScreenName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string twitter_screen_name = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.prototype.getTwitterScreenName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request} returns this
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Request.prototype.setTwitterScreenName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response;
    return proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLivePermissionTwitterAccountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RegisterBankTransferTokenV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            code: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RegisterBankTransferTokenV1Request}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RegisterBankTransferTokenV1Request;
    return proto.jp.singcolor.RegisterBankTransferTokenV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RegisterBankTransferTokenV1Request}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RegisterBankTransferTokenV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string code = 1;
 * @return {string}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.RegisterBankTransferTokenV1Request} returns this
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Request.prototype.setCode = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.RegisterBankTransferTokenV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.RegisterBankTransferTokenV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.RegisterBankTransferTokenV1Response}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.RegisterBankTransferTokenV1Response;
    return proto.jp.singcolor.RegisterBankTransferTokenV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.RegisterBankTransferTokenV1Response}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.RegisterBankTransferTokenV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.RegisterBankTransferTokenV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.RegisterBankTransferTokenV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LeagueOverwriteResetAmount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LeagueOverwriteResetAmount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LeagueOverwriteResetAmount.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            resetAmount: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LeagueOverwriteResetAmount;
    return proto.jp.singcolor.LeagueOverwriteResetAmount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LeagueOverwriteResetAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setResetAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LeagueOverwriteResetAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LeagueOverwriteResetAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getResetAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount} returns this
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount} returns this
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount} returns this
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount} returns this
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 reset_amount = 5;
 * @return {number}
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.getResetAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount} returns this
 */
proto.jp.singcolor.LeagueOverwriteResetAmount.prototype.setResetAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request;
    return proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request} returns this
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            overwriteLeagueResetAmountsList: jspb.Message.toObjectList(msg.getOverwriteLeagueResetAmountsList(), proto.jp.singcolor.LeagueOverwriteResetAmount.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response;
    return proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.LeagueOverwriteResetAmount;
                reader.readMessage(value, proto.jp.singcolor.LeagueOverwriteResetAmount.deserializeBinaryFromReader);
                msg.addOverwriteLeagueResetAmounts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOverwriteLeagueResetAmountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.LeagueOverwriteResetAmount.serializeBinaryToWriter);
    }
};
/**
 * repeated LeagueOverwriteResetAmount overwrite_league_reset_amounts = 1;
 * @return {!Array<!proto.jp.singcolor.LeagueOverwriteResetAmount>}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.getOverwriteLeagueResetAmountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LeagueOverwriteResetAmount>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.LeagueOverwriteResetAmount, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LeagueOverwriteResetAmount>} value
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response} returns this
*/
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.setOverwriteLeagueResetAmountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LeagueOverwriteResetAmount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LeagueOverwriteResetAmount}
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.addOverwriteLeagueResetAmounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LeagueOverwriteResetAmount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response} returns this
 */
proto.jp.singcolor.ListLeagueOverwriteResetAmountsV1Response.prototype.clearOverwriteLeagueResetAmountsList = function () {
    return this.setOverwriteLeagueResetAmountsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            resetAmount: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request;
    return proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setResetAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getResetAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 reset_amount = 5;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.getResetAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Request.prototype.setResetAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            overwriteLeagueResetAmount: (f = msg.getOverwriteLeagueResetAmount()) && proto.jp.singcolor.LeagueOverwriteResetAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response;
    return proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.LeagueOverwriteResetAmount;
                reader.readMessage(value, proto.jp.singcolor.LeagueOverwriteResetAmount.deserializeBinaryFromReader);
                msg.setOverwriteLeagueResetAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOverwriteLeagueResetAmount();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.LeagueOverwriteResetAmount.serializeBinaryToWriter);
    }
};
/**
 * optional LeagueOverwriteResetAmount overwrite_league_reset_amount = 1;
 * @return {?proto.jp.singcolor.LeagueOverwriteResetAmount}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.getOverwriteLeagueResetAmount = function () {
    return /** @type{?proto.jp.singcolor.LeagueOverwriteResetAmount} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.LeagueOverwriteResetAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.LeagueOverwriteResetAmount|undefined} value
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response} returns this
*/
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.setOverwriteLeagueResetAmount = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response} returns this
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.clearOverwriteLeagueResetAmount = function () {
    return this.setOverwriteLeagueResetAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateLeagueOverwriteResetAmountV1Response.prototype.hasOverwriteLeagueResetAmount = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request;
    return proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Request.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response;
    return proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLeagueOverwriteResetAmountV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.LeagueResetCompensation.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.LeagueResetCompensation.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.LeagueResetCompensation} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.LeagueResetCompensation.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            amount: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.LeagueResetCompensation}
 */
proto.jp.singcolor.LeagueResetCompensation.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.LeagueResetCompensation;
    return proto.jp.singcolor.LeagueResetCompensation.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.LeagueResetCompensation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.LeagueResetCompensation}
 */
proto.jp.singcolor.LeagueResetCompensation.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.LeagueResetCompensation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.LeagueResetCompensation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.LeagueResetCompensation.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.LeagueResetCompensation} returns this
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueResetCompensation} returns this
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueResetCompensation} returns this
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.LeagueResetCompensation} returns this
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 amount = 5;
 * @return {number}
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.LeagueResetCompensation} returns this
 */
proto.jp.singcolor.LeagueResetCompensation.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLeagueResetCompensationsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLeagueResetCompensationsV1Request;
    return proto.jp.singcolor.ListLeagueResetCompensationsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLeagueResetCompensationsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Request} returns this
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListLeagueResetCompensationsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListLeagueResetCompensationsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            overwriteLeagueResetAmountsList: jspb.Message.toObjectList(msg.getOverwriteLeagueResetAmountsList(), proto.jp.singcolor.LeagueResetCompensation.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListLeagueResetCompensationsV1Response;
    return proto.jp.singcolor.ListLeagueResetCompensationsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.LeagueResetCompensation;
                reader.readMessage(value, proto.jp.singcolor.LeagueResetCompensation.deserializeBinaryFromReader);
                msg.addOverwriteLeagueResetAmounts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListLeagueResetCompensationsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOverwriteLeagueResetAmountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.jp.singcolor.LeagueResetCompensation.serializeBinaryToWriter);
    }
};
/**
 * repeated LeagueResetCompensation overwrite_league_reset_amounts = 1;
 * @return {!Array<!proto.jp.singcolor.LeagueResetCompensation>}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.getOverwriteLeagueResetAmountsList = function () {
    return /** @type{!Array<!proto.jp.singcolor.LeagueResetCompensation>} */ (jspb.Message.getRepeatedWrapperField(this, proto.jp.singcolor.LeagueResetCompensation, 1));
};
/**
 * @param {!Array<!proto.jp.singcolor.LeagueResetCompensation>} value
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response} returns this
*/
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.setOverwriteLeagueResetAmountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.jp.singcolor.LeagueResetCompensation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.LeagueResetCompensation}
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.addOverwriteLeagueResetAmounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.jp.singcolor.LeagueResetCompensation, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListLeagueResetCompensationsV1Response} returns this
 */
proto.jp.singcolor.ListLeagueResetCompensationsV1Response.prototype.clearOverwriteLeagueResetAmountsList = function () {
    return this.setOverwriteLeagueResetAmountsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLeagueResetCompensationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0),
            amount: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLeagueResetCompensationV1Request;
    return proto.jp.singcolor.CreateLeagueResetCompensationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLeagueResetCompensationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getAmount();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional int64 amount = 5;
 * @return {number}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Request.prototype.setAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.CreateLeagueResetCompensationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.CreateLeagueResetCompensationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            leagueResetCompensation: (f = msg.getLeagueResetCompensation()) && proto.jp.singcolor.LeagueResetCompensation.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.CreateLeagueResetCompensationV1Response;
    return proto.jp.singcolor.CreateLeagueResetCompensationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.LeagueResetCompensation;
                reader.readMessage(value, proto.jp.singcolor.LeagueResetCompensation.deserializeBinaryFromReader);
                msg.setLeagueResetCompensation(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.CreateLeagueResetCompensationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLeagueResetCompensation();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.LeagueResetCompensation.serializeBinaryToWriter);
    }
};
/**
 * optional LeagueResetCompensation league_reset_compensation = 1;
 * @return {?proto.jp.singcolor.LeagueResetCompensation}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.getLeagueResetCompensation = function () {
    return /** @type{?proto.jp.singcolor.LeagueResetCompensation} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.LeagueResetCompensation, 1));
};
/**
 * @param {?proto.jp.singcolor.LeagueResetCompensation|undefined} value
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response} returns this
*/
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.setLeagueResetCompensation = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.CreateLeagueResetCompensationV1Response} returns this
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.clearLeagueResetCompensation = function () {
    return this.setLeagueResetCompensation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.CreateLeagueResetCompensationV1Response.prototype.hasLeagueResetCompensation = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            year: jspb.Message.getFieldWithDefault(msg, 2, 0),
            month: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLeagueResetCompensationV1Request;
    return proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setYear(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 4:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 year = 2;
 * @return {number}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 month = 3;
 * @return {number}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional TermOfMonth term_of_month = 4;
 * @return {!proto.TermOfMonth}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Request} returns this
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Request.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Response}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteLeagueResetCompensationV1Response;
    return proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Response}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteLeagueResetCompensationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteLeagueResetCompensationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MaskLiveBackgroundV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MaskLiveBackgroundV1Request}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MaskLiveBackgroundV1Request;
    return proto.jp.singcolor.MaskLiveBackgroundV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MaskLiveBackgroundV1Request}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MaskLiveBackgroundV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.MaskLiveBackgroundV1Request} returns this
 */
proto.jp.singcolor.MaskLiveBackgroundV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.MaskLiveBackgroundV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.MaskLiveBackgroundV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.MaskLiveBackgroundV1Response}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.MaskLiveBackgroundV1Response;
    return proto.jp.singcolor.MaskLiveBackgroundV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.MaskLiveBackgroundV1Response}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.MaskLiveBackgroundV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.MaskLiveBackgroundV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.MaskLiveBackgroundV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.MaskLiveBackgroundV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserDevicesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserDevicesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserDevicesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserDevicesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserDevicesV1Request}
 */
proto.jp.singcolor.ListUserDevicesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserDevicesV1Request;
    return proto.jp.singcolor.ListUserDevicesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserDevicesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserDevicesV1Request}
 */
proto.jp.singcolor.ListUserDevicesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserDevicesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserDevicesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserDevicesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserDevicesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListUserDevicesV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListUserDevicesV1Request} returns this
 */
proto.jp.singcolor.ListUserDevicesV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListUserDevicesV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListUserDevicesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListUserDevicesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListUserDevicesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListUserDevicesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            userDevicesList: jspb.Message.toObjectList(msg.getUserDevicesList(), users_entity_pb.UserDevice.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListUserDevicesV1Response}
 */
proto.jp.singcolor.ListUserDevicesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListUserDevicesV1Response;
    return proto.jp.singcolor.ListUserDevicesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListUserDevicesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListUserDevicesV1Response}
 */
proto.jp.singcolor.ListUserDevicesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserDevice;
                reader.readMessage(value, users_entity_pb.UserDevice.deserializeBinaryFromReader);
                msg.addUserDevices(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListUserDevicesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListUserDevicesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListUserDevicesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListUserDevicesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserDevicesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, users_entity_pb.UserDevice.serializeBinaryToWriter);
    }
};
/**
 * repeated UserDevice user_devices = 1;
 * @return {!Array<!proto.UserDevice>}
 */
proto.jp.singcolor.ListUserDevicesV1Response.prototype.getUserDevicesList = function () {
    return /** @type{!Array<!proto.UserDevice>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserDevice, 1));
};
/**
 * @param {!Array<!proto.UserDevice>} value
 * @return {!proto.jp.singcolor.ListUserDevicesV1Response} returns this
*/
proto.jp.singcolor.ListUserDevicesV1Response.prototype.setUserDevicesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.UserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UserDevice}
 */
proto.jp.singcolor.ListUserDevicesV1Response.prototype.addUserDevices = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.UserDevice, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListUserDevicesV1Response} returns this
 */
proto.jp.singcolor.ListUserDevicesV1Response.prototype.clearUserDevicesList = function () {
    return this.setUserDevicesList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteUserDeviceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteUserDeviceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteUserDeviceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            deviceId: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Request}
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteUserDeviceV1Request;
    return proto.jp.singcolor.DeleteUserDeviceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteUserDeviceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Request}
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDeviceId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteUserDeviceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteUserDeviceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDeviceId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Request} returns this
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.getDeviceId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Request} returns this
 */
proto.jp.singcolor.DeleteUserDeviceV1Request.prototype.setDeviceId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.DeleteUserDeviceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.DeleteUserDeviceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.DeleteUserDeviceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.DeleteUserDeviceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Response}
 */
proto.jp.singcolor.DeleteUserDeviceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.DeleteUserDeviceV1Response;
    return proto.jp.singcolor.DeleteUserDeviceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.DeleteUserDeviceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.DeleteUserDeviceV1Response}
 */
proto.jp.singcolor.DeleteUserDeviceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.DeleteUserDeviceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.DeleteUserDeviceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.DeleteUserDeviceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.DeleteUserDeviceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListDevicesByUserIDRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListDevicesByUserIDRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListDevicesByUserIDRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListDevicesByUserIDRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListDevicesByUserIDRequest}
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListDevicesByUserIDRequest;
    return proto.jp.singcolor.ListDevicesByUserIDRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListDevicesByUserIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListDevicesByUserIDRequest}
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListDevicesByUserIDRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListDevicesByUserIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListDevicesByUserIDRequest} returns this
 */
proto.jp.singcolor.ListDevicesByUserIDRequest.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListDevicesByUserIDResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListDevicesByUserIDResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListDevicesByUserIDResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            devicesList: jspb.Message.toObjectList(msg.getDevicesList(), ope_user_entity_pb.OpeUserDevice.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListDevicesByUserIDResponse}
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListDevicesByUserIDResponse;
    return proto.jp.singcolor.ListDevicesByUserIDResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListDevicesByUserIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListDevicesByUserIDResponse}
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new ope_user_entity_pb.OpeUserDevice;
                reader.readMessage(value, ope_user_entity_pb.OpeUserDevice.deserializeBinaryFromReader);
                msg.addDevices(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListDevicesByUserIDResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListDevicesByUserIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getDevicesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, ope_user_entity_pb.OpeUserDevice.serializeBinaryToWriter);
    }
};
/**
 * repeated OpeUserDevice devices = 1;
 * @return {!Array<!proto.OpeUserDevice>}
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.getDevicesList = function () {
    return /** @type{!Array<!proto.OpeUserDevice>} */ (jspb.Message.getRepeatedWrapperField(this, ope_user_entity_pb.OpeUserDevice, 1));
};
/**
 * @param {!Array<!proto.OpeUserDevice>} value
 * @return {!proto.jp.singcolor.ListDevicesByUserIDResponse} returns this
*/
proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.setDevicesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.OpeUserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OpeUserDevice}
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.addDevices = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OpeUserDevice, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListDevicesByUserIDResponse} returns this
 */
proto.jp.singcolor.ListDevicesByUserIDResponse.prototype.clearDevicesList = function () {
    return this.setDevicesList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.SendSystemMessageV1Request.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendSystemMessageV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendSystemMessageV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendSystemMessageV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendSystemMessageV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            message: jspb.Message.getFieldWithDefault(msg, 2, ""),
            externalLink: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request}
 */
proto.jp.singcolor.SendSystemMessageV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendSystemMessageV1Request;
    return proto.jp.singcolor.SendSystemMessageV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendSystemMessageV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request}
 */
proto.jp.singcolor.SendSystemMessageV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.addUserIds(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setExternalLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendSystemMessageV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendSystemMessageV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendSystemMessageV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getExternalLink();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * repeated string user_ids = 1;
 * @return {!Array<string>}
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.getUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request} returns this
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.setUserIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request} returns this
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.addUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request} returns this
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.clearUserIdsList = function () {
    return this.setUserIdsList([]);
};
/**
 * optional string message = 2;
 * @return {string}
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request} returns this
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string external_link = 3;
 * @return {string}
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.getExternalLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.SendSystemMessageV1Request} returns this
 */
proto.jp.singcolor.SendSystemMessageV1Request.prototype.setExternalLink = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.SendSystemMessageV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.SendSystemMessageV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.SendSystemMessageV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.SendSystemMessageV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.SendSystemMessageV1Response}
 */
proto.jp.singcolor.SendSystemMessageV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.SendSystemMessageV1Response;
    return proto.jp.singcolor.SendSystemMessageV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.SendSystemMessageV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.SendSystemMessageV1Response}
 */
proto.jp.singcolor.SendSystemMessageV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.SendSystemMessageV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.SendSystemMessageV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.SendSystemMessageV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.SendSystemMessageV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PassLivePermissionFirstReviewV1Request;
    return proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Request} returns this
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Response}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PassLivePermissionFirstReviewV1Response;
    return proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Response}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PassLivePermissionFirstReviewV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PassLivePermissionFirstReviewV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PassLivePermissionSecondReviewV1Request;
    return proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Request} returns this
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Request.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Response}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.PassLivePermissionSecondReviewV1Response;
    return proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Response}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.PassLivePermissionSecondReviewV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.PassLivePermissionSecondReviewV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
goog.object.extend(exports, proto.jp.singcolor);
