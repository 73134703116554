/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_live_v1_pb from './ope_live_v1_pb';
var OpeLiveV1Client = /** @class */ (function () {
    function OpeLiveV1Client(hostname, credentials, options) {
        this.methodDescriptorGetLive = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/GetLive', grpcWeb.MethodType.UNARY, ope_live_v1_pb.OpeGetLiveRequest, ope_live_v1_pb.OpeGetLiveResponse, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.OpeGetLiveResponse.deserializeBinary);
        this.methodDescriptorListLives = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/ListLives', grpcWeb.MethodType.UNARY, ope_live_v1_pb.OpeListLivesRequest, ope_live_v1_pb.OpeListLivesResponse, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.OpeListLivesResponse.deserializeBinary);
        this.methodDescriptorWarnLive = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/WarnLive', grpcWeb.MethodType.UNARY, ope_live_v1_pb.WarnLiveRequest, ope_live_v1_pb.WarnLiveResponse, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.WarnLiveResponse.deserializeBinary);
        this.methodDescriptorForceStopLive = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/ForceStopLive', grpcWeb.MethodType.UNARY, ope_live_v1_pb.ForceStopLiveRequest, ope_live_v1_pb.ForceStopLiveResponse, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.ForceStopLiveResponse.deserializeBinary);
        this.methodDescriptorListReviewLives = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/ListReviewLives', grpcWeb.MethodType.UNARY, ope_live_v1_pb.ListReviewLivesRequest, ope_live_v1_pb.ListReviewLivesResponse, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.ListReviewLivesResponse.deserializeBinary);
        this.methodDescriptorGetRecordHLSData = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeLiveV1/GetRecordHLSData', grpcWeb.MethodType.UNARY, ope_live_v1_pb.GetRecordHLSDataV1Request, ope_live_v1_pb.GetRecordHLSDataV1Response, function (request) {
            return request.serializeBinary();
        }, ope_live_v1_pb.GetRecordHLSDataV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeLiveV1Client.prototype.getLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/GetLive', request, metadata || {}, this.methodDescriptorGetLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/GetLive', request, metadata || {}, this.methodDescriptorGetLive);
    };
    OpeLiveV1Client.prototype.listLives = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/ListLives', request, metadata || {}, this.methodDescriptorListLives, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/ListLives', request, metadata || {}, this.methodDescriptorListLives);
    };
    OpeLiveV1Client.prototype.warnLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/WarnLive', request, metadata || {}, this.methodDescriptorWarnLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/WarnLive', request, metadata || {}, this.methodDescriptorWarnLive);
    };
    OpeLiveV1Client.prototype.forceStopLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/ForceStopLive', request, metadata || {}, this.methodDescriptorForceStopLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/ForceStopLive', request, metadata || {}, this.methodDescriptorForceStopLive);
    };
    OpeLiveV1Client.prototype.listReviewLives = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/ListReviewLives', request, metadata || {}, this.methodDescriptorListReviewLives, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/ListReviewLives', request, metadata || {}, this.methodDescriptorListReviewLives);
    };
    OpeLiveV1Client.prototype.getRecordHLSData = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeLiveV1/GetRecordHLSData', request, metadata || {}, this.methodDescriptorGetRecordHLSData, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeLiveV1/GetRecordHLSData', request, metadata || {}, this.methodDescriptorGetRecordHLSData);
    };
    return OpeLiveV1Client;
}());
export { OpeLiveV1Client };
