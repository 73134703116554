/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_master_data_v1_pb from './ope_master_data_v1_pb';
var OpeMasterDataV1Client = /** @class */ (function () {
    function OpeMasterDataV1Client(hostname, credentials, options) {
        this.methodDescriptorListVersion = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/ListVersion', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.ListVersionRequest, ope_master_data_v1_pb.ListVersionResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.ListVersionResponse.deserializeBinary);
        this.methodDescriptorRegisterVersion = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/RegisterVersion', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.RegisterVersionRequest, ope_master_data_v1_pb.RegisterVersionResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.RegisterVersionResponse.deserializeBinary);
        this.methodDescriptorEnableVersion = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/EnableVersion', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.EnableVersionRequest, ope_master_data_v1_pb.EnableVersionResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.EnableVersionResponse.deserializeBinary);
        this.methodDescriptorListViolationCategories = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/ListViolationCategories', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.ListViolationCategoriesRequest, ope_master_data_v1_pb.ListViolationCategoriesResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.ListViolationCategoriesResponse.deserializeBinary);
        this.methodDescriptorListViolationTargets = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/ListViolationTargets', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.ListViolationTargetsRequest, ope_master_data_v1_pb.ListViolationTargetsResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.ListViolationTargetsResponse.deserializeBinary);
        this.methodDescriptorListBanLevels = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMasterDataV1/ListBanLevels', grpcWeb.MethodType.UNARY, ope_master_data_v1_pb.ListBanLevelsRequest, ope_master_data_v1_pb.ListBanLevelsResponse, function (request) {
            return request.serializeBinary();
        }, ope_master_data_v1_pb.ListBanLevelsResponse.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeMasterDataV1Client.prototype.listVersion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/ListVersion', request, metadata || {}, this.methodDescriptorListVersion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/ListVersion', request, metadata || {}, this.methodDescriptorListVersion);
    };
    OpeMasterDataV1Client.prototype.registerVersion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/RegisterVersion', request, metadata || {}, this.methodDescriptorRegisterVersion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/RegisterVersion', request, metadata || {}, this.methodDescriptorRegisterVersion);
    };
    OpeMasterDataV1Client.prototype.enableVersion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/EnableVersion', request, metadata || {}, this.methodDescriptorEnableVersion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/EnableVersion', request, metadata || {}, this.methodDescriptorEnableVersion);
    };
    OpeMasterDataV1Client.prototype.listViolationCategories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/ListViolationCategories', request, metadata || {}, this.methodDescriptorListViolationCategories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/ListViolationCategories', request, metadata || {}, this.methodDescriptorListViolationCategories);
    };
    OpeMasterDataV1Client.prototype.listViolationTargets = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/ListViolationTargets', request, metadata || {}, this.methodDescriptorListViolationTargets, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/ListViolationTargets', request, metadata || {}, this.methodDescriptorListViolationTargets);
    };
    OpeMasterDataV1Client.prototype.listBanLevels = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMasterDataV1/ListBanLevels', request, metadata || {}, this.methodDescriptorListBanLevels, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMasterDataV1/ListBanLevels', request, metadata || {}, this.methodDescriptorListBanLevels);
    };
    return OpeMasterDataV1Client;
}());
export { OpeMasterDataV1Client };
