var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DiamondsV1Client } from '../singcolor-proto/ts/Diamonds_v1ServiceClientPb';
import { ExchangeDiamondStatusV1Request, ExchangeDiamondToCoinV1Request, StatementsV1Request, WithdrawDiamondV1Request, ListBankAccountsV1Request, ListBankBranchesV1Request, ListBanksV1Request, CreateBankAccountV1Request, DeleteBankAccountV1Request, ListMajorBanksV1Request, GetEstimatedExchangeRateV1Request, ListWithdrawalDiamondLedgersV1Request, GetWithdrawalDiamondLedgerV1Request, GetBankV1Request, GetWithdrawDiamondStatusV1Request } from '../singcolor-proto/ts/diamonds_v1_pb';
import { CursorPaginationRequest } from '../singcolor-proto/ts/page_entity_pb';
var DiamondsGrpcClient = /** @class */ (function () {
    function DiamondsGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new DiamondsV1Client(process.env.NEXT_PUBLIC_API_URL, null, null);
    }
    DiamondsGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    DiamondsGrpcClient.prototype.exchangeDiamondStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ExchangeDiamondStatusV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.exchangeDiamondStatus(request, metadata);
                    })];
            });
        });
    };
    DiamondsGrpcClient.prototype.exchangeDiamondToCoin = function (diamondType, planID) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ExchangeDiamondToCoinV1Request();
                request.setPlanId(planID);
                request.setDiamondType(diamondType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.exchangeDiamondToCoin(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ換金履歴
    DiamondsGrpcClient.prototype.statements = function (_a) {
        var pageToken = _a.pageToken, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, page;
            var _this = this;
            return __generator(this, function (_b) {
                request = new StatementsV1Request();
                page = new CursorPaginationRequest();
                if (pageToken)
                    page.setPageToken(pageToken);
                page.setLimit(limit);
                request.setPage(page);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.statements(request, metadata);
                    })];
            });
        });
    };
    // ダイヤの換金
    DiamondsGrpcClient.prototype.withdrawDiamond = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var bankAccountId, diamondAmount, withdrawableDiamondType, isAgreedTaxWithholding, request;
            var _this = this;
            return __generator(this, function (_a) {
                bankAccountId = params.bankAccountId, diamondAmount = params.diamondAmount, withdrawableDiamondType = params.withdrawableDiamondType, isAgreedTaxWithholding = params.isAgreedTaxWithholding;
                request = new WithdrawDiamondV1Request();
                request.setBankAccountId(bankAccountId);
                request.setWithdrawableDiamondType(withdrawableDiamondType);
                request.setDiamondAmount(diamondAmount);
                request.setIsAgreedTaxWithholding(isAgreedTaxWithholding);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.withdrawDiamond(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ交換レートの取得
    DiamondsGrpcClient.prototype.getEstimatedExchangeRate = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var diamondAmount, withdrawableDiamondType, request;
            var _this = this;
            return __generator(this, function (_a) {
                diamondAmount = params.diamondAmount, withdrawableDiamondType = params.withdrawableDiamondType;
                request = new GetEstimatedExchangeRateV1Request();
                request.setDiamondAmount(diamondAmount);
                request.setWithdrawableDiamondType(withdrawableDiamondType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getEstimatedExchangeRate(request, metadata);
                    })];
            });
        });
    };
    // 基本的な銀行名一覧
    DiamondsGrpcClient.prototype.listMajorBanks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListMajorBanksV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listMajorBanks(request, metadata);
                    })];
            });
        });
    };
    // 支店名一覧
    DiamondsGrpcClient.prototype.listBankBranches = function (_a) {
        var bankCode = _a.bankCode, initialKana = _a.initialKana;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListBankBranchesV1Request();
                request.setBankCode(bankCode);
                if (initialKana) {
                    request.setInitialKana(initialKana);
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listBankBranches(request, metadata);
                    })];
            });
        });
    };
    // 銀行リスト
    DiamondsGrpcClient.prototype.listBanks = function (_a) {
        var initalKana = _a.initalKana;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListBanksV1Request();
                request.setInitialKana(initalKana);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listBanks(request, metadata);
                    })];
            });
        });
    };
    // 銀行リスト
    DiamondsGrpcClient.prototype.getBank = function (_a) {
        var bankCode = _a.bankCode;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetBankV1Request();
                request.setBankCode(bankCode);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getBank(request, metadata);
                    })];
            });
        });
    };
    // ユーザーに紐づく銀行口座一覧
    DiamondsGrpcClient.prototype.listBankAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListBankAccountsV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listBankAccounts(request, metadata);
                    })];
            });
        });
    };
    // ユーザーに紐づく銀行口座の作成(申請)
    DiamondsGrpcClient.prototype.createBankAccount = function (_a) {
        var accountName = _a.accountName, accountNumber = _a.accountNumber, accountType = _a.accountType, address = _a.address, postalCode = _a.postalCode, bankCode = _a.bankCode, birthday = _a.birthday, companyAddress = _a.companyAddress, companyName = _a.companyName, branchCode = _a.branchCode, companyPostalCode = _a.companyPostalCode, companyRepresentativeName = _a.companyRepresentativeName, kanaName = _a.kanaName, name = _a.name, invoiceNumber = _a.invoiceNumber, invoiceRegistrationName = _a.invoiceRegistrationName, invoiceRegistrationDate = _a.invoiceRegistrationDate;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateBankAccountV1Request();
                request.setAccountName(accountName);
                request.setAccountNumber(accountNumber);
                request.setAccountType(accountType);
                request.setBankCode(bankCode);
                if (kanaName)
                    request.setKanaName(kanaName);
                if (name)
                    request.setName(name);
                if (address)
                    request.setAddress(address);
                if (postalCode)
                    request.setPostalCode(postalCode);
                if (birthday)
                    request.setBirthday(birthday);
                if (companyAddress)
                    request.setCompanyAddress(companyAddress);
                if (companyName)
                    request.setCompanyName(companyName);
                if (branchCode)
                    request.setBranchCode(branchCode);
                if (companyPostalCode)
                    request.setCompanyPostalCode(companyPostalCode);
                if (companyRepresentativeName)
                    request.setCompanyRepresentativeName(companyRepresentativeName);
                // インボイス登録番号が必要な場合は、事業者名と登録日は必須
                if (invoiceNumber) {
                    if (!invoiceRegistrationName || !invoiceRegistrationDate) {
                        throw new Error('インボイス登録日とインボイス事業者名必須です');
                    }
                    request.setInvoiceNumber(invoiceNumber);
                    request.setInvoiceRegistrationName(invoiceRegistrationName);
                    request.setInvoiceRegistrationDate(invoiceRegistrationDate);
                    console.log({ invoiceRegistrationDate: invoiceRegistrationDate, invoiceNumber: invoiceNumber, invoiceRegistrationName: invoiceRegistrationName });
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createBankAccount(request, metadata);
                    })];
            });
        });
    };
    // ユーザーに紐づく銀行口座を削除する
    DiamondsGrpcClient.prototype.deleteBankAccount = function (_a) {
        var bankAccountId = _a.bankAccountId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteBankAccountV1Request();
                request.setBankAccountId(bankAccountId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteBankAccount(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ換金申請履歴
    DiamondsGrpcClient.prototype.listWithdrawalDiamondLedgers = function (_a) {
        var targetYear = _a.targetYear;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListWithdrawalDiamondLedgersV1Request();
                if (targetYear)
                    request.setTargetYear(targetYear);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listWithdrawalDiamondLedgers(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ換金申請履歴の単体取得
    DiamondsGrpcClient.prototype.getWithdrawalDiamondLedger = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var withdrawalDiamondTransactionId, request;
            var _this = this;
            return __generator(this, function (_a) {
                withdrawalDiamondTransactionId = params.withdrawalDiamondTransactionId;
                request = new GetWithdrawalDiamondLedgerV1Request();
                request.setWithdrawalDiamondTransactionId(withdrawalDiamondTransactionId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getWithdrawalDiamondLedger(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ換金に関するステータスを返却する
    DiamondsGrpcClient.prototype.getWithdrawDiamondStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetWithdrawDiamondStatusV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getWithdrawDiamondStatus(request, metadata);
                    })];
            });
        });
    };
    return DiamondsGrpcClient;
}());
export { DiamondsGrpcClient };
