/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_maintenance_v1_pb from './ope_maintenance_v1_pb';
var OpeMaintenanceV1Client = /** @class */ (function () {
    function OpeMaintenanceV1Client(hostname, credentials, options) {
        this.methodDescriptorGetMaintenanceV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMaintenanceV1/GetMaintenanceV1', grpcWeb.MethodType.UNARY, ope_maintenance_v1_pb.OpeGetMaintenanceV1Request, ope_maintenance_v1_pb.OpeGetMaintenanceV1Response, function (request) {
            return request.serializeBinary();
        }, ope_maintenance_v1_pb.OpeGetMaintenanceV1Response.deserializeBinary);
        this.methodDescriptorUpdateMaintenanceV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeMaintenanceV1/UpdateMaintenanceV1', grpcWeb.MethodType.UNARY, ope_maintenance_v1_pb.UpdateMaintenanceV1Request, ope_maintenance_v1_pb.UpdateMaintenanceV1Response, function (request) {
            return request.serializeBinary();
        }, ope_maintenance_v1_pb.UpdateMaintenanceV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeMaintenanceV1Client.prototype.getMaintenanceV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMaintenanceV1/GetMaintenanceV1', request, metadata || {}, this.methodDescriptorGetMaintenanceV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMaintenanceV1/GetMaintenanceV1', request, metadata || {}, this.methodDescriptorGetMaintenanceV1);
    };
    OpeMaintenanceV1Client.prototype.updateMaintenanceV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeMaintenanceV1/UpdateMaintenanceV1', request, metadata || {}, this.methodDescriptorUpdateMaintenanceV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeMaintenanceV1/UpdateMaintenanceV1', request, metadata || {}, this.methodDescriptorUpdateMaintenanceV1);
    };
    return OpeMaintenanceV1Client;
}());
export { OpeMaintenanceV1Client };
