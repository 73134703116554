"use strict";
// source: lives_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var users_entity_pb = require('./users_entity_pb.js');
goog.object.extend(proto, users_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var coins_entity_pb = require('./coins_entity_pb.js');
goog.object.extend(proto, coins_entity_pb);
goog.exportSymbol('proto.EmojiComment', null, global);
goog.exportSymbol('proto.EmojiCommentTab', null, global);
goog.exportSymbol('proto.EmojiCommentTabSection', null, global);
goog.exportSymbol('proto.Gift', null, global);
goog.exportSymbol('proto.Gift.BigGiftCoinAmount', null, global);
goog.exportSymbol('proto.GiftKind', null, global);
goog.exportSymbol('proto.GiftTab', null, global);
goog.exportSymbol('proto.GiftTabSection', null, global);
goog.exportSymbol('proto.GiftTabSectionType', null, global);
goog.exportSymbol('proto.GiftTabType', null, global);
goog.exportSymbol('proto.GiftType', null, global);
goog.exportSymbol('proto.HeartfulMention', null, global);
goog.exportSymbol('proto.HeartfulMentionButton', null, global);
goog.exportSymbol('proto.ListFanInfoFaveBadgeTab', null, global);
goog.exportSymbol('proto.ListFanInfoFaveBadgeUser', null, global);
goog.exportSymbol('proto.ListFanInfoSongFaveTab', null, global);
goog.exportSymbol('proto.ListFanInfoSongFaveUser', null, global);
goog.exportSymbol('proto.ListeningReactionGiftButton', null, global);
goog.exportSymbol('proto.Live', null, global);
goog.exportSymbol('proto.LiveBackground', null, global);
goog.exportSymbol('proto.LiveBackgroundType', null, global);
goog.exportSymbol('proto.LiveComment', null, global);
goog.exportSymbol('proto.LiveCommentMention', null, global);
goog.exportSymbol('proto.LiveEvent', null, global);
goog.exportSymbol('proto.LiveEvent.EventCase', null, global);
goog.exportSymbol('proto.LiveEventAnnounce', null, global);
goog.exportSymbol('proto.LiveEventAnnounce.AnnounceType', null, global);
goog.exportSymbol('proto.LiveEventComment', null, global);
goog.exportSymbol('proto.LiveEventEnter', null, global);
goog.exportSymbol('proto.LiveEventFinish', null, global);
goog.exportSymbol('proto.LiveEventFinishSing', null, global);
goog.exportSymbol('proto.LiveEventFollow', null, global);
goog.exportSymbol('proto.LiveEventGetFaveBadge', null, global);
goog.exportSymbol('proto.LiveEventGetFaveBadgeAndSongFave', null, global);
goog.exportSymbol('proto.LiveEventGetFaveBonus', null, global);
goog.exportSymbol('proto.LiveEventGetFaveBonus.ValueCase', null, global);
goog.exportSymbol('proto.LiveEventGetFaveBonusTitle', null, global);
goog.exportSymbol('proto.LiveEventGetFavePoints', null, global);
goog.exportSymbol('proto.LiveEventGetSongFave', null, global);
goog.exportSymbol('proto.LiveEventGetSongRandomly', null, global);
goog.exportSymbol('proto.LiveEventHeartfulMention', null, global);
goog.exportSymbol('proto.LiveEventLargeGift', null, global);
goog.exportSymbol('proto.LiveEventLeave', null, global);
goog.exportSymbol('proto.LiveEventLike', null, global);
goog.exportSymbol('proto.LiveEventListenerReqCapturePossibilityUpdated', null, global);
goog.exportSymbol('proto.LiveEventLiveSettingsUpdated', null, global);
goog.exportSymbol('proto.LiveEventMembershipUpdated', null, global);
goog.exportSymbol('proto.LiveEventPickUpSongRandomly', null, global);
goog.exportSymbol('proto.LiveEventRequestSing', null, global);
goog.exportSymbol('proto.LiveEventSimplePFComment', null, global);
goog.exportSymbol('proto.LiveEventSimplePFCommentType', null, global);
goog.exportSymbol('proto.LiveEventSmallGift', null, global);
goog.exportSymbol('proto.LiveEventStart', null, global);
goog.exportSymbol('proto.LiveEventStartSing', null, global);
goog.exportSymbol('proto.LiveEventSuperLike', null, global);
goog.exportSymbol('proto.LiveEventSurgeRankingNo1Reward', null, global);
goog.exportSymbol('proto.LiveEventSystem', null, global);
goog.exportSymbol('proto.LiveEventType', null, global);
goog.exportSymbol('proto.LiveFinishReason', null, global);
goog.exportSymbol('proto.LiveHistory', null, global);
goog.exportSymbol('proto.LiveLiverInfo', null, global);
goog.exportSymbol('proto.LiveMode', null, global);
goog.exportSymbol('proto.LivePermission', null, global);
goog.exportSymbol('proto.LivePermission.FailedSecondReviewInfo', null, global);
goog.exportSymbol('proto.LivePermission.SecondReviewInfo', null, global);
goog.exportSymbol('proto.LivePermission.Status', null, global);
goog.exportSymbol('proto.LivePermission.TrialInfo', null, global);
goog.exportSymbol('proto.LiveResultFavePoint', null, global);
goog.exportSymbol('proto.LiveResultGiftCount', null, global);
goog.exportSymbol('proto.LiveResultGiftListener', null, global);
goog.exportSymbol('proto.LiveTimelineBanner', null, global);
goog.exportSymbol('proto.LiveTimelineInfo', null, global);
goog.exportSymbol('proto.LiveTimelineItem', null, global);
goog.exportSymbol('proto.LiveTimelineItem.BeginnerType', null, global);
goog.exportSymbol('proto.LiveViewingSession', null, global);
goog.exportSymbol('proto.OnlineServer', null, global);
goog.exportSymbol('proto.PfFavePointsRankingTerm', null, global);
goog.exportSymbol('proto.PfFavePointsRankingUser', null, global);
goog.exportSymbol('proto.RankingEventStatus', null, global);
goog.exportSymbol('proto.ReactionGiftButton', null, global);
goog.exportSymbol('proto.ReqCapturePossibility', null, global);
goog.exportSymbol('proto.ShareTwitterDeleteSetting', null, global);
goog.exportSymbol('proto.SmallGiftType', null, global);
goog.exportSymbol('proto.SongYellBadge', null, global);
goog.exportSymbol('proto.TermOfMonth', null, global);
goog.exportSymbol('proto.UserLiveSetting', null, global);
goog.exportSymbol('proto.WorldFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLiveSetting = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserLiveSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.UserLiveSetting.displayName = 'proto.UserLiveSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveTimelineItem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveTimelineItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveTimelineItem.displayName = 'proto.LiveTimelineItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveHistory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveHistory.displayName = 'proto.LiveHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Live = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Live, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Live.displayName = 'proto.Live';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OnlineServer = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OnlineServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.OnlineServer.displayName = 'proto.OnlineServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveComment = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.LiveComment.repeatedFields_, null);
};
goog.inherits(proto.LiveComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveComment.displayName = 'proto.LiveComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveCommentMention = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveCommentMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveCommentMention.displayName = 'proto.LiveCommentMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveViewingSession = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveViewingSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveViewingSession.displayName = 'proto.LiveViewingSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEvent = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, proto.LiveEvent.oneofGroups_);
};
goog.inherits(proto.LiveEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEvent.displayName = 'proto.LiveEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventSystem = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventSystem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventSystem.displayName = 'proto.LiveEventSystem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventStart = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventStart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventStart.displayName = 'proto.LiveEventStart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventFinish = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventFinish, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventFinish.displayName = 'proto.LiveEventFinish';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventEnter = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventEnter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventEnter.displayName = 'proto.LiveEventEnter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventLeave = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventLeave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventLeave.displayName = 'proto.LiveEventLeave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventComment = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventComment.displayName = 'proto.LiveEventComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventSmallGift = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventSmallGift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventSmallGift.displayName = 'proto.LiveEventSmallGift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventLargeGift = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventLargeGift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventLargeGift.displayName = 'proto.LiveEventLargeGift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventStartSing = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.LiveEventStartSing.repeatedFields_, null);
};
goog.inherits(proto.LiveEventStartSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventStartSing.displayName = 'proto.LiveEventStartSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListeningReactionGiftButton = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListeningReactionGiftButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ListeningReactionGiftButton.displayName = 'proto.ListeningReactionGiftButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventFinishSing = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventFinishSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventFinishSing.displayName = 'proto.LiveEventFinishSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventRequestSing = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventRequestSing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventRequestSing.displayName = 'proto.LiveEventRequestSing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetFaveBonus = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, proto.LiveEventGetFaveBonus.oneofGroups_);
};
goog.inherits(proto.LiveEventGetFaveBonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetFaveBonus.displayName = 'proto.LiveEventGetFaveBonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetFaveBonusTitle = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetFaveBonusTitle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetFaveBonusTitle.displayName = 'proto.LiveEventGetFaveBonusTitle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetFavePoints = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetFavePoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetFavePoints.displayName = 'proto.LiveEventGetFavePoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetFaveBadge = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetFaveBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetFaveBadge.displayName = 'proto.LiveEventGetFaveBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetSongFave = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetSongFave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetSongFave.displayName = 'proto.LiveEventGetSongFave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventFollow = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventFollow.displayName = 'proto.LiveEventFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventLike = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventLike, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventLike.displayName = 'proto.LiveEventLike';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventSuperLike = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventSuperLike, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventSuperLike.displayName = 'proto.LiveEventSuperLike';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventAnnounce = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.LiveEventAnnounce.repeatedFields_, null);
};
goog.inherits(proto.LiveEventAnnounce, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventAnnounce.displayName = 'proto.LiveEventAnnounce';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventSurgeRankingNo1Reward = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventSurgeRankingNo1Reward, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventSurgeRankingNo1Reward.displayName = 'proto.LiveEventSurgeRankingNo1Reward';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventSimplePFComment = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventSimplePFComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventSimplePFComment.displayName = 'proto.LiveEventSimplePFComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetFaveBadgeAndSongFave = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetFaveBadgeAndSongFave, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetFaveBadgeAndSongFave.displayName = 'proto.LiveEventGetFaveBadgeAndSongFave';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Gift = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.Gift.repeatedFields_, null);
};
goog.inherits(proto.Gift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Gift.displayName = 'proto.Gift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Gift.BigGiftCoinAmount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Gift.BigGiftCoinAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.Gift.BigGiftCoinAmount.displayName = 'proto.Gift.BigGiftCoinAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GiftTab = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.GiftTab.repeatedFields_, null);
};
goog.inherits(proto.GiftTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.GiftTab.displayName = 'proto.GiftTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GiftTabSection = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.GiftTabSection.repeatedFields_, null);
};
goog.inherits(proto.GiftTabSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.GiftTabSection.displayName = 'proto.GiftTabSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EmojiComment = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EmojiComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.EmojiComment.displayName = 'proto.EmojiComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EmojiCommentTab = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.EmojiCommentTab.repeatedFields_, null);
};
goog.inherits(proto.EmojiCommentTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.EmojiCommentTab.displayName = 'proto.EmojiCommentTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EmojiCommentTabSection = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.EmojiCommentTabSection.repeatedFields_, null);
};
goog.inherits(proto.EmojiCommentTabSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.EmojiCommentTabSection.displayName = 'proto.EmojiCommentTabSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListFanInfoFaveBadgeTab = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListFanInfoFaveBadgeTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ListFanInfoFaveBadgeTab.displayName = 'proto.ListFanInfoFaveBadgeTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListFanInfoFaveBadgeUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListFanInfoFaveBadgeUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ListFanInfoFaveBadgeUser.displayName = 'proto.ListFanInfoFaveBadgeUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListFanInfoSongFaveTab = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListFanInfoSongFaveTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ListFanInfoSongFaveTab.displayName = 'proto.ListFanInfoSongFaveTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListFanInfoSongFaveUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListFanInfoSongFaveUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ListFanInfoSongFaveUser.displayName = 'proto.ListFanInfoSongFaveUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PfFavePointsRankingTerm = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PfFavePointsRankingTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.PfFavePointsRankingTerm.displayName = 'proto.PfFavePointsRankingTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PfFavePointsRankingUser = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PfFavePointsRankingUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.PfFavePointsRankingUser.displayName = 'proto.PfFavePointsRankingUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveResultFavePoint = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveResultFavePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveResultFavePoint.displayName = 'proto.LiveResultFavePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveResultGiftListener = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.LiveResultGiftListener.repeatedFields_, null);
};
goog.inherits(proto.LiveResultGiftListener, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveResultGiftListener.displayName = 'proto.LiveResultGiftListener';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveResultGiftCount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveResultGiftCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveResultGiftCount.displayName = 'proto.LiveResultGiftCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveLiverInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveLiverInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveLiverInfo.displayName = 'proto.LiveLiverInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveTimelineInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveTimelineInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveTimelineInfo.displayName = 'proto.LiveTimelineInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveTimelineBanner = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveTimelineBanner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveTimelineBanner.displayName = 'proto.LiveTimelineBanner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeartfulMention = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.HeartfulMention.repeatedFields_, null);
};
goog.inherits(proto.HeartfulMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.HeartfulMention.displayName = 'proto.HeartfulMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventHeartfulMention = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventHeartfulMention, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventHeartfulMention.displayName = 'proto.LiveEventHeartfulMention';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventLiveSettingsUpdated = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventLiveSettingsUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventLiveSettingsUpdated.displayName = 'proto.LiveEventLiveSettingsUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventListenerReqCapturePossibilityUpdated = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventListenerReqCapturePossibilityUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventListenerReqCapturePossibilityUpdated.displayName = 'proto.LiveEventListenerReqCapturePossibilityUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventPickUpSongRandomly = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventPickUpSongRandomly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventPickUpSongRandomly.displayName = 'proto.LiveEventPickUpSongRandomly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventMembershipUpdated = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventMembershipUpdated, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventMembershipUpdated.displayName = 'proto.LiveEventMembershipUpdated';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReqCapturePossibility = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReqCapturePossibility, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ReqCapturePossibility.displayName = 'proto.ReqCapturePossibility';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveEventGetSongRandomly = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveEventGetSongRandomly, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveEventGetSongRandomly.displayName = 'proto.LiveEventGetSongRandomly';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeartfulMentionButton = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HeartfulMentionButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.HeartfulMentionButton.displayName = 'proto.HeartfulMentionButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ReactionGiftButton = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ReactionGiftButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.ReactionGiftButton.displayName = 'proto.ReactionGiftButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiveBackground = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiveBackground, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LiveBackground.displayName = 'proto.LiveBackground';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LivePermission = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LivePermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LivePermission.displayName = 'proto.LivePermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LivePermission.TrialInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LivePermission.TrialInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LivePermission.TrialInfo.displayName = 'proto.LivePermission.TrialInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LivePermission.SecondReviewInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LivePermission.SecondReviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LivePermission.SecondReviewInfo.displayName = 'proto.LivePermission.SecondReviewInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LivePermission.FailedSecondReviewInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LivePermission.FailedSecondReviewInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.LivePermission.FailedSecondReviewInfo.displayName = 'proto.LivePermission.FailedSecondReviewInfo';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.UserLiveSetting.prototype.toObject = function (opt_includeInstance) {
        return proto.UserLiveSetting.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.UserLiveSetting} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.UserLiveSetting.toObject = function (includeInstance, msg) {
        var f, obj = {
            radioMode: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            shareTwitterAccount: (f = msg.getShareTwitterAccount()) && users_entity_pb.TwitterAccount.toObject(includeInstance, f),
            shareTwitterDeleteSetting: jspb.Message.getFieldWithDefault(msg, 3, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            preEnterComment: jspb.Message.getFieldWithDefault(msg, 6, ""),
            postEnterComment: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLiveSetting}
 */
proto.UserLiveSetting.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.UserLiveSetting;
    return proto.UserLiveSetting.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLiveSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLiveSetting}
 */
proto.UserLiveSetting.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setRadioMode(value);
                break;
            case 2:
                var value = new users_entity_pb.TwitterAccount;
                reader.readMessage(value, users_entity_pb.TwitterAccount.deserializeBinaryFromReader);
                msg.setShareTwitterAccount(value);
                break;
            case 3:
                var value = /** @type {!proto.ShareTwitterDeleteSetting} */ (reader.readEnum());
                msg.setShareTwitterDeleteSetting(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setPreEnterComment(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setPostEnterComment(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLiveSetting.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.UserLiveSetting.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLiveSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLiveSetting.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRadioMode();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getShareTwitterAccount();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.TwitterAccount.serializeBinaryToWriter);
    }
    f = message.getShareTwitterDeleteSetting();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getPreEnterComment();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getPostEnterComment();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
/**
 * optional bool radio_mode = 1;
 * @return {boolean}
 */
proto.UserLiveSetting.prototype.getRadioMode = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setRadioMode = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional TwitterAccount share_twitter_account = 2;
 * @return {?proto.TwitterAccount}
 */
proto.UserLiveSetting.prototype.getShareTwitterAccount = function () {
    return /** @type{?proto.TwitterAccount} */ (jspb.Message.getWrapperField(this, users_entity_pb.TwitterAccount, 2));
};
/**
 * @param {?proto.TwitterAccount|undefined} value
 * @return {!proto.UserLiveSetting} returns this
*/
proto.UserLiveSetting.prototype.setShareTwitterAccount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.clearShareTwitterAccount = function () {
    return this.setShareTwitterAccount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserLiveSetting.prototype.hasShareTwitterAccount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional ShareTwitterDeleteSetting share_twitter_delete_setting = 3;
 * @return {!proto.ShareTwitterDeleteSetting}
 */
proto.UserLiveSetting.prototype.getShareTwitterDeleteSetting = function () {
    return /** @type {!proto.ShareTwitterDeleteSetting} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.ShareTwitterDeleteSetting} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setShareTwitterDeleteSetting = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.UserLiveSetting.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.UserLiveSetting.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string pre_enter_comment = 6;
 * @return {string}
 */
proto.UserLiveSetting.prototype.getPreEnterComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setPreEnterComment = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string post_enter_comment = 7;
 * @return {string}
 */
proto.UserLiveSetting.prototype.getPostEnterComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.UserLiveSetting} returns this
 */
proto.UserLiveSetting.prototype.setPostEnterComment = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveTimelineItem.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveTimelineItem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveTimelineItem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveTimelineItem.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && proto.Live.toObject(includeInstance, f),
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            beginnerType: jspb.Message.getFieldWithDefault(msg, 7, 0),
            liverbeginnerdaycount: jspb.Message.getFieldWithDefault(msg, 4, 0),
            livePermissionSecondReviewRemainingDays: jspb.Message.getFieldWithDefault(msg, 8, 0),
            issurging: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveTimelineItem}
 */
proto.LiveTimelineItem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveTimelineItem;
    return proto.LiveTimelineItem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveTimelineItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveTimelineItem}
 */
proto.LiveTimelineItem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.Live;
                reader.readMessage(value, proto.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 3:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 7:
                var value = /** @type {!proto.LiveTimelineItem.BeginnerType} */ (reader.readEnum());
                msg.setBeginnerType(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLiverbeginnerdaycount(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setLivePermissionSecondReviewRemainingDays(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIssurging(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveTimelineItem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveTimelineItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveTimelineItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveTimelineItem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, proto.Live.serializeBinaryToWriter);
    }
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(3, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getBeginnerType();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
    f = message.getLiverbeginnerdaycount();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getLivePermissionSecondReviewRemainingDays();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getIssurging();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * @enum {number}
 */
proto.LiveTimelineItem.BeginnerType = {
    NOTBEGINNER: 0,
    FIRSTLIVE: 1,
    NEWSEASON: 2,
    FIRSTMONTH: 3,
    LIVEPERMISSIONSECONDREVIEWTRIAL: 4,
    LIVEPERMISSIONSECONDREVIEW: 5
};
/**
 * optional Live live = 1;
 * @return {?proto.Live}
 */
proto.LiveTimelineItem.prototype.getLive = function () {
    return /** @type{?proto.Live} */ (jspb.Message.getWrapperField(this, proto.Live, 1));
};
/**
 * @param {?proto.Live|undefined} value
 * @return {!proto.LiveTimelineItem} returns this
*/
proto.LiveTimelineItem.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveTimelineItem.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItem user_item = 2;
 * @return {?proto.UserItem}
 */
proto.LiveTimelineItem.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 2));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.LiveTimelineItem} returns this
*/
proto.LiveTimelineItem.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveTimelineItem.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Song song = 3;
 * @return {?proto.Song}
 */
proto.LiveTimelineItem.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 3));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveTimelineItem} returns this
*/
proto.LiveTimelineItem.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveTimelineItem.prototype.hasSong = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional BeginnerType beginner_type = 7;
 * @return {!proto.LiveTimelineItem.BeginnerType}
 */
proto.LiveTimelineItem.prototype.getBeginnerType = function () {
    return /** @type {!proto.LiveTimelineItem.BeginnerType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.LiveTimelineItem.BeginnerType} value
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.setBeginnerType = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional int64 liverBeginnerDayCount = 4;
 * @return {number}
 */
proto.LiveTimelineItem.prototype.getLiverbeginnerdaycount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.setLiverbeginnerdaycount = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 live_permission_second_review_remaining_days = 8;
 * @return {number}
 */
proto.LiveTimelineItem.prototype.getLivePermissionSecondReviewRemainingDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.setLivePermissionSecondReviewRemainingDays = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional bool isSurging = 5;
 * @return {boolean}
 */
proto.LiveTimelineItem.prototype.getIssurging = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveTimelineItem} returns this
 */
proto.LiveTimelineItem.prototype.setIssurging = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveHistory.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveHistory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveHistory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveHistory.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && proto.Live.toObject(includeInstance, f),
            hasliveresult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveHistory}
 */
proto.LiveHistory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveHistory;
    return proto.LiveHistory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveHistory}
 */
proto.LiveHistory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.Live;
                reader.readMessage(value, proto.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasliveresult(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveHistory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveHistory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, proto.Live.serializeBinaryToWriter);
    }
    f = message.getHasliveresult();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.Live}
 */
proto.LiveHistory.prototype.getLive = function () {
    return /** @type{?proto.Live} */ (jspb.Message.getWrapperField(this, proto.Live, 1));
};
/**
 * @param {?proto.Live|undefined} value
 * @return {!proto.LiveHistory} returns this
*/
proto.LiveHistory.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveHistory} returns this
 */
proto.LiveHistory.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveHistory.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool hasLiveResult = 2;
 * @return {boolean}
 */
proto.LiveHistory.prototype.getHasliveresult = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveHistory} returns this
 */
proto.LiveHistory.prototype.setHasliveresult = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Live.prototype.toObject = function (opt_includeInstance) {
        return proto.Live.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Live} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Live.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            title: jspb.Message.getFieldWithDefault(msg, 4, ""),
            thumbnailImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
            backgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
            enteringComment: jspb.Message.getFieldWithDefault(msg, 7, ""),
            onAir: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            voiceOnly: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            liked: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
            createdAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
            startedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
            finishedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
            liveBackground: (f = msg.getLiveBackground()) && proto.LiveBackground.toObject(includeInstance, f),
            liveMode: jspb.Message.getFieldWithDefault(msg, 17, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Live}
 */
proto.Live.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Live;
    return proto.Live.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Live} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Live}
 */
proto.Live.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailImageUrl(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setBackgroundImageUrl(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setEnteringComment(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setOnAir(value);
                break;
            case 9:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setVoiceOnly(value);
                break;
            case 10:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 11:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setLiked(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 14:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStartedAt(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFinishedAt(value);
                break;
            case 16:
                var value = new proto.LiveBackground;
                reader.readMessage(value, proto.LiveBackground.deserializeBinaryFromReader);
                msg.setLiveBackground(value);
                break;
            case 17:
                var value = /** @type {!proto.LiveMode} */ (reader.readEnum());
                msg.setLiveMode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Live.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Live.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Live} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Live.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getThumbnailImageUrl();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getBackgroundImageUrl();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getEnteringComment();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getOnAir();
    if (f) {
        writer.writeBool(8, f);
    }
    f = message.getVoiceOnly();
    if (f) {
        writer.writeBool(9, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(10, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getLiked();
    if (f) {
        writer.writeBool(11, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(13, f);
    }
    f = message.getStartedAt();
    if (f !== 0) {
        writer.writeInt64(14, f);
    }
    f = message.getFinishedAt();
    if (f !== 0) {
        writer.writeInt64(15, f);
    }
    f = message.getLiveBackground();
    if (f != null) {
        writer.writeMessage(16, f, proto.LiveBackground.serializeBinaryToWriter);
    }
    f = message.getLiveMode();
    if (f !== 0.0) {
        writer.writeEnum(17, f);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.Live.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.Live.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string title = 4;
 * @return {string}
 */
proto.Live.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string thumbnail_image_url = 5;
 * @return {string}
 */
proto.Live.prototype.getThumbnailImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setThumbnailImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string background_image_url = 6;
 * @return {string}
 */
proto.Live.prototype.getBackgroundImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setBackgroundImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string entering_comment = 7;
 * @return {string}
 */
proto.Live.prototype.getEnteringComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setEnteringComment = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional bool on_air = 8;
 * @return {boolean}
 */
proto.Live.prototype.getOnAir = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setOnAir = function (value) {
    return jspb.Message.setProto3BooleanField(this, 8, value);
};
/**
 * optional bool voice_only = 9;
 * @return {boolean}
 */
proto.Live.prototype.getVoiceOnly = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setVoiceOnly = function (value) {
    return jspb.Message.setProto3BooleanField(this, 9, value);
};
/**
 * optional User user = 10;
 * @return {?proto.User}
 */
proto.Live.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 10));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.Live} returns this
*/
proto.Live.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Live.prototype.hasUser = function () {
    return jspb.Message.getField(this, 10) != null;
};
/**
 * optional bool liked = 11;
 * @return {boolean}
 */
proto.Live.prototype.getLiked = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setLiked = function (value) {
    return jspb.Message.setProto3BooleanField(this, 11, value);
};
/**
 * optional int64 created_at = 12;
 * @return {number}
 */
proto.Live.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional int64 updated_at = 13;
 * @return {number}
 */
proto.Live.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};
/**
 * @param {number} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional int64 started_at = 14;
 * @return {number}
 */
proto.Live.prototype.getStartedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};
/**
 * @param {number} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setStartedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional int64 finished_at = 15;
 * @return {number}
 */
proto.Live.prototype.getFinishedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};
/**
 * @param {number} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setFinishedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};
/**
 * optional LiveBackground live_background = 16;
 * @return {?proto.LiveBackground}
 */
proto.Live.prototype.getLiveBackground = function () {
    return /** @type{?proto.LiveBackground} */ (jspb.Message.getWrapperField(this, proto.LiveBackground, 16));
};
/**
 * @param {?proto.LiveBackground|undefined} value
 * @return {!proto.Live} returns this
*/
proto.Live.prototype.setLiveBackground = function (value) {
    return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.clearLiveBackground = function () {
    return this.setLiveBackground(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Live.prototype.hasLiveBackground = function () {
    return jspb.Message.getField(this, 16) != null;
};
/**
 * optional LiveMode live_mode = 17;
 * @return {!proto.LiveMode}
 */
proto.Live.prototype.getLiveMode = function () {
    return /** @type {!proto.LiveMode} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};
/**
 * @param {!proto.LiveMode} value
 * @return {!proto.Live} returns this
 */
proto.Live.prototype.setLiveMode = function (value) {
    return jspb.Message.setProto3EnumField(this, 17, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.OnlineServer.prototype.toObject = function (opt_includeInstance) {
        return proto.OnlineServer.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.OnlineServer} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.OnlineServer.toObject = function (includeInstance, msg) {
        var f, obj = {
            host: jspb.Message.getFieldWithDefault(msg, 1, ""),
            port: jspb.Message.getFieldWithDefault(msg, 2, 0),
            channel: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OnlineServer}
 */
proto.OnlineServer.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.OnlineServer;
    return proto.OnlineServer.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OnlineServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OnlineServer}
 */
proto.OnlineServer.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setHost(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPort(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setChannel(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OnlineServer.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.OnlineServer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OnlineServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OnlineServer.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHost();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPort();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getChannel();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional string host = 1;
 * @return {string}
 */
proto.OnlineServer.prototype.getHost = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnlineServer} returns this
 */
proto.OnlineServer.prototype.setHost = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 port = 2;
 * @return {number}
 */
proto.OnlineServer.prototype.getPort = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.OnlineServer} returns this
 */
proto.OnlineServer.prototype.setPort = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string channel = 3;
 * @return {string}
 */
proto.OnlineServer.prototype.getChannel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.OnlineServer} returns this
 */
proto.OnlineServer.prototype.setChannel = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LiveComment.repeatedFields_ = [6];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveComment.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveComment.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveComment} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveComment.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveCommentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            body: jspb.Message.getFieldWithDefault(msg, 4, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            liveCommentMentionsList: jspb.Message.toObjectList(msg.getLiveCommentMentionsList(), proto.LiveCommentMention.toObject, includeInstance),
            createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
            userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveComment}
 */
proto.LiveComment.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveComment;
    return proto.LiveComment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveComment}
 */
proto.LiveComment.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveCommentId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setBody(value);
                break;
            case 5:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 6:
                var value = new proto.LiveCommentMention;
                reader.readMessage(value, proto.LiveCommentMention.deserializeBinaryFromReader);
                msg.addLiveCommentMentions(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 9:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setUserMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveComment.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveComment.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveCommentId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getBody();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getLiveCommentMentionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(6, f, proto.LiveCommentMention.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getUserMembershipInfo();
    if (f != null) {
        writer.writeMessage(9, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional string live_comment_id = 1;
 * @return {string}
 */
proto.LiveComment.prototype.getLiveCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setLiveCommentId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.LiveComment.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.LiveComment.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string body = 4;
 * @return {string}
 */
proto.LiveComment.prototype.getBody = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setBody = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional User user = 5;
 * @return {?proto.User}
 */
proto.LiveComment.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 5));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveComment} returns this
*/
proto.LiveComment.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveComment.prototype.hasUser = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * repeated LiveCommentMention live_comment_mentions = 6;
 * @return {!Array<!proto.LiveCommentMention>}
 */
proto.LiveComment.prototype.getLiveCommentMentionsList = function () {
    return /** @type{!Array<!proto.LiveCommentMention>} */ (jspb.Message.getRepeatedWrapperField(this, proto.LiveCommentMention, 6));
};
/**
 * @param {!Array<!proto.LiveCommentMention>} value
 * @return {!proto.LiveComment} returns this
*/
proto.LiveComment.prototype.setLiveCommentMentionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 6, value);
};
/**
 * @param {!proto.LiveCommentMention=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LiveCommentMention}
 */
proto.LiveComment.prototype.addLiveCommentMentions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.LiveCommentMention, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.clearLiveCommentMentionsList = function () {
    return this.setLiveCommentMentionsList([]);
};
/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.LiveComment.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional int64 updated_at = 8;
 * @return {number}
 */
proto.LiveComment.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional ListenerMembershipInfo user_membership_info = 9;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveComment.prototype.getUserMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 9));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveComment} returns this
*/
proto.LiveComment.prototype.setUserMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveComment} returns this
 */
proto.LiveComment.prototype.clearUserMembershipInfo = function () {
    return this.setUserMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveComment.prototype.hasUserMembershipInfo = function () {
    return jspb.Message.getField(this, 9) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveCommentMention.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveCommentMention.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveCommentMention} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveCommentMention.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveCommentMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            liveCommentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            targetUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveCommentMention}
 */
proto.LiveCommentMention.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveCommentMention;
    return proto.LiveCommentMention.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveCommentMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveCommentMention}
 */
proto.LiveCommentMention.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveCommentMentionId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveCommentId(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTargetUserId(value);
                break;
            case 5:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 8:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setUserMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveCommentMention.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveCommentMention.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveCommentMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveCommentMention.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveCommentMentionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getLiveCommentId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getTargetUserId();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getUserMembershipInfo();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional string live_comment_mention_id = 1;
 * @return {string}
 */
proto.LiveCommentMention.prototype.getLiveCommentMentionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setLiveCommentMentionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.LiveCommentMention.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string live_comment_id = 3;
 * @return {string}
 */
proto.LiveCommentMention.prototype.getLiveCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setLiveCommentId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string target_user_id = 4;
 * @return {string}
 */
proto.LiveCommentMention.prototype.getTargetUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setTargetUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional User user = 5;
 * @return {?proto.User}
 */
proto.LiveCommentMention.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 5));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveCommentMention} returns this
*/
proto.LiveCommentMention.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveCommentMention.prototype.hasUser = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.LiveCommentMention.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.LiveCommentMention.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional ListenerMembershipInfo user_membership_info = 8;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveCommentMention.prototype.getUserMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveCommentMention} returns this
*/
proto.LiveCommentMention.prototype.setUserMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveCommentMention} returns this
 */
proto.LiveCommentMention.prototype.clearUserMembershipInfo = function () {
    return this.setUserMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveCommentMention.prototype.hasUserMembershipInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveViewingSession.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveViewingSession.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveViewingSession} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveViewingSession.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            startAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
            userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveViewingSession}
 */
proto.LiveViewingSession.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveViewingSession;
    return proto.LiveViewingSession.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveViewingSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveViewingSession}
 */
proto.LiveViewingSession.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setStartAt(value);
                break;
            case 3:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            case 8:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setUserMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveViewingSession.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveViewingSession.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveViewingSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveViewingSession.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getStartAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getUserMembershipInfo();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional string live_id = 1;
 * @return {string}
 */
proto.LiveViewingSession.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 start_at = 2;
 * @return {number}
 */
proto.LiveViewingSession.prototype.getStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional User user = 3;
 * @return {?proto.User}
 */
proto.LiveViewingSession.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 3));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveViewingSession} returns this
*/
proto.LiveViewingSession.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveViewingSession.prototype.hasUser = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.LiveViewingSession.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional FanType fan_type = 5;
 * @return {!proto.FanType}
 */
proto.LiveViewingSession.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.LiveViewingSession.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.LiveViewingSession.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional ListenerMembershipInfo user_membership_info = 8;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveViewingSession.prototype.getUserMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveViewingSession} returns this
*/
proto.LiveViewingSession.prototype.setUserMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveViewingSession} returns this
 */
proto.LiveViewingSession.prototype.clearUserMembershipInfo = function () {
    return this.setUserMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveViewingSession.prototype.hasUserMembershipInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.LiveEvent.oneofGroups_ = [[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 34, 35]];
/**
 * @enum {number}
 */
proto.LiveEvent.EventCase = {
    EVENT_NOT_SET: 0,
    LIVE_EVENT_SYSTEM: 5,
    LIVE_EVENT_START: 6,
    LIVE_EVENT_FINISH: 7,
    LIVE_EVENT_ENTER: 8,
    LIVE_EVENT_LEAVE: 9,
    LIVE_EVENT_COMMENT: 10,
    LIVE_EVENT_SMALL_GIFT: 11,
    LIVE_EVENT_LARGE_GIFT: 12,
    LIVE_EVENT_START_SING: 13,
    LIVE_EVENT_FINISH_SING: 14,
    LIVE_EVENT_GET_FAVE_BONUS: 15,
    LIVE_EVENT_GET_FAVE_BONUS_TITLE: 16,
    LIVE_EVENT_GET_FAVE_POINTS: 17,
    LIVE_EVENT_GET_FAVE_BADGE: 18,
    LIVE_EVENT_GET_SONG_FAVE: 19,
    LIVE_EVENT_FOLLOW: 20,
    LIVE_EVENT_LIKE: 21,
    LIVE_EVENT_SUPER_LIKE: 22,
    LIVE_EVENT_ANNOUNCE: 23,
    LIVE_EVENT_SURGE_RANKING_NO1_REWARD: 24,
    LIVE_EVENT_REQUEST_SING: 25,
    LIVE_EVENT_SIMPLE_PF_COMMENT: 26,
    LIVE_EVENT_GET_FAVE_BADGE_AND_SONG_FAVE: 27,
    LIVE_EVENT_HEARTFUL_MENTION: 28,
    LIVE_SETTINGS_UPDATED: 29,
    LISTENER_REQ_CAPTURE_POSSIBILITY_UPDATED: 31,
    LIVE_EVENT_PICK_UP_SONG_RANDOMLY: 32,
    LIVE_EVENT_MEMBERSHIP_UPDATED: 34,
    LIVE_EVENT_GET_SONG_RANDOMLY: 35
};
/**
 * @return {proto.LiveEvent.EventCase}
 */
proto.LiveEvent.prototype.getEventCase = function () {
    return /** @type {proto.LiveEvent.EventCase} */ (jspb.Message.computeOneofCase(this, proto.LiveEvent.oneofGroups_[0]));
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEvent.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEvent.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEvent.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveEventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveEventType: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liveId: jspb.Message.getFieldWithDefault(msg, 3, ""),
            createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            receivedInClient: jspb.Message.getFieldWithDefault(msg, 30, 0),
            liveEventSystem: (f = msg.getLiveEventSystem()) && proto.LiveEventSystem.toObject(includeInstance, f),
            liveEventStart: (f = msg.getLiveEventStart()) && proto.LiveEventStart.toObject(includeInstance, f),
            liveEventFinish: (f = msg.getLiveEventFinish()) && proto.LiveEventFinish.toObject(includeInstance, f),
            liveEventEnter: (f = msg.getLiveEventEnter()) && proto.LiveEventEnter.toObject(includeInstance, f),
            liveEventLeave: (f = msg.getLiveEventLeave()) && proto.LiveEventLeave.toObject(includeInstance, f),
            liveEventComment: (f = msg.getLiveEventComment()) && proto.LiveEventComment.toObject(includeInstance, f),
            liveEventSmallGift: (f = msg.getLiveEventSmallGift()) && proto.LiveEventSmallGift.toObject(includeInstance, f),
            liveEventLargeGift: (f = msg.getLiveEventLargeGift()) && proto.LiveEventLargeGift.toObject(includeInstance, f),
            liveEventStartSing: (f = msg.getLiveEventStartSing()) && proto.LiveEventStartSing.toObject(includeInstance, f),
            liveEventFinishSing: (f = msg.getLiveEventFinishSing()) && proto.LiveEventFinishSing.toObject(includeInstance, f),
            liveEventGetFaveBonus: (f = msg.getLiveEventGetFaveBonus()) && proto.LiveEventGetFaveBonus.toObject(includeInstance, f),
            liveEventGetFaveBonusTitle: (f = msg.getLiveEventGetFaveBonusTitle()) && proto.LiveEventGetFaveBonusTitle.toObject(includeInstance, f),
            liveEventGetFavePoints: (f = msg.getLiveEventGetFavePoints()) && proto.LiveEventGetFavePoints.toObject(includeInstance, f),
            liveEventGetFaveBadge: (f = msg.getLiveEventGetFaveBadge()) && proto.LiveEventGetFaveBadge.toObject(includeInstance, f),
            liveEventGetSongFave: (f = msg.getLiveEventGetSongFave()) && proto.LiveEventGetSongFave.toObject(includeInstance, f),
            liveEventFollow: (f = msg.getLiveEventFollow()) && proto.LiveEventFollow.toObject(includeInstance, f),
            liveEventLike: (f = msg.getLiveEventLike()) && proto.LiveEventLike.toObject(includeInstance, f),
            liveEventSuperLike: (f = msg.getLiveEventSuperLike()) && proto.LiveEventSuperLike.toObject(includeInstance, f),
            liveEventAnnounce: (f = msg.getLiveEventAnnounce()) && proto.LiveEventAnnounce.toObject(includeInstance, f),
            liveEventSurgeRankingNo1Reward: (f = msg.getLiveEventSurgeRankingNo1Reward()) && proto.LiveEventSurgeRankingNo1Reward.toObject(includeInstance, f),
            liveEventRequestSing: (f = msg.getLiveEventRequestSing()) && proto.LiveEventRequestSing.toObject(includeInstance, f),
            liveEventSimplePfComment: (f = msg.getLiveEventSimplePfComment()) && proto.LiveEventSimplePFComment.toObject(includeInstance, f),
            liveEventGetFaveBadgeAndSongFave: (f = msg.getLiveEventGetFaveBadgeAndSongFave()) && proto.LiveEventGetFaveBadgeAndSongFave.toObject(includeInstance, f),
            liveEventHeartfulMention: (f = msg.getLiveEventHeartfulMention()) && proto.LiveEventHeartfulMention.toObject(includeInstance, f),
            liveSettingsUpdated: (f = msg.getLiveSettingsUpdated()) && proto.LiveEventLiveSettingsUpdated.toObject(includeInstance, f),
            listenerReqCapturePossibilityUpdated: (f = msg.getListenerReqCapturePossibilityUpdated()) && proto.LiveEventListenerReqCapturePossibilityUpdated.toObject(includeInstance, f),
            liveEventPickUpSongRandomly: (f = msg.getLiveEventPickUpSongRandomly()) && proto.LiveEventPickUpSongRandomly.toObject(includeInstance, f),
            liveEventMembershipUpdated: (f = msg.getLiveEventMembershipUpdated()) && proto.LiveEventMembershipUpdated.toObject(includeInstance, f),
            liveEventGetSongRandomly: (f = msg.getLiveEventGetSongRandomly()) && proto.LiveEventGetSongRandomly.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEvent}
 */
proto.LiveEvent.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEvent;
    return proto.LiveEvent.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEvent}
 */
proto.LiveEvent.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveEventId(value);
                break;
            case 2:
                var value = /** @type {!proto.LiveEventType} */ (reader.readEnum());
                msg.setLiveEventType(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 30:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setReceivedInClient(value);
                break;
            case 5:
                var value = new proto.LiveEventSystem;
                reader.readMessage(value, proto.LiveEventSystem.deserializeBinaryFromReader);
                msg.setLiveEventSystem(value);
                break;
            case 6:
                var value = new proto.LiveEventStart;
                reader.readMessage(value, proto.LiveEventStart.deserializeBinaryFromReader);
                msg.setLiveEventStart(value);
                break;
            case 7:
                var value = new proto.LiveEventFinish;
                reader.readMessage(value, proto.LiveEventFinish.deserializeBinaryFromReader);
                msg.setLiveEventFinish(value);
                break;
            case 8:
                var value = new proto.LiveEventEnter;
                reader.readMessage(value, proto.LiveEventEnter.deserializeBinaryFromReader);
                msg.setLiveEventEnter(value);
                break;
            case 9:
                var value = new proto.LiveEventLeave;
                reader.readMessage(value, proto.LiveEventLeave.deserializeBinaryFromReader);
                msg.setLiveEventLeave(value);
                break;
            case 10:
                var value = new proto.LiveEventComment;
                reader.readMessage(value, proto.LiveEventComment.deserializeBinaryFromReader);
                msg.setLiveEventComment(value);
                break;
            case 11:
                var value = new proto.LiveEventSmallGift;
                reader.readMessage(value, proto.LiveEventSmallGift.deserializeBinaryFromReader);
                msg.setLiveEventSmallGift(value);
                break;
            case 12:
                var value = new proto.LiveEventLargeGift;
                reader.readMessage(value, proto.LiveEventLargeGift.deserializeBinaryFromReader);
                msg.setLiveEventLargeGift(value);
                break;
            case 13:
                var value = new proto.LiveEventStartSing;
                reader.readMessage(value, proto.LiveEventStartSing.deserializeBinaryFromReader);
                msg.setLiveEventStartSing(value);
                break;
            case 14:
                var value = new proto.LiveEventFinishSing;
                reader.readMessage(value, proto.LiveEventFinishSing.deserializeBinaryFromReader);
                msg.setLiveEventFinishSing(value);
                break;
            case 15:
                var value = new proto.LiveEventGetFaveBonus;
                reader.readMessage(value, proto.LiveEventGetFaveBonus.deserializeBinaryFromReader);
                msg.setLiveEventGetFaveBonus(value);
                break;
            case 16:
                var value = new proto.LiveEventGetFaveBonusTitle;
                reader.readMessage(value, proto.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader);
                msg.setLiveEventGetFaveBonusTitle(value);
                break;
            case 17:
                var value = new proto.LiveEventGetFavePoints;
                reader.readMessage(value, proto.LiveEventGetFavePoints.deserializeBinaryFromReader);
                msg.setLiveEventGetFavePoints(value);
                break;
            case 18:
                var value = new proto.LiveEventGetFaveBadge;
                reader.readMessage(value, proto.LiveEventGetFaveBadge.deserializeBinaryFromReader);
                msg.setLiveEventGetFaveBadge(value);
                break;
            case 19:
                var value = new proto.LiveEventGetSongFave;
                reader.readMessage(value, proto.LiveEventGetSongFave.deserializeBinaryFromReader);
                msg.setLiveEventGetSongFave(value);
                break;
            case 20:
                var value = new proto.LiveEventFollow;
                reader.readMessage(value, proto.LiveEventFollow.deserializeBinaryFromReader);
                msg.setLiveEventFollow(value);
                break;
            case 21:
                var value = new proto.LiveEventLike;
                reader.readMessage(value, proto.LiveEventLike.deserializeBinaryFromReader);
                msg.setLiveEventLike(value);
                break;
            case 22:
                var value = new proto.LiveEventSuperLike;
                reader.readMessage(value, proto.LiveEventSuperLike.deserializeBinaryFromReader);
                msg.setLiveEventSuperLike(value);
                break;
            case 23:
                var value = new proto.LiveEventAnnounce;
                reader.readMessage(value, proto.LiveEventAnnounce.deserializeBinaryFromReader);
                msg.setLiveEventAnnounce(value);
                break;
            case 24:
                var value = new proto.LiveEventSurgeRankingNo1Reward;
                reader.readMessage(value, proto.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader);
                msg.setLiveEventSurgeRankingNo1Reward(value);
                break;
            case 25:
                var value = new proto.LiveEventRequestSing;
                reader.readMessage(value, proto.LiveEventRequestSing.deserializeBinaryFromReader);
                msg.setLiveEventRequestSing(value);
                break;
            case 26:
                var value = new proto.LiveEventSimplePFComment;
                reader.readMessage(value, proto.LiveEventSimplePFComment.deserializeBinaryFromReader);
                msg.setLiveEventSimplePfComment(value);
                break;
            case 27:
                var value = new proto.LiveEventGetFaveBadgeAndSongFave;
                reader.readMessage(value, proto.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader);
                msg.setLiveEventGetFaveBadgeAndSongFave(value);
                break;
            case 28:
                var value = new proto.LiveEventHeartfulMention;
                reader.readMessage(value, proto.LiveEventHeartfulMention.deserializeBinaryFromReader);
                msg.setLiveEventHeartfulMention(value);
                break;
            case 29:
                var value = new proto.LiveEventLiveSettingsUpdated;
                reader.readMessage(value, proto.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader);
                msg.setLiveSettingsUpdated(value);
                break;
            case 31:
                var value = new proto.LiveEventListenerReqCapturePossibilityUpdated;
                reader.readMessage(value, proto.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader);
                msg.setListenerReqCapturePossibilityUpdated(value);
                break;
            case 32:
                var value = new proto.LiveEventPickUpSongRandomly;
                reader.readMessage(value, proto.LiveEventPickUpSongRandomly.deserializeBinaryFromReader);
                msg.setLiveEventPickUpSongRandomly(value);
                break;
            case 34:
                var value = new proto.LiveEventMembershipUpdated;
                reader.readMessage(value, proto.LiveEventMembershipUpdated.deserializeBinaryFromReader);
                msg.setLiveEventMembershipUpdated(value);
                break;
            case 35:
                var value = new proto.LiveEventGetSongRandomly;
                reader.readMessage(value, proto.LiveEventGetSongRandomly.deserializeBinaryFromReader);
                msg.setLiveEventGetSongRandomly(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEvent.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEvent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEvent.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveEventId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveEventType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getReceivedInClient();
    if (f !== 0) {
        writer.writeInt64(30, f);
    }
    f = message.getLiveEventSystem();
    if (f != null) {
        writer.writeMessage(5, f, proto.LiveEventSystem.serializeBinaryToWriter);
    }
    f = message.getLiveEventStart();
    if (f != null) {
        writer.writeMessage(6, f, proto.LiveEventStart.serializeBinaryToWriter);
    }
    f = message.getLiveEventFinish();
    if (f != null) {
        writer.writeMessage(7, f, proto.LiveEventFinish.serializeBinaryToWriter);
    }
    f = message.getLiveEventEnter();
    if (f != null) {
        writer.writeMessage(8, f, proto.LiveEventEnter.serializeBinaryToWriter);
    }
    f = message.getLiveEventLeave();
    if (f != null) {
        writer.writeMessage(9, f, proto.LiveEventLeave.serializeBinaryToWriter);
    }
    f = message.getLiveEventComment();
    if (f != null) {
        writer.writeMessage(10, f, proto.LiveEventComment.serializeBinaryToWriter);
    }
    f = message.getLiveEventSmallGift();
    if (f != null) {
        writer.writeMessage(11, f, proto.LiveEventSmallGift.serializeBinaryToWriter);
    }
    f = message.getLiveEventLargeGift();
    if (f != null) {
        writer.writeMessage(12, f, proto.LiveEventLargeGift.serializeBinaryToWriter);
    }
    f = message.getLiveEventStartSing();
    if (f != null) {
        writer.writeMessage(13, f, proto.LiveEventStartSing.serializeBinaryToWriter);
    }
    f = message.getLiveEventFinishSing();
    if (f != null) {
        writer.writeMessage(14, f, proto.LiveEventFinishSing.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetFaveBonus();
    if (f != null) {
        writer.writeMessage(15, f, proto.LiveEventGetFaveBonus.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetFaveBonusTitle();
    if (f != null) {
        writer.writeMessage(16, f, proto.LiveEventGetFaveBonusTitle.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetFavePoints();
    if (f != null) {
        writer.writeMessage(17, f, proto.LiveEventGetFavePoints.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetFaveBadge();
    if (f != null) {
        writer.writeMessage(18, f, proto.LiveEventGetFaveBadge.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetSongFave();
    if (f != null) {
        writer.writeMessage(19, f, proto.LiveEventGetSongFave.serializeBinaryToWriter);
    }
    f = message.getLiveEventFollow();
    if (f != null) {
        writer.writeMessage(20, f, proto.LiveEventFollow.serializeBinaryToWriter);
    }
    f = message.getLiveEventLike();
    if (f != null) {
        writer.writeMessage(21, f, proto.LiveEventLike.serializeBinaryToWriter);
    }
    f = message.getLiveEventSuperLike();
    if (f != null) {
        writer.writeMessage(22, f, proto.LiveEventSuperLike.serializeBinaryToWriter);
    }
    f = message.getLiveEventAnnounce();
    if (f != null) {
        writer.writeMessage(23, f, proto.LiveEventAnnounce.serializeBinaryToWriter);
    }
    f = message.getLiveEventSurgeRankingNo1Reward();
    if (f != null) {
        writer.writeMessage(24, f, proto.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter);
    }
    f = message.getLiveEventRequestSing();
    if (f != null) {
        writer.writeMessage(25, f, proto.LiveEventRequestSing.serializeBinaryToWriter);
    }
    f = message.getLiveEventSimplePfComment();
    if (f != null) {
        writer.writeMessage(26, f, proto.LiveEventSimplePFComment.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetFaveBadgeAndSongFave();
    if (f != null) {
        writer.writeMessage(27, f, proto.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter);
    }
    f = message.getLiveEventHeartfulMention();
    if (f != null) {
        writer.writeMessage(28, f, proto.LiveEventHeartfulMention.serializeBinaryToWriter);
    }
    f = message.getLiveSettingsUpdated();
    if (f != null) {
        writer.writeMessage(29, f, proto.LiveEventLiveSettingsUpdated.serializeBinaryToWriter);
    }
    f = message.getListenerReqCapturePossibilityUpdated();
    if (f != null) {
        writer.writeMessage(31, f, proto.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter);
    }
    f = message.getLiveEventPickUpSongRandomly();
    if (f != null) {
        writer.writeMessage(32, f, proto.LiveEventPickUpSongRandomly.serializeBinaryToWriter);
    }
    f = message.getLiveEventMembershipUpdated();
    if (f != null) {
        writer.writeMessage(34, f, proto.LiveEventMembershipUpdated.serializeBinaryToWriter);
    }
    f = message.getLiveEventGetSongRandomly();
    if (f != null) {
        writer.writeMessage(35, f, proto.LiveEventGetSongRandomly.serializeBinaryToWriter);
    }
};
/**
 * optional string live_event_id = 1;
 * @return {string}
 */
proto.LiveEvent.prototype.getLiveEventId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.setLiveEventId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional LiveEventType live_event_type = 2;
 * @return {!proto.LiveEventType}
 */
proto.LiveEvent.prototype.getLiveEventType = function () {
    return /** @type {!proto.LiveEventType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.LiveEventType} value
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.setLiveEventType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string live_id = 3;
 * @return {string}
 */
proto.LiveEvent.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.LiveEvent.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 received_in_client = 30;
 * @return {number}
 */
proto.LiveEvent.prototype.getReceivedInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.setReceivedInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 30, value);
};
/**
 * optional LiveEventSystem live_event_system = 5;
 * @return {?proto.LiveEventSystem}
 */
proto.LiveEvent.prototype.getLiveEventSystem = function () {
    return /** @type{?proto.LiveEventSystem} */ (jspb.Message.getWrapperField(this, proto.LiveEventSystem, 5));
};
/**
 * @param {?proto.LiveEventSystem|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventSystem = function (value) {
    return jspb.Message.setOneofWrapperField(this, 5, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventSystem = function () {
    return this.setLiveEventSystem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventSystem = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional LiveEventStart live_event_start = 6;
 * @return {?proto.LiveEventStart}
 */
proto.LiveEvent.prototype.getLiveEventStart = function () {
    return /** @type{?proto.LiveEventStart} */ (jspb.Message.getWrapperField(this, proto.LiveEventStart, 6));
};
/**
 * @param {?proto.LiveEventStart|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventStart = function (value) {
    return jspb.Message.setOneofWrapperField(this, 6, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventStart = function () {
    return this.setLiveEventStart(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventStart = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional LiveEventFinish live_event_finish = 7;
 * @return {?proto.LiveEventFinish}
 */
proto.LiveEvent.prototype.getLiveEventFinish = function () {
    return /** @type{?proto.LiveEventFinish} */ (jspb.Message.getWrapperField(this, proto.LiveEventFinish, 7));
};
/**
 * @param {?proto.LiveEventFinish|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventFinish = function (value) {
    return jspb.Message.setOneofWrapperField(this, 7, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventFinish = function () {
    return this.setLiveEventFinish(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventFinish = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional LiveEventEnter live_event_enter = 8;
 * @return {?proto.LiveEventEnter}
 */
proto.LiveEvent.prototype.getLiveEventEnter = function () {
    return /** @type{?proto.LiveEventEnter} */ (jspb.Message.getWrapperField(this, proto.LiveEventEnter, 8));
};
/**
 * @param {?proto.LiveEventEnter|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventEnter = function (value) {
    return jspb.Message.setOneofWrapperField(this, 8, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventEnter = function () {
    return this.setLiveEventEnter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventEnter = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional LiveEventLeave live_event_leave = 9;
 * @return {?proto.LiveEventLeave}
 */
proto.LiveEvent.prototype.getLiveEventLeave = function () {
    return /** @type{?proto.LiveEventLeave} */ (jspb.Message.getWrapperField(this, proto.LiveEventLeave, 9));
};
/**
 * @param {?proto.LiveEventLeave|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventLeave = function (value) {
    return jspb.Message.setOneofWrapperField(this, 9, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventLeave = function () {
    return this.setLiveEventLeave(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventLeave = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional LiveEventComment live_event_comment = 10;
 * @return {?proto.LiveEventComment}
 */
proto.LiveEvent.prototype.getLiveEventComment = function () {
    return /** @type{?proto.LiveEventComment} */ (jspb.Message.getWrapperField(this, proto.LiveEventComment, 10));
};
/**
 * @param {?proto.LiveEventComment|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventComment = function (value) {
    return jspb.Message.setOneofWrapperField(this, 10, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventComment = function () {
    return this.setLiveEventComment(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventComment = function () {
    return jspb.Message.getField(this, 10) != null;
};
/**
 * optional LiveEventSmallGift live_event_small_gift = 11;
 * @return {?proto.LiveEventSmallGift}
 */
proto.LiveEvent.prototype.getLiveEventSmallGift = function () {
    return /** @type{?proto.LiveEventSmallGift} */ (jspb.Message.getWrapperField(this, proto.LiveEventSmallGift, 11));
};
/**
 * @param {?proto.LiveEventSmallGift|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventSmallGift = function (value) {
    return jspb.Message.setOneofWrapperField(this, 11, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventSmallGift = function () {
    return this.setLiveEventSmallGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventSmallGift = function () {
    return jspb.Message.getField(this, 11) != null;
};
/**
 * optional LiveEventLargeGift live_event_large_gift = 12;
 * @return {?proto.LiveEventLargeGift}
 */
proto.LiveEvent.prototype.getLiveEventLargeGift = function () {
    return /** @type{?proto.LiveEventLargeGift} */ (jspb.Message.getWrapperField(this, proto.LiveEventLargeGift, 12));
};
/**
 * @param {?proto.LiveEventLargeGift|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventLargeGift = function (value) {
    return jspb.Message.setOneofWrapperField(this, 12, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventLargeGift = function () {
    return this.setLiveEventLargeGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventLargeGift = function () {
    return jspb.Message.getField(this, 12) != null;
};
/**
 * optional LiveEventStartSing live_event_start_sing = 13;
 * @return {?proto.LiveEventStartSing}
 */
proto.LiveEvent.prototype.getLiveEventStartSing = function () {
    return /** @type{?proto.LiveEventStartSing} */ (jspb.Message.getWrapperField(this, proto.LiveEventStartSing, 13));
};
/**
 * @param {?proto.LiveEventStartSing|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventStartSing = function (value) {
    return jspb.Message.setOneofWrapperField(this, 13, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventStartSing = function () {
    return this.setLiveEventStartSing(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventStartSing = function () {
    return jspb.Message.getField(this, 13) != null;
};
/**
 * optional LiveEventFinishSing live_event_finish_sing = 14;
 * @return {?proto.LiveEventFinishSing}
 */
proto.LiveEvent.prototype.getLiveEventFinishSing = function () {
    return /** @type{?proto.LiveEventFinishSing} */ (jspb.Message.getWrapperField(this, proto.LiveEventFinishSing, 14));
};
/**
 * @param {?proto.LiveEventFinishSing|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventFinishSing = function (value) {
    return jspb.Message.setOneofWrapperField(this, 14, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventFinishSing = function () {
    return this.setLiveEventFinishSing(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventFinishSing = function () {
    return jspb.Message.getField(this, 14) != null;
};
/**
 * optional LiveEventGetFaveBonus live_event_get_fave_bonus = 15;
 * @return {?proto.LiveEventGetFaveBonus}
 */
proto.LiveEvent.prototype.getLiveEventGetFaveBonus = function () {
    return /** @type{?proto.LiveEventGetFaveBonus} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFaveBonus, 15));
};
/**
 * @param {?proto.LiveEventGetFaveBonus|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetFaveBonus = function (value) {
    return jspb.Message.setOneofWrapperField(this, 15, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetFaveBonus = function () {
    return this.setLiveEventGetFaveBonus(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetFaveBonus = function () {
    return jspb.Message.getField(this, 15) != null;
};
/**
 * optional LiveEventGetFaveBonusTitle live_event_get_fave_bonus_title = 16;
 * @return {?proto.LiveEventGetFaveBonusTitle}
 */
proto.LiveEvent.prototype.getLiveEventGetFaveBonusTitle = function () {
    return /** @type{?proto.LiveEventGetFaveBonusTitle} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFaveBonusTitle, 16));
};
/**
 * @param {?proto.LiveEventGetFaveBonusTitle|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetFaveBonusTitle = function (value) {
    return jspb.Message.setOneofWrapperField(this, 16, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetFaveBonusTitle = function () {
    return this.setLiveEventGetFaveBonusTitle(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetFaveBonusTitle = function () {
    return jspb.Message.getField(this, 16) != null;
};
/**
 * optional LiveEventGetFavePoints live_event_get_fave_points = 17;
 * @return {?proto.LiveEventGetFavePoints}
 */
proto.LiveEvent.prototype.getLiveEventGetFavePoints = function () {
    return /** @type{?proto.LiveEventGetFavePoints} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFavePoints, 17));
};
/**
 * @param {?proto.LiveEventGetFavePoints|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetFavePoints = function (value) {
    return jspb.Message.setOneofWrapperField(this, 17, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetFavePoints = function () {
    return this.setLiveEventGetFavePoints(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetFavePoints = function () {
    return jspb.Message.getField(this, 17) != null;
};
/**
 * optional LiveEventGetFaveBadge live_event_get_fave_badge = 18;
 * @return {?proto.LiveEventGetFaveBadge}
 */
proto.LiveEvent.prototype.getLiveEventGetFaveBadge = function () {
    return /** @type{?proto.LiveEventGetFaveBadge} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFaveBadge, 18));
};
/**
 * @param {?proto.LiveEventGetFaveBadge|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetFaveBadge = function (value) {
    return jspb.Message.setOneofWrapperField(this, 18, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetFaveBadge = function () {
    return this.setLiveEventGetFaveBadge(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetFaveBadge = function () {
    return jspb.Message.getField(this, 18) != null;
};
/**
 * optional LiveEventGetSongFave live_event_get_song_fave = 19;
 * @return {?proto.LiveEventGetSongFave}
 */
proto.LiveEvent.prototype.getLiveEventGetSongFave = function () {
    return /** @type{?proto.LiveEventGetSongFave} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetSongFave, 19));
};
/**
 * @param {?proto.LiveEventGetSongFave|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetSongFave = function (value) {
    return jspb.Message.setOneofWrapperField(this, 19, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetSongFave = function () {
    return this.setLiveEventGetSongFave(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetSongFave = function () {
    return jspb.Message.getField(this, 19) != null;
};
/**
 * optional LiveEventFollow live_event_follow = 20;
 * @return {?proto.LiveEventFollow}
 */
proto.LiveEvent.prototype.getLiveEventFollow = function () {
    return /** @type{?proto.LiveEventFollow} */ (jspb.Message.getWrapperField(this, proto.LiveEventFollow, 20));
};
/**
 * @param {?proto.LiveEventFollow|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventFollow = function (value) {
    return jspb.Message.setOneofWrapperField(this, 20, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventFollow = function () {
    return this.setLiveEventFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventFollow = function () {
    return jspb.Message.getField(this, 20) != null;
};
/**
 * optional LiveEventLike live_event_like = 21;
 * @return {?proto.LiveEventLike}
 */
proto.LiveEvent.prototype.getLiveEventLike = function () {
    return /** @type{?proto.LiveEventLike} */ (jspb.Message.getWrapperField(this, proto.LiveEventLike, 21));
};
/**
 * @param {?proto.LiveEventLike|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventLike = function (value) {
    return jspb.Message.setOneofWrapperField(this, 21, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventLike = function () {
    return this.setLiveEventLike(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventLike = function () {
    return jspb.Message.getField(this, 21) != null;
};
/**
 * optional LiveEventSuperLike live_event_super_like = 22;
 * @return {?proto.LiveEventSuperLike}
 */
proto.LiveEvent.prototype.getLiveEventSuperLike = function () {
    return /** @type{?proto.LiveEventSuperLike} */ (jspb.Message.getWrapperField(this, proto.LiveEventSuperLike, 22));
};
/**
 * @param {?proto.LiveEventSuperLike|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventSuperLike = function (value) {
    return jspb.Message.setOneofWrapperField(this, 22, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventSuperLike = function () {
    return this.setLiveEventSuperLike(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventSuperLike = function () {
    return jspb.Message.getField(this, 22) != null;
};
/**
 * optional LiveEventAnnounce live_event_announce = 23;
 * @return {?proto.LiveEventAnnounce}
 */
proto.LiveEvent.prototype.getLiveEventAnnounce = function () {
    return /** @type{?proto.LiveEventAnnounce} */ (jspb.Message.getWrapperField(this, proto.LiveEventAnnounce, 23));
};
/**
 * @param {?proto.LiveEventAnnounce|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventAnnounce = function (value) {
    return jspb.Message.setOneofWrapperField(this, 23, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventAnnounce = function () {
    return this.setLiveEventAnnounce(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventAnnounce = function () {
    return jspb.Message.getField(this, 23) != null;
};
/**
 * optional LiveEventSurgeRankingNo1Reward live_event_surge_ranking_no1_reward = 24;
 * @return {?proto.LiveEventSurgeRankingNo1Reward}
 */
proto.LiveEvent.prototype.getLiveEventSurgeRankingNo1Reward = function () {
    return /** @type{?proto.LiveEventSurgeRankingNo1Reward} */ (jspb.Message.getWrapperField(this, proto.LiveEventSurgeRankingNo1Reward, 24));
};
/**
 * @param {?proto.LiveEventSurgeRankingNo1Reward|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventSurgeRankingNo1Reward = function (value) {
    return jspb.Message.setOneofWrapperField(this, 24, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventSurgeRankingNo1Reward = function () {
    return this.setLiveEventSurgeRankingNo1Reward(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventSurgeRankingNo1Reward = function () {
    return jspb.Message.getField(this, 24) != null;
};
/**
 * optional LiveEventRequestSing live_event_request_sing = 25;
 * @return {?proto.LiveEventRequestSing}
 */
proto.LiveEvent.prototype.getLiveEventRequestSing = function () {
    return /** @type{?proto.LiveEventRequestSing} */ (jspb.Message.getWrapperField(this, proto.LiveEventRequestSing, 25));
};
/**
 * @param {?proto.LiveEventRequestSing|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventRequestSing = function (value) {
    return jspb.Message.setOneofWrapperField(this, 25, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventRequestSing = function () {
    return this.setLiveEventRequestSing(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventRequestSing = function () {
    return jspb.Message.getField(this, 25) != null;
};
/**
 * optional LiveEventSimplePFComment live_event_simple_pf_comment = 26;
 * @return {?proto.LiveEventSimplePFComment}
 */
proto.LiveEvent.prototype.getLiveEventSimplePfComment = function () {
    return /** @type{?proto.LiveEventSimplePFComment} */ (jspb.Message.getWrapperField(this, proto.LiveEventSimplePFComment, 26));
};
/**
 * @param {?proto.LiveEventSimplePFComment|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventSimplePfComment = function (value) {
    return jspb.Message.setOneofWrapperField(this, 26, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventSimplePfComment = function () {
    return this.setLiveEventSimplePfComment(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventSimplePfComment = function () {
    return jspb.Message.getField(this, 26) != null;
};
/**
 * optional LiveEventGetFaveBadgeAndSongFave live_event_get_fave_badge_and_song_fave = 27;
 * @return {?proto.LiveEventGetFaveBadgeAndSongFave}
 */
proto.LiveEvent.prototype.getLiveEventGetFaveBadgeAndSongFave = function () {
    return /** @type{?proto.LiveEventGetFaveBadgeAndSongFave} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFaveBadgeAndSongFave, 27));
};
/**
 * @param {?proto.LiveEventGetFaveBadgeAndSongFave|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetFaveBadgeAndSongFave = function (value) {
    return jspb.Message.setOneofWrapperField(this, 27, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetFaveBadgeAndSongFave = function () {
    return this.setLiveEventGetFaveBadgeAndSongFave(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetFaveBadgeAndSongFave = function () {
    return jspb.Message.getField(this, 27) != null;
};
/**
 * optional LiveEventHeartfulMention live_event_heartful_mention = 28;
 * @return {?proto.LiveEventHeartfulMention}
 */
proto.LiveEvent.prototype.getLiveEventHeartfulMention = function () {
    return /** @type{?proto.LiveEventHeartfulMention} */ (jspb.Message.getWrapperField(this, proto.LiveEventHeartfulMention, 28));
};
/**
 * @param {?proto.LiveEventHeartfulMention|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventHeartfulMention = function (value) {
    return jspb.Message.setOneofWrapperField(this, 28, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventHeartfulMention = function () {
    return this.setLiveEventHeartfulMention(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventHeartfulMention = function () {
    return jspb.Message.getField(this, 28) != null;
};
/**
 * optional LiveEventLiveSettingsUpdated live_settings_updated = 29;
 * @return {?proto.LiveEventLiveSettingsUpdated}
 */
proto.LiveEvent.prototype.getLiveSettingsUpdated = function () {
    return /** @type{?proto.LiveEventLiveSettingsUpdated} */ (jspb.Message.getWrapperField(this, proto.LiveEventLiveSettingsUpdated, 29));
};
/**
 * @param {?proto.LiveEventLiveSettingsUpdated|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveSettingsUpdated = function (value) {
    return jspb.Message.setOneofWrapperField(this, 29, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveSettingsUpdated = function () {
    return this.setLiveSettingsUpdated(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveSettingsUpdated = function () {
    return jspb.Message.getField(this, 29) != null;
};
/**
 * optional LiveEventListenerReqCapturePossibilityUpdated listener_req_capture_possibility_updated = 31;
 * @return {?proto.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.LiveEvent.prototype.getListenerReqCapturePossibilityUpdated = function () {
    return /** @type{?proto.LiveEventListenerReqCapturePossibilityUpdated} */ (jspb.Message.getWrapperField(this, proto.LiveEventListenerReqCapturePossibilityUpdated, 31));
};
/**
 * @param {?proto.LiveEventListenerReqCapturePossibilityUpdated|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setListenerReqCapturePossibilityUpdated = function (value) {
    return jspb.Message.setOneofWrapperField(this, 31, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearListenerReqCapturePossibilityUpdated = function () {
    return this.setListenerReqCapturePossibilityUpdated(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasListenerReqCapturePossibilityUpdated = function () {
    return jspb.Message.getField(this, 31) != null;
};
/**
 * optional LiveEventPickUpSongRandomly live_event_pick_up_song_randomly = 32;
 * @return {?proto.LiveEventPickUpSongRandomly}
 */
proto.LiveEvent.prototype.getLiveEventPickUpSongRandomly = function () {
    return /** @type{?proto.LiveEventPickUpSongRandomly} */ (jspb.Message.getWrapperField(this, proto.LiveEventPickUpSongRandomly, 32));
};
/**
 * @param {?proto.LiveEventPickUpSongRandomly|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventPickUpSongRandomly = function (value) {
    return jspb.Message.setOneofWrapperField(this, 32, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventPickUpSongRandomly = function () {
    return this.setLiveEventPickUpSongRandomly(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventPickUpSongRandomly = function () {
    return jspb.Message.getField(this, 32) != null;
};
/**
 * optional LiveEventMembershipUpdated live_event_membership_updated = 34;
 * @return {?proto.LiveEventMembershipUpdated}
 */
proto.LiveEvent.prototype.getLiveEventMembershipUpdated = function () {
    return /** @type{?proto.LiveEventMembershipUpdated} */ (jspb.Message.getWrapperField(this, proto.LiveEventMembershipUpdated, 34));
};
/**
 * @param {?proto.LiveEventMembershipUpdated|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventMembershipUpdated = function (value) {
    return jspb.Message.setOneofWrapperField(this, 34, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventMembershipUpdated = function () {
    return this.setLiveEventMembershipUpdated(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventMembershipUpdated = function () {
    return jspb.Message.getField(this, 34) != null;
};
/**
 * optional LiveEventGetSongRandomly live_event_get_song_randomly = 35;
 * @return {?proto.LiveEventGetSongRandomly}
 */
proto.LiveEvent.prototype.getLiveEventGetSongRandomly = function () {
    return /** @type{?proto.LiveEventGetSongRandomly} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetSongRandomly, 35));
};
/**
 * @param {?proto.LiveEventGetSongRandomly|undefined} value
 * @return {!proto.LiveEvent} returns this
*/
proto.LiveEvent.prototype.setLiveEventGetSongRandomly = function (value) {
    return jspb.Message.setOneofWrapperField(this, 35, proto.LiveEvent.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEvent} returns this
 */
proto.LiveEvent.prototype.clearLiveEventGetSongRandomly = function () {
    return this.setLiveEventGetSongRandomly(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEvent.prototype.hasLiveEventGetSongRandomly = function () {
    return jspb.Message.getField(this, 35) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventSystem.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventSystem.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventSystem} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventSystem.toObject = function (includeInstance, msg) {
        var f, obj = {
            message: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventSystem}
 */
proto.LiveEventSystem.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventSystem;
    return proto.LiveEventSystem.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventSystem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventSystem}
 */
proto.LiveEventSystem.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventSystem.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventSystem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventSystem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventSystem.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string message = 1;
 * @return {string}
 */
proto.LiveEventSystem.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSystem} returns this
 */
proto.LiveEventSystem.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventStart.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventStart.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventStart} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventStart.toObject = function (includeInstance, msg) {
        var f, obj = {
            liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventStart}
 */
proto.LiveEventStart.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventStart;
    return proto.LiveEventStart.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventStart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventStart}
 */
proto.LiveEventStart.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setLiver(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventStart.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventStart.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventStart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventStart.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiver();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
};
/**
 * optional User liver = 1;
 * @return {?proto.User}
 */
proto.LiveEventStart.prototype.getLiver = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventStart} returns this
*/
proto.LiveEventStart.prototype.setLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventStart} returns this
 */
proto.LiveEventStart.prototype.clearLiver = function () {
    return this.setLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventStart.prototype.hasLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventFinish.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventFinish.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventFinish} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventFinish.toObject = function (includeInstance, msg) {
        var f, obj = {
            liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f),
            reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
            liverId: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventFinish}
 */
proto.LiveEventFinish.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventFinish;
    return proto.LiveEventFinish.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventFinish} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventFinish}
 */
proto.LiveEventFinish.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setLiver(value);
                break;
            case 2:
                var value = /** @type {!proto.LiveFinishReason} */ (reader.readEnum());
                msg.setReason(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventFinish.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventFinish.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventFinish} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventFinish.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiver();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getReason();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional User liver = 1;
 * @return {?proto.User}
 */
proto.LiveEventFinish.prototype.getLiver = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventFinish} returns this
*/
proto.LiveEventFinish.prototype.setLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFinish} returns this
 */
proto.LiveEventFinish.prototype.clearLiver = function () {
    return this.setLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFinish.prototype.hasLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional LiveFinishReason reason = 2;
 * @return {!proto.LiveFinishReason}
 */
proto.LiveEventFinish.prototype.getReason = function () {
    return /** @type {!proto.LiveFinishReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.LiveFinishReason} value
 * @return {!proto.LiveEventFinish} returns this
 */
proto.LiveEventFinish.prototype.setReason = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string liver_id = 3;
 * @return {string}
 */
proto.LiveEventFinish.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventFinish} returns this
 */
proto.LiveEventFinish.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventEnter.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventEnter.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventEnter} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventEnter.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            isFirstLook: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            pfFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            relatedTown: (f = msg.getRelatedTown()) && users_entity_pb.Town.toObject(includeInstance, f),
            relatedSinger: (f = msg.getRelatedSinger()) && karaoke_entity_pb.Singer.toObject(includeInstance, f),
            isPresentable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.HeartfulMentionButton.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventEnter}
 */
proto.LiveEventEnter.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventEnter;
    return proto.LiveEventEnter.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventEnter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventEnter}
 */
proto.LiveEventEnter.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFirstLook(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoints(value);
                break;
            case 5:
                var value = new users_entity_pb.Town;
                reader.readMessage(value, users_entity_pb.Town.deserializeBinaryFromReader);
                msg.setRelatedTown(value);
                break;
            case 6:
                var value = new karaoke_entity_pb.Singer;
                reader.readMessage(value, karaoke_entity_pb.Singer.deserializeBinaryFromReader);
                msg.setRelatedSinger(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsPresentable(value);
                break;
            case 8:
                var value = new proto.HeartfulMentionButton;
                reader.readMessage(value, proto.HeartfulMentionButton.deserializeBinaryFromReader);
                msg.setHeartfulMentionButton(value);
                break;
            case 9:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventEnter.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventEnter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventEnter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventEnter.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getIsFirstLook();
    if (f) {
        writer.writeBool(2, f);
    }
    f = message.getPfFavePoints();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getRelatedTown();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.Town.serializeBinaryToWriter);
    }
    f = message.getRelatedSinger();
    if (f != null) {
        writer.writeMessage(6, f, karaoke_entity_pb.Singer.serializeBinaryToWriter);
    }
    f = message.getIsPresentable();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getHeartfulMentionButton();
    if (f != null) {
        writer.writeMessage(8, f, proto.HeartfulMentionButton.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(9, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventEnter.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventEnter} returns this
*/
proto.LiveEventEnter.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool is_first_look = 2;
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.getIsFirstLook = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.setIsFirstLook = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional int64 pf_fave_points = 3;
 * @return {number}
 */
proto.LiveEventEnter.prototype.getPfFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.setPfFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional Town related_town = 5;
 * @return {?proto.Town}
 */
proto.LiveEventEnter.prototype.getRelatedTown = function () {
    return /** @type{?proto.Town} */ (jspb.Message.getWrapperField(this, users_entity_pb.Town, 5));
};
/**
 * @param {?proto.Town|undefined} value
 * @return {!proto.LiveEventEnter} returns this
*/
proto.LiveEventEnter.prototype.setRelatedTown = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.clearRelatedTown = function () {
    return this.setRelatedTown(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.hasRelatedTown = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional Singer related_singer = 6;
 * @return {?proto.Singer}
 */
proto.LiveEventEnter.prototype.getRelatedSinger = function () {
    return /** @type{?proto.Singer} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Singer, 6));
};
/**
 * @param {?proto.Singer|undefined} value
 * @return {!proto.LiveEventEnter} returns this
*/
proto.LiveEventEnter.prototype.setRelatedSinger = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.clearRelatedSinger = function () {
    return this.setRelatedSinger(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.hasRelatedSinger = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional bool is_presentable = 7;
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.getIsPresentable = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.setIsPresentable = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * optional HeartfulMentionButton heartful_mention_button = 8;
 * @return {?proto.HeartfulMentionButton}
 */
proto.LiveEventEnter.prototype.getHeartfulMentionButton = function () {
    return /** @type{?proto.HeartfulMentionButton} */ (jspb.Message.getWrapperField(this, proto.HeartfulMentionButton, 8));
};
/**
 * @param {?proto.HeartfulMentionButton|undefined} value
 * @return {!proto.LiveEventEnter} returns this
*/
proto.LiveEventEnter.prototype.setHeartfulMentionButton = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.clearHeartfulMentionButton = function () {
    return this.setHeartfulMentionButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.hasHeartfulMentionButton = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 9;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventEnter.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 9));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventEnter} returns this
*/
proto.LiveEventEnter.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventEnter} returns this
 */
proto.LiveEventEnter.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventEnter.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 9) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventLeave.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventLeave.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventLeave} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventLeave.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            reason: jspb.Message.getFieldWithDefault(msg, 2, 0),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventLeave}
 */
proto.LiveEventLeave.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventLeave;
    return proto.LiveEventLeave.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventLeave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventLeave}
 */
proto.LiveEventLeave.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {!proto.LiveFinishReason} */ (reader.readEnum());
                msg.setReason(value);
                break;
            case 3:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventLeave.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventLeave.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventLeave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventLeave.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getReason();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventLeave.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventLeave} returns this
*/
proto.LiveEventLeave.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLeave} returns this
 */
proto.LiveEventLeave.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLeave.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional LiveFinishReason reason = 2;
 * @return {!proto.LiveFinishReason}
 */
proto.LiveEventLeave.prototype.getReason = function () {
    return /** @type {!proto.LiveFinishReason} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.LiveFinishReason} value
 * @return {!proto.LiveEventLeave} returns this
 */
proto.LiveEventLeave.prototype.setReason = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 3;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventLeave.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 3));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventLeave} returns this
*/
proto.LiveEventLeave.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLeave} returns this
 */
proto.LiveEventLeave.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLeave.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventComment.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventComment.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventComment} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventComment.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
            mentionUser: (f = msg.getMentionUser()) && users_entity_pb.User.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 6, 0),
            songYellBadge: jspb.Message.getFieldWithDefault(msg, 7, 0),
            isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            suppressUselessComment: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
            userMembershipInfo: (f = msg.getUserMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventComment}
 */
proto.LiveEventComment.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventComment;
    return proto.LiveEventComment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventComment}
 */
proto.LiveEventComment.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setComment(value);
                break;
            case 3:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setMentionUser(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 6:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 7:
                var value = /** @type {!proto.SongYellBadge} */ (reader.readEnum());
                msg.setSongYellBadge(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBeginner(value);
                break;
            case 9:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuppressUselessComment(value);
                break;
            case 10:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setUserMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventComment.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventComment.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getComment();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getMentionUser();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getSongYellBadge();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
    f = message.getIsBeginner();
    if (f) {
        writer.writeBool(8, f);
    }
    f = message.getSuppressUselessComment();
    if (f) {
        writer.writeBool(9, f);
    }
    f = message.getUserMembershipInfo();
    if (f != null) {
        writer.writeMessage(10, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.User}
 */
proto.LiveEventComment.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventComment} returns this
*/
proto.LiveEventComment.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventComment.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string comment = 2;
 * @return {string}
 */
proto.LiveEventComment.prototype.getComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setComment = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional User mention_user = 3;
 * @return {?proto.User}
 */
proto.LiveEventComment.prototype.getMentionUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 3));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventComment} returns this
*/
proto.LiveEventComment.prototype.setMentionUser = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.clearMentionUser = function () {
    return this.setMentionUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventComment.prototype.hasMentionUser = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.LiveEventComment.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.LiveEventComment.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional FanType fan_type = 6;
 * @return {!proto.FanType}
 */
proto.LiveEventComment.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional SongYellBadge song_yell_badge = 7;
 * @return {!proto.SongYellBadge}
 */
proto.LiveEventComment.prototype.getSongYellBadge = function () {
    return /** @type {!proto.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.SongYellBadge} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setSongYellBadge = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional bool is_beginner = 8;
 * @return {boolean}
 */
proto.LiveEventComment.prototype.getIsBeginner = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setIsBeginner = function (value) {
    return jspb.Message.setProto3BooleanField(this, 8, value);
};
/**
 * optional bool suppress_useless_comment = 9;
 * @return {boolean}
 */
proto.LiveEventComment.prototype.getSuppressUselessComment = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.setSuppressUselessComment = function (value) {
    return jspb.Message.setProto3BooleanField(this, 9, value);
};
/**
 * optional ListenerMembershipInfo user_membership_info = 10;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventComment.prototype.getUserMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 10));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventComment} returns this
*/
proto.LiveEventComment.prototype.setUserMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventComment} returns this
 */
proto.LiveEventComment.prototype.clearUserMembershipInfo = function () {
    return this.setUserMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventComment.prototype.hasUserMembershipInfo = function () {
    return jspb.Message.getField(this, 10) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventSmallGift.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventSmallGift.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventSmallGift} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventSmallGift.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            gift: (f = msg.getGift()) && proto.Gift.toObject(includeInstance, f),
            giftCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 6, 0),
            songYellBadge: jspb.Message.getFieldWithDefault(msg, 7, 0),
            isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
            smallGiftType: jspb.Message.getFieldWithDefault(msg, 9, 0),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventSmallGift}
 */
proto.LiveEventSmallGift.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventSmallGift;
    return proto.LiveEventSmallGift.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventSmallGift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventSmallGift}
 */
proto.LiveEventSmallGift.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new proto.Gift;
                reader.readMessage(value, proto.Gift.deserializeBinaryFromReader);
                msg.setGift(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setGiftCount(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 6:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 7:
                var value = /** @type {!proto.SongYellBadge} */ (reader.readEnum());
                msg.setSongYellBadge(value);
                break;
            case 8:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBeginner(value);
                break;
            case 9:
                var value = /** @type {!proto.SmallGiftType} */ (reader.readEnum());
                msg.setSmallGiftType(value);
                break;
            case 10:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventSmallGift.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventSmallGift.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventSmallGift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventSmallGift.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getGift();
    if (f != null) {
        writer.writeMessage(2, f, proto.Gift.serializeBinaryToWriter);
    }
    f = message.getGiftCount();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getSongYellBadge();
    if (f !== 0.0) {
        writer.writeEnum(7, f);
    }
    f = message.getIsBeginner();
    if (f) {
        writer.writeBool(8, f);
    }
    f = message.getSmallGiftType();
    if (f !== 0.0) {
        writer.writeEnum(9, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(10, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventSmallGift.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventSmallGift} returns this
*/
proto.LiveEventSmallGift.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSmallGift.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Gift gift = 2;
 * @return {?proto.Gift}
 */
proto.LiveEventSmallGift.prototype.getGift = function () {
    return /** @type{?proto.Gift} */ (jspb.Message.getWrapperField(this, proto.Gift, 2));
};
/**
 * @param {?proto.Gift|undefined} value
 * @return {!proto.LiveEventSmallGift} returns this
*/
proto.LiveEventSmallGift.prototype.setGift = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.clearGift = function () {
    return this.setGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSmallGift.prototype.hasGift = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int32 gift_count = 3;
 * @return {number}
 */
proto.LiveEventSmallGift.prototype.getGiftCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setGiftCount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.LiveEventSmallGift.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.LiveEventSmallGift.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional FanType fan_type = 6;
 * @return {!proto.FanType}
 */
proto.LiveEventSmallGift.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional SongYellBadge song_yell_badge = 7;
 * @return {!proto.SongYellBadge}
 */
proto.LiveEventSmallGift.prototype.getSongYellBadge = function () {
    return /** @type {!proto.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {!proto.SongYellBadge} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setSongYellBadge = function (value) {
    return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional bool is_beginner = 8;
 * @return {boolean}
 */
proto.LiveEventSmallGift.prototype.getIsBeginner = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setIsBeginner = function (value) {
    return jspb.Message.setProto3BooleanField(this, 8, value);
};
/**
 * optional SmallGiftType small_gift_type = 9;
 * @return {!proto.SmallGiftType}
 */
proto.LiveEventSmallGift.prototype.getSmallGiftType = function () {
    return /** @type {!proto.SmallGiftType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {!proto.SmallGiftType} value
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.setSmallGiftType = function (value) {
    return jspb.Message.setProto3EnumField(this, 9, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 10;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventSmallGift.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 10));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventSmallGift} returns this
*/
proto.LiveEventSmallGift.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSmallGift} returns this
 */
proto.LiveEventSmallGift.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSmallGift.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 10) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventLargeGift.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventLargeGift.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventLargeGift} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventLargeGift.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            gift: (f = msg.getGift()) && proto.Gift.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            songYellBadge: jspb.Message.getFieldWithDefault(msg, 6, 0),
            isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventLargeGift}
 */
proto.LiveEventLargeGift.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventLargeGift;
    return proto.LiveEventLargeGift.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventLargeGift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventLargeGift}
 */
proto.LiveEventLargeGift.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new proto.Gift;
                reader.readMessage(value, proto.Gift.deserializeBinaryFromReader);
                msg.setGift(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 6:
                var value = /** @type {!proto.SongYellBadge} */ (reader.readEnum());
                msg.setSongYellBadge(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBeginner(value);
                break;
            case 8:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventLargeGift.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventLargeGift.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventLargeGift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventLargeGift.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getGift();
    if (f != null) {
        writer.writeMessage(2, f, proto.Gift.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getSongYellBadge();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getIsBeginner();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventLargeGift.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventLargeGift} returns this
*/
proto.LiveEventLargeGift.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLargeGift.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Gift gift = 2;
 * @return {?proto.Gift}
 */
proto.LiveEventLargeGift.prototype.getGift = function () {
    return /** @type{?proto.Gift} */ (jspb.Message.getWrapperField(this, proto.Gift, 2));
};
/**
 * @param {?proto.Gift|undefined} value
 * @return {!proto.LiveEventLargeGift} returns this
*/
proto.LiveEventLargeGift.prototype.setGift = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.clearGift = function () {
    return this.setGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLargeGift.prototype.hasGift = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 termly_fave_points = 3;
 * @return {number}
 */
proto.LiveEventLargeGift.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 monthly_fave_points = 4;
 * @return {number}
 */
proto.LiveEventLargeGift.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional FanType fan_type = 5;
 * @return {!proto.FanType}
 */
proto.LiveEventLargeGift.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional SongYellBadge song_yell_badge = 6;
 * @return {!proto.SongYellBadge}
 */
proto.LiveEventLargeGift.prototype.getSongYellBadge = function () {
    return /** @type {!proto.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.SongYellBadge} value
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.setSongYellBadge = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional bool is_beginner = 7;
 * @return {boolean}
 */
proto.LiveEventLargeGift.prototype.getIsBeginner = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.setIsBeginner = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 8;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventLargeGift.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventLargeGift} returns this
*/
proto.LiveEventLargeGift.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLargeGift} returns this
 */
proto.LiveEventLargeGift.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLargeGift.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LiveEventStartSing.repeatedFields_ = [4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventStartSing.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventStartSing.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventStartSing} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventStartSing.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            singStartTimeInClient: jspb.Message.getFieldWithDefault(msg, 2, 0),
            listeningReactionGiftButtonsList: jspb.Message.toObjectList(msg.getListeningReactionGiftButtonsList(), proto.ListeningReactionGiftButton.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventStartSing}
 */
proto.LiveEventStartSing.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventStartSing;
    return proto.LiveEventStartSing.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventStartSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventStartSing}
 */
proto.LiveEventStartSing.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingStartTimeInClient(value);
                break;
            case 4:
                var value = new proto.ListeningReactionGiftButton;
                reader.readMessage(value, proto.ListeningReactionGiftButton.deserializeBinaryFromReader);
                msg.addListeningReactionGiftButtons(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventStartSing.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventStartSing.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventStartSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventStartSing.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getSingStartTimeInClient();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getListeningReactionGiftButtonsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, proto.ListeningReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.Song}
 */
proto.LiveEventStartSing.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveEventStartSing} returns this
*/
proto.LiveEventStartSing.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventStartSing} returns this
 */
proto.LiveEventStartSing.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventStartSing.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 sing_start_time_in_client = 2;
 * @return {number}
 */
proto.LiveEventStartSing.prototype.getSingStartTimeInClient = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventStartSing} returns this
 */
proto.LiveEventStartSing.prototype.setSingStartTimeInClient = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated ListeningReactionGiftButton listening_reaction_gift_buttons = 4;
 * @return {!Array<!proto.ListeningReactionGiftButton>}
 */
proto.LiveEventStartSing.prototype.getListeningReactionGiftButtonsList = function () {
    return /** @type{!Array<!proto.ListeningReactionGiftButton>} */ (jspb.Message.getRepeatedWrapperField(this, proto.ListeningReactionGiftButton, 4));
};
/**
 * @param {!Array<!proto.ListeningReactionGiftButton>} value
 * @return {!proto.LiveEventStartSing} returns this
*/
proto.LiveEventStartSing.prototype.setListeningReactionGiftButtonsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.ListeningReactionGiftButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ListeningReactionGiftButton}
 */
proto.LiveEventStartSing.prototype.addListeningReactionGiftButtons = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ListeningReactionGiftButton, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LiveEventStartSing} returns this
 */
proto.LiveEventStartSing.prototype.clearListeningReactionGiftButtonsList = function () {
    return this.setListeningReactionGiftButtonsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ListeningReactionGiftButton.prototype.toObject = function (opt_includeInstance) {
        return proto.ListeningReactionGiftButton.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ListeningReactionGiftButton} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ListeningReactionGiftButton.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            displayTimingSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f),
            isGospelGift: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListeningReactionGiftButton}
 */
proto.ListeningReactionGiftButton.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ListeningReactionGiftButton;
    return proto.ListeningReactionGiftButton.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListeningReactionGiftButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListeningReactionGiftButton}
 */
proto.ListeningReactionGiftButton.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDisplayTimingSeconds(value);
                break;
            case 3:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsGospelGift(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListeningReactionGiftButton.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ListeningReactionGiftButton.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListeningReactionGiftButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListeningReactionGiftButton.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDisplayTimingSeconds();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(3, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
    f = message.getIsGospelGift();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.ListeningReactionGiftButton.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.ListeningReactionGiftButton} returns this
 */
proto.ListeningReactionGiftButton.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 display_timing_seconds = 2;
 * @return {number}
 */
proto.ListeningReactionGiftButton.prototype.getDisplayTimingSeconds = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListeningReactionGiftButton} returns this
 */
proto.ListeningReactionGiftButton.prototype.setDisplayTimingSeconds = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.ReactionGiftButton}
 */
proto.ListeningReactionGiftButton.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 3));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.ListeningReactionGiftButton} returns this
*/
proto.ListeningReactionGiftButton.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListeningReactionGiftButton} returns this
 */
proto.ListeningReactionGiftButton.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListeningReactionGiftButton.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional bool is_gospel_gift = 4;
 * @return {boolean}
 */
proto.ListeningReactionGiftButton.prototype.getIsGospelGift = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.ListeningReactionGiftButton} returns this
 */
proto.ListeningReactionGiftButton.prototype.setIsGospelGift = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventFinishSing.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventFinishSing.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventFinishSing} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventFinishSing.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventFinishSing}
 */
proto.LiveEventFinishSing.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventFinishSing;
    return proto.LiveEventFinishSing.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventFinishSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventFinishSing}
 */
proto.LiveEventFinishSing.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventFinishSing.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventFinishSing.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventFinishSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventFinishSing.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(2, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.Song}
 */
proto.LiveEventFinishSing.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveEventFinishSing} returns this
*/
proto.LiveEventFinishSing.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFinishSing} returns this
 */
proto.LiveEventFinishSing.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFinishSing.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ReactionGiftButton reaction_gift_button = 2;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventFinishSing.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 2));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventFinishSing} returns this
*/
proto.LiveEventFinishSing.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFinishSing} returns this
 */
proto.LiveEventFinishSing.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFinishSing.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventRequestSing.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventRequestSing.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventRequestSing} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventRequestSing.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 5, 0),
            songYellBadge: jspb.Message.getFieldWithDefault(msg, 6, 0),
            isBeginner: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventRequestSing}
 */
proto.LiveEventRequestSing.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventRequestSing;
    return proto.LiveEventRequestSing.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventRequestSing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventRequestSing}
 */
proto.LiveEventRequestSing.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 6:
                var value = /** @type {!proto.SongYellBadge} */ (reader.readEnum());
                msg.setSongYellBadge(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsBeginner(value);
                break;
            case 8:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventRequestSing.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventRequestSing.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventRequestSing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventRequestSing.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(2, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getSongYellBadge();
    if (f !== 0.0) {
        writer.writeEnum(6, f);
    }
    f = message.getIsBeginner();
    if (f) {
        writer.writeBool(7, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(8, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventRequestSing.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventRequestSing} returns this
*/
proto.LiveEventRequestSing.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventRequestSing.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Song song = 2;
 * @return {?proto.Song}
 */
proto.LiveEventRequestSing.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveEventRequestSing} returns this
*/
proto.LiveEventRequestSing.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventRequestSing.prototype.hasSong = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 termly_fave_points = 3;
 * @return {number}
 */
proto.LiveEventRequestSing.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 monthly_fave_points = 4;
 * @return {number}
 */
proto.LiveEventRequestSing.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional FanType fan_type = 5;
 * @return {!proto.FanType}
 */
proto.LiveEventRequestSing.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional SongYellBadge song_yell_badge = 6;
 * @return {!proto.SongYellBadge}
 */
proto.LiveEventRequestSing.prototype.getSongYellBadge = function () {
    return /** @type {!proto.SongYellBadge} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {!proto.SongYellBadge} value
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.setSongYellBadge = function (value) {
    return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional bool is_beginner = 7;
 * @return {boolean}
 */
proto.LiveEventRequestSing.prototype.getIsBeginner = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.setIsBeginner = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 8;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventRequestSing.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 8));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventRequestSing} returns this
*/
proto.LiveEventRequestSing.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventRequestSing} returns this
 */
proto.LiveEventRequestSing.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventRequestSing.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.LiveEventGetFaveBonus.oneofGroups_ = [[2, 3]];
/**
 * @enum {number}
 */
proto.LiveEventGetFaveBonus.ValueCase = {
    VALUE_NOT_SET: 0,
    NUMBER_OF_SONG_FAVE: 2,
    FAVE_POINTS: 3
};
/**
 * @return {proto.LiveEventGetFaveBonus.ValueCase}
 */
proto.LiveEventGetFaveBonus.prototype.getValueCase = function () {
    return /** @type {proto.LiveEventGetFaveBonus.ValueCase} */ (jspb.Message.computeOneofCase(this, proto.LiveEventGetFaveBonus.oneofGroups_[0]));
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetFaveBonus.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetFaveBonus.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetFaveBonus} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetFaveBonus.toObject = function (includeInstance, msg) {
        var f, obj = {
            faveBonusRate: jspb.Message.getFieldWithDefault(msg, 1, ""),
            numberOfSongFave: jspb.Message.getFieldWithDefault(msg, 2, 0),
            favePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetFaveBonus}
 */
proto.LiveEventGetFaveBonus.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetFaveBonus;
    return proto.LiveEventGetFaveBonus.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetFaveBonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetFaveBonus}
 */
proto.LiveEventGetFaveBonus.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusRate(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfSongFave(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePoints(value);
                break;
            case 4:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetFaveBonus.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetFaveBonus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetFaveBonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetFaveBonus.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
        writer.writeInt64(2, f);
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
        writer.writeInt64(3, f);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(4, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional string fave_bonus_rate = 1;
 * @return {string}
 */
proto.LiveEventGetFaveBonus.prototype.getFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.setFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 number_of_song_fave = 2;
 * @return {number}
 */
proto.LiveEventGetFaveBonus.prototype.getNumberOfSongFave = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.setNumberOfSongFave = function (value) {
    return jspb.Message.setOneofField(this, 2, proto.LiveEventGetFaveBonus.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.clearNumberOfSongFave = function () {
    return jspb.Message.setOneofField(this, 2, proto.LiveEventGetFaveBonus.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBonus.prototype.hasNumberOfSongFave = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 fave_points = 3;
 * @return {number}
 */
proto.LiveEventGetFaveBonus.prototype.getFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.setFavePoints = function (value) {
    return jspb.Message.setOneofField(this, 3, proto.LiveEventGetFaveBonus.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.clearFavePoints = function () {
    return jspb.Message.setOneofField(this, 3, proto.LiveEventGetFaveBonus.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBonus.prototype.hasFavePoints = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional ReactionGiftButton reaction_gift_button = 4;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventGetFaveBonus.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 4));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventGetFaveBonus} returns this
*/
proto.LiveEventGetFaveBonus.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBonus} returns this
 */
proto.LiveEventGetFaveBonus.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBonus.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 4) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetFaveBonusTitle.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetFaveBonusTitle.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetFaveBonusTitle} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetFaveBonusTitle.toObject = function (includeInstance, msg) {
        var f, obj = {
            liver: (f = msg.getLiver()) && users_entity_pb.User.toObject(includeInstance, f),
            faveBonusTitleRate: jspb.Message.getFieldWithDefault(msg, 2, ""),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetFaveBonusTitle}
 */
proto.LiveEventGetFaveBonusTitle.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetFaveBonusTitle;
    return proto.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetFaveBonusTitle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetFaveBonusTitle}
 */
proto.LiveEventGetFaveBonusTitle.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setLiver(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusTitleRate(value);
                break;
            case 3:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetFaveBonusTitle.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetFaveBonusTitle.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetFaveBonusTitle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetFaveBonusTitle.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiver();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getFaveBonusTitleRate();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(3, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional User liver = 1;
 * @return {?proto.User}
 */
proto.LiveEventGetFaveBonusTitle.prototype.getLiver = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventGetFaveBonusTitle} returns this
*/
proto.LiveEventGetFaveBonusTitle.prototype.setLiver = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBonusTitle} returns this
 */
proto.LiveEventGetFaveBonusTitle.prototype.clearLiver = function () {
    return this.setLiver(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBonusTitle.prototype.hasLiver = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string fave_bonus_title_rate = 2;
 * @return {string}
 */
proto.LiveEventGetFaveBonusTitle.prototype.getFaveBonusTitleRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventGetFaveBonusTitle} returns this
 */
proto.LiveEventGetFaveBonusTitle.prototype.setFaveBonusTitleRate = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventGetFaveBonusTitle.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 3));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventGetFaveBonusTitle} returns this
*/
proto.LiveEventGetFaveBonusTitle.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBonusTitle} returns this
 */
proto.LiveEventGetFaveBonusTitle.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBonusTitle.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetFavePoints.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetFavePoints.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetFavePoints} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetFavePoints.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            favePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.HeartfulMentionButton.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetFavePoints}
 */
proto.LiveEventGetFavePoints.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetFavePoints;
    return proto.LiveEventGetFavePoints.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetFavePoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetFavePoints}
 */
proto.LiveEventGetFavePoints.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setFavePoints(value);
                break;
            case 3:
                var value = new proto.HeartfulMentionButton;
                reader.readMessage(value, proto.HeartfulMentionButton.deserializeBinaryFromReader);
                msg.setHeartfulMentionButton(value);
                break;
            case 4:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetFavePoints.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetFavePoints.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetFavePoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetFavePoints.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getHeartfulMentionButton();
    if (f != null) {
        writer.writeMessage(3, f, proto.HeartfulMentionButton.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventGetFavePoints.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventGetFavePoints} returns this
*/
proto.LiveEventGetFavePoints.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFavePoints} returns this
 */
proto.LiveEventGetFavePoints.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFavePoints.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 fave_points = 2;
 * @return {number}
 */
proto.LiveEventGetFavePoints.prototype.getFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetFavePoints} returns this
 */
proto.LiveEventGetFavePoints.prototype.setFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional HeartfulMentionButton heartful_mention_button = 3;
 * @return {?proto.HeartfulMentionButton}
 */
proto.LiveEventGetFavePoints.prototype.getHeartfulMentionButton = function () {
    return /** @type{?proto.HeartfulMentionButton} */ (jspb.Message.getWrapperField(this, proto.HeartfulMentionButton, 3));
};
/**
 * @param {?proto.HeartfulMentionButton|undefined} value
 * @return {!proto.LiveEventGetFavePoints} returns this
*/
proto.LiveEventGetFavePoints.prototype.setHeartfulMentionButton = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFavePoints} returns this
 */
proto.LiveEventGetFavePoints.prototype.clearHeartfulMentionButton = function () {
    return this.setHeartfulMentionButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFavePoints.prototype.hasHeartfulMentionButton = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 4;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventGetFavePoints.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 4));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventGetFavePoints} returns this
*/
proto.LiveEventGetFavePoints.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFavePoints} returns this
 */
proto.LiveEventGetFavePoints.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFavePoints.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 4) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetFaveBadge.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetFaveBadge.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetFaveBadge} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetFaveBadge.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            isFirstFaveBadge: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetFaveBadge}
 */
proto.LiveEventGetFaveBadge.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetFaveBadge;
    return proto.LiveEventGetFaveBadge.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetFaveBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetFaveBadge}
 */
proto.LiveEventGetFaveBadge.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsFirstFaveBadge(value);
                break;
            case 4:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetFaveBadge.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetFaveBadge.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetFaveBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetFaveBadge.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getIsFirstFaveBadge();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(4, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventGetFaveBadge.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventGetFaveBadge} returns this
*/
proto.LiveEventGetFaveBadge.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadge} returns this
 */
proto.LiveEventGetFaveBadge.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadge.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 termly_fave_points = 2;
 * @return {number}
 */
proto.LiveEventGetFaveBadge.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetFaveBadge} returns this
 */
proto.LiveEventGetFaveBadge.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional bool is_first_fave_badge = 3;
 * @return {boolean}
 */
proto.LiveEventGetFaveBadge.prototype.getIsFirstFaveBadge = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventGetFaveBadge} returns this
 */
proto.LiveEventGetFaveBadge.prototype.setIsFirstFaveBadge = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 4;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventGetFaveBadge.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 4));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventGetFaveBadge} returns this
*/
proto.LiveEventGetFaveBadge.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadge} returns this
 */
proto.LiveEventGetFaveBadge.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadge.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 4) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetSongFave.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetSongFave.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetSongFave} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetSongFave.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            numberOfGettingSongFaveClass: jspb.Message.getFieldWithDefault(msg, 3, 0),
            numberOfMonthsInRow: jspb.Message.getFieldWithDefault(msg, 4, 0),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetSongFave}
 */
proto.LiveEventGetSongFave.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetSongFave;
    return proto.LiveEventGetSongFave.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetSongFave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetSongFave}
 */
proto.LiveEventGetSongFave.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfGettingSongFaveClass(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfMonthsInRow(value);
                break;
            case 5:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetSongFave.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetSongFave.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetSongFave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetSongFave.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNumberOfGettingSongFaveClass();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getNumberOfMonthsInRow();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventGetSongFave.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventGetSongFave} returns this
*/
proto.LiveEventGetSongFave.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetSongFave} returns this
 */
proto.LiveEventGetSongFave.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetSongFave.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 monthly_fave_points = 2;
 * @return {number}
 */
proto.LiveEventGetSongFave.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetSongFave} returns this
 */
proto.LiveEventGetSongFave.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 number_of_getting_song_fave_class = 3;
 * @return {number}
 */
proto.LiveEventGetSongFave.prototype.getNumberOfGettingSongFaveClass = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetSongFave} returns this
 */
proto.LiveEventGetSongFave.prototype.setNumberOfGettingSongFaveClass = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 number_of_months_in_row = 4;
 * @return {number}
 */
proto.LiveEventGetSongFave.prototype.getNumberOfMonthsInRow = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventGetSongFave} returns this
 */
proto.LiveEventGetSongFave.prototype.setNumberOfMonthsInRow = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 5;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventGetSongFave.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 5));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventGetSongFave} returns this
*/
proto.LiveEventGetSongFave.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetSongFave} returns this
 */
proto.LiveEventGetSongFave.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetSongFave.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventFollow.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventFollow.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventFollow} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventFollow.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.HeartfulMentionButton.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventFollow}
 */
proto.LiveEventFollow.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventFollow;
    return proto.LiveEventFollow.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventFollow}
 */
proto.LiveEventFollow.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new proto.HeartfulMentionButton;
                reader.readMessage(value, proto.HeartfulMentionButton.deserializeBinaryFromReader);
                msg.setHeartfulMentionButton(value);
                break;
            case 3:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventFollow.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventFollow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventFollow.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getHeartfulMentionButton();
    if (f != null) {
        writer.writeMessage(2, f, proto.HeartfulMentionButton.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventFollow.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventFollow} returns this
*/
proto.LiveEventFollow.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFollow} returns this
 */
proto.LiveEventFollow.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFollow.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional HeartfulMentionButton heartful_mention_button = 2;
 * @return {?proto.HeartfulMentionButton}
 */
proto.LiveEventFollow.prototype.getHeartfulMentionButton = function () {
    return /** @type{?proto.HeartfulMentionButton} */ (jspb.Message.getWrapperField(this, proto.HeartfulMentionButton, 2));
};
/**
 * @param {?proto.HeartfulMentionButton|undefined} value
 * @return {!proto.LiveEventFollow} returns this
*/
proto.LiveEventFollow.prototype.setHeartfulMentionButton = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFollow} returns this
 */
proto.LiveEventFollow.prototype.clearHeartfulMentionButton = function () {
    return this.setHeartfulMentionButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFollow.prototype.hasHeartfulMentionButton = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 3;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventFollow.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 3));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventFollow} returns this
*/
proto.LiveEventFollow.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventFollow} returns this
 */
proto.LiveEventFollow.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventFollow.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventLike.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventLike.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventLike} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventLike.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventLike}
 */
proto.LiveEventLike.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventLike;
    return proto.LiveEventLike.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventLike} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventLike}
 */
proto.LiveEventLike.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventLike.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventLike.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventLike} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventLike.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventLike.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventLike} returns this
*/
proto.LiveEventLike.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLike} returns this
 */
proto.LiveEventLike.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLike.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 2;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventLike.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 2));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventLike} returns this
*/
proto.LiveEventLike.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLike} returns this
 */
proto.LiveEventLike.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLike.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventSuperLike.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventSuperLike.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventSuperLike} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventSuperLike.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            dailyCountToLiver: jspb.Message.getFieldWithDefault(msg, 2, 0),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventSuperLike}
 */
proto.LiveEventSuperLike.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventSuperLike;
    return proto.LiveEventSuperLike.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventSuperLike} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventSuperLike}
 */
proto.LiveEventSuperLike.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDailyCountToLiver(value);
                break;
            case 3:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventSuperLike.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventSuperLike.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventSuperLike} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventSuperLike.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getDailyCountToLiver();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(3, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventSuperLike.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventSuperLike} returns this
*/
proto.LiveEventSuperLike.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSuperLike} returns this
 */
proto.LiveEventSuperLike.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSuperLike.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 daily_count_to_liver = 2;
 * @return {number}
 */
proto.LiveEventSuperLike.prototype.getDailyCountToLiver = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSuperLike} returns this
 */
proto.LiveEventSuperLike.prototype.setDailyCountToLiver = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 3;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventSuperLike.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 3));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventSuperLike} returns this
*/
proto.LiveEventSuperLike.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSuperLike} returns this
 */
proto.LiveEventSuperLike.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSuperLike.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LiveEventAnnounce.repeatedFields_ = [3, 4];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventAnnounce.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventAnnounce.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventAnnounce} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventAnnounce.toObject = function (includeInstance, msg) {
        var f, obj = {
            message: jspb.Message.getFieldWithDefault(msg, 1, ""),
            type: jspb.Message.getFieldWithDefault(msg, 2, 0),
            invisibleUserIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
            visibleUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventAnnounce}
 */
proto.LiveEventAnnounce.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventAnnounce;
    return proto.LiveEventAnnounce.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventAnnounce} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventAnnounce}
 */
proto.LiveEventAnnounce.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 2:
                var value = /** @type {!proto.LiveEventAnnounce.AnnounceType} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.addInvisibleUserIds(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.addVisibleUserIds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventAnnounce.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventAnnounce.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventAnnounce} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventAnnounce.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getInvisibleUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(3, f);
    }
    f = message.getVisibleUserIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(4, f);
    }
};
/**
 * @enum {number}
 */
proto.LiveEventAnnounce.AnnounceType = {
    ANNOUNCE_TYPE_NORMAL: 0,
    ANNOUNCE_TYPE_WARN: 1,
    ANNOUNCE_TYPE_ALERT: 2
};
/**
 * optional string message = 1;
 * @return {string}
 */
proto.LiveEventAnnounce.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional AnnounceType type = 2;
 * @return {!proto.LiveEventAnnounce.AnnounceType}
 */
proto.LiveEventAnnounce.prototype.getType = function () {
    return /** @type {!proto.LiveEventAnnounce.AnnounceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.LiveEventAnnounce.AnnounceType} value
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * repeated string invisible_user_ids = 3;
 * @return {!Array<string>}
 */
proto.LiveEventAnnounce.prototype.getInvisibleUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.setInvisibleUserIdsList = function (value) {
    return jspb.Message.setField(this, 3, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.addInvisibleUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.clearInvisibleUserIdsList = function () {
    return this.setInvisibleUserIdsList([]);
};
/**
 * repeated string visible_user_ids = 4;
 * @return {!Array<string>}
 */
proto.LiveEventAnnounce.prototype.getVisibleUserIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.setVisibleUserIdsList = function (value) {
    return jspb.Message.setField(this, 4, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.addVisibleUserIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LiveEventAnnounce} returns this
 */
proto.LiveEventAnnounce.prototype.clearVisibleUserIdsList = function () {
    return this.setVisibleUserIdsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventSurgeRankingNo1Reward.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventSurgeRankingNo1Reward.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventSurgeRankingNo1Reward} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventSurgeRankingNo1Reward.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
            listenerMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventSurgeRankingNo1Reward}
 */
proto.LiveEventSurgeRankingNo1Reward.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventSurgeRankingNo1Reward;
    return proto.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventSurgeRankingNo1Reward} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventSurgeRankingNo1Reward}
 */
proto.LiveEventSurgeRankingNo1Reward.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverMessage(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerMessage(value);
                break;
            case 3:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventSurgeRankingNo1Reward} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventSurgeRankingNo1Reward.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverMessage();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getListenerMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(3, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional string liver_message = 1;
 * @return {string}
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.getLiverMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.setLiverMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string listener_message = 2;
 * @return {string}
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.getListenerMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.setListenerMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional ReactionGiftButton reaction_gift_button = 3;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 3));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventSurgeRankingNo1Reward} returns this
*/
proto.LiveEventSurgeRankingNo1Reward.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSurgeRankingNo1Reward} returns this
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSurgeRankingNo1Reward.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 3) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventSimplePFComment.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventSimplePFComment.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventSimplePFComment} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventSimplePFComment.toObject = function (includeInstance, msg) {
        var f, obj = {
            type: jspb.Message.getFieldWithDefault(msg, 1, 0),
            message: jspb.Message.getFieldWithDefault(msg, 2, ""),
            borderWidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            textColor: jspb.Message.getFieldWithDefault(msg, 4, ""),
            backgroundColor: jspb.Message.getFieldWithDefault(msg, 5, ""),
            borderColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
            textAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
            backgroundAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
            borderAlpha: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventSimplePFComment}
 */
proto.LiveEventSimplePFComment.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventSimplePFComment;
    return proto.LiveEventSimplePFComment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventSimplePFComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventSimplePFComment}
 */
proto.LiveEventSimplePFComment.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.LiveEventSimplePFCommentType} */ (reader.readEnum());
                msg.setType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBorderWidth(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setTextColor(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setBackgroundColor(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setBorderColor(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setTextAlpha(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBackgroundAlpha(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readFloat());
                msg.setBorderAlpha(value);
                break;
            case 10:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventSimplePFComment.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventSimplePFComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventSimplePFComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventSimplePFComment.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getMessage();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getBorderWidth();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getTextColor();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getBorderColor();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getTextAlpha();
    if (f !== 0.0) {
        writer.writeFloat(7, f);
    }
    f = message.getBackgroundAlpha();
    if (f !== 0.0) {
        writer.writeFloat(8, f);
    }
    f = message.getBorderAlpha();
    if (f !== 0.0) {
        writer.writeFloat(9, f);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(10, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional LiveEventSimplePFCommentType type = 1;
 * @return {!proto.LiveEventSimplePFCommentType}
 */
proto.LiveEventSimplePFComment.prototype.getType = function () {
    return /** @type {!proto.LiveEventSimplePFCommentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.LiveEventSimplePFCommentType} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string message = 2;
 * @return {string}
 */
proto.LiveEventSimplePFComment.prototype.getMessage = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setMessage = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 border_width = 3;
 * @return {number}
 */
proto.LiveEventSimplePFComment.prototype.getBorderWidth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setBorderWidth = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string text_color = 4;
 * @return {string}
 */
proto.LiveEventSimplePFComment.prototype.getTextColor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setTextColor = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string background_color = 5;
 * @return {string}
 */
proto.LiveEventSimplePFComment.prototype.getBackgroundColor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setBackgroundColor = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string border_color = 6;
 * @return {string}
 */
proto.LiveEventSimplePFComment.prototype.getBorderColor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setBorderColor = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional float text_alpha = 7;
 * @return {number}
 */
proto.LiveEventSimplePFComment.prototype.getTextAlpha = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setTextAlpha = function (value) {
    return jspb.Message.setProto3FloatField(this, 7, value);
};
/**
 * optional float background_alpha = 8;
 * @return {number}
 */
proto.LiveEventSimplePFComment.prototype.getBackgroundAlpha = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setBackgroundAlpha = function (value) {
    return jspb.Message.setProto3FloatField(this, 8, value);
};
/**
 * optional float border_alpha = 9;
 * @return {number}
 */
proto.LiveEventSimplePFComment.prototype.getBorderAlpha = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.setBorderAlpha = function (value) {
    return jspb.Message.setProto3FloatField(this, 9, value);
};
/**
 * optional ReactionGiftButton reaction_gift_button = 10;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventSimplePFComment.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 10));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventSimplePFComment} returns this
*/
proto.LiveEventSimplePFComment.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventSimplePFComment} returns this
 */
proto.LiveEventSimplePFComment.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventSimplePFComment.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 10) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetFaveBadgeAndSongFave.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetFaveBadgeAndSongFave.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetFaveBadgeAndSongFave} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetFaveBadgeAndSongFave.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            getfavebadgeevent: (f = msg.getGetfavebadgeevent()) && proto.LiveEventGetFaveBadge.toObject(includeInstance, f),
            getsongfaveevent: (f = msg.getGetsongfaveevent()) && proto.LiveEventGetSongFave.toObject(includeInstance, f),
            heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.HeartfulMentionButton.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave}
 */
proto.LiveEventGetFaveBadgeAndSongFave.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetFaveBadgeAndSongFave;
    return proto.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetFaveBadgeAndSongFave} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave}
 */
proto.LiveEventGetFaveBadgeAndSongFave.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new proto.LiveEventGetFaveBadge;
                reader.readMessage(value, proto.LiveEventGetFaveBadge.deserializeBinaryFromReader);
                msg.setGetfavebadgeevent(value);
                break;
            case 3:
                var value = new proto.LiveEventGetSongFave;
                reader.readMessage(value, proto.LiveEventGetSongFave.deserializeBinaryFromReader);
                msg.setGetsongfaveevent(value);
                break;
            case 4:
                var value = new proto.HeartfulMentionButton;
                reader.readMessage(value, proto.HeartfulMentionButton.deserializeBinaryFromReader);
                msg.setHeartfulMentionButton(value);
                break;
            case 5:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetFaveBadgeAndSongFave} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetFaveBadgeAndSongFave.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getGetfavebadgeevent();
    if (f != null) {
        writer.writeMessage(2, f, proto.LiveEventGetFaveBadge.serializeBinaryToWriter);
    }
    f = message.getGetsongfaveevent();
    if (f != null) {
        writer.writeMessage(3, f, proto.LiveEventGetSongFave.serializeBinaryToWriter);
    }
    f = message.getHeartfulMentionButton();
    if (f != null) {
        writer.writeMessage(4, f, proto.HeartfulMentionButton.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.LiveEventGetFaveBadgeAndSongFave.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional LiveEventGetFaveBadge getFaveBadgeEvent = 2;
 * @return {?proto.LiveEventGetFaveBadge}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.getGetfavebadgeevent = function () {
    return /** @type{?proto.LiveEventGetFaveBadge} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetFaveBadge, 2));
};
/**
 * @param {?proto.LiveEventGetFaveBadge|undefined} value
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.LiveEventGetFaveBadgeAndSongFave.prototype.setGetfavebadgeevent = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.clearGetfavebadgeevent = function () {
    return this.setGetfavebadgeevent(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.hasGetfavebadgeevent = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional LiveEventGetSongFave getSongFaveEvent = 3;
 * @return {?proto.LiveEventGetSongFave}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.getGetsongfaveevent = function () {
    return /** @type{?proto.LiveEventGetSongFave} */ (jspb.Message.getWrapperField(this, proto.LiveEventGetSongFave, 3));
};
/**
 * @param {?proto.LiveEventGetSongFave|undefined} value
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.LiveEventGetFaveBadgeAndSongFave.prototype.setGetsongfaveevent = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.clearGetsongfaveevent = function () {
    return this.setGetsongfaveevent(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.hasGetsongfaveevent = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional HeartfulMentionButton heartful_mention_button = 4;
 * @return {?proto.HeartfulMentionButton}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.getHeartfulMentionButton = function () {
    return /** @type{?proto.HeartfulMentionButton} */ (jspb.Message.getWrapperField(this, proto.HeartfulMentionButton, 4));
};
/**
 * @param {?proto.HeartfulMentionButton|undefined} value
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.LiveEventGetFaveBadgeAndSongFave.prototype.setHeartfulMentionButton = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.clearHeartfulMentionButton = function () {
    return this.setHeartfulMentionButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.hasHeartfulMentionButton = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 5;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 5));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
*/
proto.LiveEventGetFaveBadgeAndSongFave.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetFaveBadgeAndSongFave} returns this
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetFaveBadgeAndSongFave.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Gift.repeatedFields_ = [16, 17];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Gift.prototype.toObject = function (opt_includeInstance) {
        return proto.Gift.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Gift} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Gift.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            giftKind: jspb.Message.getFieldWithDefault(msg, 18, 0),
            giftType: jspb.Message.getFieldWithDefault(msg, 2, 0),
            displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
            kana: jspb.Message.getFieldWithDefault(msg, 15, ""),
            suggestionWordsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
            suggestionSubWordsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
            coinAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
            coin: (f = msg.getCoin()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            coinAmountBigGift: (f = msg.getCoinAmountBigGift()) && proto.Gift.BigGiftCoinAmount.toObject(includeInstance, f),
            chainable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            assetPath: jspb.Message.getFieldWithDefault(msg, 6, ""),
            thumbnailPath: jspb.Message.getFieldWithDefault(msg, 7, ""),
            assetHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
            thumbnailHash: jspb.Message.getFieldWithDefault(msg, 11, ""),
            downloadPriority: jspb.Message.getFieldWithDefault(msg, 12, 0),
            appBundle: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
            createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Gift}
 */
proto.Gift.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Gift;
    return proto.Gift.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Gift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Gift}
 */
proto.Gift.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setGiftId(value);
                break;
            case 18:
                var value = /** @type {!proto.GiftKind} */ (reader.readEnum());
                msg.setGiftKind(value);
                break;
            case 2:
                var value = /** @type {!proto.GiftType} */ (reader.readEnum());
                msg.setGiftType(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setDisplayName(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());
                msg.setKana(value);
                break;
            case 16:
                var value = /** @type {string} */ (reader.readString());
                msg.addSuggestionWords(value);
                break;
            case 17:
                var value = /** @type {string} */ (reader.readString());
                msg.addSuggestionSubWords(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmount(value);
                break;
            case 19:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setCoin(value);
                break;
            case 14:
                var value = new proto.Gift.BigGiftCoinAmount;
                reader.readMessage(value, proto.Gift.BigGiftCoinAmount.deserializeBinaryFromReader);
                msg.setCoinAmountBigGift(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setChainable(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setAssetPath(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailPath(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setAssetHash(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());
                msg.setThumbnailHash(value);
                break;
            case 12:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDownloadPriority(value);
                break;
            case 13:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setAppBundle(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Gift.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Gift.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Gift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Gift.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getGiftKind();
    if (f !== 0.0) {
        writer.writeEnum(18, f);
    }
    f = message.getGiftType();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getDisplayName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getKana();
    if (f.length > 0) {
        writer.writeString(15, f);
    }
    f = message.getSuggestionWordsList();
    if (f.length > 0) {
        writer.writeRepeatedString(16, f);
    }
    f = message.getSuggestionSubWordsList();
    if (f.length > 0) {
        writer.writeRepeatedString(17, f);
    }
    f = message.getCoinAmount();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getCoin();
    if (f != null) {
        writer.writeMessage(19, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getCoinAmountBigGift();
    if (f != null) {
        writer.writeMessage(14, f, proto.Gift.BigGiftCoinAmount.serializeBinaryToWriter);
    }
    f = message.getChainable();
    if (f) {
        writer.writeBool(5, f);
    }
    f = message.getAssetPath();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getThumbnailPath();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getAssetHash();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
    f = message.getThumbnailHash();
    if (f.length > 0) {
        writer.writeString(11, f);
    }
    f = message.getDownloadPriority();
    if (f !== 0) {
        writer.writeInt64(12, f);
    }
    f = message.getAppBundle();
    if (f) {
        writer.writeBool(13, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.Gift.BigGiftCoinAmount.prototype.toObject = function (opt_includeInstance) {
        return proto.Gift.BigGiftCoinAmount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.Gift.BigGiftCoinAmount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.Gift.BigGiftCoinAmount.toObject = function (includeInstance, msg) {
        var f, obj = {
            coinAmountBig: jspb.Message.getFieldWithDefault(msg, 1, 0),
            coinBig: (f = msg.getCoinBig()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f),
            coinAmountBigHeart: jspb.Message.getFieldWithDefault(msg, 2, 0),
            coinBigHeart: (f = msg.getCoinBigHeart()) && coins_entity_pb.CoinAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Gift.BigGiftCoinAmount}
 */
proto.Gift.BigGiftCoinAmount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.Gift.BigGiftCoinAmount;
    return proto.Gift.BigGiftCoinAmount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Gift.BigGiftCoinAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Gift.BigGiftCoinAmount}
 */
proto.Gift.BigGiftCoinAmount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmountBig(value);
                break;
            case 3:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setCoinBig(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmountBigHeart(value);
                break;
            case 4:
                var value = new coins_entity_pb.CoinAmount;
                reader.readMessage(value, coins_entity_pb.CoinAmount.deserializeBinaryFromReader);
                msg.setCoinBigHeart(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Gift.BigGiftCoinAmount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.Gift.BigGiftCoinAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Gift.BigGiftCoinAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Gift.BigGiftCoinAmount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCoinAmountBig();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getCoinBig();
    if (f != null) {
        writer.writeMessage(3, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getCoinAmountBigHeart();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getCoinBigHeart();
    if (f != null) {
        writer.writeMessage(4, f, coins_entity_pb.CoinAmount.serializeBinaryToWriter);
    }
};
/**
 * optional int64 coin_amount_big = 1;
 * @return {number}
 */
proto.Gift.BigGiftCoinAmount.prototype.getCoinAmountBig = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
 */
proto.Gift.BigGiftCoinAmount.prototype.setCoinAmountBig = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional CoinAmount coin_big = 3;
 * @return {?proto.CoinAmount}
 */
proto.Gift.BigGiftCoinAmount.prototype.getCoinBig = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 3));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
*/
proto.Gift.BigGiftCoinAmount.prototype.setCoinBig = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
 */
proto.Gift.BigGiftCoinAmount.prototype.clearCoinBig = function () {
    return this.setCoinBig(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Gift.BigGiftCoinAmount.prototype.hasCoinBig = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional int64 coin_amount_big_heart = 2;
 * @return {number}
 */
proto.Gift.BigGiftCoinAmount.prototype.getCoinAmountBigHeart = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
 */
proto.Gift.BigGiftCoinAmount.prototype.setCoinAmountBigHeart = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional CoinAmount coin_big_heart = 4;
 * @return {?proto.CoinAmount}
 */
proto.Gift.BigGiftCoinAmount.prototype.getCoinBigHeart = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 4));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
*/
proto.Gift.BigGiftCoinAmount.prototype.setCoinBigHeart = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Gift.BigGiftCoinAmount} returns this
 */
proto.Gift.BigGiftCoinAmount.prototype.clearCoinBigHeart = function () {
    return this.setCoinBigHeart(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Gift.BigGiftCoinAmount.prototype.hasCoinBigHeart = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional string gift_id = 1;
 * @return {string}
 */
proto.Gift.prototype.getGiftId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setGiftId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional GiftKind gift_kind = 18;
 * @return {!proto.GiftKind}
 */
proto.Gift.prototype.getGiftKind = function () {
    return /** @type {!proto.GiftKind} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};
/**
 * @param {!proto.GiftKind} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setGiftKind = function (value) {
    return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * optional GiftType gift_type = 2;
 * @return {!proto.GiftType}
 */
proto.Gift.prototype.getGiftType = function () {
    return /** @type {!proto.GiftType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.GiftType} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setGiftType = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.Gift.prototype.getDisplayName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setDisplayName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string kana = 15;
 * @return {string}
 */
proto.Gift.prototype.getKana = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setKana = function (value) {
    return jspb.Message.setProto3StringField(this, 15, value);
};
/**
 * repeated string suggestion_words = 16;
 * @return {!Array<string>}
 */
proto.Gift.prototype.getSuggestionWordsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setSuggestionWordsList = function (value) {
    return jspb.Message.setField(this, 16, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.addSuggestionWords = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.clearSuggestionWordsList = function () {
    return this.setSuggestionWordsList([]);
};
/**
 * repeated string suggestion_sub_words = 17;
 * @return {!Array<string>}
 */
proto.Gift.prototype.getSuggestionSubWordsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setSuggestionSubWordsList = function (value) {
    return jspb.Message.setField(this, 17, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.addSuggestionSubWords = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.clearSuggestionSubWordsList = function () {
    return this.setSuggestionSubWordsList([]);
};
/**
 * optional int64 coin_amount = 4;
 * @return {number}
 */
proto.Gift.prototype.getCoinAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setCoinAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional CoinAmount coin = 19;
 * @return {?proto.CoinAmount}
 */
proto.Gift.prototype.getCoin = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, coins_entity_pb.CoinAmount, 19));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.Gift} returns this
*/
proto.Gift.prototype.setCoin = function (value) {
    return jspb.Message.setWrapperField(this, 19, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.clearCoin = function () {
    return this.setCoin(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Gift.prototype.hasCoin = function () {
    return jspb.Message.getField(this, 19) != null;
};
/**
 * optional BigGiftCoinAmount coin_amount_big_gift = 14;
 * @return {?proto.Gift.BigGiftCoinAmount}
 */
proto.Gift.prototype.getCoinAmountBigGift = function () {
    return /** @type{?proto.Gift.BigGiftCoinAmount} */ (jspb.Message.getWrapperField(this, proto.Gift.BigGiftCoinAmount, 14));
};
/**
 * @param {?proto.Gift.BigGiftCoinAmount|undefined} value
 * @return {!proto.Gift} returns this
*/
proto.Gift.prototype.setCoinAmountBigGift = function (value) {
    return jspb.Message.setWrapperField(this, 14, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.clearCoinAmountBigGift = function () {
    return this.setCoinAmountBigGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Gift.prototype.hasCoinAmountBigGift = function () {
    return jspb.Message.getField(this, 14) != null;
};
/**
 * optional bool chainable = 5;
 * @return {boolean}
 */
proto.Gift.prototype.getChainable = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setChainable = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * optional string asset_path = 6;
 * @return {string}
 */
proto.Gift.prototype.getAssetPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setAssetPath = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string thumbnail_path = 7;
 * @return {string}
 */
proto.Gift.prototype.getThumbnailPath = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setThumbnailPath = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string asset_hash = 10;
 * @return {string}
 */
proto.Gift.prototype.getAssetHash = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setAssetHash = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional string thumbnail_hash = 11;
 * @return {string}
 */
proto.Gift.prototype.getThumbnailHash = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};
/**
 * @param {string} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setThumbnailHash = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};
/**
 * optional int64 download_priority = 12;
 * @return {number}
 */
proto.Gift.prototype.getDownloadPriority = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setDownloadPriority = function (value) {
    return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional bool app_bundle = 13;
 * @return {boolean}
 */
proto.Gift.prototype.getAppBundle = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};
/**
 * @param {boolean} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setAppBundle = function (value) {
    return jspb.Message.setProto3BooleanField(this, 13, value);
};
/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.Gift.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.Gift.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.Gift} returns this
 */
proto.Gift.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GiftTab.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.GiftTab.prototype.toObject = function (opt_includeInstance) {
        return proto.GiftTab.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.GiftTab} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.GiftTab.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftTabType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isdefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            normalorderindex: jspb.Message.getFieldWithDefault(msg, 5, 0),
            singingorderindex: jspb.Message.getFieldWithDefault(msg, 6, 0),
            sectionsList: jspb.Message.toObjectList(msg.getSectionsList(), proto.GiftTabSection.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GiftTab}
 */
proto.GiftTab.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.GiftTab;
    return proto.GiftTab.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GiftTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GiftTab}
 */
proto.GiftTab.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.GiftTabType} */ (reader.readEnum());
                msg.setGiftTabType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsdefault(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNormalorderindex(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSingingorderindex(value);
                break;
            case 3:
                var value = new proto.GiftTabSection;
                reader.readMessage(value, proto.GiftTabSection.deserializeBinaryFromReader);
                msg.addSections(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GiftTab.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.GiftTab.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GiftTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GiftTab.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftTabType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsdefault();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getNormalorderindex();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getSingingorderindex();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getSectionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.GiftTabSection.serializeBinaryToWriter);
    }
};
/**
 * optional GiftTabType gift_tab_type = 1;
 * @return {!proto.GiftTabType}
 */
proto.GiftTab.prototype.getGiftTabType = function () {
    return /** @type {!proto.GiftTabType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.GiftTabType} value
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.setGiftTabType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.GiftTab.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool isDefault = 4;
 * @return {boolean}
 */
proto.GiftTab.prototype.getIsdefault = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.setIsdefault = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional int64 normalOrderIndex = 5;
 * @return {number}
 */
proto.GiftTab.prototype.getNormalorderindex = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.setNormalorderindex = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 singingOrderIndex = 6;
 * @return {number}
 */
proto.GiftTab.prototype.getSingingorderindex = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.setSingingorderindex = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * repeated GiftTabSection sections = 3;
 * @return {!Array<!proto.GiftTabSection>}
 */
proto.GiftTab.prototype.getSectionsList = function () {
    return /** @type{!Array<!proto.GiftTabSection>} */ (jspb.Message.getRepeatedWrapperField(this, proto.GiftTabSection, 3));
};
/**
 * @param {!Array<!proto.GiftTabSection>} value
 * @return {!proto.GiftTab} returns this
*/
proto.GiftTab.prototype.setSectionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.GiftTabSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GiftTabSection}
 */
proto.GiftTab.prototype.addSections = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.GiftTabSection, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GiftTab} returns this
 */
proto.GiftTab.prototype.clearSectionsList = function () {
    return this.setSectionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GiftTabSection.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.GiftTabSection.prototype.toObject = function (opt_includeInstance) {
        return proto.GiftTabSection.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.GiftTabSection} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.GiftTabSection.toObject = function (includeInstance, msg) {
        var f, obj = {
            giftTabSectionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            giftsList: jspb.Message.toObjectList(msg.getGiftsList(), proto.Gift.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GiftTabSection}
 */
proto.GiftTabSection.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.GiftTabSection;
    return proto.GiftTabSection.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GiftTabSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GiftTabSection}
 */
proto.GiftTabSection.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.GiftTabSectionType} */ (reader.readEnum());
                msg.setGiftTabSectionType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = new proto.Gift;
                reader.readMessage(value, proto.Gift.deserializeBinaryFromReader);
                msg.addGifts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GiftTabSection.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.GiftTabSection.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GiftTabSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GiftTabSection.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGiftTabSectionType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getGiftsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.Gift.serializeBinaryToWriter);
    }
};
/**
 * optional GiftTabSectionType gift_tab_section_type = 1;
 * @return {!proto.GiftTabSectionType}
 */
proto.GiftTabSection.prototype.getGiftTabSectionType = function () {
    return /** @type {!proto.GiftTabSectionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.GiftTabSectionType} value
 * @return {!proto.GiftTabSection} returns this
 */
proto.GiftTabSection.prototype.setGiftTabSectionType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.GiftTabSection.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.GiftTabSection} returns this
 */
proto.GiftTabSection.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated Gift gifts = 3;
 * @return {!Array<!proto.Gift>}
 */
proto.GiftTabSection.prototype.getGiftsList = function () {
    return /** @type{!Array<!proto.Gift>} */ (jspb.Message.getRepeatedWrapperField(this, proto.Gift, 3));
};
/**
 * @param {!Array<!proto.Gift>} value
 * @return {!proto.GiftTabSection} returns this
*/
proto.GiftTabSection.prototype.setGiftsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.Gift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Gift}
 */
proto.GiftTabSection.prototype.addGifts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Gift, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GiftTabSection} returns this
 */
proto.GiftTabSection.prototype.clearGiftsList = function () {
    return this.setGiftsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.EmojiComment.prototype.toObject = function (opt_includeInstance) {
        return proto.EmojiComment.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.EmojiComment} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.EmojiComment.toObject = function (includeInstance, msg) {
        var f, obj = {
            emojiCommentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
            coinAmount: jspb.Message.getFieldWithDefault(msg, 3, 0),
            createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EmojiComment}
 */
proto.EmojiComment.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.EmojiComment;
    return proto.EmojiComment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EmojiComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EmojiComment}
 */
proto.EmojiComment.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmojiCommentId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setIcon(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCoinAmount(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EmojiComment.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.EmojiComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EmojiComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EmojiComment.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmojiCommentId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIcon();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCoinAmount();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional string emoji_comment_id = 1;
 * @return {string}
 */
proto.EmojiComment.prototype.getEmojiCommentId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiComment} returns this
 */
proto.EmojiComment.prototype.setEmojiCommentId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string icon = 2;
 * @return {string}
 */
proto.EmojiComment.prototype.getIcon = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiComment} returns this
 */
proto.EmojiComment.prototype.setIcon = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 coin_amount = 3;
 * @return {number}
 */
proto.EmojiComment.prototype.getCoinAmount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.EmojiComment} returns this
 */
proto.EmojiComment.prototype.setCoinAmount = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.EmojiComment.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.EmojiComment} returns this
 */
proto.EmojiComment.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.EmojiComment.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.EmojiComment} returns this
 */
proto.EmojiComment.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EmojiCommentTab.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.EmojiCommentTab.prototype.toObject = function (opt_includeInstance) {
        return proto.EmojiCommentTab.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.EmojiCommentTab} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.EmojiCommentTab.toObject = function (includeInstance, msg) {
        var f, obj = {
            emojiCommentTabId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
            sectionsList: jspb.Message.toObjectList(msg.getSectionsList(), proto.EmojiCommentTabSection.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EmojiCommentTab}
 */
proto.EmojiCommentTab.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.EmojiCommentTab;
    return proto.EmojiCommentTab.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EmojiCommentTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EmojiCommentTab}
 */
proto.EmojiCommentTab.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmojiCommentTabId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setIcon(value);
                break;
            case 3:
                var value = new proto.EmojiCommentTabSection;
                reader.readMessage(value, proto.EmojiCommentTabSection.deserializeBinaryFromReader);
                msg.addSections(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EmojiCommentTab.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.EmojiCommentTab.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EmojiCommentTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EmojiCommentTab.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmojiCommentTabId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getIcon();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getSectionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.EmojiCommentTabSection.serializeBinaryToWriter);
    }
};
/**
 * optional string emoji_comment_tab_id = 1;
 * @return {string}
 */
proto.EmojiCommentTab.prototype.getEmojiCommentTabId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiCommentTab} returns this
 */
proto.EmojiCommentTab.prototype.setEmojiCommentTabId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string icon = 2;
 * @return {string}
 */
proto.EmojiCommentTab.prototype.getIcon = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiCommentTab} returns this
 */
proto.EmojiCommentTab.prototype.setIcon = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated EmojiCommentTabSection sections = 3;
 * @return {!Array<!proto.EmojiCommentTabSection>}
 */
proto.EmojiCommentTab.prototype.getSectionsList = function () {
    return /** @type{!Array<!proto.EmojiCommentTabSection>} */ (jspb.Message.getRepeatedWrapperField(this, proto.EmojiCommentTabSection, 3));
};
/**
 * @param {!Array<!proto.EmojiCommentTabSection>} value
 * @return {!proto.EmojiCommentTab} returns this
*/
proto.EmojiCommentTab.prototype.setSectionsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.EmojiCommentTabSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EmojiCommentTabSection}
 */
proto.EmojiCommentTab.prototype.addSections = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.EmojiCommentTabSection, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EmojiCommentTab} returns this
 */
proto.EmojiCommentTab.prototype.clearSectionsList = function () {
    return this.setSectionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EmojiCommentTabSection.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.EmojiCommentTabSection.prototype.toObject = function (opt_includeInstance) {
        return proto.EmojiCommentTabSection.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.EmojiCommentTabSection} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.EmojiCommentTabSection.toObject = function (includeInstance, msg) {
        var f, obj = {
            index: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            emojiCommentsList: jspb.Message.toObjectList(msg.getEmojiCommentsList(), proto.EmojiComment.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EmojiCommentTabSection}
 */
proto.EmojiCommentTabSection.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.EmojiCommentTabSection;
    return proto.EmojiCommentTabSection.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EmojiCommentTabSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EmojiCommentTabSection}
 */
proto.EmojiCommentTabSection.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setIndex(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = new proto.EmojiComment;
                reader.readMessage(value, proto.EmojiComment.deserializeBinaryFromReader);
                msg.addEmojiComments(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EmojiCommentTabSection.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.EmojiCommentTabSection.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EmojiCommentTabSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EmojiCommentTabSection.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIndex();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getEmojiCommentsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.EmojiComment.serializeBinaryToWriter);
    }
};
/**
 * optional string index = 1;
 * @return {string}
 */
proto.EmojiCommentTabSection.prototype.getIndex = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiCommentTabSection} returns this
 */
proto.EmojiCommentTabSection.prototype.setIndex = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.EmojiCommentTabSection.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.EmojiCommentTabSection} returns this
 */
proto.EmojiCommentTabSection.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated EmojiComment emoji_comments = 3;
 * @return {!Array<!proto.EmojiComment>}
 */
proto.EmojiCommentTabSection.prototype.getEmojiCommentsList = function () {
    return /** @type{!Array<!proto.EmojiComment>} */ (jspb.Message.getRepeatedWrapperField(this, proto.EmojiComment, 3));
};
/**
 * @param {!Array<!proto.EmojiComment>} value
 * @return {!proto.EmojiCommentTabSection} returns this
*/
proto.EmojiCommentTabSection.prototype.setEmojiCommentsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.EmojiComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EmojiComment}
 */
proto.EmojiCommentTabSection.prototype.addEmojiComments = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.EmojiComment, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EmojiCommentTabSection} returns this
 */
proto.EmojiCommentTabSection.prototype.clearEmojiCommentsList = function () {
    return this.setEmojiCommentsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ListFanInfoFaveBadgeTab.prototype.toObject = function (opt_includeInstance) {
        return proto.ListFanInfoFaveBadgeTab.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ListFanInfoFaveBadgeTab} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ListFanInfoFaveBadgeTab.toObject = function (includeInstance, msg) {
        var f, obj = {
            year: jspb.Message.getFieldWithDefault(msg, 1, 0),
            month: jspb.Message.getFieldWithDefault(msg, 2, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            label: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFanInfoFaveBadgeTab}
 */
proto.ListFanInfoFaveBadgeTab.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ListFanInfoFaveBadgeTab;
    return proto.ListFanInfoFaveBadgeTab.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFanInfoFaveBadgeTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFanInfoFaveBadgeTab}
 */
proto.ListFanInfoFaveBadgeTab.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 3:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setLabel(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListFanInfoFaveBadgeTab.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ListFanInfoFaveBadgeTab.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFanInfoFaveBadgeTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListFanInfoFaveBadgeTab.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getLabel();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.ListFanInfoFaveBadgeTab.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoFaveBadgeTab} returns this
 */
proto.ListFanInfoFaveBadgeTab.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.ListFanInfoFaveBadgeTab.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoFaveBadgeTab} returns this
 */
proto.ListFanInfoFaveBadgeTab.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional TermOfMonth term_of_month = 3;
 * @return {!proto.TermOfMonth}
 */
proto.ListFanInfoFaveBadgeTab.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.ListFanInfoFaveBadgeTab} returns this
 */
proto.ListFanInfoFaveBadgeTab.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string label = 4;
 * @return {string}
 */
proto.ListFanInfoFaveBadgeTab.prototype.getLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.ListFanInfoFaveBadgeTab} returns this
 */
proto.ListFanInfoFaveBadgeTab.prototype.setLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ListFanInfoFaveBadgeUser.prototype.toObject = function (opt_includeInstance) {
        return proto.ListFanInfoFaveBadgeUser.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ListFanInfoFaveBadgeUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ListFanInfoFaveBadgeUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            fanType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFanInfoFaveBadgeUser}
 */
proto.ListFanInfoFaveBadgeUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ListFanInfoFaveBadgeUser;
    return proto.ListFanInfoFaveBadgeUser.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFanInfoFaveBadgeUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFanInfoFaveBadgeUser}
 */
proto.ListFanInfoFaveBadgeUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListFanInfoFaveBadgeUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ListFanInfoFaveBadgeUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFanInfoFaveBadgeUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListFanInfoFaveBadgeUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.ListFanInfoFaveBadgeUser.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
*/
proto.ListFanInfoFaveBadgeUser.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
 */
proto.ListFanInfoFaveBadgeUser.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListFanInfoFaveBadgeUser.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.ListFanInfoFaveBadgeUser.prototype.getUserFollow = function () {
    return /** @type{?proto.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
*/
proto.ListFanInfoFaveBadgeUser.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
 */
proto.ListFanInfoFaveBadgeUser.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListFanInfoFaveBadgeUser.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional FanType fan_type = 3;
 * @return {!proto.FanType}
 */
proto.ListFanInfoFaveBadgeUser.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
 */
proto.ListFanInfoFaveBadgeUser.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.ListFanInfoFaveBadgeUser.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
 */
proto.ListFanInfoFaveBadgeUser.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.ListFanInfoFaveBadgeUser.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoFaveBadgeUser} returns this
 */
proto.ListFanInfoFaveBadgeUser.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ListFanInfoSongFaveTab.prototype.toObject = function (opt_includeInstance) {
        return proto.ListFanInfoSongFaveTab.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ListFanInfoSongFaveTab} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ListFanInfoSongFaveTab.toObject = function (includeInstance, msg) {
        var f, obj = {
            year: jspb.Message.getFieldWithDefault(msg, 1, 0),
            month: jspb.Message.getFieldWithDefault(msg, 2, 0),
            label: jspb.Message.getFieldWithDefault(msg, 3, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFanInfoSongFaveTab}
 */
proto.ListFanInfoSongFaveTab.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ListFanInfoSongFaveTab;
    return proto.ListFanInfoSongFaveTab.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFanInfoSongFaveTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFanInfoSongFaveTab}
 */
proto.ListFanInfoSongFaveTab.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setYear(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMonth(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLabel(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListFanInfoSongFaveTab.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ListFanInfoSongFaveTab.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFanInfoSongFaveTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListFanInfoSongFaveTab.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getYear();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
    f = message.getLabel();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};
/**
 * optional int32 year = 1;
 * @return {number}
 */
proto.ListFanInfoSongFaveTab.prototype.getYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoSongFaveTab} returns this
 */
proto.ListFanInfoSongFaveTab.prototype.setYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.ListFanInfoSongFaveTab.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoSongFaveTab} returns this
 */
proto.ListFanInfoSongFaveTab.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string label = 3;
 * @return {string}
 */
proto.ListFanInfoSongFaveTab.prototype.getLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.ListFanInfoSongFaveTab} returns this
 */
proto.ListFanInfoSongFaveTab.prototype.setLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ListFanInfoSongFaveUser.prototype.toObject = function (opt_includeInstance) {
        return proto.ListFanInfoSongFaveUser.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ListFanInfoSongFaveUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ListFanInfoSongFaveUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            fanType: jspb.Message.getFieldWithDefault(msg, 3, 0),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFanInfoSongFaveUser}
 */
proto.ListFanInfoSongFaveUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ListFanInfoSongFaveUser;
    return proto.ListFanInfoSongFaveUser.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFanInfoSongFaveUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFanInfoSongFaveUser}
 */
proto.ListFanInfoSongFaveUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListFanInfoSongFaveUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ListFanInfoSongFaveUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFanInfoSongFaveUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListFanInfoSongFaveUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.ListFanInfoSongFaveUser.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.ListFanInfoSongFaveUser} returns this
*/
proto.ListFanInfoSongFaveUser.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFanInfoSongFaveUser} returns this
 */
proto.ListFanInfoSongFaveUser.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListFanInfoSongFaveUser.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.ListFanInfoSongFaveUser.prototype.getUserFollow = function () {
    return /** @type{?proto.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.ListFanInfoSongFaveUser} returns this
*/
proto.ListFanInfoSongFaveUser.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFanInfoSongFaveUser} returns this
 */
proto.ListFanInfoSongFaveUser.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListFanInfoSongFaveUser.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional FanType fan_type = 3;
 * @return {!proto.FanType}
 */
proto.ListFanInfoSongFaveUser.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.ListFanInfoSongFaveUser} returns this
 */
proto.ListFanInfoSongFaveUser.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 termly_fave_points = 4;
 * @return {number}
 */
proto.ListFanInfoSongFaveUser.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoSongFaveUser} returns this
 */
proto.ListFanInfoSongFaveUser.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 monthly_fave_points = 5;
 * @return {number}
 */
proto.ListFanInfoSongFaveUser.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.ListFanInfoSongFaveUser} returns this
 */
proto.ListFanInfoSongFaveUser.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.PfFavePointsRankingTerm.prototype.toObject = function (opt_includeInstance) {
        return proto.PfFavePointsRankingTerm.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.PfFavePointsRankingTerm} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.PfFavePointsRankingTerm.toObject = function (includeInstance, msg) {
        var f, obj = {
            month: jspb.Message.getFieldWithDefault(msg, 1, 0),
            termOfMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
            label: jspb.Message.getFieldWithDefault(msg, 3, ""),
            day: jspb.Message.getFieldWithDefault(msg, 4, 0),
            periodTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PfFavePointsRankingTerm}
 */
proto.PfFavePointsRankingTerm.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.PfFavePointsRankingTerm;
    return proto.PfFavePointsRankingTerm.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PfFavePointsRankingTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PfFavePointsRankingTerm}
 */
proto.PfFavePointsRankingTerm.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 2:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setTermOfMonth(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLabel(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDay(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPeriodTimestamp(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PfFavePointsRankingTerm.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.PfFavePointsRankingTerm.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PfFavePointsRankingTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PfFavePointsRankingTerm.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
    f = message.getLabel();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getDay();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getPeriodTimestamp();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
};
/**
 * optional int64 month = 1;
 * @return {number}
 */
proto.PfFavePointsRankingTerm.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.PfFavePointsRankingTerm} returns this
 */
proto.PfFavePointsRankingTerm.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional TermOfMonth term_of_month = 2;
 * @return {!proto.TermOfMonth}
 */
proto.PfFavePointsRankingTerm.prototype.getTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.PfFavePointsRankingTerm} returns this
 */
proto.PfFavePointsRankingTerm.prototype.setTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string label = 3;
 * @return {string}
 */
proto.PfFavePointsRankingTerm.prototype.getLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.PfFavePointsRankingTerm} returns this
 */
proto.PfFavePointsRankingTerm.prototype.setLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 day = 4;
 * @return {number}
 */
proto.PfFavePointsRankingTerm.prototype.getDay = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.PfFavePointsRankingTerm} returns this
 */
proto.PfFavePointsRankingTerm.prototype.setDay = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 period_timestamp = 5;
 * @return {number}
 */
proto.PfFavePointsRankingTerm.prototype.getPeriodTimestamp = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.PfFavePointsRankingTerm} returns this
 */
proto.PfFavePointsRankingTerm.prototype.setPeriodTimestamp = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.PfFavePointsRankingUser.prototype.toObject = function (opt_includeInstance) {
        return proto.PfFavePointsRankingUser.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.PfFavePointsRankingUser} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.PfFavePointsRankingUser.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            rankingOrder: jspb.Message.getFieldWithDefault(msg, 3, 0),
            pfFavePoints: jspb.Message.getFieldWithDefault(msg, 4, 0),
            hidden: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PfFavePointsRankingUser}
 */
proto.PfFavePointsRankingUser.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.PfFavePointsRankingUser;
    return proto.PfFavePointsRankingUser.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PfFavePointsRankingUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PfFavePointsRankingUser}
 */
proto.PfFavePointsRankingUser.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRankingOrder(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPfFavePoints(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHidden(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PfFavePointsRankingUser.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.PfFavePointsRankingUser.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PfFavePointsRankingUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PfFavePointsRankingUser.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getRankingOrder();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getPfFavePoints();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getHidden();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.PfFavePointsRankingUser.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.PfFavePointsRankingUser} returns this
*/
proto.PfFavePointsRankingUser.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.PfFavePointsRankingUser} returns this
 */
proto.PfFavePointsRankingUser.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PfFavePointsRankingUser.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.PfFavePointsRankingUser.prototype.getUserFollow = function () {
    return /** @type{?proto.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.PfFavePointsRankingUser} returns this
*/
proto.PfFavePointsRankingUser.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.PfFavePointsRankingUser} returns this
 */
proto.PfFavePointsRankingUser.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PfFavePointsRankingUser.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 ranking_order = 3;
 * @return {number}
 */
proto.PfFavePointsRankingUser.prototype.getRankingOrder = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.PfFavePointsRankingUser} returns this
 */
proto.PfFavePointsRankingUser.prototype.setRankingOrder = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 pf_fave_points = 4;
 * @return {number}
 */
proto.PfFavePointsRankingUser.prototype.getPfFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.PfFavePointsRankingUser} returns this
 */
proto.PfFavePointsRankingUser.prototype.setPfFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional bool hidden = 5;
 * @return {boolean}
 */
proto.PfFavePointsRankingUser.prototype.getHidden = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.PfFavePointsRankingUser} returns this
 */
proto.PfFavePointsRankingUser.prototype.setHidden = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveResultFavePoint.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveResultFavePoint.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveResultFavePoint} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveResultFavePoint.toObject = function (includeInstance, msg) {
        var f, obj = {
            month: jspb.Message.getFieldWithDefault(msg, 1, 0),
            monthlyReceivedFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            monthlyReceivedFavePointsDiff: jspb.Message.getFieldWithDefault(msg, 3, 0),
            monthlyReceivedFavePointsNext: jspb.Message.getFieldWithDefault(msg, 4, 0),
            numberOfMonthlySongFaves: jspb.Message.getFieldWithDefault(msg, 5, 0),
            numberOfMonthlySongFavesDiff: jspb.Message.getFieldWithDefault(msg, 6, 0),
            numberOfMonthlySongFavesNext: jspb.Message.getFieldWithDefault(msg, 7, 0),
            faveBonusRate: jspb.Message.getFieldWithDefault(msg, 8, ""),
            faveBonusRateDiff: jspb.Message.getFieldWithDefault(msg, 9, ""),
            faveBonusTitleRate: jspb.Message.getFieldWithDefault(msg, 10, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveResultFavePoint}
 */
proto.LiveResultFavePoint.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveResultFavePoint;
    return proto.LiveResultFavePoint.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveResultFavePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveResultFavePoint}
 */
proto.LiveResultFavePoint.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonth(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyReceivedFavePoints(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyReceivedFavePointsDiff(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyReceivedFavePointsNext(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfMonthlySongFaves(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfMonthlySongFavesDiff(value);
                break;
            case 7:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNumberOfMonthlySongFavesNext(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusRate(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusRateDiff(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusTitleRate(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveResultFavePoint.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveResultFavePoint.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveResultFavePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveResultFavePoint.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMonth();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getMonthlyReceivedFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonthlyReceivedFavePointsDiff();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getMonthlyReceivedFavePointsNext();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getNumberOfMonthlySongFaves();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getNumberOfMonthlySongFavesDiff();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
    f = message.getNumberOfMonthlySongFavesNext();
    if (f !== 0) {
        writer.writeInt64(7, f);
    }
    f = message.getFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
    f = message.getFaveBonusRateDiff();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
    f = message.getFaveBonusTitleRate();
    if (f.length > 0) {
        writer.writeString(10, f);
    }
};
/**
 * optional int64 month = 1;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 monthly_received_fave_points = 2;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getMonthlyReceivedFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setMonthlyReceivedFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 monthly_received_fave_points_diff = 3;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getMonthlyReceivedFavePointsDiff = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setMonthlyReceivedFavePointsDiff = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 monthly_received_fave_points_next = 4;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getMonthlyReceivedFavePointsNext = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setMonthlyReceivedFavePointsNext = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 number_of_monthly_song_faves = 5;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getNumberOfMonthlySongFaves = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setNumberOfMonthlySongFaves = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 number_of_monthly_song_faves_diff = 6;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getNumberOfMonthlySongFavesDiff = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setNumberOfMonthlySongFavesDiff = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional int64 number_of_monthly_song_faves_next = 7;
 * @return {number}
 */
proto.LiveResultFavePoint.prototype.getNumberOfMonthlySongFavesNext = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setNumberOfMonthlySongFavesNext = function (value) {
    return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional string fave_bonus_rate = 8;
 * @return {string}
 */
proto.LiveResultFavePoint.prototype.getFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string fave_bonus_rate_diff = 9;
 * @return {string}
 */
proto.LiveResultFavePoint.prototype.getFaveBonusRateDiff = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setFaveBonusRateDiff = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string fave_bonus_title_rate = 10;
 * @return {string}
 */
proto.LiveResultFavePoint.prototype.getFaveBonusTitleRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveResultFavePoint} returns this
 */
proto.LiveResultFavePoint.prototype.setFaveBonusTitleRate = function (value) {
    return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LiveResultGiftListener.repeatedFields_ = [5];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveResultGiftListener.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveResultGiftListener.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveResultGiftListener} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveResultGiftListener.toObject = function (includeInstance, msg) {
        var f, obj = {
            user: (f = msg.getUser()) && users_entity_pb.User.toObject(includeInstance, f),
            termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
            monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
            fanType: jspb.Message.getFieldWithDefault(msg, 4, 0),
            giftCountsList: jspb.Message.toObjectList(msg.getGiftCountsList(), proto.LiveResultGiftCount.toObject, includeInstance)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveResultGiftListener}
 */
proto.LiveResultGiftListener.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveResultGiftListener;
    return proto.LiveResultGiftListener.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveResultGiftListener} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveResultGiftListener}
 */
proto.LiveResultGiftListener.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTermlyFavePoints(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setMonthlyFavePoints(value);
                break;
            case 4:
                var value = /** @type {!proto.FanType} */ (reader.readEnum());
                msg.setFanType(value);
                break;
            case 5:
                var value = new proto.LiveResultGiftCount;
                reader.readMessage(value, proto.LiveResultGiftCount.deserializeBinaryFromReader);
                msg.addGiftCounts(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveResultGiftListener.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveResultGiftListener.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveResultGiftListener} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveResultGiftListener.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getTermlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getMonthlyFavePoints();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getFanType();
    if (f !== 0.0) {
        writer.writeEnum(4, f);
    }
    f = message.getGiftCountsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, proto.LiveResultGiftCount.serializeBinaryToWriter);
    }
};
/**
 * optional User user = 1;
 * @return {?proto.User}
 */
proto.LiveResultGiftListener.prototype.getUser = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveResultGiftListener} returns this
*/
proto.LiveResultGiftListener.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveResultGiftListener} returns this
 */
proto.LiveResultGiftListener.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveResultGiftListener.prototype.hasUser = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 termly_fave_points = 2;
 * @return {number}
 */
proto.LiveResultGiftListener.prototype.getTermlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultGiftListener} returns this
 */
proto.LiveResultGiftListener.prototype.setTermlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 monthly_fave_points = 3;
 * @return {number}
 */
proto.LiveResultGiftListener.prototype.getMonthlyFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultGiftListener} returns this
 */
proto.LiveResultGiftListener.prototype.setMonthlyFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional FanType fan_type = 4;
 * @return {!proto.FanType}
 */
proto.LiveResultGiftListener.prototype.getFanType = function () {
    return /** @type {!proto.FanType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {!proto.FanType} value
 * @return {!proto.LiveResultGiftListener} returns this
 */
proto.LiveResultGiftListener.prototype.setFanType = function (value) {
    return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * repeated LiveResultGiftCount gift_counts = 5;
 * @return {!Array<!proto.LiveResultGiftCount>}
 */
proto.LiveResultGiftListener.prototype.getGiftCountsList = function () {
    return /** @type{!Array<!proto.LiveResultGiftCount>} */ (jspb.Message.getRepeatedWrapperField(this, proto.LiveResultGiftCount, 5));
};
/**
 * @param {!Array<!proto.LiveResultGiftCount>} value
 * @return {!proto.LiveResultGiftListener} returns this
*/
proto.LiveResultGiftListener.prototype.setGiftCountsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.LiveResultGiftCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LiveResultGiftCount}
 */
proto.LiveResultGiftListener.prototype.addGiftCounts = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.LiveResultGiftCount, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LiveResultGiftListener} returns this
 */
proto.LiveResultGiftListener.prototype.clearGiftCountsList = function () {
    return this.setGiftCountsList([]);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveResultGiftCount.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveResultGiftCount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveResultGiftCount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveResultGiftCount.toObject = function (includeInstance, msg) {
        var f, obj = {
            gift: (f = msg.getGift()) && proto.Gift.toObject(includeInstance, f),
            count: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveResultGiftCount}
 */
proto.LiveResultGiftCount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveResultGiftCount;
    return proto.LiveResultGiftCount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveResultGiftCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveResultGiftCount}
 */
proto.LiveResultGiftCount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.Gift;
                reader.readMessage(value, proto.Gift.deserializeBinaryFromReader);
                msg.setGift(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveResultGiftCount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveResultGiftCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveResultGiftCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveResultGiftCount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGift();
    if (f != null) {
        writer.writeMessage(1, f, proto.Gift.serializeBinaryToWriter);
    }
    f = message.getCount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional Gift gift = 1;
 * @return {?proto.Gift}
 */
proto.LiveResultGiftCount.prototype.getGift = function () {
    return /** @type{?proto.Gift} */ (jspb.Message.getWrapperField(this, proto.Gift, 1));
};
/**
 * @param {?proto.Gift|undefined} value
 * @return {!proto.LiveResultGiftCount} returns this
*/
proto.LiveResultGiftCount.prototype.setGift = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveResultGiftCount} returns this
 */
proto.LiveResultGiftCount.prototype.clearGift = function () {
    return this.setGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveResultGiftCount.prototype.hasGift = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.LiveResultGiftCount.prototype.getCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveResultGiftCount} returns this
 */
proto.LiveResultGiftCount.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveLiverInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveLiverInfo.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveLiverInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveLiverInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            userItem: (f = msg.getUserItem()) && users_entity_pb.UserItem.toObject(includeInstance, f),
            userFollow: (f = msg.getUserFollow()) && users_entity_pb.UserFollow.toObject(includeInstance, f),
            liveTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
            superLikeInDayCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
            surgingMark: jspb.Message.getFieldWithDefault(msg, 5, ""),
            meterRangeInformation: jspb.Message.getFieldWithDefault(msg, 6, ""),
            faveBonusRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
            currentFavePoints: jspb.Message.getFieldWithDefault(msg, 8, 0),
            nextFaveBonusRequiredFavePoints: jspb.Message.getFieldWithDefault(msg, 9, 0),
            currentSongFaveCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
            nextFaveBonusRequiredSongFaveCount: jspb.Message.getFieldWithDefault(msg, 11, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveLiverInfo}
 */
proto.LiveLiverInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveLiverInfo;
    return proto.LiveLiverInfo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveLiverInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveLiverInfo}
 */
proto.LiveLiverInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.UserItem;
                reader.readMessage(value, users_entity_pb.UserItem.deserializeBinaryFromReader);
                msg.setUserItem(value);
                break;
            case 2:
                var value = new users_entity_pb.UserFollow;
                reader.readMessage(value, users_entity_pb.UserFollow.deserializeBinaryFromReader);
                msg.setUserFollow(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveTitle(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setSuperLikeInDayCount(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setSurgingMark(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setMeterRangeInformation(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setFaveBonusRate(value);
                break;
            case 8:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentFavePoints(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextFaveBonusRequiredFavePoints(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCurrentSongFaveCount(value);
                break;
            case 11:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextFaveBonusRequiredSongFaveCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveLiverInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveLiverInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveLiverInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveLiverInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserItem();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.UserItem.serializeBinaryToWriter);
    }
    f = message.getUserFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserFollow.serializeBinaryToWriter);
    }
    f = message.getLiveTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getSuperLikeInDayCount();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getSurgingMark();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getMeterRangeInformation();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getFaveBonusRate();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
    f = message.getCurrentFavePoints();
    if (f !== 0) {
        writer.writeInt64(8, f);
    }
    f = message.getNextFaveBonusRequiredFavePoints();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
    f = message.getCurrentSongFaveCount();
    if (f !== 0) {
        writer.writeInt64(10, f);
    }
    f = message.getNextFaveBonusRequiredSongFaveCount();
    if (f !== 0) {
        writer.writeInt64(11, f);
    }
};
/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.LiveLiverInfo.prototype.getUserItem = function () {
    return /** @type{?proto.UserItem} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItem, 1));
};
/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.LiveLiverInfo} returns this
*/
proto.LiveLiverInfo.prototype.setUserItem = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.clearUserItem = function () {
    return this.setUserItem(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveLiverInfo.prototype.hasUserItem = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.LiveLiverInfo.prototype.getUserFollow = function () {
    return /** @type{?proto.UserFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserFollow, 2));
};
/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.LiveLiverInfo} returns this
*/
proto.LiveLiverInfo.prototype.setUserFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.clearUserFollow = function () {
    return this.setUserFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveLiverInfo.prototype.hasUserFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string live_title = 3;
 * @return {string}
 */
proto.LiveLiverInfo.prototype.getLiveTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setLiveTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 super_like_in_day_count = 4;
 * @return {number}
 */
proto.LiveLiverInfo.prototype.getSuperLikeInDayCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setSuperLikeInDayCount = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string surging_mark = 5;
 * @return {string}
 */
proto.LiveLiverInfo.prototype.getSurgingMark = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setSurgingMark = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string meter_range_information = 6;
 * @return {string}
 */
proto.LiveLiverInfo.prototype.getMeterRangeInformation = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setMeterRangeInformation = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string fave_bonus_rate = 7;
 * @return {string}
 */
proto.LiveLiverInfo.prototype.getFaveBonusRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setFaveBonusRate = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional int64 current_fave_points = 8;
 * @return {number}
 */
proto.LiveLiverInfo.prototype.getCurrentFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setCurrentFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int64 next_fave_bonus_required_fave_points = 9;
 * @return {number}
 */
proto.LiveLiverInfo.prototype.getNextFaveBonusRequiredFavePoints = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setNextFaveBonusRequiredFavePoints = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int64 current_song_fave_count = 10;
 * @return {number}
 */
proto.LiveLiverInfo.prototype.getCurrentSongFaveCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setCurrentSongFaveCount = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional int64 next_fave_bonus_required_song_fave_count = 11;
 * @return {number}
 */
proto.LiveLiverInfo.prototype.getNextFaveBonusRequiredSongFaveCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveLiverInfo} returns this
 */
proto.LiveLiverInfo.prototype.setNextFaveBonusRequiredSongFaveCount = function (value) {
    return jspb.Message.setProto3IntField(this, 11, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveTimelineInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveTimelineInfo.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveTimelineInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveTimelineInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            timelineId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            timelineName: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveTimelineInfo}
 */
proto.LiveTimelineInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveTimelineInfo;
    return proto.LiveTimelineInfo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveTimelineInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveTimelineInfo}
 */
proto.LiveTimelineInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveTimelineInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveTimelineInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveTimelineInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveTimelineInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTimelineId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTimelineName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string timeline_id = 1;
 * @return {string}
 */
proto.LiveTimelineInfo.prototype.getTimelineId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineInfo} returns this
 */
proto.LiveTimelineInfo.prototype.setTimelineId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string timeline_name = 2;
 * @return {string}
 */
proto.LiveTimelineInfo.prototype.getTimelineName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineInfo} returns this
 */
proto.LiveTimelineInfo.prototype.setTimelineName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveTimelineBanner.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveTimelineBanner.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveTimelineBanner} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveTimelineBanner.toObject = function (includeInstance, msg) {
        var f, obj = {
            live: (f = msg.getLive()) && proto.Live.toObject(includeInstance, f),
            userItemWithFollow: (f = msg.getUserItemWithFollow()) && users_entity_pb.UserItemWithFollow.toObject(includeInstance, f),
            title: jspb.Message.getFieldWithDefault(msg, 3, ""),
            rank: jspb.Message.getFieldWithDefault(msg, 4, 0),
            periodText: jspb.Message.getFieldWithDefault(msg, 5, ""),
            backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
            timelineName: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveTimelineBanner}
 */
proto.LiveTimelineBanner.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveTimelineBanner;
    return proto.LiveTimelineBanner.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveTimelineBanner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveTimelineBanner}
 */
proto.LiveTimelineBanner.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.Live;
                reader.readMessage(value, proto.Live.deserializeBinaryFromReader);
                msg.setLive(value);
                break;
            case 2:
                var value = new users_entity_pb.UserItemWithFollow;
                reader.readMessage(value, users_entity_pb.UserItemWithFollow.deserializeBinaryFromReader);
                msg.setUserItemWithFollow(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setTitle(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRank(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setPeriodText(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());
                msg.setBackgroundColor(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setTimelineName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveTimelineBanner.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveTimelineBanner.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveTimelineBanner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveTimelineBanner.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLive();
    if (f != null) {
        writer.writeMessage(1, f, proto.Live.serializeBinaryToWriter);
    }
    f = message.getUserItemWithFollow();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.UserItemWithFollow.serializeBinaryToWriter);
    }
    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getRank();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getPeriodText();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
    f = message.getTimelineName();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
/**
 * optional Live live = 1;
 * @return {?proto.Live}
 */
proto.LiveTimelineBanner.prototype.getLive = function () {
    return /** @type{?proto.Live} */ (jspb.Message.getWrapperField(this, proto.Live, 1));
};
/**
 * @param {?proto.Live|undefined} value
 * @return {!proto.LiveTimelineBanner} returns this
*/
proto.LiveTimelineBanner.prototype.setLive = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.clearLive = function () {
    return this.setLive(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveTimelineBanner.prototype.hasLive = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional UserItemWithFollow user_item_with_follow = 2;
 * @return {?proto.UserItemWithFollow}
 */
proto.LiveTimelineBanner.prototype.getUserItemWithFollow = function () {
    return /** @type{?proto.UserItemWithFollow} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFollow, 2));
};
/**
 * @param {?proto.UserItemWithFollow|undefined} value
 * @return {!proto.LiveTimelineBanner} returns this
*/
proto.LiveTimelineBanner.prototype.setUserItemWithFollow = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.clearUserItemWithFollow = function () {
    return this.setUserItemWithFollow(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveTimelineBanner.prototype.hasUserItemWithFollow = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string title = 3;
 * @return {string}
 */
proto.LiveTimelineBanner.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 rank = 4;
 * @return {number}
 */
proto.LiveTimelineBanner.prototype.getRank = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.setRank = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string period_text = 5;
 * @return {string}
 */
proto.LiveTimelineBanner.prototype.getPeriodText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.setPeriodText = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string background_color = 6;
 * @return {string}
 */
proto.LiveTimelineBanner.prototype.getBackgroundColor = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.setBackgroundColor = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string timeline_name = 7;
 * @return {string}
 */
proto.LiveTimelineBanner.prototype.getTimelineName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveTimelineBanner} returns this
 */
proto.LiveTimelineBanner.prototype.setTimelineName = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.HeartfulMention.repeatedFields_ = [7, 8];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.HeartfulMention.prototype.toObject = function (opt_includeInstance) {
        return proto.HeartfulMention.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.HeartfulMention} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.HeartfulMention.toObject = function (includeInstance, msg) {
        var f, obj = {
            heartfulMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            liveId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
            stampImageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
            user: (f = msg.getUser()) && users_entity_pb.UserItemWithFavePoint.toObject(includeInstance, f),
            mentionUser: (f = msg.getMentionUser()) && users_entity_pb.UserItemWithFavePoint.toObject(includeInstance, f),
            mentionTopSendersList: jspb.Message.toObjectList(msg.getMentionTopSendersList(), users_entity_pb.UserItemWithFavePoint.toObject, includeInstance),
            mentionSenderIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
            visibleDelaySeconds: jspb.Message.getFieldWithDefault(msg, 9, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeartfulMention}
 */
proto.HeartfulMention.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.HeartfulMention;
    return proto.HeartfulMention.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeartfulMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeartfulMention}
 */
proto.HeartfulMention.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setHeartfulMentionId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiveId(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setComment(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setStampImageUrl(value);
                break;
            case 5:
                var value = new users_entity_pb.UserItemWithFavePoint;
                reader.readMessage(value, users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
                msg.setUser(value);
                break;
            case 6:
                var value = new users_entity_pb.UserItemWithFavePoint;
                reader.readMessage(value, users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
                msg.setMentionUser(value);
                break;
            case 7:
                var value = new users_entity_pb.UserItemWithFavePoint;
                reader.readMessage(value, users_entity_pb.UserItemWithFavePoint.deserializeBinaryFromReader);
                msg.addMentionTopSenders(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());
                msg.addMentionSenderIds(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setVisibleDelaySeconds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeartfulMention.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.HeartfulMention.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeartfulMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartfulMention.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHeartfulMentionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLiveId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getComment();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getStampImageUrl();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getUser();
    if (f != null) {
        writer.writeMessage(5, f, users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter);
    }
    f = message.getMentionUser();
    if (f != null) {
        writer.writeMessage(6, f, users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter);
    }
    f = message.getMentionTopSendersList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, users_entity_pb.UserItemWithFavePoint.serializeBinaryToWriter);
    }
    f = message.getMentionSenderIdsList();
    if (f.length > 0) {
        writer.writeRepeatedString(8, f);
    }
    f = message.getVisibleDelaySeconds();
    if (f !== 0) {
        writer.writeInt64(9, f);
    }
};
/**
 * optional string heartful_mention_id = 1;
 * @return {string}
 */
proto.HeartfulMention.prototype.getHeartfulMentionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setHeartfulMentionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string live_id = 2;
 * @return {string}
 */
proto.HeartfulMention.prototype.getLiveId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setLiveId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string comment = 3;
 * @return {string}
 */
proto.HeartfulMention.prototype.getComment = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setComment = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string stamp_image_url = 4;
 * @return {string}
 */
proto.HeartfulMention.prototype.getStampImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setStampImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional UserItemWithFavePoint user = 5;
 * @return {?proto.UserItemWithFavePoint}
 */
proto.HeartfulMention.prototype.getUser = function () {
    return /** @type{?proto.UserItemWithFavePoint} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFavePoint, 5));
};
/**
 * @param {?proto.UserItemWithFavePoint|undefined} value
 * @return {!proto.HeartfulMention} returns this
*/
proto.HeartfulMention.prototype.setUser = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.clearUser = function () {
    return this.setUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeartfulMention.prototype.hasUser = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional UserItemWithFavePoint mention_user = 6;
 * @return {?proto.UserItemWithFavePoint}
 */
proto.HeartfulMention.prototype.getMentionUser = function () {
    return /** @type{?proto.UserItemWithFavePoint} */ (jspb.Message.getWrapperField(this, users_entity_pb.UserItemWithFavePoint, 6));
};
/**
 * @param {?proto.UserItemWithFavePoint|undefined} value
 * @return {!proto.HeartfulMention} returns this
*/
proto.HeartfulMention.prototype.setMentionUser = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.clearMentionUser = function () {
    return this.setMentionUser(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.HeartfulMention.prototype.hasMentionUser = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * repeated UserItemWithFavePoint Mention_top_senders = 7;
 * @return {!Array<!proto.UserItemWithFavePoint>}
 */
proto.HeartfulMention.prototype.getMentionTopSendersList = function () {
    return /** @type{!Array<!proto.UserItemWithFavePoint>} */ (jspb.Message.getRepeatedWrapperField(this, users_entity_pb.UserItemWithFavePoint, 7));
};
/**
 * @param {!Array<!proto.UserItemWithFavePoint>} value
 * @return {!proto.HeartfulMention} returns this
*/
proto.HeartfulMention.prototype.setMentionTopSendersList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.UserItemWithFavePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.UserItemWithFavePoint}
 */
proto.HeartfulMention.prototype.addMentionTopSenders = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.UserItemWithFavePoint, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.clearMentionTopSendersList = function () {
    return this.setMentionTopSendersList([]);
};
/**
 * repeated string Mention_sender_ids = 8;
 * @return {!Array<string>}
 */
proto.HeartfulMention.prototype.getMentionSenderIdsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};
/**
 * @param {!Array<string>} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setMentionSenderIdsList = function (value) {
    return jspb.Message.setField(this, 8, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.addMentionSenderIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.clearMentionSenderIdsList = function () {
    return this.setMentionSenderIdsList([]);
};
/**
 * optional int64 visible_delay_seconds = 9;
 * @return {number}
 */
proto.HeartfulMention.prototype.getVisibleDelaySeconds = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};
/**
 * @param {number} value
 * @return {!proto.HeartfulMention} returns this
 */
proto.HeartfulMention.prototype.setVisibleDelaySeconds = function (value) {
    return jspb.Message.setProto3IntField(this, 9, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventHeartfulMention.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventHeartfulMention.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventHeartfulMention} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventHeartfulMention.toObject = function (includeInstance, msg) {
        var f, obj = {
            heartfulMention: (f = msg.getHeartfulMention()) && proto.HeartfulMention.toObject(includeInstance, f),
            reactionGiftButton: (f = msg.getReactionGiftButton()) && proto.ReactionGiftButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventHeartfulMention}
 */
proto.LiveEventHeartfulMention.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventHeartfulMention;
    return proto.LiveEventHeartfulMention.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventHeartfulMention} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventHeartfulMention}
 */
proto.LiveEventHeartfulMention.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.HeartfulMention;
                reader.readMessage(value, proto.HeartfulMention.deserializeBinaryFromReader);
                msg.setHeartfulMention(value);
                break;
            case 2:
                var value = new proto.ReactionGiftButton;
                reader.readMessage(value, proto.ReactionGiftButton.deserializeBinaryFromReader);
                msg.setReactionGiftButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventHeartfulMention.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventHeartfulMention.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventHeartfulMention} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventHeartfulMention.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHeartfulMention();
    if (f != null) {
        writer.writeMessage(1, f, proto.HeartfulMention.serializeBinaryToWriter);
    }
    f = message.getReactionGiftButton();
    if (f != null) {
        writer.writeMessage(2, f, proto.ReactionGiftButton.serializeBinaryToWriter);
    }
};
/**
 * optional HeartfulMention heartful_mention = 1;
 * @return {?proto.HeartfulMention}
 */
proto.LiveEventHeartfulMention.prototype.getHeartfulMention = function () {
    return /** @type{?proto.HeartfulMention} */ (jspb.Message.getWrapperField(this, proto.HeartfulMention, 1));
};
/**
 * @param {?proto.HeartfulMention|undefined} value
 * @return {!proto.LiveEventHeartfulMention} returns this
*/
proto.LiveEventHeartfulMention.prototype.setHeartfulMention = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventHeartfulMention} returns this
 */
proto.LiveEventHeartfulMention.prototype.clearHeartfulMention = function () {
    return this.setHeartfulMention(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventHeartfulMention.prototype.hasHeartfulMention = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ReactionGiftButton reaction_gift_button = 2;
 * @return {?proto.ReactionGiftButton}
 */
proto.LiveEventHeartfulMention.prototype.getReactionGiftButton = function () {
    return /** @type{?proto.ReactionGiftButton} */ (jspb.Message.getWrapperField(this, proto.ReactionGiftButton, 2));
};
/**
 * @param {?proto.ReactionGiftButton|undefined} value
 * @return {!proto.LiveEventHeartfulMention} returns this
*/
proto.LiveEventHeartfulMention.prototype.setReactionGiftButton = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventHeartfulMention} returns this
 */
proto.LiveEventHeartfulMention.prototype.clearReactionGiftButton = function () {
    return this.setReactionGiftButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventHeartfulMention.prototype.hasReactionGiftButton = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventLiveSettingsUpdated.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventLiveSettingsUpdated.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventLiveSettingsUpdated} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventLiveSettingsUpdated.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveBackground: (f = msg.getLiveBackground()) && proto.LiveBackground.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventLiveSettingsUpdated}
 */
proto.LiveEventLiveSettingsUpdated.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventLiveSettingsUpdated;
    return proto.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventLiveSettingsUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventLiveSettingsUpdated}
 */
proto.LiveEventLiveSettingsUpdated.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.LiveBackground;
                reader.readMessage(value, proto.LiveBackground.deserializeBinaryFromReader);
                msg.setLiveBackground(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventLiveSettingsUpdated.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventLiveSettingsUpdated.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventLiveSettingsUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventLiveSettingsUpdated.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveBackground();
    if (f != null) {
        writer.writeMessage(1, f, proto.LiveBackground.serializeBinaryToWriter);
    }
};
/**
 * optional LiveBackground live_background = 1;
 * @return {?proto.LiveBackground}
 */
proto.LiveEventLiveSettingsUpdated.prototype.getLiveBackground = function () {
    return /** @type{?proto.LiveBackground} */ (jspb.Message.getWrapperField(this, proto.LiveBackground, 1));
};
/**
 * @param {?proto.LiveBackground|undefined} value
 * @return {!proto.LiveEventLiveSettingsUpdated} returns this
*/
proto.LiveEventLiveSettingsUpdated.prototype.setLiveBackground = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventLiveSettingsUpdated} returns this
 */
proto.LiveEventLiveSettingsUpdated.prototype.clearLiveBackground = function () {
    return this.setLiveBackground(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventLiveSettingsUpdated.prototype.hasLiveBackground = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventListenerReqCapturePossibilityUpdated.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventListenerReqCapturePossibilityUpdated} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventListenerReqCapturePossibilityUpdated.toObject = function (includeInstance, msg) {
        var f, obj = {
            listenerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            reqCapturePossibility: (f = msg.getReqCapturePossibility()) && proto.ReqCapturePossibility.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventListenerReqCapturePossibilityUpdated;
    return proto.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventListenerReqCapturePossibilityUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventListenerReqCapturePossibilityUpdated}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setListenerId(value);
                break;
            case 2:
                var value = new proto.ReqCapturePossibility;
                reader.readMessage(value, proto.ReqCapturePossibility.deserializeBinaryFromReader);
                msg.setReqCapturePossibility(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventListenerReqCapturePossibilityUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListenerId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getReqCapturePossibility();
    if (f != null) {
        writer.writeMessage(2, f, proto.ReqCapturePossibility.serializeBinaryToWriter);
    }
};
/**
 * optional string listener_id = 1;
 * @return {string}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.getListenerId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventListenerReqCapturePossibilityUpdated} returns this
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.setListenerId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional ReqCapturePossibility req_capture_possibility = 2;
 * @return {?proto.ReqCapturePossibility}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.getReqCapturePossibility = function () {
    return /** @type{?proto.ReqCapturePossibility} */ (jspb.Message.getWrapperField(this, proto.ReqCapturePossibility, 2));
};
/**
 * @param {?proto.ReqCapturePossibility|undefined} value
 * @return {!proto.LiveEventListenerReqCapturePossibilityUpdated} returns this
*/
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.setReqCapturePossibility = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventListenerReqCapturePossibilityUpdated} returns this
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.clearReqCapturePossibility = function () {
    return this.setReqCapturePossibility(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventListenerReqCapturePossibilityUpdated.prototype.hasReqCapturePossibility = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventPickUpSongRandomly.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventPickUpSongRandomly.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventPickUpSongRandomly} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventPickUpSongRandomly.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            pickUpNumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventPickUpSongRandomly}
 */
proto.LiveEventPickUpSongRandomly.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventPickUpSongRandomly;
    return proto.LiveEventPickUpSongRandomly.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventPickUpSongRandomly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventPickUpSongRandomly}
 */
proto.LiveEventPickUpSongRandomly.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPickUpNumber(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventPickUpSongRandomly.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventPickUpSongRandomly.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventPickUpSongRandomly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventPickUpSongRandomly.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getPickUpNumber();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.Song}
 */
proto.LiveEventPickUpSongRandomly.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveEventPickUpSongRandomly} returns this
*/
proto.LiveEventPickUpSongRandomly.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventPickUpSongRandomly} returns this
 */
proto.LiveEventPickUpSongRandomly.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventPickUpSongRandomly.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 pick_up_number = 2;
 * @return {number}
 */
proto.LiveEventPickUpSongRandomly.prototype.getPickUpNumber = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LiveEventPickUpSongRandomly} returns this
 */
proto.LiveEventPickUpSongRandomly.prototype.setPickUpNumber = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventMembershipUpdated.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventMembershipUpdated.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventMembershipUpdated} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventMembershipUpdated.toObject = function (includeInstance, msg) {
        var f, obj = {
            listener: (f = msg.getListener()) && users_entity_pb.User.toObject(includeInstance, f),
            listenerMembershipInfo: (f = msg.getListenerMembershipInfo()) && users_entity_pb.ListenerMembershipInfo.toObject(includeInstance, f),
            messageForLiver: jspb.Message.getFieldWithDefault(msg, 3, ""),
            messageForListener: jspb.Message.getFieldWithDefault(msg, 4, ""),
            heartfulMentionButton: (f = msg.getHeartfulMentionButton()) && proto.HeartfulMentionButton.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventMembershipUpdated}
 */
proto.LiveEventMembershipUpdated.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventMembershipUpdated;
    return proto.LiveEventMembershipUpdated.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventMembershipUpdated} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventMembershipUpdated}
 */
proto.LiveEventMembershipUpdated.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new users_entity_pb.User;
                reader.readMessage(value, users_entity_pb.User.deserializeBinaryFromReader);
                msg.setListener(value);
                break;
            case 2:
                var value = new users_entity_pb.ListenerMembershipInfo;
                reader.readMessage(value, users_entity_pb.ListenerMembershipInfo.deserializeBinaryFromReader);
                msg.setListenerMembershipInfo(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessageForLiver(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setMessageForListener(value);
                break;
            case 5:
                var value = new proto.HeartfulMentionButton;
                reader.readMessage(value, proto.HeartfulMentionButton.deserializeBinaryFromReader);
                msg.setHeartfulMentionButton(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventMembershipUpdated.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventMembershipUpdated.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventMembershipUpdated} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventMembershipUpdated.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getListener();
    if (f != null) {
        writer.writeMessage(1, f, users_entity_pb.User.serializeBinaryToWriter);
    }
    f = message.getListenerMembershipInfo();
    if (f != null) {
        writer.writeMessage(2, f, users_entity_pb.ListenerMembershipInfo.serializeBinaryToWriter);
    }
    f = message.getMessageForLiver();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getMessageForListener();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getHeartfulMentionButton();
    if (f != null) {
        writer.writeMessage(5, f, proto.HeartfulMentionButton.serializeBinaryToWriter);
    }
};
/**
 * optional User listener = 1;
 * @return {?proto.User}
 */
proto.LiveEventMembershipUpdated.prototype.getListener = function () {
    return /** @type{?proto.User} */ (jspb.Message.getWrapperField(this, users_entity_pb.User, 1));
};
/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiveEventMembershipUpdated} returns this
*/
proto.LiveEventMembershipUpdated.prototype.setListener = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventMembershipUpdated} returns this
 */
proto.LiveEventMembershipUpdated.prototype.clearListener = function () {
    return this.setListener(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventMembershipUpdated.prototype.hasListener = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ListenerMembershipInfo listener_membership_info = 2;
 * @return {?proto.ListenerMembershipInfo}
 */
proto.LiveEventMembershipUpdated.prototype.getListenerMembershipInfo = function () {
    return /** @type{?proto.ListenerMembershipInfo} */ (jspb.Message.getWrapperField(this, users_entity_pb.ListenerMembershipInfo, 2));
};
/**
 * @param {?proto.ListenerMembershipInfo|undefined} value
 * @return {!proto.LiveEventMembershipUpdated} returns this
*/
proto.LiveEventMembershipUpdated.prototype.setListenerMembershipInfo = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventMembershipUpdated} returns this
 */
proto.LiveEventMembershipUpdated.prototype.clearListenerMembershipInfo = function () {
    return this.setListenerMembershipInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventMembershipUpdated.prototype.hasListenerMembershipInfo = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string message_for_liver = 3;
 * @return {string}
 */
proto.LiveEventMembershipUpdated.prototype.getMessageForLiver = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventMembershipUpdated} returns this
 */
proto.LiveEventMembershipUpdated.prototype.setMessageForLiver = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string message_for_listener = 4;
 * @return {string}
 */
proto.LiveEventMembershipUpdated.prototype.getMessageForListener = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveEventMembershipUpdated} returns this
 */
proto.LiveEventMembershipUpdated.prototype.setMessageForListener = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional HeartfulMentionButton heartful_mention_button = 5;
 * @return {?proto.HeartfulMentionButton}
 */
proto.LiveEventMembershipUpdated.prototype.getHeartfulMentionButton = function () {
    return /** @type{?proto.HeartfulMentionButton} */ (jspb.Message.getWrapperField(this, proto.HeartfulMentionButton, 5));
};
/**
 * @param {?proto.HeartfulMentionButton|undefined} value
 * @return {!proto.LiveEventMembershipUpdated} returns this
*/
proto.LiveEventMembershipUpdated.prototype.setHeartfulMentionButton = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventMembershipUpdated} returns this
 */
proto.LiveEventMembershipUpdated.prototype.clearHeartfulMentionButton = function () {
    return this.setHeartfulMentionButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventMembershipUpdated.prototype.hasHeartfulMentionButton = function () {
    return jspb.Message.getField(this, 5) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ReqCapturePossibility.prototype.toObject = function (opt_includeInstance) {
        return proto.ReqCapturePossibility.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ReqCapturePossibility} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ReqCapturePossibility.toObject = function (includeInstance, msg) {
        var f, obj = {
            canRequestSing: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            cannotRequestSingReason: jspb.Message.getFieldWithDefault(msg, 2, ""),
            canCapture: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            cannotCaptureReason: jspb.Message.getFieldWithDefault(msg, 4, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReqCapturePossibility}
 */
proto.ReqCapturePossibility.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ReqCapturePossibility;
    return proto.ReqCapturePossibility.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReqCapturePossibility} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReqCapturePossibility}
 */
proto.ReqCapturePossibility.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanRequestSing(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCannotRequestSingReason(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanCapture(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setCannotCaptureReason(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReqCapturePossibility.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ReqCapturePossibility.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReqCapturePossibility} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReqCapturePossibility.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCanRequestSing();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getCannotRequestSingReason();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCanCapture();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getCannotCaptureReason();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};
/**
 * optional bool can_request_sing = 1;
 * @return {boolean}
 */
proto.ReqCapturePossibility.prototype.getCanRequestSing = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.ReqCapturePossibility} returns this
 */
proto.ReqCapturePossibility.prototype.setCanRequestSing = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional string cannot_request_sing_reason = 2;
 * @return {string}
 */
proto.ReqCapturePossibility.prototype.getCannotRequestSingReason = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.ReqCapturePossibility} returns this
 */
proto.ReqCapturePossibility.prototype.setCannotRequestSingReason = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool can_capture = 3;
 * @return {boolean}
 */
proto.ReqCapturePossibility.prototype.getCanCapture = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.ReqCapturePossibility} returns this
 */
proto.ReqCapturePossibility.prototype.setCanCapture = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional string cannot_capture_reason = 4;
 * @return {string}
 */
proto.ReqCapturePossibility.prototype.getCannotCaptureReason = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.ReqCapturePossibility} returns this
 */
proto.ReqCapturePossibility.prototype.setCannotCaptureReason = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveEventGetSongRandomly.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveEventGetSongRandomly.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveEventGetSongRandomly} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveEventGetSongRandomly.toObject = function (includeInstance, msg) {
        var f, obj = {
            song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
            isReSelected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveEventGetSongRandomly}
 */
proto.LiveEventGetSongRandomly.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveEventGetSongRandomly;
    return proto.LiveEventGetSongRandomly.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveEventGetSongRandomly} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveEventGetSongRandomly}
 */
proto.LiveEventGetSongRandomly.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new karaoke_entity_pb.Song;
                reader.readMessage(value, karaoke_entity_pb.Song.deserializeBinaryFromReader);
                msg.setSong(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsReSelected(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveEventGetSongRandomly.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveEventGetSongRandomly.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveEventGetSongRandomly} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveEventGetSongRandomly.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSong();
    if (f != null) {
        writer.writeMessage(1, f, karaoke_entity_pb.Song.serializeBinaryToWriter);
    }
    f = message.getIsReSelected();
    if (f) {
        writer.writeBool(2, f);
    }
};
/**
 * optional Song song = 1;
 * @return {?proto.Song}
 */
proto.LiveEventGetSongRandomly.prototype.getSong = function () {
    return /** @type{?proto.Song} */ (jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 1));
};
/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiveEventGetSongRandomly} returns this
*/
proto.LiveEventGetSongRandomly.prototype.setSong = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LiveEventGetSongRandomly} returns this
 */
proto.LiveEventGetSongRandomly.prototype.clearSong = function () {
    return this.setSong(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiveEventGetSongRandomly.prototype.hasSong = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional bool is_re_selected = 2;
 * @return {boolean}
 */
proto.LiveEventGetSongRandomly.prototype.getIsReSelected = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LiveEventGetSongRandomly} returns this
 */
proto.LiveEventGetSongRandomly.prototype.setIsReSelected = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.HeartfulMentionButton.prototype.toObject = function (opt_includeInstance) {
        return proto.HeartfulMentionButton.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.HeartfulMentionButton} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.HeartfulMentionButton.toObject = function (includeInstance, msg) {
        var f, obj = {
            heartfulMentionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            buttonLabel: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeartfulMentionButton}
 */
proto.HeartfulMentionButton.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.HeartfulMentionButton;
    return proto.HeartfulMentionButton.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeartfulMentionButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeartfulMentionButton}
 */
proto.HeartfulMentionButton.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setHeartfulMentionId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setButtonLabel(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeartfulMentionButton.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.HeartfulMentionButton.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeartfulMentionButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartfulMentionButton.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getHeartfulMentionId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getButtonLabel();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional string heartful_mention_id = 1;
 * @return {string}
 */
proto.HeartfulMentionButton.prototype.getHeartfulMentionId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMentionButton} returns this
 */
proto.HeartfulMentionButton.prototype.setHeartfulMentionId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string button_label = 2;
 * @return {string}
 */
proto.HeartfulMentionButton.prototype.getButtonLabel = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.HeartfulMentionButton} returns this
 */
proto.HeartfulMentionButton.prototype.setButtonLabel = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.ReactionGiftButton.prototype.toObject = function (opt_includeInstance) {
        return proto.ReactionGiftButton.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.ReactionGiftButton} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.ReactionGiftButton.toObject = function (includeInstance, msg) {
        var f, obj = {
            gift: (f = msg.getGift()) && proto.Gift.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReactionGiftButton}
 */
proto.ReactionGiftButton.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.ReactionGiftButton;
    return proto.ReactionGiftButton.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReactionGiftButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReactionGiftButton}
 */
proto.ReactionGiftButton.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.Gift;
                reader.readMessage(value, proto.Gift.deserializeBinaryFromReader);
                msg.setGift(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ReactionGiftButton.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.ReactionGiftButton.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReactionGiftButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ReactionGiftButton.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getGift();
    if (f != null) {
        writer.writeMessage(1, f, proto.Gift.serializeBinaryToWriter);
    }
};
/**
 * optional Gift gift = 1;
 * @return {?proto.Gift}
 */
proto.ReactionGiftButton.prototype.getGift = function () {
    return /** @type{?proto.Gift} */ (jspb.Message.getWrapperField(this, proto.Gift, 1));
};
/**
 * @param {?proto.Gift|undefined} value
 * @return {!proto.ReactionGiftButton} returns this
*/
proto.ReactionGiftButton.prototype.setGift = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ReactionGiftButton} returns this
 */
proto.ReactionGiftButton.prototype.clearGift = function () {
    return this.setGift(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ReactionGiftButton.prototype.hasGift = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LiveBackground.prototype.toObject = function (opt_includeInstance) {
        return proto.LiveBackground.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LiveBackground} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LiveBackground.toObject = function (includeInstance, msg) {
        var f, obj = {
            liveBackgroundType: jspb.Message.getFieldWithDefault(msg, 1, 0),
            backgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiveBackground}
 */
proto.LiveBackground.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LiveBackground;
    return proto.LiveBackground.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiveBackground} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiveBackground}
 */
proto.LiveBackground.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {!proto.LiveBackgroundType} */ (reader.readEnum());
                msg.setLiveBackgroundType(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());
                msg.setBackgroundImageUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiveBackground.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LiveBackground.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiveBackground} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiveBackground.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiveBackgroundType();
    if (f !== 0.0) {
        writer.writeEnum(1, f);
    }
    f = message.getBackgroundImageUrl();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};
/**
 * optional LiveBackgroundType live_background_type = 1;
 * @return {!proto.LiveBackgroundType}
 */
proto.LiveBackground.prototype.getLiveBackgroundType = function () {
    return /** @type {!proto.LiveBackgroundType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {!proto.LiveBackgroundType} value
 * @return {!proto.LiveBackground} returns this
 */
proto.LiveBackground.prototype.setLiveBackgroundType = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string background_image_url = 7;
 * @return {string}
 */
proto.LiveBackground.prototype.getBackgroundImageUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};
/**
 * @param {string} value
 * @return {!proto.LiveBackground} returns this
 */
proto.LiveBackground.prototype.setBackgroundImageUrl = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LivePermission.prototype.toObject = function (opt_includeInstance) {
        return proto.LivePermission.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LivePermission} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LivePermission.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            permitted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            status: jspb.Message.getFieldWithDefault(msg, 5, 0),
            trialInfo: (f = msg.getTrialInfo()) && proto.LivePermission.TrialInfo.toObject(includeInstance, f),
            secondReviewInfo: (f = msg.getSecondReviewInfo()) && proto.LivePermission.SecondReviewInfo.toObject(includeInstance, f),
            failedSecondReviewInfo: (f = msg.getFailedSecondReviewInfo()) && proto.LivePermission.FailedSecondReviewInfo.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LivePermission}
 */
proto.LivePermission.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LivePermission;
    return proto.LivePermission.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LivePermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LivePermission}
 */
proto.LivePermission.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setPermitted(value);
                break;
            case 5:
                var value = /** @type {!proto.LivePermission.Status} */ (reader.readEnum());
                msg.setStatus(value);
                break;
            case 6:
                var value = new proto.LivePermission.TrialInfo;
                reader.readMessage(value, proto.LivePermission.TrialInfo.deserializeBinaryFromReader);
                msg.setTrialInfo(value);
                break;
            case 7:
                var value = new proto.LivePermission.SecondReviewInfo;
                reader.readMessage(value, proto.LivePermission.SecondReviewInfo.deserializeBinaryFromReader);
                msg.setSecondReviewInfo(value);
                break;
            case 8:
                var value = new proto.LivePermission.FailedSecondReviewInfo;
                reader.readMessage(value, proto.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader);
                msg.setFailedSecondReviewInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LivePermission.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LivePermission.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LivePermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LivePermission.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPermitted();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getStatus();
    if (f !== 0.0) {
        writer.writeEnum(5, f);
    }
    f = message.getTrialInfo();
    if (f != null) {
        writer.writeMessage(6, f, proto.LivePermission.TrialInfo.serializeBinaryToWriter);
    }
    f = message.getSecondReviewInfo();
    if (f != null) {
        writer.writeMessage(7, f, proto.LivePermission.SecondReviewInfo.serializeBinaryToWriter);
    }
    f = message.getFailedSecondReviewInfo();
    if (f != null) {
        writer.writeMessage(8, f, proto.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter);
    }
};
/**
 * @enum {number}
 */
proto.LivePermission.Status = {
    NOT_REVIEWED: 0,
    PASSED_FIRST_REVIEW: 1,
    SECOND_REVIEW_TRIAL: 2,
    SECOND_REVIEW: 3,
    PASSED_SECOND_REVIEW: 4,
    FAILED_SECOND_REVIEW: 5
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LivePermission.TrialInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.LivePermission.TrialInfo.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LivePermission.TrialInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LivePermission.TrialInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            trialDayCount: jspb.Message.getFieldWithDefault(msg, 1, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LivePermission.TrialInfo}
 */
proto.LivePermission.TrialInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LivePermission.TrialInfo;
    return proto.LivePermission.TrialInfo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LivePermission.TrialInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LivePermission.TrialInfo}
 */
proto.LivePermission.TrialInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setTrialDayCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LivePermission.TrialInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LivePermission.TrialInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LivePermission.TrialInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LivePermission.TrialInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTrialDayCount();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
};
/**
 * optional int64 trial_day_count = 1;
 * @return {number}
 */
proto.LivePermission.TrialInfo.prototype.getTrialDayCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.TrialInfo} returns this
 */
proto.LivePermission.TrialInfo.prototype.setTrialDayCount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LivePermission.SecondReviewInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.LivePermission.SecondReviewInfo.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LivePermission.SecondReviewInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LivePermission.SecondReviewInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            endYear: jspb.Message.getFieldWithDefault(msg, 1, 0),
            endMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
            endTermOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
            remainingDays: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LivePermission.SecondReviewInfo}
 */
proto.LivePermission.SecondReviewInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LivePermission.SecondReviewInfo;
    return proto.LivePermission.SecondReviewInfo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LivePermission.SecondReviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LivePermission.SecondReviewInfo}
 */
proto.LivePermission.SecondReviewInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndYear(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndMonth(value);
                break;
            case 3:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setEndTermOfMonth(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setRemainingDays(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LivePermission.SecondReviewInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LivePermission.SecondReviewInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LivePermission.SecondReviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LivePermission.SecondReviewInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEndYear();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getEndMonth();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getEndTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
    f = message.getRemainingDays();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional int64 end_year = 1;
 * @return {number}
 */
proto.LivePermission.SecondReviewInfo.prototype.getEndYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.SecondReviewInfo} returns this
 */
proto.LivePermission.SecondReviewInfo.prototype.setEndYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 end_month = 2;
 * @return {number}
 */
proto.LivePermission.SecondReviewInfo.prototype.getEndMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.SecondReviewInfo} returns this
 */
proto.LivePermission.SecondReviewInfo.prototype.setEndMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional TermOfMonth end_term_of_month = 3;
 * @return {!proto.TermOfMonth}
 */
proto.LivePermission.SecondReviewInfo.prototype.getEndTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.LivePermission.SecondReviewInfo} returns this
 */
proto.LivePermission.SecondReviewInfo.prototype.setEndTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int64 remaining_days = 4;
 * @return {number}
 */
proto.LivePermission.SecondReviewInfo.prototype.getRemainingDays = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.SecondReviewInfo} returns this
 */
proto.LivePermission.SecondReviewInfo.prototype.setRemainingDays = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.LivePermission.FailedSecondReviewInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.LivePermission.FailedSecondReviewInfo.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.LivePermission.FailedSecondReviewInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.LivePermission.FailedSecondReviewInfo.toObject = function (includeInstance, msg) {
        var f, obj = {
            nextReviewStartYear: jspb.Message.getFieldWithDefault(msg, 1, 0),
            nextReviewStartMonth: jspb.Message.getFieldWithDefault(msg, 2, 0),
            nextReviewStartTermOfMonth: jspb.Message.getFieldWithDefault(msg, 3, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LivePermission.FailedSecondReviewInfo}
 */
proto.LivePermission.FailedSecondReviewInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.LivePermission.FailedSecondReviewInfo;
    return proto.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LivePermission.FailedSecondReviewInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LivePermission.FailedSecondReviewInfo}
 */
proto.LivePermission.FailedSecondReviewInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextReviewStartYear(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setNextReviewStartMonth(value);
                break;
            case 3:
                var value = /** @type {!proto.TermOfMonth} */ (reader.readEnum());
                msg.setNextReviewStartTermOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LivePermission.FailedSecondReviewInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LivePermission.FailedSecondReviewInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getNextReviewStartYear();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getNextReviewStartMonth();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getNextReviewStartTermOfMonth();
    if (f !== 0.0) {
        writer.writeEnum(3, f);
    }
};
/**
 * optional int64 next_review_start_year = 1;
 * @return {number}
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartYear = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 next_review_start_month = 2;
 * @return {number}
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartMonth = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartMonth = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional TermOfMonth next_review_start_term_of_month = 3;
 * @return {!proto.TermOfMonth}
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.getNextReviewStartTermOfMonth = function () {
    return /** @type {!proto.TermOfMonth} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {!proto.TermOfMonth} value
 * @return {!proto.LivePermission.FailedSecondReviewInfo} returns this
 */
proto.LivePermission.FailedSecondReviewInfo.prototype.setNextReviewStartTermOfMonth = function (value) {
    return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.LivePermission.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool permitted = 4;
 * @return {boolean}
 */
proto.LivePermission.prototype.getPermitted = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.setPermitted = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional Status status = 5;
 * @return {!proto.LivePermission.Status}
 */
proto.LivePermission.prototype.getStatus = function () {
    return /** @type {!proto.LivePermission.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {!proto.LivePermission.Status} value
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional TrialInfo trial_info = 6;
 * @return {?proto.LivePermission.TrialInfo}
 */
proto.LivePermission.prototype.getTrialInfo = function () {
    return /** @type{?proto.LivePermission.TrialInfo} */ (jspb.Message.getWrapperField(this, proto.LivePermission.TrialInfo, 6));
};
/**
 * @param {?proto.LivePermission.TrialInfo|undefined} value
 * @return {!proto.LivePermission} returns this
*/
proto.LivePermission.prototype.setTrialInfo = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.clearTrialInfo = function () {
    return this.setTrialInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LivePermission.prototype.hasTrialInfo = function () {
    return jspb.Message.getField(this, 6) != null;
};
/**
 * optional SecondReviewInfo second_review_info = 7;
 * @return {?proto.LivePermission.SecondReviewInfo}
 */
proto.LivePermission.prototype.getSecondReviewInfo = function () {
    return /** @type{?proto.LivePermission.SecondReviewInfo} */ (jspb.Message.getWrapperField(this, proto.LivePermission.SecondReviewInfo, 7));
};
/**
 * @param {?proto.LivePermission.SecondReviewInfo|undefined} value
 * @return {!proto.LivePermission} returns this
*/
proto.LivePermission.prototype.setSecondReviewInfo = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.clearSecondReviewInfo = function () {
    return this.setSecondReviewInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LivePermission.prototype.hasSecondReviewInfo = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional FailedSecondReviewInfo failed_second_review_info = 8;
 * @return {?proto.LivePermission.FailedSecondReviewInfo}
 */
proto.LivePermission.prototype.getFailedSecondReviewInfo = function () {
    return /** @type{?proto.LivePermission.FailedSecondReviewInfo} */ (jspb.Message.getWrapperField(this, proto.LivePermission.FailedSecondReviewInfo, 8));
};
/**
 * @param {?proto.LivePermission.FailedSecondReviewInfo|undefined} value
 * @return {!proto.LivePermission} returns this
*/
proto.LivePermission.prototype.setFailedSecondReviewInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LivePermission} returns this
 */
proto.LivePermission.prototype.clearFailedSecondReviewInfo = function () {
    return this.setFailedSecondReviewInfo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LivePermission.prototype.hasFailedSecondReviewInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * @enum {number}
 */
proto.ShareTwitterDeleteSetting = {
    SHARE_TWITER_DELETE_SETTING_UNKNOWN: 0,
    SHARE_TWITER_DELETE_SETTING_DISABLED: 1,
    SHARE_TWITER_DELETE_SETTING_ENABLED_1_DAY: 2,
    SHARE_TWITER_DELETE_SETTING_ENABLED_ONE_TIME: 3
};
/**
 * @enum {number}
 */
proto.LiveFinishReason = {
    LIVE_FINISH_REASON_UNKNOWN: 0,
    LIVE_FINISH_REASON_NORMAL: 1,
    LIVE_FINISH_REASON_BANNED: 2,
    LIVE_FINISH_REASON_OPE: 3,
    LIVE_FINISH_REASON_BLOCKED: 4,
    LIVE_FINISH_REASON_BLOCKING: 5,
    LIVE_FINISH_REASON_MAINTENANCE: 6,
    LIVE_FINISH_REASON_NOT_AVAILABLE_TIME: 7
};
/**
 * @enum {number}
 */
proto.LiveEventType = {
    LIVE_EVENT_TYPE_UNKNOWN: 0,
    LIVE_EVENT_TYPE_SYSTEM: 1,
    LIVE_EVENT_TYPE_START: 2,
    LIVE_EVENT_TYPE_FINISH: 3,
    LIVE_EVENT_TYPE_ENTER: 4,
    LIVE_EVENT_TYPE_LEAVE: 5,
    LIVE_EVENT_TYPE_COMMENT: 6,
    LIVE_EVENT_TYPE_SMALL_GIFT: 7,
    LIVE_EVENT_TYPE_LARGE_GIFT: 8,
    LIVE_EVENT_TYPE_START_SING: 9,
    LIVE_EVENT_TYPE_FINISH_SING: 10,
    LIVE_EVENT_TYPE_GET_FAVE_BONUS: 11,
    LIVE_EVENT_TYPE_GET_FAVE_BONUS_TITLE: 12,
    LIVE_EVENT_TYPE_GET_FAVE_POINTS: 13,
    LIVE_EVENT_TYPE_GET_FAVE_BADGE: 14,
    LIVE_EVENT_TYPE_GET_SONG_FAVE: 15,
    LIVE_EVENT_TYPE_FOLLOW: 16,
    LIVE_EVENT_TYPE_LIKE: 17,
    LIVE_EVENT_TYPE_SUPER_LIKE: 18,
    LIVE_EVENT_TYPE_ANNOUNCE: 19,
    LIVE_EVENT_TYPE_SURGE_RANKING_NO1_REWARD: 20,
    LIVE_EVENT_TYPE_REQUEST_SING: 21,
    LIVE_EVENT_TYPE_SIMPLE_PF_COMMENT: 22,
    LIVE_EVENT_TYPE_GET_FAVE_BADGE_AND_SONG_FAVE: 23,
    LIVE_EVENT_TYPE_HEARTFUL_MENTION: 24,
    LIVE_EVENT_TYPE_LIVE_SETTINGS_UPDATED: 25,
    LIVE_EVENT_TYPE_REQ_CAPTURE_POSSIBILITY_UPDATED: 26,
    LIVE_EVENT_TYPE_PICK_UP_SONG_RANDOMLY: 27,
    LIVE_EVENT_TYPE_MEMBERSHIP_UPDATED: 29,
    LIVE_EVENT_TYPE_GET_SONG_RANDOMLY: 30
};
/**
 * @enum {number}
 */
proto.LiveEventSimplePFCommentType = {
    LIVE_EVENT_SIMPLE_PF_COMMENT_TYPE_UNKNOWN: 0,
    LIVE_EVENT_SIMPLE_PF_COMMENT_TYPE_LIVE_TIMELINE_BANNER_PICK_UP: 1
};
/**
 * @enum {number}
 */
proto.GiftKind = {
    GIFT_KIND_NORMAL: 0,
    GIFT_KIND_REACTION: 1
};
/**
 * @enum {number}
 */
proto.GiftType = {
    GIFT_TYPE_UNKNOWN: 0,
    GIFT_TYPE_SMALL: 1,
    GIFT_TYPE_MIDDLE: 2,
    GIFT_TYPE_LARGE: 3
};
/**
 * @enum {number}
 */
proto.SmallGiftType = {
    SMALL_GIFT_TYPE_UNKNOWN: 0,
    SMALL_GIFT_TYPE_DEFAULT: 1,
    SMALL_GIFT_TYPE_BIG: 2,
    SMALL_GIFT_TYPE_BIG_HEART: 3
};
/**
 * @enum {number}
 */
proto.GiftTabType = {
    GIFT_TAB_TYPE_UNKNOWN: 0,
    GIFT_TAB_TYPE_HOME: 1,
    GIFT_TAB_TYPE_KANA: 2,
    GIFT_TAB_TYPE_PRICE: 3,
    GIFT_TAB_TYPE_CATEGORY: 4
};
/**
 * @enum {number}
 */
proto.GiftTabSectionType = {
    GIFT_TAB_SECTION_TYPE_UNKNOWN: 0,
    GIFT_TAB_SECTION_TYPE_ALL: 1,
    GIFT_TAB_SECTION_TYPE_RECOMMEND: 2,
    GIFT_TAB_SECTION_TYPE_HISTORY: 3,
    GIFT_TAB_SECTION_TYPE_USE_FREQUENTLY_S: 4,
    GIFT_TAB_SECTION_TYPE_POPULAR_S: 5,
    GIFT_TAB_SECTION_TYPE_USE_FREQUENTLY_ML: 6,
    GIFT_TAB_SECTION_TYPE_POPULAR_ML: 7,
    GIFT_TAB_SECTION_TYPE_KANA_S: 8,
    GIFT_TAB_SECTION_TYPE_KANA_M: 9,
    GIFT_TAB_SECTION_TYPE_KANA_L: 10,
    GIFT_TAB_SECTION_TYPE_PRICE: 11
};
/**
 * @enum {number}
 */
proto.SongYellBadge = {
    SONG_YELL_BADGE_UNKNOWN: 0,
    SONG_YELL_BADGE_LV1: 1,
    SONG_YELL_BADGE_LV2: 2,
    SONG_YELL_BADGE_LV3: 3,
    SONG_YELL_BADGE_LV4: 4,
    SONG_YELL_BADGE_LV5: 5
};
/**
 * @enum {number}
 */
proto.TermOfMonth = {
    TERM_OF_MONTH_UNKNOWN: 0,
    TERM_OF_MONTH_FIRST: 1,
    TERM_OF_MONTH_SECOND: 2,
    TERM_OF_MONTH_THIRD: 3
};
/**
 * @enum {number}
 */
proto.WorldFilter = {
    WORLD_FILTER_ALL: 0,
    WORLD_FILTER_RADIO_MODE: 1,
    WORLD_FILTER_V_TUVER: 2,
    WORLD_FILTER_RADIO_AND_V: 3,
    WORLD_FILTER_CAMERA_MODE: 4
};
/**
 * @enum {number}
 */
proto.RankingEventStatus = {
    RANKING_EVENT_STATUS_NONE: 0,
    RANKING_EVENT_STATUS_ONGOING: 1,
    RANKING_EVENT_STATUS_CALCULATING: 2,
    RANKING_EVENT_STATUS_CONFIRMED: 3
};
/**
 * @enum {number}
 */
proto.LiveBackgroundType = {
    LIVE_BACKGROUND_TYPE_NONE: 0,
    LIVE_BACKGROUND_TYPE_SIMPLE: 1,
    LIVE_BACKGROUND_TYPE_CUSTOM: 2
};
/**
 * @enum {number}
 */
proto.LiveMode = {
    LIVE_MODE_TYPE_NONE: 0,
    LIVE_MODE_TYPE_DEFAULT_VIDEO: 1,
    LIVE_MODE_TYPE_RADIO: 2,
    LIVE_MODE_TYPE_VTUBER: 3
};
goog.object.extend(exports, proto);
