"use strict";
// source: master/version.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.jp.singcolor.master.MasterVersion', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.master.MasterVersion = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.master.MasterVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.master.MasterVersion.displayName = 'proto.jp.singcolor.master.MasterVersion';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.master.MasterVersion.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.master.MasterVersion.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.master.MasterVersion} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.master.MasterVersion.toObject = function (includeInstance, msg) {
        var f, obj = {
            masterVersion: jspb.Message.getFieldWithDefault(msg, 1, ""),
            description: jspb.Message.getFieldWithDefault(msg, 2, ""),
            enable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.master.MasterVersion}
 */
proto.jp.singcolor.master.MasterVersion.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.master.MasterVersion;
    return proto.jp.singcolor.master.MasterVersion.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.master.MasterVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.master.MasterVersion}
 */
proto.jp.singcolor.master.MasterVersion.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setMasterVersion(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setDescription(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setEnable(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.master.MasterVersion.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.master.MasterVersion.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.master.MasterVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.master.MasterVersion.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMasterVersion();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getEnable();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string master_version = 1;
 * @return {string}
 */
proto.jp.singcolor.master.MasterVersion.prototype.getMasterVersion = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.MasterVersion} returns this
 */
proto.jp.singcolor.master.MasterVersion.prototype.setMasterVersion = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string description = 2;
 * @return {string}
 */
proto.jp.singcolor.master.MasterVersion.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.MasterVersion} returns this
 */
proto.jp.singcolor.master.MasterVersion.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool enable = 3;
 * @return {boolean}
 */
proto.jp.singcolor.master.MasterVersion.prototype.getEnable = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.MasterVersion} returns this
 */
proto.jp.singcolor.master.MasterVersion.prototype.setEnable = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
goog.object.extend(exports, proto.jp.singcolor.master);
