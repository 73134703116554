var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DebugClient } from '../singcolor-proto/ts/DebugServiceClientPb';
import { CreateTokenRequest, GetListenerLiveActivitiesRequest, GetLiverLiveActivitiesRequest, UpdateLeagueRequest, ListUsersRequest, SaveServerRequestedTimeRequest, GetServerRequestedTimeRequest, GetForceSuperLikeBonusCoinSettingRequest, SaveForceSuperLikeBonusCoinSettingRequest, CreateUsersRequest, AddFavePointsSourceRequest, GetDiamondAcquisitionRightRequest, UpdateDiamondAcquisitionRightRequest, GetUserDeviceRequest, UpdateUserDeviceRequest, CreateMultiLivesRequest, GetLatestUserDiamondDistributedDayRequest, UpdateLatestUserDiamondDistributedDayRequest, RunDistributeDiamondCommandRequest, UpdateLeaguePromotionBorderAtClosingTimeRequest, DeleteLiverReceivedCoinHistoryRequest, GetDiamondFundRequest, OverwriteDiamondFundRequest, DeleteDiamondFundOverwriteRequest, ListSingHistoriesRequest, UpdateSingHistoryRequest, DeleteSingHistoryRequest, GetUsedSingDiamondAcquisitionRightRequest, UpdateUsedSingDiamondAcquisitionRightRequest, AddCoinRequest, GetCoinBalanceRequest, GetCoinPurchaseFailureModeRequest, UpdateCoinPurchaseFailureModeRequest, AddFaveBonusSourceRequest, GetDiamondBalanceRequest, GetDiamondBalanceInOutHistoriesRequest, GetTimelineDummyDataSettingRequest, UpdateTimelineDummyDataSettingRequest, DeleteServerRequestedTimeRequest, DebugGetLiverPromotionMeterV1Request, DeleteUserBanHistoriesRequest, GetIgnoreLiveAvailableTimeRequest, UpdateIgnoreLiveAvailableTimeRequest, AddDiamondRequest, RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest, SaveForceUseCSSRequest, RunSubtractDiamondCommandRequest, DistributePearlByPearlCupResultV1Request, GetForceCapturePossibilityRequest, UpdateForceCapturePossibilityRequest, CreateOnePhraseEventEntriesRequest } from '../singcolor-proto/ts/debug_pb';
import { SingHistory } from '../singcolor-proto/ts/debug_entity_pb';
var DebugGrpcClient = /** @class */ (function () {
    function DebugGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new DebugClient(process.env.NEXT_PUBLIC_API_URL, null, null);
    }
    DebugGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    DebugGrpcClient.prototype.createCustomToken = function (_a) {
        var userId = _a.userId, loginType = _a.loginType;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateTokenRequest();
                request.setUserId(userId);
                request.setLoginType(loginType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createToken(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.listUsers = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListUsersRequest();
                request.setPage(page);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listUsers(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getLiverLiveActivities = function (_a) {
        var liverUserId = _a.liverUserId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetLiverLiveActivitiesRequest();
                request.setLiverUserId(liverUserId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getLiverLiveActivities(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getListenerLiveActivities = function (_a) {
        var listenerUserId = _a.listenerUserId, liverUserId = _a.liverUserId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetListenerLiveActivitiesRequest();
                request.setLiverUserId(liverUserId);
                request.setListenerUserId(listenerUserId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getListenerLiveActivities(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateLeague = function (_a) {
        var liverUserId = _a.liverUserId, leagueId = _a.leagueId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateLeagueRequest();
                request.setLiverUserId(liverUserId);
                request.setLeagueId(leagueId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateLeague(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.saveServerRequestedTime = function (_a) {
        var requestedTime = _a.requestedTime;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new SaveServerRequestedTimeRequest();
                request.setRequestedTime(requestedTime);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.saveServerRequestedTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateIgnoreLiveAvailableTime = function (_a) {
        var ignoreLiveAvailableTime = _a.ignoreLiveAvailableTime;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateIgnoreLiveAvailableTimeRequest();
                request.setIgnoreLiveAvailableTime(ignoreLiveAvailableTime);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateIgnoreLiveAvailableTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.deleteServerRequestedTime = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new DeleteServerRequestedTimeRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteServerRequestedTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getServerRequestedTime = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetServerRequestedTimeRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getServerRequestedTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getIgnoreLiveAvailableTime = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetIgnoreLiveAvailableTimeRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getIgnoreLiveAvailableTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getForceSuperLikeBonusCoinSetting = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetForceSuperLikeBonusCoinSettingRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getForceSuperLikeBonusCoinSetting(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.saveForceSuperLikeBonusCoinSetting = function (_a) {
        var forceSuperLikeBonusCoinSetting = _a.forceSuperLikeBonusCoinSetting;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new SaveForceSuperLikeBonusCoinSettingRequest();
                request.setForceSuperLikeBonusCoinSetting(forceSuperLikeBonusCoinSetting);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.saveForceSuperLikeBonusCoinSetting(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.createUsers = function (_a) {
        var createUserType = _a.createUserType, createNum = _a.createNum;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateUsersRequest();
                request.setCreateUserType(createUserType);
                request.setCreateNum(createNum);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createUsers(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getUserDevices = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUserDeviceRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserDevice(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateUserDevices = function (_a) {
        var userId = _a.userId, deviceId = _a.deviceId, permitMultipleUser = _a.permitMultipleUser;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateUserDeviceRequest();
                request.setUserId(userId);
                request.setDeviceId(deviceId);
                request.setPermitMultipleUser(permitMultipleUser);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateUserDevice(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.addFavePointsSource = function (_a) {
        var listenerUserId = _a.listenerUserId, liverUserId = _a.liverUserId, giftId = _a.giftId, usedCoin = _a.usedCoin, viewingDurationSeconds = _a.viewingDurationSeconds, superLikeCount = _a.superLikeCount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new AddFavePointsSourceRequest();
                request.setListenerUserId(listenerUserId);
                request.setLiverUserId(liverUserId);
                request.setGiftId(giftId);
                request.setUsedCoin(usedCoin);
                request.setViewingTimeDurationSeconds(viewingDurationSeconds);
                request.setSuperLikeCount(superLikeCount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.addFavePointsSource(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.addFaveBonusSource = function (_a) {
        var liverUserId = _a.liverUserId, monthlyFavePoints = _a.monthlyFavePoints, monthlySongFaveCount = _a.monthlySongFaveCount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new AddFaveBonusSourceRequest();
                request.setLiverUserId(liverUserId);
                request.setMonthlyFavePoints(monthlyFavePoints);
                request.setMonthlySongFaveCount(monthlySongFaveCount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.addFaveBonusSource(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getDiamondAcquisitionRight = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetDiamondAcquisitionRightRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getDiamondAcquisitionRight(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateDiamondAcquisitionRightRequest = function (_a) {
        var userId = _a.userId, singAmount = _a.singAmount, chatAmount = _a.chatAmount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateDiamondAcquisitionRightRequest();
                request.setUserId(userId);
                request.setSingAmount(singAmount);
                request.setChatAmount(chatAmount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateDiamondAcquisitionRight(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.createMultiLives = function (_a) {
        var liverUserId = _a.liverUserId, beginAt = _a.beginAt;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateMultiLivesRequest();
                request.setLiverUserId(liverUserId);
                request.setBeginAt(Math.floor(beginAt.getTime() / 1000));
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createMultiLives(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getLatestUserDiamondDistributedDay = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetLatestUserDiamondDistributedDayRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getLatestUserDiamondDistributedDay(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateLatestUserDiamondDistributedDay = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, dayOfTerm = _a.dayOfTerm;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateLatestUserDiamondDistributedDayRequest();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setDayOfTerm(dayOfTerm);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateLatestUserDiamondDistributedDay(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.runDistributeDiamondCommand = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, dayOfTerm = _a.dayOfTerm;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RunDistributeDiamondCommandRequest();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setDayOfTerm(dayOfTerm);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.runDistributeDiamondCommand(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.recalculateLiverLeague = function (_a) {
        var liverUserId = _a.liverUserId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateLeagueRequest();
                request.setLiverUserId(liverUserId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.recalculateLiverLeague(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateLeaguePromotionBorderAtClosingTime = function (_a) {
        var year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, dayOfTerm = _a.dayOfTerm, leagueId = _a.leagueId, closingTimeJst = _a.closingTimeJst, borderLiveScore1 = _a.borderLiveScore1, borderLiveScore2 = _a.borderLiveScore2, borderLiveScore3 = _a.borderLiveScore3;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateLeaguePromotionBorderAtClosingTimeRequest();
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setDayOfTerm(dayOfTerm);
                request.setLeagueId(leagueId);
                request.setClosingTimeJst(closingTimeJst);
                request.setBorderLiveScore1(borderLiveScore1);
                request.setBorderLiveScore2(borderLiveScore2);
                request.setBorderLiveScore3(borderLiveScore3);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateLeaguePromotionBorderAtClosingTime(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.deleteLiverReceivedCoinHistory = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, dayOfTerm = _a.dayOfTerm;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteLiverReceivedCoinHistoryRequest();
                request.setLiverUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setDayOfTerm(dayOfTerm);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteLiverReceivedCoinHistory(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getDiamondFund = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, dayOfTerm = _a.dayOfTerm;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetDiamondFundRequest();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setDayOfTerm(dayOfTerm);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getDiamondFund(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.overwriteDiamondFund = function (_a) {
        var userId = _a.userId, dayAmount = _a.dayAmount, accumulationAmount = _a.accumulationAmount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new OverwriteDiamondFundRequest();
                request.setUserId(userId);
                request.setDayAmount(dayAmount);
                request.setAccumulationAmount(accumulationAmount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.overwriteDiamondFund(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.deleteDiamondFundOverwrite = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteDiamondFundOverwriteRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteDiamondFundOverwrite(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.listSingHistories = function (_a) {
        var userId = _a.userId, pageToken = _a.pageToken, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListSingHistoriesRequest();
                request.setUserId(userId);
                request.setPageToken(pageToken);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listSingHistories(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateSingHistory = function (_a) {
        var userId = _a.userId, songId = _a.songId, userSingHistoryId = _a.userSingHistoryId, liveId = _a.liveId, finishedAt = _a.finishedAt, createdAt = _a.createdAt;
        return __awaiter(this, void 0, void 0, function () {
            var request, singHistory;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateSingHistoryRequest();
                singHistory = new SingHistory();
                singHistory.setUserId(userId);
                singHistory.setSongId(songId);
                singHistory.setUserSingHistoryId(userSingHistoryId);
                singHistory.setLiveId(liveId);
                singHistory.setFinishedAt(finishedAt);
                singHistory.setCreatedAt(createdAt);
                request.setSingHistory(singHistory);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateSingHistory(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.deleteSingHistory = function (_a) {
        var userId = _a.userId, songId = _a.songId, userSingHistoryId = _a.userSingHistoryId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteSingHistoryRequest();
                request.setUserId(userId);
                request.setSongId(songId);
                request.setUserSingHistoryId(userSingHistoryId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteSingHistory(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getUsedSingDiamondAcquisitionRight = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUsedSingDiamondAcquisitionRightRequest();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUsedSingDiamondAcquisitionRight(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateUsedSingDiamondAcquisitionRight = function (_a) {
        var userId = _a.userId, year = _a.year, month = _a.month, termOfMonth = _a.termOfMonth, usedSingDiamondAcquisitionRight = _a.usedSingDiamondAcquisitionRight;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateUsedSingDiamondAcquisitionRightRequest();
                request.setUserId(userId);
                request.setYear(year);
                request.setMonth(month);
                request.setTermOfMonth(termOfMonth);
                request.setUsedSingDiamondAcquisitionRight(usedSingDiamondAcquisitionRight);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateUsedSingDiamondAcquisitionRight(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getCoinBalance = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetCoinBalanceRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getCoinBalance(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.addCoin = function (_a) {
        var userId = _a.userId, amount = _a.amount, liverId = _a.liverId, type = _a.type;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new AddCoinRequest();
                request.setUserId(userId);
                request.setCoinAmount(amount);
                request.setLiverId(liverId);
                request.setType(type);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.addCoin(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getCoinPurchaseFailureMode = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetCoinPurchaseFailureModeRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getCoinPurchaseFailureMode(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateCoinPurchaseFailureMode = function (_a) {
        var userId = _a.userId, failureMode = _a.failureMode;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateCoinPurchaseFailureModeRequest();
                request.setUserId(userId);
                request.setMode(failureMode);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateCoinPurchaseFailureMode(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getDiamondBalance = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetDiamondBalanceRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getDiamondBalance(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.addDiamond = function (_a) {
        var userId = _a.userId, amount = _a.amount, diamondType = _a.diamondType;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new AddDiamondRequest();
                request.setUserId(userId);
                request.setAmount(amount);
                request.setDiamondType(diamondType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.addDiamond(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getDiamondBalanceInOutHistories = function (_a) {
        var userId = _a.userId, pageToken = _a.pageToken, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetDiamondBalanceInOutHistoriesRequest();
                request.setUserId(userId);
                request.setPageToken(pageToken);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getDiamondBalanceInOutHistories(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getTimelineDummyDataSetting = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetTimelineDummyDataSettingRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getTimelineDummyDataSetting(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateTimelineDummyDataSetting = function (_a) {
        var fillToCount = _a.fillToCount;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateTimelineDummyDataSettingRequest();
                request.setFillToCount(fillToCount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateTimelineDummyDataSetting(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getLiverPromotinMeter = function (_a) {
        var liverId = _a.liverId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DebugGetLiverPromotionMeterV1Request();
                request.setLiverId(liverId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.debugGetLiverPromotionMeter(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.deleteUserBanHistories = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteUserBanHistoriesRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteUserBanHistories(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.runUpdateWithdrawalDiamondLedgersLast2Weeks = function (_a) {
        var mockType = _a.mockType;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RunUpdateWithdrawalDiamondLedgersLast2WeeksRequest();
                if (mockType)
                    request.setGmoAozoraMockType(mockType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.runUpdateWithdrawalDiamondLedgersLast2Weeks(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.saveForceUseCSS = function (_a) {
        var forceUseCSS = _a.forceUseCSS;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new SaveForceUseCSSRequest();
                request.setForceUseCss(forceUseCSS);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.saveForceUseCSS(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.runSubtractDiamondCommand = function (_a) {
        var organizationId = _a.organizationId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RunSubtractDiamondCommandRequest();
                request.setOrganizationId(organizationId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.runSubtractDiamondCommand(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.distributePearlByPearlCupResult = function (_a) {
        var pearlCupId = _a.pearlCupId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DistributePearlByPearlCupResultV1Request();
                request.setPearlCupId(pearlCupId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.distributePearlByPearlCupResult(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.getForceCapturePossibility = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new GetForceCapturePossibilityRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getForceCapturePossibility(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.updateForceCapturePossibility = function (_a) {
        var forceCapturePossibility = _a.forceCapturePossibility;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateForceCapturePossibilityRequest();
                request.setForceCapturePossibility(forceCapturePossibility);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateForceCapturePossibility(request, metadata);
                    })];
            });
        });
    };
    DebugGrpcClient.prototype.createOnePhraseEventEntries = function (_a) {
        var num = _a.num;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateOnePhraseEventEntriesRequest();
                request.setNum(num);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createOnePhraseEventEntries(request, metadata);
                    })];
            });
        });
    };
    return DebugGrpcClient;
}());
export { DebugGrpcClient };
