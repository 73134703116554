/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as diamonds_v1_pb from './diamonds_v1_pb';
var DiamondsV1Client = /** @class */ (function () {
    function DiamondsV1Client(hostname, credentials, options) {
        this.methodDescriptorExchangeDiamondStatus = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ExchangeDiamondStatus', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ExchangeDiamondStatusV1Request, diamonds_v1_pb.ExchangeDiamondStatusV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ExchangeDiamondStatusV1Response.deserializeBinary);
        this.methodDescriptorGetEstimatedExchangeRate = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/GetEstimatedExchangeRate', grpcWeb.MethodType.UNARY, diamonds_v1_pb.GetEstimatedExchangeRateV1Request, diamonds_v1_pb.GetEstimatedExchangeRateV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.GetEstimatedExchangeRateV1Response.deserializeBinary);
        this.methodDescriptorGetWithdrawDiamondStatus = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/GetWithdrawDiamondStatus', grpcWeb.MethodType.UNARY, diamonds_v1_pb.GetWithdrawDiamondStatusV1Request, diamonds_v1_pb.GetWithdrawDiamondStatusV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.GetWithdrawDiamondStatusV1Response.deserializeBinary);
        this.methodDescriptorWithdrawDiamond = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/WithdrawDiamond', grpcWeb.MethodType.UNARY, diamonds_v1_pb.WithdrawDiamondV1Request, diamonds_v1_pb.WithdrawDiamondV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.WithdrawDiamondV1Response.deserializeBinary);
        this.methodDescriptorGetWithdrawalDiamondLedger = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/GetWithdrawalDiamondLedger', grpcWeb.MethodType.UNARY, diamonds_v1_pb.GetWithdrawalDiamondLedgerV1Request, diamonds_v1_pb.GetWithdrawalDiamondLedgerV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.GetWithdrawalDiamondLedgerV1Response.deserializeBinary);
        this.methodDescriptorListWithdrawalDiamondLedgers = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListWithdrawalDiamondLedgers', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListWithdrawalDiamondLedgersV1Request, diamonds_v1_pb.ListWithdrawalDiamondLedgersV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListWithdrawalDiamondLedgersV1Response.deserializeBinary);
        this.methodDescriptorExchangeDiamondToCoin = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ExchangeDiamondToCoin', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ExchangeDiamondToCoinV1Request, diamonds_v1_pb.ExchangeDiamondToCoinV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ExchangeDiamondToCoinV1Response.deserializeBinary);
        this.methodDescriptorStatements = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/Statements', grpcWeb.MethodType.UNARY, diamonds_v1_pb.StatementsV1Request, diamonds_v1_pb.StatementsV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.StatementsV1Response.deserializeBinary);
        this.methodDescriptorListMajorBanks = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListMajorBanks', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListMajorBanksV1Request, diamonds_v1_pb.ListMajorBanksV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListMajorBanksV1Response.deserializeBinary);
        this.methodDescriptorGetBank = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/GetBank', grpcWeb.MethodType.UNARY, diamonds_v1_pb.GetBankV1Request, diamonds_v1_pb.GetBankV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.GetBankV1Response.deserializeBinary);
        this.methodDescriptorListBanks = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListBanks', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListBanksV1Request, diamonds_v1_pb.ListBanksV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListBanksV1Response.deserializeBinary);
        this.methodDescriptorListBankBranches = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListBankBranches', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListBankBranchesV1Request, diamonds_v1_pb.ListBankBranchesV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListBankBranchesV1Response.deserializeBinary);
        this.methodDescriptorCreateBankAccount = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/CreateBankAccount', grpcWeb.MethodType.UNARY, diamonds_v1_pb.CreateBankAccountV1Request, diamonds_v1_pb.CreateBankAccountV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.CreateBankAccountV1Response.deserializeBinary);
        this.methodDescriptorListBankAccounts = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListBankAccounts', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListBankAccountsV1Request, diamonds_v1_pb.ListBankAccountsV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListBankAccountsV1Response.deserializeBinary);
        this.methodDescriptorDeleteBankAccount = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/DeleteBankAccount', grpcWeb.MethodType.UNARY, diamonds_v1_pb.DeleteBankAccountV1Request, diamonds_v1_pb.DeleteBankAccountV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.DeleteBankAccountV1Response.deserializeBinary);
        this.methodDescriptorListStatements = new grpcWeb.MethodDescriptor('/jp.singcolor.DiamondsV1/ListStatements', grpcWeb.MethodType.UNARY, diamonds_v1_pb.ListStatementsV1Request, diamonds_v1_pb.ListStatementsV1Response, function (request) {
            return request.serializeBinary();
        }, diamonds_v1_pb.ListStatementsV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    DiamondsV1Client.prototype.exchangeDiamondStatus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ExchangeDiamondStatus', request, metadata || {}, this.methodDescriptorExchangeDiamondStatus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ExchangeDiamondStatus', request, metadata || {}, this.methodDescriptorExchangeDiamondStatus);
    };
    DiamondsV1Client.prototype.getEstimatedExchangeRate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/GetEstimatedExchangeRate', request, metadata || {}, this.methodDescriptorGetEstimatedExchangeRate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/GetEstimatedExchangeRate', request, metadata || {}, this.methodDescriptorGetEstimatedExchangeRate);
    };
    DiamondsV1Client.prototype.getWithdrawDiamondStatus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/GetWithdrawDiamondStatus', request, metadata || {}, this.methodDescriptorGetWithdrawDiamondStatus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/GetWithdrawDiamondStatus', request, metadata || {}, this.methodDescriptorGetWithdrawDiamondStatus);
    };
    DiamondsV1Client.prototype.withdrawDiamond = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/WithdrawDiamond', request, metadata || {}, this.methodDescriptorWithdrawDiamond, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/WithdrawDiamond', request, metadata || {}, this.methodDescriptorWithdrawDiamond);
    };
    DiamondsV1Client.prototype.getWithdrawalDiamondLedger = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/GetWithdrawalDiamondLedger', request, metadata || {}, this.methodDescriptorGetWithdrawalDiamondLedger, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/GetWithdrawalDiamondLedger', request, metadata || {}, this.methodDescriptorGetWithdrawalDiamondLedger);
    };
    DiamondsV1Client.prototype.listWithdrawalDiamondLedgers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorListWithdrawalDiamondLedgers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListWithdrawalDiamondLedgers', request, metadata || {}, this.methodDescriptorListWithdrawalDiamondLedgers);
    };
    DiamondsV1Client.prototype.exchangeDiamondToCoin = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ExchangeDiamondToCoin', request, metadata || {}, this.methodDescriptorExchangeDiamondToCoin, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ExchangeDiamondToCoin', request, metadata || {}, this.methodDescriptorExchangeDiamondToCoin);
    };
    DiamondsV1Client.prototype.statements = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/Statements', request, metadata || {}, this.methodDescriptorStatements, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/Statements', request, metadata || {}, this.methodDescriptorStatements);
    };
    DiamondsV1Client.prototype.listMajorBanks = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListMajorBanks', request, metadata || {}, this.methodDescriptorListMajorBanks, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListMajorBanks', request, metadata || {}, this.methodDescriptorListMajorBanks);
    };
    DiamondsV1Client.prototype.getBank = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/GetBank', request, metadata || {}, this.methodDescriptorGetBank, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/GetBank', request, metadata || {}, this.methodDescriptorGetBank);
    };
    DiamondsV1Client.prototype.listBanks = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListBanks', request, metadata || {}, this.methodDescriptorListBanks, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListBanks', request, metadata || {}, this.methodDescriptorListBanks);
    };
    DiamondsV1Client.prototype.listBankBranches = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListBankBranches', request, metadata || {}, this.methodDescriptorListBankBranches, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListBankBranches', request, metadata || {}, this.methodDescriptorListBankBranches);
    };
    DiamondsV1Client.prototype.createBankAccount = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/CreateBankAccount', request, metadata || {}, this.methodDescriptorCreateBankAccount, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/CreateBankAccount', request, metadata || {}, this.methodDescriptorCreateBankAccount);
    };
    DiamondsV1Client.prototype.listBankAccounts = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListBankAccounts', request, metadata || {}, this.methodDescriptorListBankAccounts, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListBankAccounts', request, metadata || {}, this.methodDescriptorListBankAccounts);
    };
    DiamondsV1Client.prototype.deleteBankAccount = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/DeleteBankAccount', request, metadata || {}, this.methodDescriptorDeleteBankAccount, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/DeleteBankAccount', request, metadata || {}, this.methodDescriptorDeleteBankAccount);
    };
    DiamondsV1Client.prototype.listStatements = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.DiamondsV1/ListStatements', request, metadata || {}, this.methodDescriptorListStatements, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.DiamondsV1/ListStatements', request, metadata || {}, this.methodDescriptorListStatements);
    };
    return DiamondsV1Client;
}());
export { DiamondsV1Client };
