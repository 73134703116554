var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { OrganizationV1Client } from '../singcolor-proto/ts/Organization_v1ServiceClientPb';
import { ApproveOrganizationLiverApplicationV1Request, ApproveOrganizationStaffApplicationV1Request, CreateOrganizationLiverApplicationV1Request, CreateOrganizationStaffApplicationV1Request, CreateOrganizationV1Request, DeleteOrganizationLiverApplicationV1Request, GetOrganizationV1Request, ListOrganizationLiverApplicationsV1Request, ListOrganizationLiversV1Request, ListOrganizationStaffApplicationsV1Request, ListOrganizationStaffsV1Request, ListSupportPlansV1Request, ListSupportPlanTagsV1Request, ListRedashDashboardsV1Request, MyOrganizationApplicationInfoV1Request, RejectOrganizationLiverApplicationV1Request, RejectOrganizationStaffApplicationV1Request, SearchOrganizationV1Request, UpdateOrganizationV1Request, CreateSupportPlanV1Request, UpdateSupportPlanV1Request, DeleteOrganizationStaffV1Request, GetOrganizationStaffV1Request, UpdateOrganizationStaffRoleV1Request, GetOrganizationLiverV1Request, DeleteOrganizationLiverV1Request, DeleteOrganizationStaffApplicationV1Request, ListRedashExportHistoriesByQueryTypeV1Request, ListRedashExportQueryTypesV1Request, GenerateRedashExportPresignedUrlV1Request, ListOrganizationStaffRoleUpdateHistoriesV1Request, ListOrganizationUpdateHistoriesV1Request, ListOrganizationLiverLeaveHistoriesV1Request, ListSupportPlanUpdateHistoriesV1Request, ListSupportPlanCreationHistoriesV1Request } from '../singcolor-proto/ts/organization_v1_pb';
import { SupportPlanCashOutMethod } from '../singcolor-proto/ts/organization_entity_pb';
import { SimplePaginationRequest } from '../singcolor-proto/ts/page_entity_pb';
var OrganizationGrpcClient = /** @class */ (function () {
    function OrganizationGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new OrganizationV1Client(process.env.NEXT_PUBLIC_API_URL, null, null);
    }
    OrganizationGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    OrganizationGrpcClient.prototype.myOrganizationApplicationInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new MyOrganizationApplicationInfoV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.myOrganizationApplicationInfo(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listSupportPlanTags = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListSupportPlanTagsV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listSupportPlanTags(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listSupportPlans = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListSupportPlansV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listSupportPlans(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.createOrganization = function (_a) {
        var supportPlanParams = _a.supportPlanParams, params = __rest(_a, ["supportPlanParams"]);
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateOrganizationV1Request();
                request.setName(params.name);
                request.setCode(params.code);
                if (params.description)
                    request.setDescription(params.description);
                if (params.url)
                    request.setUrl(params.url);
                if (params.twitterScreenName)
                    request.setTwitterScreenName(params.twitterScreenName);
                if (params.contactUrl)
                    request.setContactUrl(params.contactUrl);
                if (params.contactButtonTitle)
                    request.setContactButtonTitle(params.contactButtonTitle);
                request.setSupportPlanName(supportPlanParams.name);
                request.setSupportPlanCashOutMethod(supportPlanParams.cashOutMethod);
                if (supportPlanParams.description)
                    request.setSupportPlanDescription(supportPlanParams.description);
                request.setSupportPlanOrganizerCommissionRate10000x(supportPlanParams.organizerCommissionRate10000x);
                request.setSupportPlanTagIdsList(supportPlanParams.supportPlanTagIds);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createOrganization(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.searchOrganizations = function (_a) {
        var keyword = _a.keyword;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new SearchOrganizationV1Request();
                request.setKeyword(keyword);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.searchOrganization(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.getOrganization = function (_a) {
        var id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetOrganizationV1Request();
                if (id)
                    request.setOrganizationId(id);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getOrganization(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.updateOrganization = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new UpdateOrganizationV1Request();
                request.setOrganizationId(params.organizationId);
                request.setCode(params.code);
                request.setName(params.name);
                request.setUrl(params.url);
                request.setTwitterScreenName(params.twitterScreenName);
                request.setDescription(params.description);
                if (params.thumbnail)
                    request.setThumbnail(params.thumbnail);
                if (params.contactUrl)
                    request.setContactUrl(params.contactUrl);
                if (params.contactButtonTitle)
                    request.setContactButtonTitle(params.contactButtonTitle);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateOrganization(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.createOrganizationStaffApplication = function (_a) {
        var id = _a.id;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateOrganizationStaffApplicationV1Request();
                request.setOrganizationId(id);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createOrganizationStaffApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationStaffs = function (_a) {
        var id = _a.id, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationStaffsV1Request();
                request.setOrganizationId(id);
                request.setPage(page);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationStaffs(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.approveOrganizationStaffApplication = function (_a) {
        var applicationId = _a.applicationId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ApproveOrganizationStaffApplicationV1Request();
                request.setOrganizationStaffApplicationId(applicationId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.approveOrganizationStaffApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.rejectOrganizationStaffApplication = function (_a) {
        var applicationId = _a.applicationId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RejectOrganizationStaffApplicationV1Request();
                request.setOrganizationStaffApplicationId(applicationId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.rejectOrganizationStaffApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.createOrganizationLiverApplication = function (_a) {
        var organizationId = _a.organizationId, supportPlanId = _a.supportPlanId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateOrganizationLiverApplicationV1Request();
                request.setOrganizationId(organizationId);
                request.setSupportPlanId(supportPlanId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createOrganizationLiverApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.deleteOrganizationLiverApplication = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new DeleteOrganizationLiverApplicationV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteOrganizationLiverApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.approveOrganizationLiverApplication = function (_a) {
        var applicationId = _a.applicationId, supportPlanId = _a.supportPlanId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ApproveOrganizationLiverApplicationV1Request();
                request.setOrganizationLiverApplicationId(applicationId);
                request.setSupportPlanId(supportPlanId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.approveOrganizationLiverApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.rejectOrganizationLiverApplication = function (_a) {
        var applicationId = _a.applicationId, supportPlanId = _a.supportPlanId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new RejectOrganizationLiverApplicationV1Request();
                request.setOrganizationLiverApplicationId(applicationId);
                request.setSupportPlanId(supportPlanId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.rejectOrganizationLiverApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationLivers = function (_a) {
        var id = _a.id, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationLiversV1Request();
                request.setOrganizationId(id);
                request.setPage(page);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationLivers(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationLiverApplications = function (_a) {
        var id = _a.id, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationLiverApplicationsV1Request();
                request.setOrganizationId(id);
                request.setPage(page);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationLiverApplications(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationStaffApplications = function (_a) {
        var id = _a.id, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationStaffApplicationsV1Request();
                request.setOrganizationId(id);
                request.setPage(page);
                request.setLimit(limit);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationStaffApplications(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listRedashDashboards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListRedashDashboardsV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listRedashDashboards(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.CreateSupportPlan = function (_a) {
        var name = _a.name, description = _a.description, tagIds = _a.tagIds, cashOutMethod = _a.cashOutMethod, organizerCommissionRate = _a.organizerCommissionRate;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CreateSupportPlanV1Request();
                request.setName(name);
                request.setCashOutMethod(cashOutMethod);
                if (description) {
                    request.setDescription(description);
                }
                if (organizerCommissionRate) {
                    request.setOrganizerCommissionRate10000x(organizerCommissionRate);
                }
                if (tagIds) {
                    request.clearSupportPlanTagIdsList();
                    tagIds.forEach(function (id) {
                        request.addSupportPlanTagIds(id);
                    });
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.createSupportPlan(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.UpdateSupportPlan = function (_a) {
        var planId = _a.planId, name = _a.name, description = _a.description, tagIds = _a.tagIds, cashOutMethod = _a.cashOutMethod, organizerCommissionRate = _a.organizerCommissionRate, status = _a.status;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateSupportPlanV1Request();
                request.setSupportPlanId(planId);
                if (name) {
                    request.setName(name);
                }
                if (description) {
                    request.setDescription(description);
                }
                if (tagIds) {
                    request.clearSupportPlanTagIdsList();
                    tagIds.forEach(function (id) {
                        request.addSupportPlanTagIds(id);
                    });
                }
                if (cashOutMethod) {
                    request.setCashOutMethod(cashOutMethod);
                }
                if (organizerCommissionRate) {
                    request.setOrganizerCommissionRate10000x(organizerCommissionRate);
                }
                if (status) {
                    request.setStatus(status);
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateSupportPlan(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.getOrganizationStaff = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetOrganizationStaffV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getOrganizationStaff(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.deleteOrganizationStaff = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteOrganizationStaffV1Request();
                request.setTargetUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteOrganizationStaff(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.updateOrganizationStaffRole = function (_a) {
        var userId = _a.userId, role = _a.role;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateOrganizationStaffRoleV1Request();
                request.setUserId(userId);
                request.setRole(role);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateOrganizationStaffRole(request, metadata);
                    })];
            });
        });
    };
    // liver の取得
    OrganizationGrpcClient.prototype.getOrganizationLiver = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetOrganizationLiverV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getOrganizationLiver(request, metadata);
                    })];
            });
        });
    };
    // liver の削除
    OrganizationGrpcClient.prototype.deleteOrganizationLiver = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new DeleteOrganizationLiverV1Request();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteOrganizationLiver(request, metadata);
                    })];
            });
        });
    };
    // 申請の取り下げ
    OrganizationGrpcClient.prototype.deleteOrganizationStaffApplication = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new DeleteOrganizationStaffApplicationV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.deleteOrganizationStaffApplication(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.generateRedashExportPresignedUrl = function (_a) {
        var organizationRedashExportHistoryId = _a.organizationRedashExportHistoryId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GenerateRedashExportPresignedUrlV1Request();
                request.setOrganizationRedashExportHistoryId(organizationRedashExportHistoryId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.generateRedashExportPresignedUrl(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listRedashExportQueryTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListRedashExportQueryTypesV1Request();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listRedashExportQueryTypes(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listRedashExportHistoriesByQueryType = function (_a) {
        var queryType = _a.queryType, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListRedashExportHistoriesByQueryTypeV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                request.setQueryType(queryType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listRedashExportHistoriesByQueryType(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationStaffRoleUpdateHistories = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationStaffRoleUpdateHistoriesV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationStaffRoleUpdateHistories(request, metadata);
                    })
                        .then(function (item) {
                        console.log(item);
                        return item;
                    })
                        .catch(function (err) {
                        console.log(err);
                        return err;
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationUpdateHistories = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationUpdateHistoriesV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationUpdateHistories(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listOrganizationLiverLeaveHistories = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListOrganizationLiverLeaveHistoriesV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listOrganizationLiverLeaveHistories(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listSupportPlanUpdateHistories = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListSupportPlanUpdateHistoriesV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listSupportPlanUpdateHistories(request, metadata);
                    })];
            });
        });
    };
    OrganizationGrpcClient.prototype.listSupportPlanCreationHistories = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, pagenation;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListSupportPlanCreationHistoriesV1Request();
                pagenation = new SimplePaginationRequest();
                pagenation.setLimit(limit);
                pagenation.setPage(page);
                request.setPage(pagenation);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listSupportPlanCreationHistories(request, metadata);
                    })];
            });
        });
    };
    return OrganizationGrpcClient;
}());
export { OrganizationGrpcClient };
export { SupportPlanCashOutMethod };
