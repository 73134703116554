/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as karaoke_v1_pb from './karaoke_v1_pb';
var KaraokeV1Client = /** @class */ (function () {
    function KaraokeV1Client(hostname, credentials, options) {
        this.methodDescriptorListSingers = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListSingers', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListSingersV1Request, karaoke_v1_pb.ListSingersV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListSingersV1Response.deserializeBinary);
        this.methodDescriptorUpdateFavoriteSingers = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/UpdateFavoriteSingers', grpcWeb.MethodType.UNARY, karaoke_v1_pb.UpdateFavoriteSingersV1Request, karaoke_v1_pb.UpdateFavoriteSingersV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.UpdateFavoriteSingersV1Response.deserializeBinary);
        this.methodDescriptorListSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListSongsV1Request, karaoke_v1_pb.ListSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListSongsV1Response.deserializeBinary);
        this.methodDescriptorSearchSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/SearchSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.SearchSongsV1Request, karaoke_v1_pb.SearchSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.SearchSongsV1Response.deserializeBinary);
        this.methodDescriptorSearchSingers = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/SearchSingers', grpcWeb.MethodType.UNARY, karaoke_v1_pb.SearchSingersV1Request, karaoke_v1_pb.SearchSingersV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.SearchSingersV1Response.deserializeBinary);
        this.methodDescriptorListLatestPopularSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListLatestPopularSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListLatestPopularSongsV1Request, karaoke_v1_pb.ListLatestPopularSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListLatestPopularSongsV1Response.deserializeBinary);
        this.methodDescriptorListWorldPopularSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListWorldPopularSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListWorldPopularSongsV1Request, karaoke_v1_pb.ListWorldPopularSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListWorldPopularSongsV1Response.deserializeBinary);
        this.methodDescriptorListFavoriteSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListFavoriteSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListFavoriteSongsV1Request, karaoke_v1_pb.ListFavoriteSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListFavoriteSongsV1Response.deserializeBinary);
        this.methodDescriptorListSingingHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListSingingHistory', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListSingingHistoryV1Request, karaoke_v1_pb.ListSingingHistoryV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListSingingHistoryV1Response.deserializeBinary);
        this.methodDescriptorListRequestedHistory = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListRequestedHistory', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListRequestedHistoryV1Request, karaoke_v1_pb.ListRequestedHistoryV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListRequestedHistoryV1Response.deserializeBinary);
        this.methodDescriptorListListenerFavoriteSongs = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/ListListenerFavoriteSongs', grpcWeb.MethodType.UNARY, karaoke_v1_pb.ListListenerFavoriteSongsV1Request, karaoke_v1_pb.ListListenerFavoriteSongsV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.ListListenerFavoriteSongsV1Response.deserializeBinary);
        this.methodDescriptorGetSong = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/GetSong', grpcWeb.MethodType.UNARY, karaoke_v1_pb.GetSongV1Request, karaoke_v1_pb.GetSongV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.GetSongV1Response.deserializeBinary);
        this.methodDescriptorUpdateFavoriteSong = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/UpdateFavoriteSong', grpcWeb.MethodType.UNARY, karaoke_v1_pb.UpdateFavoriteSongV1Request, karaoke_v1_pb.UpdateFavoriteSongV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.UpdateFavoriteSongV1Response.deserializeBinary);
        this.methodDescriptorUpdateListenerFavoriteSong = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/UpdateListenerFavoriteSong', grpcWeb.MethodType.UNARY, karaoke_v1_pb.UpdateListenerFavoriteSongV1Request, karaoke_v1_pb.UpdateListenerFavoriteSongV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.UpdateListenerFavoriteSongV1Response.deserializeBinary);
        this.methodDescriptorGetSongByXingJoysoundWebInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/GetSongByXingJoysoundWebInfo', grpcWeb.MethodType.UNARY, karaoke_v1_pb.GetSongByXingJoysoundWebInfoV1Request, karaoke_v1_pb.GetSongByXingJoysoundWebInfoV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.GetSongByXingJoysoundWebInfoV1Response.deserializeBinary);
        this.methodDescriptorRequestToAddNewSong = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/RequestToAddNewSong', grpcWeb.MethodType.UNARY, karaoke_v1_pb.RequestToAddNewSongV1Request, karaoke_v1_pb.RequestToAddNewSongV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.RequestToAddNewSongV1Response.deserializeBinary);
        this.methodDescriptorGetSongRandomly = new grpcWeb.MethodDescriptor('/jp.singcolor.KaraokeV1/GetSongRandomly', grpcWeb.MethodType.UNARY, karaoke_v1_pb.GetSongRandomlyV1Request, karaoke_v1_pb.GetSongRandomlyV1Response, function (request) {
            return request.serializeBinary();
        }, karaoke_v1_pb.GetSongRandomlyV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    KaraokeV1Client.prototype.listSingers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListSingers', request, metadata || {}, this.methodDescriptorListSingers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListSingers', request, metadata || {}, this.methodDescriptorListSingers);
    };
    KaraokeV1Client.prototype.updateFavoriteSingers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/UpdateFavoriteSingers', request, metadata || {}, this.methodDescriptorUpdateFavoriteSingers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/UpdateFavoriteSingers', request, metadata || {}, this.methodDescriptorUpdateFavoriteSingers);
    };
    KaraokeV1Client.prototype.listSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListSongs', request, metadata || {}, this.methodDescriptorListSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListSongs', request, metadata || {}, this.methodDescriptorListSongs);
    };
    KaraokeV1Client.prototype.searchSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/SearchSongs', request, metadata || {}, this.methodDescriptorSearchSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/SearchSongs', request, metadata || {}, this.methodDescriptorSearchSongs);
    };
    KaraokeV1Client.prototype.searchSingers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/SearchSingers', request, metadata || {}, this.methodDescriptorSearchSingers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/SearchSingers', request, metadata || {}, this.methodDescriptorSearchSingers);
    };
    KaraokeV1Client.prototype.listLatestPopularSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListLatestPopularSongs', request, metadata || {}, this.methodDescriptorListLatestPopularSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListLatestPopularSongs', request, metadata || {}, this.methodDescriptorListLatestPopularSongs);
    };
    KaraokeV1Client.prototype.listWorldPopularSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListWorldPopularSongs', request, metadata || {}, this.methodDescriptorListWorldPopularSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListWorldPopularSongs', request, metadata || {}, this.methodDescriptorListWorldPopularSongs);
    };
    KaraokeV1Client.prototype.listFavoriteSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListFavoriteSongs', request, metadata || {}, this.methodDescriptorListFavoriteSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListFavoriteSongs', request, metadata || {}, this.methodDescriptorListFavoriteSongs);
    };
    KaraokeV1Client.prototype.listSingingHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListSingingHistory', request, metadata || {}, this.methodDescriptorListSingingHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListSingingHistory', request, metadata || {}, this.methodDescriptorListSingingHistory);
    };
    KaraokeV1Client.prototype.listRequestedHistory = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListRequestedHistory', request, metadata || {}, this.methodDescriptorListRequestedHistory, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListRequestedHistory', request, metadata || {}, this.methodDescriptorListRequestedHistory);
    };
    KaraokeV1Client.prototype.listListenerFavoriteSongs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/ListListenerFavoriteSongs', request, metadata || {}, this.methodDescriptorListListenerFavoriteSongs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/ListListenerFavoriteSongs', request, metadata || {}, this.methodDescriptorListListenerFavoriteSongs);
    };
    KaraokeV1Client.prototype.getSong = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/GetSong', request, metadata || {}, this.methodDescriptorGetSong, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/GetSong', request, metadata || {}, this.methodDescriptorGetSong);
    };
    KaraokeV1Client.prototype.updateFavoriteSong = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/UpdateFavoriteSong', request, metadata || {}, this.methodDescriptorUpdateFavoriteSong, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/UpdateFavoriteSong', request, metadata || {}, this.methodDescriptorUpdateFavoriteSong);
    };
    KaraokeV1Client.prototype.updateListenerFavoriteSong = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/UpdateListenerFavoriteSong', request, metadata || {}, this.methodDescriptorUpdateListenerFavoriteSong, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/UpdateListenerFavoriteSong', request, metadata || {}, this.methodDescriptorUpdateListenerFavoriteSong);
    };
    KaraokeV1Client.prototype.getSongByXingJoysoundWebInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/GetSongByXingJoysoundWebInfo', request, metadata || {}, this.methodDescriptorGetSongByXingJoysoundWebInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/GetSongByXingJoysoundWebInfo', request, metadata || {}, this.methodDescriptorGetSongByXingJoysoundWebInfo);
    };
    KaraokeV1Client.prototype.requestToAddNewSong = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/RequestToAddNewSong', request, metadata || {}, this.methodDescriptorRequestToAddNewSong, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/RequestToAddNewSong', request, metadata || {}, this.methodDescriptorRequestToAddNewSong);
    };
    KaraokeV1Client.prototype.getSongRandomly = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.KaraokeV1/GetSongRandomly', request, metadata || {}, this.methodDescriptorGetSongRandomly, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.KaraokeV1/GetSongRandomly', request, metadata || {}, this.methodDescriptorGetSongRandomly);
    };
    return KaraokeV1Client;
}());
export { KaraokeV1Client };
