/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_pearl_pb from './ope_pearl_pb';
var OpePearlV1Client = /** @class */ (function () {
    function OpePearlV1Client(hostname, credentials, options) {
        this.methodDescriptorGetPearlBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.OpePearlV1/GetPearlBalance', grpcWeb.MethodType.UNARY, ope_pearl_pb.GetPearlBalanceV1Request, ope_pearl_pb.GetPearlBalanceV1Response, function (request) {
            return request.serializeBinary();
        }, ope_pearl_pb.GetPearlBalanceV1Response.deserializeBinary);
        this.methodDescriptorAddPearl = new grpcWeb.MethodDescriptor('/jp.singcolor.OpePearlV1/AddPearl', grpcWeb.MethodType.UNARY, ope_pearl_pb.AddPearlV1Request, ope_pearl_pb.AddPearlV1Response, function (request) {
            return request.serializeBinary();
        }, ope_pearl_pb.AddPearlV1Response.deserializeBinary);
        this.methodDescriptorSpendPearl = new grpcWeb.MethodDescriptor('/jp.singcolor.OpePearlV1/SpendPearl', grpcWeb.MethodType.UNARY, ope_pearl_pb.SpendPearlV1Request, ope_pearl_pb.SpendPearlV1Response, function (request) {
            return request.serializeBinary();
        }, ope_pearl_pb.SpendPearlV1Response.deserializeBinary);
        this.methodDescriptorForceUpdatePearlBalance = new grpcWeb.MethodDescriptor('/jp.singcolor.OpePearlV1/ForceUpdatePearlBalance', grpcWeb.MethodType.UNARY, ope_pearl_pb.ForceUpdatePearlBalanceV1Request, ope_pearl_pb.ForceUpdatePearlBalanceV1Response, function (request) {
            return request.serializeBinary();
        }, ope_pearl_pb.ForceUpdatePearlBalanceV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpePearlV1Client.prototype.getPearlBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpePearlV1/GetPearlBalance', request, metadata || {}, this.methodDescriptorGetPearlBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpePearlV1/GetPearlBalance', request, metadata || {}, this.methodDescriptorGetPearlBalance);
    };
    OpePearlV1Client.prototype.addPearl = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpePearlV1/AddPearl', request, metadata || {}, this.methodDescriptorAddPearl, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpePearlV1/AddPearl', request, metadata || {}, this.methodDescriptorAddPearl);
    };
    OpePearlV1Client.prototype.spendPearl = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpePearlV1/SpendPearl', request, metadata || {}, this.methodDescriptorSpendPearl, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpePearlV1/SpendPearl', request, metadata || {}, this.methodDescriptorSpendPearl);
    };
    OpePearlV1Client.prototype.forceUpdatePearlBalance = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpePearlV1/ForceUpdatePearlBalance', request, metadata || {}, this.methodDescriptorForceUpdatePearlBalance, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpePearlV1/ForceUpdatePearlBalance', request, metadata || {}, this.methodDescriptorForceUpdatePearlBalance);
    };
    return OpePearlV1Client;
}());
export { OpePearlV1Client };
