var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ApproveWithdrawalDiamondLedgersRequest, ListAcceptedWithdrawalDiamondLedgersRequest, ListRiskeyesArticleLinksRequest, ListUserBankAccountCreditCheckStatusesRequest, ListUserBankAccountsRequest, UpdateUserBankAccountCreditCheckStatusRequest, GetUserBankAccountCreditCheckStatusRequest, OpeAddDiamondRequest, OpeSubtractDiamondRequest, OpeListStatementsRequest, ListBankTransferRequestsRequest, CancelBankTransferRequestRequest } from '../singcolor-proto/ts/ope_diamond_pb';
import { OpeDiamondV1Client } from '../singcolor-proto/ts/Ope_diamondServiceClientPb';
import { CursorPaginationRequest, SimplePaginationRequest } from '../singcolor-proto/ts/page_entity_pb';
var OpeDiamondsGrpcClient = /** @class */ (function () {
    function OpeDiamondsGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new OpeDiamondV1Client(process.env.NEXT_PUBLIC_OPE_API_URL, null, null);
    }
    OpeDiamondsGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    // ダイヤ換金申請一覧
    OpeDiamondsGrpcClient.prototype.listAcceptedWithdrawalDiamondLedgers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                request = new ListAcceptedWithdrawalDiamondLedgersRequest();
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listAcceptedWithdrawalDiamondLedgers(request, metadata);
                    })];
            });
        });
    };
    // 銀行振込依頼一覧を返却する
    // GMOの振込番号の降順で返却する
    OpeDiamondsGrpcClient.prototype.listBankTransferRequests = function (_a) {
        var page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, p;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListBankTransferRequestsRequest();
                if (page && limit) {
                    p = new SimplePaginationRequest();
                    p.setLimit(limit);
                    p.setPage(page);
                    request.setPage(p);
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listBankTransferRequests(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.cancelBankTransferRequest = function (_a) {
        var bankTransferRequestId = _a.bankTransferRequestId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CancelBankTransferRequestRequest();
                request.setBankTransferRequestId(bankTransferRequestId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.cancelBankTransferRequest(request, metadata);
                    })];
            });
        });
    };
    // ダイヤ換金申請の許諾
    OpeDiamondsGrpcClient.prototype.approveWithdrawalDiamondLedgers = function (_a) {
        var idList = _a.idList;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ApproveWithdrawalDiamondLedgersRequest();
                request.setWithdrawalDiamondTransactionIdsList(idList);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.approveWithdrawalDiamondLedgers(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.listRiskeyesArticleLinks = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListRiskeyesArticleLinksRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listRiskeyesArticleLinks(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.listUserBankAccountCreditCheckStatuses = function (_a) {
        var creditCheckStatus = _a.creditCheckStatus, page = _a.page, limit = _a.limit;
        return __awaiter(this, void 0, void 0, function () {
            var request, p;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListUserBankAccountCreditCheckStatusesRequest();
                request.setCreditCheckStatus(creditCheckStatus);
                if (page && limit) {
                    p = new SimplePaginationRequest();
                    p.setLimit(limit);
                    p.setPage(page);
                    request.setPage(p);
                }
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listUserBankAccountCreditCheckStatuses(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.listUserBankAccounts = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new ListUserBankAccountsRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listUserBankAccounts(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.updateUserBankAccountCreditCheckStatus = function (_a) {
        var userId = _a.userId, bankAccountCreditCheckStatus = _a.bankAccountCreditCheckStatus;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new UpdateUserBankAccountCreditCheckStatusRequest();
                request.setUserId(userId);
                request.setCreditCheckStatus(bankAccountCreditCheckStatus);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.updateUserBankAccountCreditCheckStatus(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.getUserBankAccountCreditCheckStatus = function (_a) {
        var userId = _a.userId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetUserBankAccountCreditCheckStatusRequest();
                request.setUserId(userId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getUserBankAccountCreditCheckStatus(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.addDiamond = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userId, amount, diamondType, request;
            var _this = this;
            return __generator(this, function (_a) {
                userId = params.userId, amount = params.amount, diamondType = params.diamondType;
                request = new OpeAddDiamondRequest();
                request.setUserId(userId);
                request.setAmount(amount);
                request.setDiamondType(diamondType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.addDiamond(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.substractDiamond = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userId, amount, diamondType, request;
            var _this = this;
            return __generator(this, function (_a) {
                userId = params.userId, amount = params.amount, diamondType = params.diamondType;
                request = new OpeSubtractDiamondRequest();
                request.setUserId(userId);
                request.setAmount(amount);
                request.setDiamondType(diamondType);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.subtractDiamond(request, metadata);
                    })];
            });
        });
    };
    OpeDiamondsGrpcClient.prototype.listStatements = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var userId, pageToken, limit, request, page;
            var _this = this;
            return __generator(this, function (_a) {
                userId = params.userId, pageToken = params.pageToken, limit = params.limit;
                request = new OpeListStatementsRequest();
                page = new CursorPaginationRequest();
                request.setUserId(userId);
                if (pageToken)
                    page.setPageToken(pageToken);
                page.setLimit(limit);
                request.setPage(page);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.listStatements(request, metadata);
                    })];
            });
        });
    };
    return OpeDiamondsGrpcClient;
}());
export { OpeDiamondsGrpcClient };
