"use strict";
// source: coins_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.CoinAmount', null, global);
goog.exportSymbol('proto.CoinBalance', null, global);
goog.exportSymbol('proto.CoinEarningRate', null, global);
goog.exportSymbol('proto.CoinEarningRate.Star', null, global);
goog.exportSymbol('proto.CoinPurchasePlan', null, global);
goog.exportSymbol('proto.MembershipType', null, global);
goog.exportSymbol('proto.SpecificLiverCoin', null, global);
goog.exportSymbol('proto.WebCoinPurchasePlan', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoinBalance = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoinBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CoinBalance.displayName = 'proto.CoinBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SpecificLiverCoin = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SpecificLiverCoin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.SpecificLiverCoin.displayName = 'proto.SpecificLiverCoin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoinAmount = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoinAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CoinAmount.displayName = 'proto.CoinAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoinPurchasePlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoinPurchasePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CoinPurchasePlan.displayName = 'proto.CoinPurchasePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WebCoinPurchasePlan = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WebCoinPurchasePlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.WebCoinPurchasePlan.displayName = 'proto.WebCoinPurchasePlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoinEarningRate = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoinEarningRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CoinEarningRate.displayName = 'proto.CoinEarningRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoinEarningRate.Star = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoinEarningRate.Star, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.CoinEarningRate.Star.displayName = 'proto.CoinEarningRate.Star';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CoinBalance.prototype.toObject = function (opt_includeInstance) {
        return proto.CoinBalance.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CoinBalance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CoinBalance.toObject = function (includeInstance, msg) {
        var f, obj = {
            userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            totalAmount: (f = msg.getTotalAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            paidAmount: (f = msg.getPaidAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            fromDiamondAmount: (f = msg.getFromDiamondAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            discountAmount: (f = msg.getDiscountAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            doublePaidAmount: (f = msg.getDoublePaidAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            freeAmount: (f = msg.getFreeAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
            updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoinBalance}
 */
proto.CoinBalance.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CoinBalance;
    return proto.CoinBalance.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoinBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoinBalance}
 */
proto.CoinBalance.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserId(value);
                break;
            case 2:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setTotalAmount(value);
                break;
            case 3:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setPaidAmount(value);
                break;
            case 7:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setFromDiamondAmount(value);
                break;
            case 8:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setDiscountAmount(value);
                break;
            case 9:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setDoublePaidAmount(value);
                break;
            case 4:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setFreeAmount(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setCreatedAt(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUpdatedAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoinBalance.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CoinBalance.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoinBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoinBalance.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTotalAmount();
    if (f != null) {
        writer.writeMessage(2, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getPaidAmount();
    if (f != null) {
        writer.writeMessage(3, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getFromDiamondAmount();
    if (f != null) {
        writer.writeMessage(7, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getDiscountAmount();
    if (f != null) {
        writer.writeMessage(8, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getDoublePaidAmount();
    if (f != null) {
        writer.writeMessage(9, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getFreeAmount();
    if (f != null) {
        writer.writeMessage(4, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getCreatedAt();
    if (f !== 0) {
        writer.writeInt64(5, f);
    }
    f = message.getUpdatedAt();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};
/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.CoinBalance.prototype.getUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.setUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional CoinAmount total_amount = 2;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getTotalAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 2));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setTotalAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearTotalAmount = function () {
    return this.setTotalAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasTotalAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional CoinAmount paid_amount = 3;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getPaidAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 3));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setPaidAmount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearPaidAmount = function () {
    return this.setPaidAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasPaidAmount = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional CoinAmount from_diamond_amount = 7;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getFromDiamondAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 7));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setFromDiamondAmount = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearFromDiamondAmount = function () {
    return this.setFromDiamondAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasFromDiamondAmount = function () {
    return jspb.Message.getField(this, 7) != null;
};
/**
 * optional CoinAmount discount_amount = 8;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getDiscountAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 8));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setDiscountAmount = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearDiscountAmount = function () {
    return this.setDiscountAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasDiscountAmount = function () {
    return jspb.Message.getField(this, 8) != null;
};
/**
 * optional CoinAmount double_paid_amount = 9;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getDoublePaidAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 9));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setDoublePaidAmount = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearDoublePaidAmount = function () {
    return this.setDoublePaidAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasDoublePaidAmount = function () {
    return jspb.Message.getField(this, 9) != null;
};
/**
 * optional CoinAmount free_amount = 4;
 * @return {?proto.CoinAmount}
 */
proto.CoinBalance.prototype.getFreeAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 4));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinBalance} returns this
*/
proto.CoinBalance.prototype.setFreeAmount = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.clearFreeAmount = function () {
    return this.setFreeAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinBalance.prototype.hasFreeAmount = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.CoinBalance.prototype.getCreatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};
/**
 * @param {number} value
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.CoinBalance.prototype.getUpdatedAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.CoinBalance} returns this
 */
proto.CoinBalance.prototype.setUpdatedAt = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.SpecificLiverCoin.prototype.toObject = function (opt_includeInstance) {
        return proto.SpecificLiverCoin.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SpecificLiverCoin} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.SpecificLiverCoin.toObject = function (includeInstance, msg) {
        var f, obj = {
            liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            coinAmount: (f = msg.getCoinAmount()) && proto.CoinAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SpecificLiverCoin}
 */
proto.SpecificLiverCoin.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.SpecificLiverCoin;
    return proto.SpecificLiverCoin.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SpecificLiverCoin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SpecificLiverCoin}
 */
proto.SpecificLiverCoin.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setLiverId(value);
                break;
            case 2:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setCoinAmount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SpecificLiverCoin.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.SpecificLiverCoin.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SpecificLiverCoin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SpecificLiverCoin.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLiverId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCoinAmount();
    if (f != null) {
        writer.writeMessage(2, f, proto.CoinAmount.serializeBinaryToWriter);
    }
};
/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.SpecificLiverCoin.prototype.getLiverId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.SpecificLiverCoin} returns this
 */
proto.SpecificLiverCoin.prototype.setLiverId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional CoinAmount coin_amount = 2;
 * @return {?proto.CoinAmount}
 */
proto.SpecificLiverCoin.prototype.getCoinAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 2));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.SpecificLiverCoin} returns this
*/
proto.SpecificLiverCoin.prototype.setCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.SpecificLiverCoin} returns this
 */
proto.SpecificLiverCoin.prototype.clearCoinAmount = function () {
    return this.setCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SpecificLiverCoin.prototype.hasCoinAmount = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CoinAmount.prototype.toObject = function (opt_includeInstance) {
        return proto.CoinAmount.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CoinAmount} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CoinAmount.toObject = function (includeInstance, msg) {
        var f, obj = {
            amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
            amountText: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoinAmount}
 */
proto.CoinAmount.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CoinAmount;
    return proto.CoinAmount.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoinAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoinAmount}
 */
proto.CoinAmount.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());
                msg.setAmount(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setAmountText(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoinAmount.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CoinAmount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoinAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoinAmount.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAmount();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }
    f = message.getAmountText();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional double amount = 1;
 * @return {number}
 */
proto.CoinAmount.prototype.getAmount = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};
/**
 * @param {number} value
 * @return {!proto.CoinAmount} returns this
 */
proto.CoinAmount.prototype.setAmount = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};
/**
 * optional string amount_text = 2;
 * @return {string}
 */
proto.CoinAmount.prototype.getAmountText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinAmount} returns this
 */
proto.CoinAmount.prototype.setAmountText = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CoinPurchasePlan.prototype.toObject = function (opt_includeInstance) {
        return proto.CoinPurchasePlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CoinPurchasePlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CoinPurchasePlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            storeProductId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            coinAmount: (f = msg.getCoinAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            overpricedCoinAmount: (f = msg.getOverpricedCoinAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            overpricedStoreProductId: jspb.Message.getFieldWithDefault(msg, 5, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoinPurchasePlan}
 */
proto.CoinPurchasePlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CoinPurchasePlan;
    return proto.CoinPurchasePlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoinPurchasePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoinPurchasePlan}
 */
proto.CoinPurchasePlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setStoreProductId(value);
                break;
            case 3:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setCoinAmount(value);
                break;
            case 4:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setOverpricedCoinAmount(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());
                msg.setOverpricedStoreProductId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoinPurchasePlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CoinPurchasePlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoinPurchasePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoinPurchasePlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getStoreProductId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCoinAmount();
    if (f != null) {
        writer.writeMessage(3, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getOverpricedCoinAmount();
    if (f != null) {
        writer.writeMessage(4, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getOverpricedStoreProductId();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};
/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.CoinPurchasePlan.prototype.getPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinPurchasePlan} returns this
 */
proto.CoinPurchasePlan.prototype.setPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string store_product_id = 2;
 * @return {string}
 */
proto.CoinPurchasePlan.prototype.getStoreProductId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinPurchasePlan} returns this
 */
proto.CoinPurchasePlan.prototype.setStoreProductId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional CoinAmount coin_amount = 3;
 * @return {?proto.CoinAmount}
 */
proto.CoinPurchasePlan.prototype.getCoinAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 3));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinPurchasePlan} returns this
*/
proto.CoinPurchasePlan.prototype.setCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinPurchasePlan} returns this
 */
proto.CoinPurchasePlan.prototype.clearCoinAmount = function () {
    return this.setCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinPurchasePlan.prototype.hasCoinAmount = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional CoinAmount overpriced_coin_amount = 4;
 * @return {?proto.CoinAmount}
 */
proto.CoinPurchasePlan.prototype.getOverpricedCoinAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 4));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.CoinPurchasePlan} returns this
*/
proto.CoinPurchasePlan.prototype.setOverpricedCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinPurchasePlan} returns this
 */
proto.CoinPurchasePlan.prototype.clearOverpricedCoinAmount = function () {
    return this.setOverpricedCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinPurchasePlan.prototype.hasOverpricedCoinAmount = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * optional string overpriced_store_product_id = 5;
 * @return {string}
 */
proto.CoinPurchasePlan.prototype.getOverpricedStoreProductId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinPurchasePlan} returns this
 */
proto.CoinPurchasePlan.prototype.setOverpricedStoreProductId = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.WebCoinPurchasePlan.prototype.toObject = function (opt_includeInstance) {
        return proto.WebCoinPurchasePlan.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.WebCoinPurchasePlan} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.WebCoinPurchasePlan.toObject = function (includeInstance, msg) {
        var f, obj = {
            planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            planName: jspb.Message.getFieldWithDefault(msg, 2, ""),
            priceJpy: jspb.Message.getFieldWithDefault(msg, 3, 0),
            originalPriceJpy: jspb.Message.getFieldWithDefault(msg, 4, 0),
            coinAmount: (f = msg.getCoinAmount()) && proto.CoinAmount.toObject(includeInstance, f),
            acquireStarLv: jspb.Message.getFieldWithDefault(msg, 6, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WebCoinPurchasePlan}
 */
proto.WebCoinPurchasePlan.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.WebCoinPurchasePlan;
    return proto.WebCoinPurchasePlan.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WebCoinPurchasePlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WebCoinPurchasePlan}
 */
proto.WebCoinPurchasePlan.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPlanId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPlanName(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setPriceJpy(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setOriginalPriceJpy(value);
                break;
            case 5:
                var value = new proto.CoinAmount;
                reader.readMessage(value, proto.CoinAmount.deserializeBinaryFromReader);
                msg.setCoinAmount(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setAcquireStarLv(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WebCoinPurchasePlan.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.WebCoinPurchasePlan.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WebCoinPurchasePlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WebCoinPurchasePlan.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPlanId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPlanName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getPriceJpy();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getOriginalPriceJpy();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
    f = message.getCoinAmount();
    if (f != null) {
        writer.writeMessage(5, f, proto.CoinAmount.serializeBinaryToWriter);
    }
    f = message.getAcquireStarLv();
    if (f !== 0) {
        writer.writeInt64(6, f);
    }
};
/**
 * optional string plan_id = 1;
 * @return {string}
 */
proto.WebCoinPurchasePlan.prototype.getPlanId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.setPlanId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string plan_name = 2;
 * @return {string}
 */
proto.WebCoinPurchasePlan.prototype.getPlanName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.setPlanName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 price_jpy = 3;
 * @return {number}
 */
proto.WebCoinPurchasePlan.prototype.getPriceJpy = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.setPriceJpy = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 original_price_jpy = 4;
 * @return {number}
 */
proto.WebCoinPurchasePlan.prototype.getOriginalPriceJpy = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.setOriginalPriceJpy = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional CoinAmount coin_amount = 5;
 * @return {?proto.CoinAmount}
 */
proto.WebCoinPurchasePlan.prototype.getCoinAmount = function () {
    return /** @type{?proto.CoinAmount} */ (jspb.Message.getWrapperField(this, proto.CoinAmount, 5));
};
/**
 * @param {?proto.CoinAmount|undefined} value
 * @return {!proto.WebCoinPurchasePlan} returns this
*/
proto.WebCoinPurchasePlan.prototype.setCoinAmount = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.clearCoinAmount = function () {
    return this.setCoinAmount(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.WebCoinPurchasePlan.prototype.hasCoinAmount = function () {
    return jspb.Message.getField(this, 5) != null;
};
/**
 * optional int64 acquire_star_lv = 6;
 * @return {number}
 */
proto.WebCoinPurchasePlan.prototype.getAcquireStarLv = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};
/**
 * @param {number} value
 * @return {!proto.WebCoinPurchasePlan} returns this
 */
proto.WebCoinPurchasePlan.prototype.setAcquireStarLv = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CoinEarningRate.prototype.toObject = function (opt_includeInstance) {
        return proto.CoinEarningRate.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CoinEarningRate} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CoinEarningRate.toObject = function (includeInstance, msg) {
        var f, obj = {
            webCoinPurchaseRate: jspb.Message.getFieldWithDefault(msg, 1, ""),
            starLv1: (f = msg.getStarLv1()) && proto.CoinEarningRate.Star.toObject(includeInstance, f),
            starLv2: (f = msg.getStarLv2()) && proto.CoinEarningRate.Star.toObject(includeInstance, f),
            starLv3: (f = msg.getStarLv3()) && proto.CoinEarningRate.Star.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoinEarningRate}
 */
proto.CoinEarningRate.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CoinEarningRate;
    return proto.CoinEarningRate.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoinEarningRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoinEarningRate}
 */
proto.CoinEarningRate.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setWebCoinPurchaseRate(value);
                break;
            case 2:
                var value = new proto.CoinEarningRate.Star;
                reader.readMessage(value, proto.CoinEarningRate.Star.deserializeBinaryFromReader);
                msg.setStarLv1(value);
                break;
            case 3:
                var value = new proto.CoinEarningRate.Star;
                reader.readMessage(value, proto.CoinEarningRate.Star.deserializeBinaryFromReader);
                msg.setStarLv2(value);
                break;
            case 4:
                var value = new proto.CoinEarningRate.Star;
                reader.readMessage(value, proto.CoinEarningRate.Star.deserializeBinaryFromReader);
                msg.setStarLv3(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoinEarningRate.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CoinEarningRate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoinEarningRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoinEarningRate.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getWebCoinPurchaseRate();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getStarLv1();
    if (f != null) {
        writer.writeMessage(2, f, proto.CoinEarningRate.Star.serializeBinaryToWriter);
    }
    f = message.getStarLv2();
    if (f != null) {
        writer.writeMessage(3, f, proto.CoinEarningRate.Star.serializeBinaryToWriter);
    }
    f = message.getStarLv3();
    if (f != null) {
        writer.writeMessage(4, f, proto.CoinEarningRate.Star.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.CoinEarningRate.Star.prototype.toObject = function (opt_includeInstance) {
        return proto.CoinEarningRate.Star.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.CoinEarningRate.Star} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.CoinEarningRate.Star.toObject = function (includeInstance, msg) {
        var f, obj = {
            isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
            expiresAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            isRateSatisfied: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoinEarningRate.Star}
 */
proto.CoinEarningRate.Star.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.CoinEarningRate.Star;
    return proto.CoinEarningRate.Star.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoinEarningRate.Star} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoinEarningRate.Star}
 */
proto.CoinEarningRate.Star.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsEnabled(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setExpiresAt(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsExpired(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsRateSatisfied(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoinEarningRate.Star.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.CoinEarningRate.Star.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoinEarningRate.Star} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoinEarningRate.Star.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getIsEnabled();
    if (f) {
        writer.writeBool(1, f);
    }
    f = message.getExpiresAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getIsExpired();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getIsRateSatisfied();
    if (f) {
        writer.writeBool(4, f);
    }
};
/**
 * optional bool is_enabled = 1;
 * @return {boolean}
 */
proto.CoinEarningRate.Star.prototype.getIsEnabled = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};
/**
 * @param {boolean} value
 * @return {!proto.CoinEarningRate.Star} returns this
 */
proto.CoinEarningRate.Star.prototype.setIsEnabled = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional int64 expires_at = 2;
 * @return {number}
 */
proto.CoinEarningRate.Star.prototype.getExpiresAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.CoinEarningRate.Star} returns this
 */
proto.CoinEarningRate.Star.prototype.setExpiresAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional bool is_expired = 3;
 * @return {boolean}
 */
proto.CoinEarningRate.Star.prototype.getIsExpired = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.CoinEarningRate.Star} returns this
 */
proto.CoinEarningRate.Star.prototype.setIsExpired = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional bool is_rate_satisfied = 4;
 * @return {boolean}
 */
proto.CoinEarningRate.Star.prototype.getIsRateSatisfied = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.CoinEarningRate.Star} returns this
 */
proto.CoinEarningRate.Star.prototype.setIsRateSatisfied = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional string web_coin_purchase_rate = 1;
 * @return {string}
 */
proto.CoinEarningRate.prototype.getWebCoinPurchaseRate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.CoinEarningRate} returns this
 */
proto.CoinEarningRate.prototype.setWebCoinPurchaseRate = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Star star_lv1 = 2;
 * @return {?proto.CoinEarningRate.Star}
 */
proto.CoinEarningRate.prototype.getStarLv1 = function () {
    return /** @type{?proto.CoinEarningRate.Star} */ (jspb.Message.getWrapperField(this, proto.CoinEarningRate.Star, 2));
};
/**
 * @param {?proto.CoinEarningRate.Star|undefined} value
 * @return {!proto.CoinEarningRate} returns this
*/
proto.CoinEarningRate.prototype.setStarLv1 = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinEarningRate} returns this
 */
proto.CoinEarningRate.prototype.clearStarLv1 = function () {
    return this.setStarLv1(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinEarningRate.prototype.hasStarLv1 = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Star star_lv2 = 3;
 * @return {?proto.CoinEarningRate.Star}
 */
proto.CoinEarningRate.prototype.getStarLv2 = function () {
    return /** @type{?proto.CoinEarningRate.Star} */ (jspb.Message.getWrapperField(this, proto.CoinEarningRate.Star, 3));
};
/**
 * @param {?proto.CoinEarningRate.Star|undefined} value
 * @return {!proto.CoinEarningRate} returns this
*/
proto.CoinEarningRate.prototype.setStarLv2 = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinEarningRate} returns this
 */
proto.CoinEarningRate.prototype.clearStarLv2 = function () {
    return this.setStarLv2(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinEarningRate.prototype.hasStarLv2 = function () {
    return jspb.Message.getField(this, 3) != null;
};
/**
 * optional Star star_lv3 = 4;
 * @return {?proto.CoinEarningRate.Star}
 */
proto.CoinEarningRate.prototype.getStarLv3 = function () {
    return /** @type{?proto.CoinEarningRate.Star} */ (jspb.Message.getWrapperField(this, proto.CoinEarningRate.Star, 4));
};
/**
 * @param {?proto.CoinEarningRate.Star|undefined} value
 * @return {!proto.CoinEarningRate} returns this
*/
proto.CoinEarningRate.prototype.setStarLv3 = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CoinEarningRate} returns this
 */
proto.CoinEarningRate.prototype.clearStarLv3 = function () {
    return this.setStarLv3(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoinEarningRate.prototype.hasStarLv3 = function () {
    return jspb.Message.getField(this, 4) != null;
};
/**
 * @enum {number}
 */
proto.MembershipType = {
    MEMBERSHIP_TYPE_1_MONTH: 0,
    MEMBERSHIP_TYPE_3_MONTHS: 1,
    MEMBERSHIP_TYPE_6_MONTHS: 2
};
goog.object.extend(exports, proto);
