import { UAParser } from 'ua-parser-js';
var storageKey = 'sc_device_info';
var isServer = function () { return typeof window === 'undefined'; };
// LocalStorageに設定する
export var setDeviceInfoFromNativeApp = function (info) {
    if (isServer())
        return;
    localStorage.setItem(storageKey, JSON.stringify(info));
};
// LocalStorageからDeviceInfoを読み取る
var readDeviceInfoFromStorage = function () {
    if (isServer())
        return;
    var str = localStorage.getItem(storageKey);
    if (!str)
        return;
    try {
        return JSON.parse(str);
    }
    catch (_a) {
        return;
    }
};
export var buildGrpcHeader = function () {
    var info = readDeviceInfoFromStorage();
    var uaInfo = buildDeviceInfoFromUA();
    var headers = {
        'X-SINGCOLOR-DEVICE-ID': info === null || info === void 0 ? void 0 : info.deviceId,
        'X-SINGCOLOR-PLATFORM': 'Web',
        'X-SINGCOLOR-OS': (info === null || info === void 0 ? void 0 : info.os) || (uaInfo === null || uaInfo === void 0 ? void 0 : uaInfo.os),
        'X-SINGCOLOR-OS-VERSION': (info === null || info === void 0 ? void 0 : info.osVersion) || (uaInfo === null || uaInfo === void 0 ? void 0 : uaInfo.osVersion),
        'X-SINGCOLOR-MODEL': (info === null || info === void 0 ? void 0 : info.deviceModel) || (uaInfo === null || uaInfo === void 0 ? void 0 : uaInfo.device),
        'X-SINGCOLOR-APP-VERSION': info === null || info === void 0 ? void 0 : info.appVersion,
        'X-SINGCOLOR-APP-VERSION-CODE': info === null || info === void 0 ? void 0 : info.appVersionCode,
        'X-SINGCOLOR-TIMEZONE': (info === null || info === void 0 ? void 0 : info.timezone) || Intl.DateTimeFormat().resolvedOptions().timeZone,
        'X-SINGCOLOR-LANGUAGE': (info === null || info === void 0 ? void 0 : info.language) || getLanguage(),
        'X-SINGCOLOR-LOCATION': info === null || info === void 0 ? void 0 : info.location,
        'X-SINGCOLOR-BROWSER': uaInfo === null || uaInfo === void 0 ? void 0 : uaInfo.browser
    };
    return Object.keys(headers).reduce(function (p, c) {
        var value = headers[c];
        if (value) {
            p[c] = value;
        }
        return p;
    }, {});
};
var buildDeviceInfoFromUA = function () {
    if (isServer())
        return;
    var ua = navigator.userAgent;
    var parser = new UAParser(ua);
    var result = parser.getResult();
    return {
        os: result.os.name,
        osVersion: result.os.version,
        device: [result.device.model, result.device.vendor, result.device.type].map(function (n) { return n || ''; }).join(' '),
        browser: [result.browser.name, result.browser.version].map(function (n) { return n !== null && n !== void 0 ? n : ''; }).join(' ')
    };
};
var getLanguage = function () {
    if (isServer())
        return;
    return navigator.language;
};
