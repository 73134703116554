"use strict";
// source: ope_maintenance_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var maintenance_entity_pb = require('./maintenance_entity_pb.js');
goog.object.extend(proto, maintenance_entity_pb);
goog.exportSymbol('proto.jp.singcolor.OpeGetMaintenanceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetMaintenanceV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMaintenanceV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule', null, global);
goog.exportSymbol('proto.jp.singcolor.UpdateMaintenanceV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetMaintenanceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetMaintenanceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Request.displayName = 'proto.jp.singcolor.OpeGetMaintenanceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetMaintenanceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Response.displayName = 'proto.jp.singcolor.OpeGetMaintenanceV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMaintenanceV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMaintenanceV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.displayName = 'proto.jp.singcolor.UpdateMaintenanceV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.displayName = 'proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.UpdateMaintenanceV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.UpdateMaintenanceV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.UpdateMaintenanceV1Response.displayName = 'proto.jp.singcolor.UpdateMaintenanceV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetMaintenanceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Request}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetMaintenanceV1Request;
    return proto.jp.singcolor.OpeGetMaintenanceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Request}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetMaintenanceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetMaintenanceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetMaintenanceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetMaintenanceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            maintenance: (f = msg.getMaintenance()) && maintenance_entity_pb.MaintenanceSchedule.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Response}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetMaintenanceV1Response;
    return proto.jp.singcolor.OpeGetMaintenanceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Response}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new maintenance_entity_pb.MaintenanceSchedule;
                reader.readMessage(value, maintenance_entity_pb.MaintenanceSchedule.deserializeBinaryFromReader);
                msg.setMaintenance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetMaintenanceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetMaintenanceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMaintenance();
    if (f != null) {
        writer.writeMessage(1, f, maintenance_entity_pb.MaintenanceSchedule.serializeBinaryToWriter);
    }
};
/**
 * optional MaintenanceSchedule maintenance = 1;
 * @return {?proto.jp.singcolor.MaintenanceSchedule}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.getMaintenance = function () {
    return /** @type{?proto.jp.singcolor.MaintenanceSchedule} */ (jspb.Message.getWrapperField(this, maintenance_entity_pb.MaintenanceSchedule, 1));
};
/**
 * @param {?proto.jp.singcolor.MaintenanceSchedule|undefined} value
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Response} returns this
*/
proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.setMaintenance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetMaintenanceV1Response} returns this
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.clearMaintenance = function () {
    return this.setMaintenance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetMaintenanceV1Response.prototype.hasMaintenance = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMaintenanceV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            maintenance: (f = msg.getMaintenance()) && proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMaintenanceV1Request;
    return proto.jp.singcolor.UpdateMaintenanceV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule;
                reader.readMessage(value, proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.deserializeBinaryFromReader);
                msg.setMaintenance(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMaintenanceV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMaintenance();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.serializeBinaryToWriter);
    }
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.toObject = function (includeInstance, msg) {
        var f, obj = {
            beginAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
            endAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule;
    return proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setBeginAt(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setEndAt(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getBeginAt();
    if (f !== 0) {
        writer.writeInt64(1, f);
    }
    f = message.getEndAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};
/**
 * optional int64 begin_at = 1;
 * @return {number}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.getBeginAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} returns this
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.setBeginAt = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 end_at = 2;
 * @return {number}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.getEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} returns this
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule.prototype.setEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional MaintenanceSchedule maintenance = 1;
 * @return {?proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.getMaintenance = function () {
    return /** @type{?proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule, 1));
};
/**
 * @param {?proto.jp.singcolor.UpdateMaintenanceV1Request.MaintenanceSchedule|undefined} value
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request} returns this
*/
proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.setMaintenance = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Request} returns this
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.clearMaintenance = function () {
    return this.setMaintenance(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.UpdateMaintenanceV1Request.prototype.hasMaintenance = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.UpdateMaintenanceV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.UpdateMaintenanceV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.UpdateMaintenanceV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.UpdateMaintenanceV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Response}
 */
proto.jp.singcolor.UpdateMaintenanceV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.UpdateMaintenanceV1Response;
    return proto.jp.singcolor.UpdateMaintenanceV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.UpdateMaintenanceV1Response}
 */
proto.jp.singcolor.UpdateMaintenanceV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.UpdateMaintenanceV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.UpdateMaintenanceV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.UpdateMaintenanceV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.UpdateMaintenanceV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
goog.object.extend(exports, proto.jp.singcolor);
