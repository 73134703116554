"use strict";
// source: ope_organization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var organization_entity_pb = require('./organization_entity_pb.js');
goog.object.extend(proto, organization_entity_pb);
var page_entity_pb = require('./page_entity_pb.js');
goog.object.extend(proto, page_entity_pb);
goog.exportSymbol('proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetOrganizationV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeGetOrganizationV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListOrganizationsV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeListOrganizationsV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeOrganization', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeUpdateOrganizationRequest', null, global);
goog.exportSymbol('proto.jp.singcolor.OpeUpdateOrganizationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeOrganization = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeOrganization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeOrganization.displayName = 'proto.jp.singcolor.OpeOrganization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.displayName = 'proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.displayName = 'proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeUpdateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeUpdateOrganizationRequest.displayName = 'proto.jp.singcolor.OpeUpdateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeUpdateOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeUpdateOrganizationResponse.displayName = 'proto.jp.singcolor.OpeUpdateOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetOrganizationV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetOrganizationV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetOrganizationV1Request.displayName = 'proto.jp.singcolor.OpeGetOrganizationV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeGetOrganizationV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeGetOrganizationV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeGetOrganizationV1Response.displayName = 'proto.jp.singcolor.OpeGetOrganizationV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListOrganizationsV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.OpeListOrganizationsV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListOrganizationsV1Request.displayName = 'proto.jp.singcolor.OpeListOrganizationsV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.OpeListOrganizationsV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.OpeListOrganizationsV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.OpeListOrganizationsV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.OpeListOrganizationsV1Response.displayName = 'proto.jp.singcolor.OpeListOrganizationsV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeOrganization.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeOrganization.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeOrganization} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeOrganization.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && organization_entity_pb.Organization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeOrganization}
 */
proto.jp.singcolor.OpeOrganization.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeOrganization;
    return proto.jp.singcolor.OpeOrganization.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeOrganization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeOrganization}
 */
proto.jp.singcolor.OpeOrganization.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeOrganization.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeOrganization.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeOrganization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeOrganization.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
};
/**
 * optional Organization organization = 1;
 * @return {?proto.Organization}
 */
proto.jp.singcolor.OpeOrganization.prototype.getOrganization = function () {
    return /** @type{?proto.Organization} */ (jspb.Message.getWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {?proto.Organization|undefined} value
 * @return {!proto.jp.singcolor.OpeOrganization} returns this
*/
proto.jp.singcolor.OpeOrganization.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeOrganization} returns this
 */
proto.jp.singcolor.OpeOrganization.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeOrganization.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            ownerUserId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest;
    return proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOwnerUserId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOwnerUserId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string owner_user_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.prototype.getOwnerUserId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest} returns this
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDRequest.prototype.setOwnerUserId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.OpeOrganization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse;
    return proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeOrganization;
                reader.readMessage(value, proto.jp.singcolor.OpeOrganization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.OpeOrganization.serializeBinaryToWriter);
    }
};
/**
 * optional OpeOrganization organization = 1;
 * @return {?proto.jp.singcolor.OpeOrganization}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.OpeOrganization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OpeOrganization, 1));
};
/**
 * @param {?proto.jp.singcolor.OpeOrganization|undefined} value
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse} returns this
*/
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse} returns this
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetOrganizationByOwnerUserIDResponse.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeUpdateOrganizationRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeUpdateOrganizationRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeUpdateOrganizationRequest.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            diamondRewardValidityStartAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
            diamondRewardValidityEndAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
            diamondRewardRate10000x: jspb.Message.getFieldWithDefault(msg, 4, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeUpdateOrganizationRequest;
    return proto.jp.singcolor.OpeUpdateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeUpdateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardValidityStartAt(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardValidityEndAt(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setDiamondRewardRate10000x(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeUpdateOrganizationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeUpdateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getDiamondRewardValidityStartAt();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
    f = message.getDiamondRewardValidityEndAt();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
    f = message.getDiamondRewardRate10000x();
    if (f !== 0) {
        writer.writeInt64(4, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest} returns this
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 diamond_reward_validity_start_at = 2;
 * @return {number}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.getDiamondRewardValidityStartAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest} returns this
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.setDiamondRewardValidityStartAt = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int64 diamond_reward_validity_end_at = 3;
 * @return {number}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.getDiamondRewardValidityEndAt = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest} returns this
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.setDiamondRewardValidityEndAt = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int64 diamond_reward_rate_10000x = 4;
 * @return {number}
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.getDiamondRewardRate10000x = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationRequest} returns this
 */
proto.jp.singcolor.OpeUpdateOrganizationRequest.prototype.setDiamondRewardRate10000x = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeUpdateOrganizationResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeUpdateOrganizationResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeUpdateOrganizationResponse.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.OpeOrganization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationResponse}
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeUpdateOrganizationResponse;
    return proto.jp.singcolor.OpeUpdateOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeUpdateOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationResponse}
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeOrganization;
                reader.readMessage(value, proto.jp.singcolor.OpeOrganization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeUpdateOrganizationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeUpdateOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.OpeOrganization.serializeBinaryToWriter);
    }
};
/**
 * optional OpeOrganization organization = 1;
 * @return {?proto.jp.singcolor.OpeOrganization}
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.OpeOrganization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OpeOrganization, 1));
};
/**
 * @param {?proto.jp.singcolor.OpeOrganization|undefined} value
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationResponse} returns this
*/
proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeUpdateOrganizationResponse} returns this
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeUpdateOrganizationResponse.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetOrganizationV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetOrganizationV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetOrganizationV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetOrganizationV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationId: jspb.Message.getFieldWithDefault(msg, 1, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Request}
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetOrganizationV1Request;
    return proto.jp.singcolor.OpeGetOrganizationV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetOrganizationV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Request}
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setOrganizationId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetOrganizationV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetOrganizationV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};
/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.prototype.getOrganizationId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Request} returns this
 */
proto.jp.singcolor.OpeGetOrganizationV1Request.prototype.setOrganizationId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeGetOrganizationV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeGetOrganizationV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeGetOrganizationV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organization: (f = msg.getOrganization()) && proto.jp.singcolor.OpeOrganization.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Response}
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeGetOrganizationV1Response;
    return proto.jp.singcolor.OpeGetOrganizationV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeGetOrganizationV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Response}
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.jp.singcolor.OpeOrganization;
                reader.readMessage(value, proto.jp.singcolor.OpeOrganization.deserializeBinaryFromReader);
                msg.setOrganization(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeGetOrganizationV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeGetOrganizationV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganization();
    if (f != null) {
        writer.writeMessage(1, f, proto.jp.singcolor.OpeOrganization.serializeBinaryToWriter);
    }
};
/**
 * optional OpeOrganization organization = 1;
 * @return {?proto.jp.singcolor.OpeOrganization}
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.getOrganization = function () {
    return /** @type{?proto.jp.singcolor.OpeOrganization} */ (jspb.Message.getWrapperField(this, proto.jp.singcolor.OpeOrganization, 1));
};
/**
 * @param {?proto.jp.singcolor.OpeOrganization|undefined} value
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Response} returns this
*/
proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.setOrganization = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeGetOrganizationV1Response} returns this
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.clearOrganization = function () {
    return this.setOrganization(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeGetOrganizationV1Response.prototype.hasOrganization = function () {
    return jspb.Message.getField(this, 1) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListOrganizationsV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListOrganizationsV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListOrganizationsV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationRequest.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Request}
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListOrganizationsV1Request;
    return proto.jp.singcolor.OpeListOrganizationsV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListOrganizationsV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Request}
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new page_entity_pb.SimplePaginationRequest;
                reader.readMessage(value, page_entity_pb.SimplePaginationRequest.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListOrganizationsV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListOrganizationsV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(1, f, page_entity_pb.SimplePaginationRequest.serializeBinaryToWriter);
    }
};
/**
 * optional SimplePaginationRequest page = 1;
 * @return {?proto.SimplePaginationRequest}
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationRequest} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationRequest, 1));
};
/**
 * @param {?proto.SimplePaginationRequest|undefined} value
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Request} returns this
*/
proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Request} returns this
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListOrganizationsV1Request.prototype.hasPage = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.repeatedFields_ = [1];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.OpeListOrganizationsV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.OpeListOrganizationsV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.OpeListOrganizationsV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(), organization_entity_pb.Organization.toObject, includeInstance),
            page: (f = msg.getPage()) && page_entity_pb.SimplePaginationResult.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.OpeListOrganizationsV1Response;
    return proto.jp.singcolor.OpeListOrganizationsV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.OpeListOrganizationsV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new organization_entity_pb.Organization;
                reader.readMessage(value, organization_entity_pb.Organization.deserializeBinaryFromReader);
                msg.addOrganizations(value);
                break;
            case 2:
                var value = new page_entity_pb.SimplePaginationResult;
                reader.readMessage(value, page_entity_pb.SimplePaginationResult.deserializeBinaryFromReader);
                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.OpeListOrganizationsV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.OpeListOrganizationsV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrganizationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, organization_entity_pb.Organization.serializeBinaryToWriter);
    }
    f = message.getPage();
    if (f != null) {
        writer.writeMessage(2, f, page_entity_pb.SimplePaginationResult.serializeBinaryToWriter);
    }
};
/**
 * repeated Organization organizations = 1;
 * @return {!Array<!proto.Organization>}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.getOrganizationsList = function () {
    return /** @type{!Array<!proto.Organization>} */ (jspb.Message.getRepeatedWrapperField(this, organization_entity_pb.Organization, 1));
};
/**
 * @param {!Array<!proto.Organization>} value
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response} returns this
*/
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.setOrganizationsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Organization}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.addOrganizations = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Organization, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response} returns this
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.clearOrganizationsList = function () {
    return this.setOrganizationsList([]);
};
/**
 * optional SimplePaginationResult page = 2;
 * @return {?proto.SimplePaginationResult}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.getPage = function () {
    return /** @type{?proto.SimplePaginationResult} */ (jspb.Message.getWrapperField(this, page_entity_pb.SimplePaginationResult, 2));
};
/**
 * @param {?proto.SimplePaginationResult|undefined} value
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response} returns this
*/
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.setPage = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.OpeListOrganizationsV1Response} returns this
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.clearPage = function () {
    return this.setPage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.OpeListOrganizationsV1Response.prototype.hasPage = function () {
    return jspb.Message.getField(this, 2) != null;
};
goog.object.extend(exports, proto.jp.singcolor);
