"use strict";
// source: master/violation_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.jp.singcolor.master.BanLevel', null, global);
goog.exportSymbol('proto.jp.singcolor.master.ViolationCategory', null, global);
goog.exportSymbol('proto.jp.singcolor.master.ViolationTarget', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.master.ViolationCategory = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.master.ViolationCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.master.ViolationCategory.displayName = 'proto.jp.singcolor.master.ViolationCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.master.ViolationTarget = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.master.ViolationTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.master.ViolationTarget.displayName = 'proto.jp.singcolor.master.ViolationTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.master.BanLevel = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.master.BanLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.master.BanLevel.displayName = 'proto.jp.singcolor.master.BanLevel';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.master.ViolationCategory.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.master.ViolationCategory.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.master.ViolationCategory} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.master.ViolationCategory.toObject = function (includeInstance, msg) {
        var f, obj = {
            violationCategoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            isViolationTargetOnlyLiveContent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
            canBan: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            canMask: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
            canWarnLive: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
            canForceStopLive: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.master.ViolationCategory}
 */
proto.jp.singcolor.master.ViolationCategory.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.master.ViolationCategory;
    return proto.jp.singcolor.master.ViolationCategory.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.master.ViolationCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.master.ViolationCategory}
 */
proto.jp.singcolor.master.ViolationCategory.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationCategoryId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setIsViolationTargetOnlyLiveContent(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanBan(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanMask(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanWarnLive(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanForceStopLive(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.master.ViolationCategory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.master.ViolationCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.master.ViolationCategory.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getViolationCategoryId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getIsViolationTargetOnlyLiveContent();
    if (f) {
        writer.writeBool(3, f);
    }
    f = message.getCanBan();
    if (f) {
        writer.writeBool(4, f);
    }
    f = message.getCanMask();
    if (f) {
        writer.writeBool(5, f);
    }
    f = message.getCanWarnLive();
    if (f) {
        writer.writeBool(6, f);
    }
    f = message.getCanForceStopLive();
    if (f) {
        writer.writeBool(7, f);
    }
};
/**
 * optional string violation_category_id = 1;
 * @return {string}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getViolationCategoryId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setViolationCategoryId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool is_violation_target_only_live_content = 3;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getIsViolationTargetOnlyLiveContent = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setIsViolationTargetOnlyLiveContent = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional bool can_ban = 4;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getCanBan = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setCanBan = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional bool can_mask = 5;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getCanMask = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setCanMask = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * optional bool can_warn_live = 6;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getCanWarnLive = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setCanWarnLive = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};
/**
 * optional bool can_force_stop_live = 7;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationCategory.prototype.getCanForceStopLive = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationCategory} returns this
 */
proto.jp.singcolor.master.ViolationCategory.prototype.setCanForceStopLive = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.master.ViolationTarget.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.master.ViolationTarget.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.master.ViolationTarget} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.master.ViolationTarget.toObject = function (includeInstance, msg) {
        var f, obj = {
            violationTargetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
            name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            canMask: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.master.ViolationTarget}
 */
proto.jp.singcolor.master.ViolationTarget.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.master.ViolationTarget;
    return proto.jp.singcolor.master.ViolationTarget.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.master.ViolationTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.master.ViolationTarget}
 */
proto.jp.singcolor.master.ViolationTarget.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setViolationTargetId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setCanMask(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.master.ViolationTarget.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.master.ViolationTarget.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.master.ViolationTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.master.ViolationTarget.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getViolationTargetId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCanMask();
    if (f) {
        writer.writeBool(3, f);
    }
};
/**
 * optional string violation_target_id = 1;
 * @return {string}
 */
proto.jp.singcolor.master.ViolationTarget.prototype.getViolationTargetId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.ViolationTarget} returns this
 */
proto.jp.singcolor.master.ViolationTarget.prototype.setViolationTargetId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.master.ViolationTarget.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.ViolationTarget} returns this
 */
proto.jp.singcolor.master.ViolationTarget.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool can_mask = 3;
 * @return {boolean}
 */
proto.jp.singcolor.master.ViolationTarget.prototype.getCanMask = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.master.ViolationTarget} returns this
 */
proto.jp.singcolor.master.ViolationTarget.prototype.setCanMask = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.master.BanLevel.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.master.BanLevel.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.master.BanLevel} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.master.BanLevel.toObject = function (includeInstance, msg) {
        var f, obj = {
            level: jspb.Message.getFieldWithDefault(msg, 1, 0),
            name: jspb.Message.getFieldWithDefault(msg, 2, "")
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.master.BanLevel}
 */
proto.jp.singcolor.master.BanLevel.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.master.BanLevel;
    return proto.jp.singcolor.master.BanLevel.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.master.BanLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.master.BanLevel}
 */
proto.jp.singcolor.master.BanLevel.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readUint32());
                msg.setLevel(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.master.BanLevel.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.master.BanLevel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.master.BanLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.master.BanLevel.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getLevel();
    if (f !== 0) {
        writer.writeUint32(1, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};
/**
 * optional uint32 level = 1;
 * @return {number}
 */
proto.jp.singcolor.master.BanLevel.prototype.getLevel = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.master.BanLevel} returns this
 */
proto.jp.singcolor.master.BanLevel.prototype.setLevel = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */
proto.jp.singcolor.master.BanLevel.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.master.BanLevel} returns this
 */
proto.jp.singcolor.master.BanLevel.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};
goog.object.extend(exports, proto.jp.singcolor.master);
